import React, { useState } from "react";
import "./HeaderSuperAdmin.css";
import { logo } from "../../constants/pictures/Pictures";
import { Link, useNavigate } from "react-router-dom";
import LogOutApi from "../../service/api/LogoutApi";
import Swal from "sweetalert2";
import RemoveCookie from "../../persistence/cookies/removeCookie/RemoveCookie";
import { googleLogout } from "@react-oauth/google";
import { loginResponsecode } from "../../dtos/Token.type";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";

const HeaderSuperAdmin = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const logout = () => {
    // Show the SweetAlert confirmation modal
    Swal.fire({
      html: "<h4>Are you sure you want to logout?</h4>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "NextButton", // Replace with your class name for the confirm button
        cancelButton: "CancelButton", // Replace with your class name for the cancel button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout();
      }
    });
  };
  const ResponseParsed = JSON.parse(loginResponsecode);
  const userAuthToken = GetCookie('token');
  const handleLogout = async () => {
    const AuthToken = {
      "authToken": userAuthToken,
    };
    const authTokenStringfy = JSON.stringify(AuthToken);
    const result = await LogOutApi(authTokenStringfy);
    console.log(result,"LogOutApi 1")
    if (result === true) {
      let newURL;
      if (userAuthToken) {
        if (window.location.hostname == "localhost") {
          navigate("/login");
        } else {
          newURL = `https://www.vivrn.com/login`;
          window.location.href = newURL;
        }
      }
      RemoveCookie("token");
      RemoveCookie("email");
      RemoveCookie("password");
      RemoveCookie("loginResponsecode");
      RemoveCookie("workSpaceDTO");
      RemoveCookie("userDTO");
      googleLogout();
    } else {
      // Logout failed
    }
  };
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link to="/home">
          <img src={logo} alt="logo" className="VirnLogoSuper" />
        </Link>
        <div className="NavSearch">
          <div
            className="dropdown dropdownHeaderSuperAdmin"
            style={{ position: "relative" }}
          >
            <div
              className="profileImageDiv dropdown-toggle"
              id="shareDropdown"
              data-bs-toggle="dropdown"
              aria-expanded={isOpen}
              onClick={toggleDropdown}
            >
              <i className="fa-solid fa-circle-user"></i>
            </div>
            <ul
              className={`dropdown-menu ${isOpen ? "show p-0" : "p-0"}`}
              aria-labelledby="shareDropdown"
              style={{ right: 0, left: "auto" }}
            >
              <li className="dropdown-item bgChange">
                <div className="user-profile d-flex">
                  <div className="profileImageDiv">
                    <i className="fa-solid fa-circle-user"></i>
                  </div>
                  <div className="user-details ms-3">
                    <h5 className="user-name mb-0">
                      {ResponseParsed?.emailId?.slice(0, 5)}
                    </h5>
                    <p className="user-email">{ResponseParsed?.emailId}</p>
                  </div>
                </div>
              </li>
              <li className="dropdown-item cursor-pointer editProfile">
                <span>Edit Profile</span>
              </li>
              <li className="dropdown-item cursor-pointer editProfile">
                <span>Security Settings</span>
              </li>
              <li
                className="dropdown-item cursor-pointer editProfile"
                onClick={logout}
              >
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderSuperAdmin;
