import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { loginApiUrl } from "../url/UrlConfig";

export default async function CreateWorkspaceAPI(payload) {
    const token = GetCookie('token');
    console.log(token, "tokennn")
    try {
        const response = await fetch(
            loginApiUrl() + apiUrls.workSpace.createWorkspace, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authToken": token
            },
            body: payload,
        }
        );
        const result = await response.json();
        if (result.httpStatusCode === 200) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error.message || error);
        return null;
    }
}
