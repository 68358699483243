import { enumChartTypes } from "../../../enums/enum";
import AreaChartSingle from "../../../graphHeatMap/area/areaSingle/AreaSingle";
import ClusteredColumnSingle from "../../../graphHeatMap/clusteredColumn/clusteredColumnSingle/ClusteredColumnSingle";
import DonutChartSingle from "../../../graphHeatMap/donut/donutSingle/DonutSingle";
import LineChartSingle from "../../../graphHeatMap/line/lineSingle/LineSingle";
import PieChartSingle from "../../../graphHeatMap/pie/pieSingle/PieSingle";
import React from "react";
import StackedBarSingle from "../../../graphHeatMap/stackedBar/stackedBarSingle/StackedBarSingle";
import StackedColumnSingle from "../../../graphHeatMap/stackedColumn/stackedColumnSingle/StackedColumnSingle";
import StackedColumnPercentageSingle from "../../../graphHeatMap/stackedColumnPercentage/stackedColumnPercentageSingle/StackedColumnPercenatgeSingle";
import StackedBarPercentageSingle from "../../../graphHeatMap/stackedBarPercentage/stackedBarPercentageSingle/StackedBarPercentageSingle";
import TextGraph from "../../../graphHeatMap/text/TextGraph";
import ClusteredColumnDoubleToggle from "../../../graphHeatMap/clusteredColumn/clusteredColumnDouble/ClusteredColumnDoubleToggle";
import PieDoubleToggle from "../../../graphHeatMap/pie/pieDouble/PieDoubleToggle";
import LineDoubleToggle from "../../../graphHeatMap/line/lineDouble/LineDoubleToggle";
import DonutDoubleToggle from "../../../graphHeatMap/donut/donutDouble/DonutDoubleToggle";
import AreaDoubleToggle from "../../../graphHeatMap/area/areaDouble/AreaDoubleToggle";
import StackedBarDoubleToggle from "../../../graphHeatMap/stackedBar/stackedBarDouble/StackedBarDoubleToggle";
import StackedColumnDoubleToggle from "../../../graphHeatMap/stackedColumn/stackedColumnDouble/StackedColumnDoubleToggle";
import StackedColumnPercentageDoubleToggle from "../../../graphHeatMap/stackedColumnPercentage/stackedColumnPercentageDouble/StackedColumnPercentageDoubleToggle";
import StackedBarPercentageDoubleToggle from "../../../graphHeatMap/stackedBarPercentage/stackedBarPercentageDouble/StackedBarPercentageDoubleToggle";
import ScatterChartSingle from "../../../graphHeatMap/scatterChart/scatterChartSingle/ScatterSingle";
import ScatterDoubleToggle from "../../../graphHeatMap/scatterChart/scatterChartDouble/Scattertoggle";
import TableData from "../../../graphHeatMap/table/TableData";

// resize
const Chart = ({ responseData, chartType }) => {
  switch (chartType) {
    case enumChartTypes.PIE:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <PieDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <PieChartSingle responseData={responseData} />
          ) : (
            <p>Please Select Pie Chart double group by or single group by</p>
          )}
        </>
      );
    case enumChartTypes.LINE:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <LineDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <LineChartSingle responseData={responseData} />
          ) : (
            <p>Please Select Line Chart double group by or single group by</p>
          )}
        </>
      );
    case enumChartTypes.DONUT:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <DonutDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <DonutChartSingle responseData={responseData} />
          ) : (
            <p>Please Select Donut Chart double group by or single group by</p>
          )}
        </>
      );
    case enumChartTypes.AREA:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <AreaDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <AreaChartSingle responseData={responseData} />
          ) : (
            <p>Please Select Area Chart double group by or single group by</p>
          )}
        </>
      );
    case enumChartTypes.CLUSTERED_COLUMN:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <ClusteredColumnDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <ClusteredColumnSingle responseData={responseData} />
          ) : (
            <p>
              Please Select Clustered Column double group by or single group by
            </p>
          )}
        </>
      );
    case enumChartTypes.STACKED_BAR:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <StackedBarDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <StackedBarSingle responseData={responseData} />
          ) : (
            <p>Please Select Stacked Bar double group by or single group by</p>
          )}
        </>
      );
    case enumChartTypes.STACKED_COLUMN:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <StackedColumnDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <StackedColumnSingle responseData={responseData} />
          ) : (
            <p>
              Please Select Stacked Column double group by or single group by
            </p>
          )}
        </>
      );
    case enumChartTypes.STACKED_COLUMN_100:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <StackedColumnPercentageDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <StackedColumnPercentageSingle responseData={responseData} />
          ) : (
            <p>
              Please Select Stacked column % double group by or single group by
            </p>
          )}
        </>
      );
    case enumChartTypes.STACKED_BAR_100:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <StackedBarPercentageDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <StackedBarPercentageSingle responseData={responseData} />
          ) : (
            <p>
              Please Select Stacked column % double group by or single group by
            </p>
          )}
        </>
      );
    case enumChartTypes.SCATTER:
      return (
        <>
          {responseData?.groupByMapDTOS?.length == 2 ? (
            <ScatterDoubleToggle responseData={responseData} />
          ) : responseData?.groupByMapDTOS?.length == 1 ? (
            <ScatterChartSingle responseData={responseData} />
          ) : (
            <p>Please Select Scatter double group by or single group by</p>
          )}
        </>
      );
    case enumChartTypes.TEXT:
      return (
        <>
          {responseData?.groupByMapDTOS?.length === 0 && (
            <TextGraph responseData={responseData} />
          )}
        </>
      );
      case enumChartTypes.TABLE:
      return (
        <>
          {/* {responseData?.groupByMapDTOS?.length === 1 && ( */}
            <TableData responseData={responseData} />
          {/* )} */}
        </>
      );
    default:
      return null;
  }
};

export default Chart;
