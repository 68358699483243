import { GoogleLogin } from '@react-oauth/google';
import React, { useEffect } from 'react';
const GoogleSignIn = ({ onLoginSuccess }) => {
    const handleSuccess = (credentialResponse) => {
        console.log(credentialResponse);
        onLoginSuccess(credentialResponse);
    };
    return (
        <>
            <GoogleLogin
                onSuccess={handleSuccess}
                onError={() => {
                    console.log('Login Failed');
                }}
                useOneTap={false}
                auto_select={false}
            />
        </>
    )
}
export default GoogleSignIn;