import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./DatabaseTypeModal.css";
import {
  Excel,
  GoogleDrive,
  Image7,
  mysql,
} from "../../constants/pictures/Pictures";
import ConnectData from "../../pages/dataWarehouse/connectData/ConnectData";
import { steps } from "../../dtos/Database.type";
import { orgId } from "../../util/Utils";
import { todayDate } from "../../util/todayDate";
import { icons } from "../../constants/icons/Icons";

const DatabaseTypeModal = () => {
  const [showModal, setShowModal] = useState(false);
  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);
  const [selectedDbType, setSelectedDbType] = useState("");
  const [getpayload, setGetPayload] = useState({});
  const step1Schema = Yup.object().shape({
    dbType: Yup.string().required("Please select at least one database type"),
  });

  const step2Schema = Yup.object().shape({
    host: Yup.string().required("Host is required"),
    port: Yup.number().required("Port is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
    warehouse: Yup.string(),
    database: Yup.string(),
    schema: Yup.string(),
  });

  const step3Schema = Yup.object().shape({
    // Define validation for step 3
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dbType: "",
      host: "",
      port: "",
      username: "",
      password: "",
      warehouse: "",
      database: "",
      schema: "",
    },
    validationSchema:
      currentStep === 1
        ? step1Schema
        : currentStep === 2
          ? step2Schema
          : step3Schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (currentStep === 2) {
          const valueStored = {
            host: values.host,
            port: values.port,
            username: values.username,
            password: values.password,
            dataSourceType: values.dbType,
            databaseName: values.database,
          };

          localStorage.setItem("DetailsOfDataSource", JSON.stringify(valueStored));
          const payload = {
            host: values.host,
            port: values.port,
            username: values.username,
            password: values.password,
            dataSourceType: values.dbType,
            databaseName: values.database,
            organizationDTO: {
              id: orgId,
            },
            createdDate: todayDate,
          };
          setGetPayload(payload);
        }
        if (currentStep === 3) {
          // Handle submission for the final step
        } else {
          setCompletedSteps([...completedSteps, currentStep]);
          setCurrentStep(currentStep + 1);
        }
      } catch (error) {
        console.error("Submission Error:", error);
      }
    },
  });

  const validateStep = async (stepIndex) => {
    try {
      let validationSchema;
      switch (stepIndex) {
        case 1:
          validationSchema = step1Schema;
          break;
        case 2:
          validationSchema = step2Schema;
          break;
        case 3:
          validationSchema = step3Schema;
          break;
        default:
          break;
      }
      await validationSchema.validate(formik.values, { abortEarly: false });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleStepClick = async (stepIndex) => {
    if (stepIndex < currentStep) {
      setCurrentStep(stepIndex);
    } else {
      const validationPassed = await validateStep(currentStep);
      if (validationPassed) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <div className="Customcard" onClick={handleShow}>
        <div className="card-body cursor-pointer">
          <div className="upload-data">
            <img src={icons.connectData} alt="Vector" />
            <p className="Analyticsupload-set">Connect to Datasource</p>
          </div>
          <p className="card-text">
            Use data modules to connect data from multiple Datasource
          </p>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        centered
        backdrop="static"
        size={currentStep == 2 || currentStep == 1 ? "lg" : "xl"}
        className="zIndexModal"
      >
        {(currentStep === 1 || currentStep === 2) && (
          <Modal.Body className="CustomModalBody">
            <div className="StepByStepHeading">
              {steps?.map((step, index) => (
                <div
                  key={index}
                  className={`progress-step ${completedSteps.includes(index + 1) ? "completed" : ""
                    } ${index + 1 === currentStep ? "active" : ""}`}
                  onClick={() => handleStepClick(index + 1)}
                >
                  <div className="Index">{index + 1}</div>
                  <div className="Label">{step?.label}</div>
                  <div className="borderBottom"></div>
                </div>
              ))}
            </div>

            <form onSubmit={formik.handleSubmit}>
              {currentStep === 1 && (
                <>
                  <div className="firstStepContainer">
                    <h5 className="text-center">Choose a database type</h5>
                    <div className="row">
                      <div className="col-md-3 col-sm-4 col-6">
                        <div
                          className={`DatabaseTypeBox ${formik.values.dbType === "Google Drive"
                              ? "selected"
                              : ""
                            }`}
                        >
                          <input
                            type="radio"
                            id="googleDrive"
                            name="dbType"
                            value="Google Drive"
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="googleDrive">
                            <img
                              className="dataTypeImage"
                              src={GoogleDrive}
                              alt="Google Drive"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div
                          className={`DatabaseTypeBox ${formik.values.dbType === "Excel" ? "selected" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            id="excel"
                            name="dbType"
                            value="Excel"
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="excel">
                            <img
                              className="dataTypeImage"
                              src={Excel}
                              alt="Excel"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div
                          className={`DatabaseTypeBox ${formik.values.dbType === "Image7" ? "selected" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            id="image7"
                            name="dbType"
                            value="Image7"
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="image7">
                            <img
                              className="dataTypeImage"
                              src={Image7}
                              alt="Image 7"
                            />
                          </label>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-4 col-6">
                        <div
                          className={`DatabaseTypeBox ${formik.values.dbType === "MYSQL" ? "selected" : ""
                            }`}
                        >
                          <input
                            type="radio"
                            id="mysql"
                            name="dbType"
                            value="MYSQL"
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="mysql">
                            <img
                              className="dataTypeImage"
                              src={mysql}
                              alt="MYSQL"
                            />
                          </label>
                        </div>
                      </div>
                      {/* Repeat the above block for other database types */}
                    </div>
                  </div>
                </>
              )}

              {currentStep === 2 && (
                <div className="secondStepContainer">
                  <h5 className="text-center">Enter Credentials</h5>
                  <div className="InputBox">
                    <label className="LabelInput">Database Type </label>
                    <div className="DatabaseTypeShow">
                      <h6 className="mb-0">{formik.values.dbType}</h6>
                      <button
                        type="button"
                        className="whiteButton"
                        onClick={handlePrevious}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8">
                      <div className="InputBox mt20">
                        <label className="LabelInput">Host*</label>
                        <div className="CommonInput">
                          <input
                            type="text"
                            name="host"
                            onChange={formik.handleChange}
                            value={formik.values.host}
                          />
                          {formik.touched.host && formik.errors.host && (
                            <div className="ErrorMessage">
                              {formik.errors.host}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="InputBox mt20">
                        <label className="LabelInput">Port*</label>
                        <div className="CommonInput">
                          <input
                            type="number"
                            name="port"
                            onChange={formik.handleChange}
                            value={formik.values.port}
                          />
                          {formik.touched.port && formik.errors.port && (
                            <div className="ErrorMessage">
                              {formik.errors.port}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="InputBox mt20">
                    <label className="LabelInput">Username*</label>
                    <div className="CommonInput">
                      <input
                        type="text"
                        name="username"
                        onChange={formik.handleChange}
                        value={formik.values.username}
                      />
                      {formik.touched.username && formik.errors.username && (
                        <div className="ErrorMessage">
                          {formik.errors.username}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="InputBox mt20">
                    <label className="LabelInput">Password*</label>
                    <div className="CommonInput">
                      <input
                        type="password"
                        name="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                      />
                      {formik.touched.password && formik.errors.password && (
                        <div className="ErrorMessage">
                          {formik.errors.password}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="InputBox mt20">
                        <label className="LabelInput">Warehouse</label>
                        <div className="CommonInput">
                          <input
                            type="text"
                            name="warehouse"
                            onChange={formik.handleChange}
                            value={formik.values.warehouse}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="InputBox mt20">
                        <label className="LabelInput">Database</label>
                        <div className="CommonInput">
                          <input
                            type="text"
                            name="database"
                            onChange={formik.handleChange}
                            value={formik.values.database}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="InputBox mt20">
                    <label className="LabelInput">Schema (optional)</label>
                    <div className="CommonInput">
                      <input
                        type="text"
                        name="schema"
                        onChange={formik.handleChange}
                        value={formik.values.schema}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="ButtonContainer">
                {currentStep === 1 && (
                  <button
                    type="button"
                    className="whiteButton"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                )}
                {currentStep === 2 && (
                  <button
                    type="button"
                    className="whiteButton"
                    onClick={handlePrevious}
                  >
                    Back
                  </button>
                )}

                {currentStep < 2 && (
                  <button
                    type="submit"
                    className="BlueButton"
                    disabled={!formik.isValid}
                  >
                    Next
                  </button>
                )}
                {currentStep === 2 && (
                  <button
                    type="submit"
                    className="BlueButton"
                    disabled={!formik.isValid}
                  >
                    connect
                  </button>
                )}
              </div>
            </form>
          </Modal.Body>
        )}
        {currentStep === 3 && (
          <div className="thirdStepContainer">
            <ConnectData getpayload={getpayload} />
          </div>
        )}
      </Modal>
    </>
  );
};

export default DatabaseTypeModal;
