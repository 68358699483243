import React from "react";
import "./ToolTip.css";

const TooltipAnalytics = ({ text, children, className }) => {
  return (
    <div className="tooltip">
      {children}
      <span className={`tooltip-text ${className}`}>{text}</span>
    </div>
  );
};

export default TooltipAnalytics;
