import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const GetReportByIdApi = async (id) => {
    const userAuthToken = GetCookie('token');
    try {
      const response = await fetch(
      getApiUrl()+apiUrls.report.getReportById(id),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "authToken": userAuthToken,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
       return result;
      } else {
        console.error("Request failed. Please try again.");
      }
    } catch (error) {
      console.error(error, "Please Try Again");
    }
  }


  export default GetReportByIdApi;