import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleSignIn from './GoogleLogin';

const GoogleProtected = ({onLoginParentSucess}) => {
    const handleLoginSuccess = (credentialResponse) => {
        onLoginParentSucess(credentialResponse);
        console.log(credentialResponse,"credentialResponseParent")
    
  };
  return (

    <>
    <GoogleOAuthProvider clientId="516451595612-fe0438iq6jlmea4cb9vkcrogn1uk7mtn.apps.googleusercontent.com">
      <GoogleSignIn onLoginSuccess={handleLoginSuccess}/>
    </GoogleOAuthProvider>
    </>
  );
};

export default GoogleProtected;