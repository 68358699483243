import React from "react";
import Layout from "../../../component/layout/Layout";
import MonacoEditorSection from "./MonacoEditor";
import GraphController from "./GraphController";
import { enumChartTypes } from "../../../enums/enum";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import { useSelector } from "react-redux";
import QueryPlaygroundGraph from "./QueryPlaygroundGraph";
import MarkDownType from "./MarkDown";
import TableMetaDataList from "./TablelistData";

// const queryPromptsData =
// {
//     "name": "first_notebook",
//     "deleted": false,
//     "cellDTOS": [
//         {
//             "cellType": "SQL",
//             "cellContent": "SELECT SUM(Marks), first_name FROM LoadFlow GROUP BY first_name"
//         },
//         {
//             "cellType": "VISUALIZATION",
//             "widgetDTO": {
//                 "uuid": "d0b17868-4988-4bec-88bf-759e443703c6",
//                 "deleted": false,
//                 "data": [
//                     {
//                         "metric": {
//                             "SUM(MARKS)": 80
//                         },
//                         "sgb": "Back"
//                     },
//                     {
//                         "metric": {
//                             "SUM(MARKS)": 100
//                         },
//                         "sgb": "Tack"
//                     },
//                     {
//                         "metric": {
//                             "SUM(MARKS)": 90
//                         },
//                         "sgb": "Sack"
//                     },
//                     {
//                         "metric": {
//                             "SUM(MARKS)": 60
//                         },
//                         "sgb": "Mack"
//                     },
//                     {
//                         "metric": {
//                             "SUM(MARKS)": 70
//                         },
//                         "sgb": "Jack"
//                     }
//                 ],
//                 "chartType": "CLUSTERED_COLUMN",
//                 "validChartTypes": [
//                     "LINE",
//                     "CLUSTERED_COLUMN",
//                     "PIE",
//                     "DONUT"
//                 ],
//                 "logicalResourceDTOS": [
//                     {
//                         "id": 98,
//                         "label": "LoadFlow",
//                         "physicalResourceDTO": {
//                             "id": 99,
//                             "createdDate": 1715626520487,
//                             "name": "LoadFlow_1_1715626821767_952",
//                             "dataSourceDTO": {
//                                 "id": 5,
//                                 "createdDate": 1687705954,
//                                 "lastModifiedDate": 1687705954,
//                                 "host": "yvy02uloss.ap-south-1.aws.clickhouse.cloud",
//                                 "port": 8443,
//                                 "dataSourceType": "CLICKHOUSE",
//                                 "username": "default",
//                                 "password": "bSM2CW_98Kwn_",
//                                 "databaseName": "vivrn_csv",
//                                 "workspaceDTO": {
//                                     "id": 1
//                                 }
//                             },
//                             "workspaceDTO": {
//                                 "id": 1
//                             },
//                             "datasetDTO": {
//                                 "id": 149,
//                                 "createdDate": 1715626520487,
//                                 "lastModifiedDate": 1715626520487,
//                                 "uuid": "1760909291",
//                                 "baseName": "LoadFlow",
//                                 "referredName": "LoadFlow_1_1715626821767_952",
//                                 "uploadedDataTypeEnum": "CSV",
//                                 "storageFilePath": "vivrn-dataset/csv/",
//                                 "workspaceDTO": {
//                                     "id": 1
//                                 },
//                                 "uploadedBy": {
//                                     "id": 1
//                                 },
//                                 "deleted": false
//                             }
//                         },
//                         "deleted": false
//                     }
//                 ],
//                 "metricDTOS": [
//                     {
//                         "selectColumn": {
//                             "id": 745,
//                             "createdDate": 1715626520487,
//                             "lastModifiedDate": 1715626520487,
//                             "uuid": "3034169780",
//                             "label": "Marks",
//                             "formattedLabel": "Marks",
//                             "logicalResourceDTO": {
//                                 "id": 98,
//                                 "createdDate": 1715626520487,
//                                 "uuid": "3264141192",
//                                 "label": "LoadFlow",
//                                 "physicalResourceDTO": {
//                                     "id": 99,
//                                     "createdDate": 1715626520487,
//                                     "name": "LoadFlow_1_1715626821767_952",
//                                     "dataSourceDTO": {
//                                         "id": 5,
//                                         "createdDate": 1687705954,
//                                         "lastModifiedDate": 1687705954,
//                                         "host": "yvy02uloss.ap-south-1.aws.clickhouse.cloud",
//                                         "port": 8443,
//                                         "dataSourceType": "CLICKHOUSE",
//                                         "username": "default",
//                                         "password": "bSM2CW_98Kwn_",
//                                         "databaseName": "vivrn_csv",
//                                         "workspaceDTO": {
//                                             "id": 1
//                                         }
//                                     },
//                                     "workspaceDTO": {
//                                         "id": 1
//                                     },
//                                     "datasetDTO": {
//                                         "id": 149,
//                                         "createdDate": 1715626520487,
//                                         "lastModifiedDate": 1715626520487,
//                                         "uuid": "1760909291",
//                                         "baseName": "LoadFlow",
//                                         "referredName": "LoadFlow_1_1715626821767_952",
//                                         "uploadedDataTypeEnum": "CSV",
//                                         "storageFilePath": "vivrn-dataset/csv/",
//                                         "workspaceDTO": {
//                                             "id": 1
//                                         },
//                                         "uploadedBy": {
//                                             "id": 1
//                                         },
//                                         "deleted": false
//                                     }
//                                 },
//                                 "workspaceDTO": {
//                                     "id": 1
//                                 },
//                                 "deleted": false
//                             },
//                             "attributeTypeEnum": "DIRECT",
//                             "dataType": "NUMBER",
//                             "workspaceDTO": {
//                                 "id": 1,
//                                 "createdDate": 16178987,
//                                 "uuid": "1245244",
//                                 "name": "Sample Workspace",
//                                 "organizationDTO": {
//                                     "id": 1,
//                                     "createdDate": 1686962370,
//                                     "uuid": "1315942858",
//                                     "organizationUrl": "force.vivrn.com",
//                                     "name": "vivrn"
//                                 }
//                             }
//                         },
//                         "aggregateFunction": "SUM"
//                     }
//                 ],
//                 "groupByMapDTOS": [
//                     {
//                         "logicalAttributeDTO": {
//                             "id": 738,
//                             "createdDate": 1715626520487,
//                             "lastModifiedDate": 1715626520487,
//                             "uuid": "617997512",
//                             "label": "first_name",
//                             "formattedLabel": "first_name",
//                             "logicalResourceDTO": {
//                                 "id": 98,
//                                 "createdDate": 1715626520487,
//                                 "uuid": "3264141192",
//                                 "label": "LoadFlow",
//                                 "physicalResourceDTO": {
//                                     "id": 99,
//                                     "createdDate": 1715626520487,
//                                     "name": "LoadFlow_1_1715626821767_952",
//                                     "dataSourceDTO": {
//                                         "id": 5,
//                                         "createdDate": 1687705954,
//                                         "lastModifiedDate": 1687705954,
//                                         "host": "yvy02uloss.ap-south-1.aws.clickhouse.cloud",
//                                         "port": 8443,
//                                         "dataSourceType": "CLICKHOUSE",
//                                         "username": "default",
//                                         "password": "bSM2CW_98Kwn_",
//                                         "databaseName": "vivrn_csv",
//                                         "workspaceDTO": {
//                                             "id": 1
//                                         }
//                                     },
//                                     "workspaceDTO": {
//                                         "id": 1
//                                     },
//                                     "datasetDTO": {
//                                         "id": 149,
//                                         "createdDate": 1715626520487,
//                                         "lastModifiedDate": 1715626520487,
//                                         "uuid": "1760909291",
//                                         "baseName": "LoadFlow",
//                                         "referredName": "LoadFlow_1_1715626821767_952",
//                                         "uploadedDataTypeEnum": "CSV",
//                                         "storageFilePath": "vivrn-dataset/csv/",
//                                         "workspaceDTO": {
//                                             "id": 1
//                                         },
//                                         "uploadedBy": {
//                                             "id": 1
//                                         },
//                                         "deleted": false
//                                     }
//                                 },
//                                 "workspaceDTO": {
//                                     "id": 1
//                                 },
//                                 "deleted": false
//                             },
//                             "attributeTypeEnum": "DIRECT",
//                             "dataType": "TEXT",
//                             "workspaceDTO": {
//                                 "id": 1,
//                                 "createdDate": 16178987,
//                                 "uuid": "1245244",
//                                 "name": "Sample Workspace",
//                                 "organizationDTO": {
//                                     "id": 1,
//                                     "createdDate": 1686962370,
//                                     "uuid": "1315942858",
//                                     "organizationUrl": "force.vivrn.com",
//                                     "name": "vivrn"
//                                 }
//                             }
//                         }
//                     }
//                 ],
//                 "widgetJoinDTOS": [],
//                 "decimalColumn": false,
//                 "columnWithSpaces": false,
//                 "dataFromDifferentSource": false,
//                 "query": "select sum(Marks), first_name from LoadFlow_1_1715626821767_952 group by first_name",
//                 "layout": {
//                     "width": 200,
//                     "height": 200,
//                     "x": null,
//                     "y": null
//                 }
//             }
//         }
//     ],
//     "logicalResourceDTOS": [
//         {
//             "id": 98,
//             "label": "LoadFlow",
//             "physicalResourceDTO": {
//                 "id": 99
//             },
//             "deleted": false
//         }
//     ],
//     "tableMetaDataList": [
//         {
//             "schemaDBTableName": {
//                 "schemaName": null,
//                 "tableName": "LoadFlow",
//                 "databaseName": null
//             },
//             "columns": [
//                 {
//                     "modified": false,
//                     "columnName": "id",
//                     "displayType": null,
//                     "sqlType": "BIGINT",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "first_name",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "last_name",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "date_of_birth",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "ethnicity",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "gender",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "status",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "entry_academic_period",
//                     "displayType": null,
//                     "sqlType": "VARCHAR",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 },
//                 {
//                     "modified": false,
//                     "columnName": "Marks",
//                     "displayType": null,
//                     "sqlType": "BIGINT",
//                     "changedType": null,
//                     "referencedTableName": null,
//                     "referencedColumnName": null,
//                     "nullable": false,
//                     "foreignKey": false
//                 }
//             ],
//             "hash": null,
//             "state": null
//         }
//     ]
// }

// const getUniqueHeaders = (data) => {

//     console.log(data, "dataaa")
//     const headersSet = new Set();
//     data?.forEach(item => {
//         item?.outputs?.forEach(output => {
//             console.log(output, "outputttt")
//             if (output.output_type === "table") {
//                 Object.keys(output.data.text[0]).forEach(key => headersSet.add(key));
//                 console.log(output.data.text[0], "output")
//             }
//         });
//     });
//     return Array.from(headersSet);
// };
const QueryPromptDetails = () => {
    const queryPromptsData = useSelector((state) => state.cellDataApi);
    // const queryPromptsDataStringify = localStorage.getItem("promptCellData");
    // const queryPromptsDataStringify = GetCookie("promptCellData");
    // const queryPromptsData = JSON.parse(queryPromptsDataStringify);
    // const headers = getUniqueHeaders(queryPromptsData);
    // console.log(queryPromptsDataStringify,"queryPromptsDataStringify")
    console.log(queryPromptsData, "queryPromptsDataa");
    return (
        <Layout>
            <div className="AnalyticsContainer">
                <div className="container homeSection">
                    <div className="row">
                        <div className="col-md-12 ">
                            {/* {queryPromptsData?.map((item, index) => (
                                <div key={index}> */}
                            {queryPromptsData?.response?.cellDTOS?.map((outputs, outputIndex) => (
                            // {queryPromptsData?.cellDTOS?.map((outputs, outputIndex) => (
                                <div key={outputIndex}>
                                    {/* {outputs.cellType === "table" && (
                                                <table border="1">
                                                    <thead>
                                                        <tr>
                                                            {headers.map((header) => (
                                                                <th key={header}>{header}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {outputs.data.text.map((row, rowIndex) => (
                                                            <tr key={rowIndex}>
                                                                {headers.map((header) => (
                                                                    <td key={header}>{row[header]}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )} */}
                                              {outputs.cellType === "MARKDOWN" && (
                                        <MarkDownType cellContent={outputs.cellContent}/>
                                    )}
                                    {outputs.cellType === "SQL" && (
                                        <MonacoEditorSection cellContent={outputs.cellContent}/>
                                    )}
                                    {outputs.cellType === "bullet_points" && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                {outputs.data.label + ":"}
                                            </div>
                                            {outputs.data.text.map((e, i) => (
                                                <ul key={i}>
                                                    <li>{e}</li>
                                                </ul>
                                            ))}
                                            <div className="col-md-12">
                                                {outputs.data.summary}
                                            </div>
                                        </div>
                                    )}
                                    {outputs.cellType === "VISUALIZATION" && (
                                        <>
                                            {/* {console.log(outputswidgetDTO,"outputsdatagraphData")} */}
                                            <QueryPlaygroundGraph responseData={outputs.widgetDTO} chartType={outputs.widgetDTO.chartType} />
                                            {/* <GraphController responseData={outputs.widgetDTO} chartType={outputs.widgetDTO.chartType} /> */}
                                            <TableMetaDataList responseData={outputs.widgetDTO}/>
                                        </>
                                    )}

                                </div>
                            ))}
                           

                           
                        </div>
                        {/* ))}
                        </div> */}

                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default QueryPromptDetails;