import React from "react";
import { IMPORT_STATEMENT } from "../../../dtos/UploadData.type";
import { EXPORT_TITLE } from "../../../dtos/Title.type";

export default function Modalheader() {
  return (
    <>
      <div className="modal-header-content">
        <h3 className="modal-title">{EXPORT_TITLE}</h3>
        <div>
          <p>
            {IMPORT_STATEMENT}
          </p>
        </div>
      </div>
    </>
  );
}
