import Swal from "sweetalert2";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";

export default async function SaveSchemaApi(payload) {
    const userAuthToken = GetCookie('token');
    try {
        const response = await fetch(
            getApiUrl() + apiUrls.uploadFiles.saveSchema,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authToken": userAuthToken,
                },
                body: JSON.stringify(payload),
            }
        );
        const result = await response.json();
        if (response.ok) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}