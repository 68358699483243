import React, { useState } from "react";
import LineDoubleMain from "./LineDoubleMain";
import LineDouble from "./LineDouble";

const LineDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? (
        <LineDoubleMain responseData={responseData} />
      ) : (
        <LineDouble responseData={responseData} />
      )}
    </div>
  );
};

export default LineDoubleToggle;
