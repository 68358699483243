import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./ImportData.css";
import "react-toastify/dist/ReactToastify.css";
import { acceptedFileTypes } from "../../dtos/import.type";
import { userAuthToken } from "../../dtos/Token.type";
import TableComponent from "../../pages/upload/table/TableComponent";
import ModalComponent from "../../pages/upload/modal/ModalComponent";
import ModalFooter from "../../pages/upload/modal/ModalFooter.component";
import { TYPE_FILE } from "../../dtos/UploadData.type";
import { getApiUrl } from "../../service/url/UrlConfig";
import { apiUrls } from "../../service/endPoints/ApiUrls";
import { todayDate } from "../../util/todayDate";
import UplodedFileApi from "../../service/api/UploadedFileApi";
import { icons } from "../../constants/icons/Icons";
import DeleteUploadedFileApi from "../../service/api/DeleteUploadedFile";

export default function ImportData({hideText}) {
  localStorage.removeItem("previousData");
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState("CSV");
  const [jsonData, setJsonData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showUploadSection, setShowUploadSection] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [fileName, setFileName] = useState("");
  console.log(jsonData, "jsonData");

  const handleClose = async () => {
    if (!fileName) {
      setShow(false);
      return;
    }
    else {
      try {
        const success = await DeleteUploadedFileApi(jsonData.datasetDTO);
        if (success) {
          setFileName("");
          setShow(false);
          setShowTable(false);
          setShowUploadSection(true);
          setJsonData([]);
          setProjectName("");
          document.getElementById("file-input").value = "";
          document.getElementById("fileType").selectedIndex = 0;
        } else {
          // Handle error if DeleteUploadedFileApi call fails
        }
      } catch (error) {
        // Handle any exceptions that occur during the API call
      }
    }
  };
  const handleShow = () => setShow(true);

  const handleNext = () => {
    const json_data = JSON.stringify(jsonData);
  };

  const fetchUploadFile = async (file) => {
    setFileName(file.name);
    if (!file) {
      console.error("No file provided.");
      return;
    }
    let data = {
      baseName: projectName,
      uploadedDataTypeEnum: fileType,
      createdDate: todayDate,
    };
    const dataStringyFY = JSON.stringify(data);
    const blob = new Blob([dataStringyFY], {
      type: "application/json",
    });

    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", blob);
    try {
      const tableData = await UplodedFileApi(formData);
      if (tableData && tableData.response) {
        setJsonData(tableData.response);
        setShowTable(true);
      } else {
        console.error("Unexpected error occurred:");
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      fetchUploadFile(file);
    } else {
      console.error("No file uploaded.");
    }
  };

  const handlePrevious = async () => {
    if (!fileName) {
      
      return;
    }
    else {
      try {
        const success = await DeleteUploadedFileApi(jsonData.datasetDTO);
        if (success) {
          setFileName("");
          setShowTable(false);
          setShowUploadSection(true);
          setJsonData([]);
          setProjectName("");
          document.getElementById("file-input").value = "";
          document.getElementById("fileType").selectedIndex = 0;
        } else {
          // Handle error if DeleteUploadedFileApi call fails
        }
      } catch (error) {
        // Handle any exceptions that occur during the API call
      }
    }
    };

  const acceptedFileType = acceptedFileTypes[fileType];

  const dataWithKeys = {
    jsonData: jsonData,
    projectName: projectName,
  };
  localStorage.setItem("myDataWithKeys", JSON.stringify(dataWithKeys));
  return (
    <>
      <div className="card-body cursor-pointer" onClick={handleShow}>
      {hideText ? <button className=" btn  add-data-btn">
    Widget
    <i className="fa-solid fa-plus ms-2"></i>
  </button> : (
        <div className="upload-data">
          <img src={icons.upload} alt="upload" />
          <p className="Analyticsupload-set">Upload data</p>
        </div>
      )}
     
        
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        size="lg"
        className="PresentDataModal"
      >
        <Modal.Header closeButton className="modal-header">
          <div className="modal-header-content">
            <Modal.Title className="modal-title">Import your Data</Modal.Title>
            <div>
              <p>
                Import data from Excel, CSV, HTML, XML, JSON, Statistical & MS
                Access files
              </p>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div>
            <ModalComponent
              projectName={projectName}
              setProjectName={setProjectName}
              fileType={fileType}
              setFileType={setFileType}
              acceptedFileType={acceptedFileType}
              handleFileChange={handleFileChange}
            />
            {showTable && (
              <TableComponent
                jsonData={jsonData}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setShowTable={setShowTable}
                setHiddenColumns={setHiddenColumns}
                hiddenColumns={hiddenColumns}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
            )}
          </div>
          <ModalFooter
          jsonData={jsonData}
            showTable={showTable}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            handleClose={handleClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
