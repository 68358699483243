import React, { useRef, useState, useEffect, useCallback } from "react";
import MonacoEditor from "react-monaco-editor";
import * as monaco from "monaco-editor";
import "./Editor.css";
import { format } from 'sql-formatter';

const MonacoEditorSection = (props) => {
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy");
  const editorRef = useRef(null);
  const [sqlData, setSqlData] = useState("");

  useEffect(() => {
    setSqlData(props.cellContent);
  }, [props.cellContent]);

  useEffect(() => {
    monaco.languages.register({ id: "sql" });
    monaco.languages.setMonarchTokensProvider("sql", {
      tokenizer: {
        root: [
          [/SELECT|FROM|WHERE|AND|TABLE_SCHEMA|TABLE_NAME|INDEX_NAME/, "keyword"],
          [/your_database_name|your_table_name/, "identifier"],
          [/[a-z_$][\w$]*/, "identifier"],
          [/[ \t\r\n]+/, "whitespace"],
          [/--.*$/, "comment"],
          [/\/\*.*\*\//, "comment"],
        ],
      },
    });

    monaco.editor.defineTheme("sql-dark", {
      base: "vs-dark",
      inherit: true,
      rules: [
        { token: "keyword", foreground: "569cd6" },
        { token: "identifier", foreground: "9cdcfe" },
        { token: "comment", foreground: "6a9955" },
        { token: "whitespace" },
      ],
      colors: {
        "editor.foreground": "#FFFFFF",
        "editor.background": "#1E1E1E",
        "editorCursor.foreground": "#FFFFFF",
        "editor.lineHighlightBackground": "#2B2B2B",
        "editorLineNumber.foreground": "#858585",
        "editor.selectionBackground": "#264F78",
        "editor.inactiveSelectionBackground": "#3A3D41",
      },
    });
  }, []);

  const handleEditorDidMount = (editor, monaco) => {
    setIsEditorReady(true);
    editorRef.current = editor;
    adjustEditorHeight(editor);
    editor.onDidChangeModelContent(() => adjustEditorHeight(editor));
  };

  const adjustEditorHeight = useCallback((editor) => {
    if (!editor) return;
    const lineCount = editor.getModel().getLineCount();
    const lineHeight = 19; // Same as in the options
    const editorHeight = Math.max(lineHeight * lineCount + 20, 200); // Add some padding and set a minimum height
    editor.layout({ height: editorHeight });
  }, []);

  const copyCode = () => {
    if (editorRef.current) {
      const code = editorRef.current.getValue();
      navigator.clipboard.writeText(code).then(
        () => {
          setCopyStatus("Copied!");
          setTimeout(() => setCopyStatus("Copy"), 2000);
        },
        () => {
          alert("Failed to copy code.");
        }
      );
    }
  };

  const toggleEditor = () => {
    setIsCollapsed(!isCollapsed);
    if (!isCollapsed && editorRef.current) {
      adjustEditorHeight(editorRef.current);
    }
  };

  const options = {
    selectOnLineNumbers: true,
    autoIndent: true,
    formatOnPaste: true,
    formatOnType: true,
    automaticLayout: true,
    roundedSelection: true,
    lineHeight: 19,
    scrollBeyondLastLine: false,
  };

  monaco.languages.registerDocumentFormattingEditProvider('sql', {
    provideDocumentFormattingEdits(model, options) {
      var formatted = format(model.getValue(), {
        indent: ' '.repeat(options.tabSize)
      });
      return [
        {
          range: model.getFullModelRange(),
          text: formatted
        }
      ];
    }
  });

  monaco.languages.registerDocumentRangeFormattingEditProvider('sql', {
    provideDocumentRangeFormattingEdits(model, range, options) {
      var formatted = format(model.getValueInRange(range), {
        indent: ' '.repeat(options.tabSize)
      });
      return [
        {
          range: range,
          text: formatted
        }
      ];
    }
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div onClick={toggleEditor} className="toggle_for_editor">
          {isCollapsed ? (
            <span>
              Show code <i className="fa-solid fa-angle-down"></i>
            </span>
          ) : (
            <span>
              Hide code <i className="fa-solid fa-angle-up"></i>
            </span>
          )}
        </div>
      </div>
      <div className="col-md-12 pb-4 editor-container">
        {!isCollapsed && (
          <>
            <div className="copy-btn" onClick={copyCode} disabled={!isEditorReady}>
              <i className="fa-regular fa-copy"></i>
              {copyStatus}
            </div>
            <MonacoEditor
              language="sql"
              theme="sql-light"
              value={sqlData}
              options={options}
              editorDidMount={handleEditorDidMount}
              className="moncoeditor-div"
            />
          </>
        )}
      </div>
    </>
  );
};

export default MonacoEditorSection;
