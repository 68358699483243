import { RESTMethod } from "../../enums/enum";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const  GetWidgetByIdApi = async (id) => {
    const token = GetCookie("token");
    try {
      const response = await fetch(
        getApiUrl() +apiUrls.widget.getWidgetById(id),
        {
          method: RESTMethod.GET,
          headers: {
            "Content-Type": "application/json",
            "authToken": token,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error(`Request failed for ID ${id}. Please try again.`);
      }
    } catch (error) {
      console.error(error, `Error fetching data for ID ${id}. Please Try Again`);
    }
  }

  export default GetWidgetByIdApi;