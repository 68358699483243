import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getSignUp } from "../url/UrlConfig";

export default async function VerifyEmail(payload) {

  try {
    const response = await fetch(getSignUp() + apiUrls.signUp.verifyEmail, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    // Handle HTTP errors
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    return true;
  } catch (error) {
    console.error(error.message || error);
    return false;
  }
}
