import React from "react";
import { icons } from "../../../constants/icons/Icons";

const FilterReport = () => {
    return(
        <>
        <div className="filterHeader">
            <img src={icons.PIE} alt="filter"/>
            <h6>Filter</h6>
        </div>
        <div className="filterBody">
            <p className="filterVisual">Filter on this visual</p>
        </div>
        </>
    )
}
export default FilterReport;