import React, { useState } from "react";
import StackedColumnPercentageDoubleMain from "./StackedColumnPercentageMain";
import StackedColumnPercentageDouble from "./StackedColumnPercentageDouble";

const StackedColumnPercentageDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? (
        <StackedColumnPercentageDoubleMain responseData={responseData} />
      ) : (
        <StackedColumnPercentageDouble responseData={responseData} />
      )}
    </div>
  );
};

export default StackedColumnPercentageDoubleToggle;
