import { apiUrls } from "../endPoints/ApiUrls";
import { getSignUp } from "../url/UrlConfig";

export default async function CheckUrlApi(payload) {
  try {
    const response = await fetch(
      getSignUp()+apiUrls.signUp.checkUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: payload,
      }
    );
    const result = await response.json();
    if (result.httpStatusCode === 200) {
      return true;
  } else if (result.httpStatusCode === 401) {
      return false;
  } else {
      return false;
  }
  } catch (error) {
    console.error(error.message || error);
    return false;
  }
}
