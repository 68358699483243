import { RESTMethod } from "../../enums/enum";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

 const TrashApiFetch = async (id) => {
  const userAuthToken = GetCookie("token");
    try {
      const response=await fetch(
       getApiUrl()+apiUrls.dataSet.trashDataset(id),
        {
          method: RESTMethod.PUT,
          headers: {
            "Content-Type": "application/json",
            "authToken": userAuthToken,
          },
        }
      );
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error("Request failed. Please try again.");
      }
    } catch (error) {
      console.error("Failed to delete:", error);
      throw error;
    }
  };

  export default TrashApiFetch;