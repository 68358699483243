import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { uploadFile } from '../../../../redux/redux/action';

function CsvTable(props) {
    const uploadedFiles = props.uploadedFiles;
    const numUploadedFiles = uploadedFiles?.length;
    // date
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");
    const formattedDate = `${day}-${month}-${year}`;

    return (
        <div className="csvTable">
            {numUploadedFiles === 0 ? (
                ""
            ) : (
                <div className="ContainerUploadedFile">
                    <div className="UploadedFileCus">
                        {numUploadedFiles > 0 && (
                            <p>{`${numUploadedFiles} file${numUploadedFiles === 1 ? "" : "s"
                                } uploaded`}</p>
                        )}

                        <form role="search">
                            <div className="SearchIconConta">
                                <div className="SearchIcon">
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </div>
                                <input
                                    className="Customform-control"
                                    type="search"
                                    aria-label="Search"
                                />
                            </div>
                        </form>
                    </div>
                    <div className="tableresponsive">
                        <div className="CustomTable">
                            <div className="TableHeader">
                                <p>File Name</p>
                                <p>Date Uploaded</p>
                            </div>
                            <div className="tableBody">
                                {uploadedFiles?.map((file, index) => (
                                    <div className="TableBodyCus" key={index}>
                                        <div>
                                            <i className="fa-regular fa-file-lines me-2"></i>
                                            <Link to={`/uploadData`}>{file[0].fileName}</Link>
                                        </div>
                                        <div>
                                            <p>{formattedDate}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        uploadedFiles: state.uploadedFiles,
    };
};

const mapDispatchToProps = {
    uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(CsvTable);
