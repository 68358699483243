import React, { useState, useEffect } from "react";
import LayoutSuperAdmin from "../../../component/layout/LayoutSuperAdmin";
import "./AllUser.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import getAllUsers from "../../../service/api/AlluserApi";

const AllUser = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [users, setUsers] = useState([]);
  const userAuthToken = GetCookie("token");
  const [searchInput, setSearchInput] = useState("");

  const fetchApi = async () => {
    const data = await getAllUsers();
    setUsers(data.response);
  };
  useEffect(() => {
    fetchApi();
  }, [userAuthToken]);

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };
  const filteredUsers = users?.filter((user) =>
    user.firstName.toLowerCase().includes(searchInput.toLowerCase())
  );

  useEffect(() => {
    if (filteredUsers?.length === 0 && searchInput !== "") {
      toast.error("User not found");
    }
  }, [filteredUsers, searchInput]);

  return (
    <LayoutSuperAdmin>
      <div className="AnalyticsContainer AnalyticsContainerSuperAdmin">
        <div className="container-fluid p-0">
          <div className="row w-100 m-0">
            <div className="col-md-12 p-0">
              <div className="UsersContainer">
                <h4 className="mb-0">Users</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row pt12">
            <div className="col-4 p-0">
              <div className="SidebarDataWareAllUser">
                <div className="SideAllUserDiv">
                  <input
                    type="text"
                    id="searchInput"
                    className="form-control"
                    placeholder="search..."
                    value={searchInput}
                    onChange={handleSearchInputChange}
                  />
                </div>
                <div className="AllUserBox">
                  {filteredUsers && filteredUsers.length > 0 ? (
                    filteredUsers?.map((user) => (
                      <div
                        key={user.id}
                        className={`SideAllUserProfile ${
                          selectedTab === user.id ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(user.id)}
                      >
                        <div className="user-profile d-flex">
                          <div className="profileImageDiv">
                            {user.firstName.charAt(0).toUpperCase()}
                            {user.lastName.charAt(0).toUpperCase()}
                          </div>
                          <div className="user-details ms-3">
                            <h5 className="user-name mb-0">
                              {user.firstName} {user.lastName}
                            </h5>
                            <p className="user-email">{user.email_id}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>Data not found.</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-8">
              {filteredUsers && filteredUsers.length > 0 ? (
                filteredUsers?.map((user) => (
                  <div
                    key={user.id}
                    className={`tab-content ${
                      selectedTab === user.id ? "active" : ""
                    }`}
                  >
                    {selectedTab === user.id && (
                      <div className="SuperAdminDiv mt-0">
                        <div className="user-profile d-flex">
                          <div className="profileImageDiv">
                            {user.firstName.charAt(0).toUpperCase()}
                            {user.lastName.charAt(0).toUpperCase()}
                          </div>
                          <div className="user-details ms-3">
                            <h4 className="user-name mb-0">
                              {user.firstName} {user.lastName}
                            </h4>
                            <p className="user-email">{user.email_id}</p>
                          </div>
                          <button
                            type="button"
                            className="NextButton btn btn-primary ms-auto"
                          >
                            Add as <br />
                            Super Admin
                          </button>
                        </div>
                      </div>
                    )}
                    {selectedTab === user.id && (
                      <div className="SuperAdminDiv">
                        <h4 className="allUserdiv">Work Information</h4>
                        <div className="WorkInformationDiv">
                          <div className="row">
                            <div className="col-sm-6 allUserdiv">
                              <h6>First name*</h6>
                              <p>{user.firstName} </p>
                            </div>
                            <div className="col-sm-6 allUserdiv">
                              <h6>Last name*</h6>
                              <p>{user.lastName}</p>
                            </div>
                            <div className="col-sm-12 allUserdiv">
                              <h6>Email*</h6>
                              <p>{user.email_id}</p>
                            </div>
                            <div className="col-sm-6 allUserdiv">
                              <h6>Company*</h6>
                              <p>Mountain Drop</p>
                            </div>
                            <div className="col-sm-6 allUserdiv">
                              <h6>Job title*</h6>
                              <p>UI/UX designer</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div>Data not found.</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </LayoutSuperAdmin>
  );
};

export default AllUser;
