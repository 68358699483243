import React, { useEffect, useState } from "react"
import './QueryPrompt.css';
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
const MarkDownType=(props)=>{
    const [masrkDownText,setMaekDowntext]=useState('')
    const userDTOStringfy = GetCookie("userDTO");
    const userDTO = JSON.parse(userDTOStringfy);
    useEffect(() => {
        setMaekDowntext(props.cellContent);
      }, [])
return(
    <>
    <div className="row">
        <div className="col-md-12" style={{"margin-bottom": "0px"}}>
        <span className="circle-text ">{userDTO.firstName.substring(0,1)}</span>
        <span  className="ms-4" style={{"font-weight": "bold"}}>{masrkDownText}</span>
        </div>
    </div>
    </>
)
}

export default MarkDownType