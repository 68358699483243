import React, { useState, useEffect } from "react";
import { formatDate } from "../../../directives/date-formate";
import DataNotFound from "../../../component/dataNotFound/DataNotFound";

const TrashTableRow = ({
  filteredData,
  handleCheckboxChange,
  selectAll,
  setSelectAll,
  setIndeterminate,
}) => {
  const [checkedRows, setCheckedRows] = useState({});

  useEffect(() => {
    const initialCheckedRows = filteredData.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: selectAll,
      }),
      {}
    );
    setCheckedRows(initialCheckedRows);
  }, [filteredData, selectAll]);

  const handleRowCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [id]: isChecked,
    }));
    handleCheckboxChange(event, id);

    const allChecked = Object.values(checkedRows).every(Boolean);
    const someChecked = Object.values(checkedRows).some(Boolean);

    setSelectAll(allChecked);
    setIndeterminate(someChecked && !allChecked);
  };

  return (
    <>
      {filteredData && filteredData.length > 0 ? (
        filteredData.map((item) => (
          <tr key={item?.id}>
            <td>
              <input
                name="table-row"
                type="checkbox"
                checked={checkedRows[item.id] || false}
                onChange={(event) => handleRowCheckboxChange(event, item.id)}
              />
            </td>
            <td>{item.id}</td>
            <td>{formatDate(item.createdDate)}</td>
            <td>{formatDate(item.lastModifiedDate)}</td>
            <td>{item.title}</td>
            <td>{item.createdBy.updatedBy}</td>
          </tr>
        ))
      ) : (
        <DataNotFound />
      )}
    </>
  );
};

export default TrashTableRow;