import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import 'highcharts/css/highcharts.css';
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { useDispatch } from 'react-redux';
import { focusModeClassData, resetfocusModeClassData } from '../../../redux/reduxToolkit/Reducer';

// Initialize exporting modules
exportingInit(Highcharts);
exportDataInit(Highcharts);


export default function DonutDoubleMain(props) {
  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(props?.responseData?.responseData?.data);
  useEffect(() => {
      setFetchData(props?.responseData?.responseData?.data);
  }, [props,fetchData]);
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props?.responseData?.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus,props?.responseData?.responseData]);
  const toggleFocusMode = () => {
    setGetFocus(prev => !prev);
};
  const seriesData = Object.entries(fetchData).flatMap(([category, data]) =>
    data.flatMap(item =>
      Object.entries(item.metric).map(([matrixName, value]) => ({
        name: `${category} - ${matrixName}`,
        y: value,
        sgb: item.sgb,
        drilldown: item.sgb,
        category: category,
      }))
    )
  );

  const options = {
    chart: {
      type: 'pie',
      height: '100%'
    },
    credits: {
      enabled: false
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        innerSize: '50%', 
        dataLabels: {
          enabled: true,
          format: '<b>{point.sgb}</b>: {point.y}<br/>({point.category})',
        },
      },
    },
    tooltip: {
      pointFormat: '<b>{point.sgb}</b>: {point.y}<br/>({point.category})',
    },
    series: [{
      name: 'Metrics',
      colorByPoint: true,
      data: seriesData,
    }],
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [fetchData]);

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    </>
  );
}
