import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleSignUp from "./GoogleSignUp";
import decodeJWT from "../../util/Decode";

const GoogleProtectedSignUp = ({ onLoginParentSucess }) => {
  const handleLoginSuccess = (credentialResponse) => {
    onLoginParentSucess(credentialResponse);
    if (credentialResponse) {
      const decodeToken = decodeJWT(credentialResponse?.credential);
      localStorage.setItem(
        "googleSignToken",
        JSON.stringify(decodeToken.payload)
      );
    }
  };
  return (
    <>
      <GoogleOAuthProvider clientId="516451595612-fe0438iq6jlmea4cb9vkcrogn1uk7mtn.apps.googleusercontent.com">
        <GoogleSignUp onLoginSuccess={handleLoginSuccess} />
      </GoogleOAuthProvider>
    </>
  );
};

export default GoogleProtectedSignUp;
