import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./SignUpWithGoogle.css";
import { useNavigate } from "react-router";
import { steps } from "../../dtos/Database.type";
import TellUsAboutForm from "./signUpWithGoogleForm/TellUsAboutForm";
import CompanyDetailsForm from "./signUpWithGoogleForm/CompanyDetailsForm";
import CompanyWebSiteForm from "./signUpWithGoogleForm/CompanyWebsiteForm";
import SignUpNavbar from "../register/signUp/SignUpNavbar";
import useJWT from "../../util/Encode";
import CheckUrlApi from "../../service/api/CheckUrlApi";
import SignUpWithGoggle from "../../service/api/SignUpWithGoogleApi";
import SetCookie from "../../persistence/cookies/setCookie/SetCookie";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const SignUpWithGoogleStepForm = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1); //1
  const [completedSteps, setCompletedSteps] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
  const [messageApi, setMessageApi] = useState("");
  const step1Schema = Yup.object().shape({
    firstName: Yup.string().required("firstName is required"),
    lastName: Yup.string().required("lastName is required"),
    phoneNumber: Yup.string().required("phoneNumber is required"),
    businessName: Yup.string().required("businessName is required"),
    jobOption: Yup.string().required("jobPotion is required"),
  });

  const step2Schema = Yup.object().shape({
    companySize: Yup.string().required("Please select a company size"),
  });

  const step3Schema = Yup.object().shape({
    cwebsite: Yup.string().required("company website is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      phoneNumber: "",
      jobOption: "",
      companySize: "",
      cwebsite: "",
    },
    validationSchema:
      currentStep === 1
        ? step1Schema
        : currentStep === 2
        ? step2Schema
        : step3Schema,
    onSubmit: async (values, { setSubmitting }) => {
      if (currentStep === 3) {
        const userEmailDetails = localStorage.getItem("googleSignToken");
        const userEmailDetailsParse = JSON.parse(userEmailDetails);
        const getEmailByGoogleToken = userEmailDetailsParse?.email;
        const fullPhoneNumber = `${selectedCountryCode}${values.phoneNumber}`;
        const fullurlUser = values.cwebsite + ".vivrn.com";
        console.log(fullurlUser +"fullurlUser")
        const verifyEmailToken = {
          emailId: getEmailByGoogleToken,
          firstName: values.firstName,
          lastName: values.lastName,
          userRoleInCompany: values.jobOption,
          receivePromotionalEmails: true,
          organizationUrl: fullurlUser,
          phoneNumber: fullPhoneNumber,
          organizationName: values.businessName,
        };
        const token = useJWT(verifyEmailToken);
        const ecodeToken = {
          googleToken: token,
        };
        console.log(ecodeToken);
        const result = await SignUpWithGoggle(ecodeToken);
        if(result.httpStatusCode===409){
          toast.error(result.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else if(result.httpStatusCode==500){
          toast.error("something went wrong" ,{
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        if (result!==null) {
          toast.sucess("Sign Up SuccessFully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          const token = GetCookie('token');
          const [encodedHeader1, encodedPayload, signature1] = token.split(".");
          const decodedHeader = CryptoJS.enc.Base64.parse(encodedHeader1).toString(CryptoJS.enc.Utf8);
          const decodedPayload = CryptoJS.enc.Base64.parse(encodedPayload).toString(CryptoJS.enc.Utf8);
          const parsedHeader = JSON.parse(decodedHeader);
          SetCookie('orgId', parsedHeader); 
          const parsedPayload = JSON.parse(decodedPayload);
          SetCookie('loginResponsecode', JSON.stringify(parsedPayload));
          const organizationUrl = parsedPayload.organizationUrl;
          let subdomain = null;
  
          if (parsedPayload && organizationUrl) {
            const parts = organizationUrl.split('.');
            if (parts.length >= 3) {
              subdomain = parts[0];
              parts.splice(0, 1);
            }
          }
          let newURL;
        if (window.location.hostname === "localhost") {
          navigate("/workSpace"); 
        } else {
          newURL = `https://${subdomain}.vivrn.com/workSpace`;
          window.location.href = newURL; 
        }
        }else{
          toast.error("somthing wen wrong", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setCompletedSteps([...completedSteps, currentStep]);
        setCurrentStep(currentStep + 1);
      }
    },
  });

  const validateStep = async (stepIndex) => {
    try {
      let validationSchema;
      switch (stepIndex) {
        case 1:
          validationSchema = step1Schema;
          break;
        case 2:
          validationSchema = step2Schema;
          break;
        case 3:
          validationSchema = step3Schema;
          break;
        default:
          break;
      }
      await validationSchema.validate(formik.values, { abortEarly: false });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleStepClick = async (stepIndex) => {
    if (stepIndex < currentStep) {
      setCurrentStep(stepIndex);
    } else {
      const validationPassed = await validateStep(currentStep);

      if (validationPassed) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const totalSteps = steps.length;
  const progress = Math.ceil((completedSteps.length / totalSteps) * 100);

  
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  console.log(formik.values.cwebsite, "formik.values.cwebsite");
  const handleCheckUrl = async () => {
    const url = formik.values.cwebsite + ".vivrn.com";
    try {
      if (formik.values.cwebsite === "") {
        return null;
      } else {
        const apiResponse = await CheckUrlApi(url);
        if (apiResponse === true) {
          setMessageApi("Your subdomain is correct");
          setSubmitButtonDisable(false);
        } else {
          setMessageApi("Please try another subdomain");
          setSubmitButtonDisable(true);
        }
      }
    } catch (error) {
      console.error("Error occurred while checking URL:", error);
    }
  };
  useEffect(() => {
    setSubmitButtonDisable(true);
    const timer = setTimeout(() => {
      handleCheckUrl();
      console.log("Function called after 2 seconds");
    }, 2000);

    // Clear the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [formik.values.cwebsite]);
  const updatedCountryCode = (value) => {
    setSelectedCountryCode(value);
  };
  return (
    <>
      <div className="container-fluid backgroundColorBody p-0">
        <SignUpNavbar />
        <div className="row w-100">
          <div className="col-md-12">
            <div className="setUpAccountContainer">
              <div className="AccountSetUp">
                <p>Account set up</p>
                <p>
                  {currentStep}/{totalSteps}
                </p>
              </div>
              <div className="setUpAccount">
                <div className="ProgressBarContainer">
                  <div
                    className="ProgressBar"
                    style={{ width: `${progress}%` }}
                  />
                </div>

                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`progress-step ${
                      completedSteps.includes(index + 1) ? "completed" : ""
                    } ${index + 1 === currentStep ? "active" : ""}`}
                    onClick={() => handleStepClick(index + 1)}
                    style={{ width: "33.3%" }}
                  >
                    <div className="Index"></div>
                    <div className="borderBottom" />
                  </div>
                ))}
              </div>

              <form onSubmit={formik.handleSubmit}>
                {currentStep === 1 && (
                  <>
                    <TellUsAboutForm
                      formik={formik}
                      handleChange={formik.handleChange}
                      value={formik.values}
                      errors={formik.errors}
                      touched={formik.touched}
                      updatedCountryCode={updatedCountryCode}
                    />
                  </>
                )}

                {currentStep === 2 && (
                  <CompanyDetailsForm
                    formik={formik}
                    handleChange={formik.handleChange}
                    value={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                  />
                )}
                {currentStep === 3 && (
                  <>
                    <CompanyWebSiteForm
                      formik={formik}
                      handleChange={formik.handleChange}
                      value={formik.values}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                    <div className="success-message">
                      <p>{messageApi}</p>
                    </div>
                  </>
                )}
                <div className="ButtonContainer">
                  {currentStep > 1 && (
                    <button
                      type="button"
                      className="whiteButton"
                      onClick={handlePrevious}
                    >
                      Back
                    </button>
                  )}

                  {currentStep < 3 && (
                    <button
                      type="submit"
                      className="BlueButton"
                      disabled={!formik.isValid}
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 3 && (
                    <button
                      type="submit"
                      className="BlueButton"
                      disabled={submitButtonDisable}
                      // onClick={apiUrlCheck}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpWithGoogleStepForm;
