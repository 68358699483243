import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import getWorkspaceId from "../../util/AttributeUtil";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const getAllUsers = async () => {
    const userAuthToken = GetCookie('token');
    try {
      const response = await fetch(
       getApiUrl()+apiUrls.user.getAllUser(getWorkspaceId),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authToken: userAuthToken,
          },
        }
      );
      const data = await response.json();
      return data 
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  export default getAllUsers;