export const icons = {
    dashboardTab: require("../../assets/icons/dashboardTab.png"),
    dashboardTabBlue: require("../../assets/icons/dashboardTabBlue.png"),
    dataSourceTab: require("../../assets/icons/dataSourceTab.png"),
    dataSourceTabBlue: require("../../assets/icons/dataSourceTabBlue.png"),
    dataTab: require("../../assets/icons/dataTab.png"),
    dataTabBlue: require("../../assets/icons/dataTabBlue.png"),
    homeTab: require("../../assets/icons/homeTab.png"),
    homeTabBlue: require("../../assets/icons/homeTabBlue.png"),
    reportTab: require("../../assets/icons/reportTab.png"),
    reportTabBlue: require("../../assets/icons/reportTabBlue.png"),
    settingsTab: require("../../assets/icons/settingsTab.png"),
    settingsTabBlue: require("../../assets/icons/settingsTabBlue.png"),
    trashTab: require("../../assets/icons/trashTab.png"),
    trashTabBlue: require("../../assets/icons/trashTabBlue.png"),
    wiFi: require("../../assets/icons/wiFi.png"),
    notification: require("../../assets/icons/notification.png"),
    settingsBlack: require("../../assets/icons/settingsBlack.png"),
    help: require("../../assets/icons/help.png"),
    dummyProfile: require("../../assets/icons/dummyProfile.png"),
    accountIcon: require("../../assets/icons/accountIcon.png"),
    logOutIcon: require('../../assets/icons/logOutIcon.png'),
    searchPrompt: require('../../assets/icons/searchPrompt.png'),
    microphone: require('../../assets/icons/microphone.png'),
    upload: require('../../assets/icons/upload.png'),
    connectData: require('../../assets/icons/connectData.png'),
    createReport: require('../../assets/icons/createReport.png'),
    csvIcon: require('../../assets/icons/csvIcon.png'),
    tableIcon: require('../../assets/icons/tableIcon.png'),
    username: require('../../assets/icons/username.png'),
    passwordInput: require('../../assets/icons/passwordInput.png'),
    eyeClosed: require('../../assets/icons/eyeClosed.png'),
    eyeOpen: require('../../assets/icons/eyeOpen.png'),
    attachment: require('../../assets/icons/attachment.png'),
    crossIcon: require('../../assets/icons/crossIcon.png'),
    plusIcon: require('../../assets/icons/plusIcon.png'),
    widgetEdit: require('../../assets/icons/widgetEdit.png'),
    CLUSTERED_COLUMN: require("../../assets/icons/CLUSTERED_COLUMN.png"),
    STACKED_COLUMN: require("../../assets/icons/STACKED_COLUMN.png"),
    STACKED_BAR: require('../../assets/icons/STACKED_BAR.png'),
    PIE: require('../../assets/icons/PIE.png'),
    DONUT: require('../../assets/icons/DONUT.png'),
    LINE: require('../../assets/icons/LINE.png'),
    AREA: require('../../assets/icons/AREA.png'),
    favIconVivrn: require('../../assets/icons/favIconVivrn.png'),
  };