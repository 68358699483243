import React, { useState } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";
import Select from "react-select";
import * as Yup from "yup";
import CreateScheduleApi from "../../service/api/CreateScheduleApi";
import { allTimeZone, frequencySelection, onTheMonthData } from "../../dtos/TimeZone";
import { todayDate } from "../../util/todayDate";
import { orgId } from "../../util/Utils";
import { useFormik } from "formik";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import './ScheduleReportModal.css';
import { icons } from "../../constants/icons/Icons";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { Form } from 'react-bootstrap';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Box from '@mui/material/Box';
import ApplicationButton from "../applicationButton/ApplicationButton";
import Swal from "sweetalert2";
const ScheduleReportModal = ({ reportDataResponse, modalOpen, closeModal }) => {
  if (!reportDataResponse) {
    return "No report data available";
  }
  const { id, workspaceDTO, widgetDTOS } = reportDataResponse
  const [showInputs, setShowInputs] = useState(true);
  const [submittedTime, setSubmittedTime] = useState("");
  const [frequency, setFrequency] = useState("hourly");
  const [onTheMonth, setOnTheMonth] = useState("First");
  const [weekNameMonthly, setWeekNameMonthly] = useState("Monday");
  let widgetId = null;
  if (widgetDTOS && widgetDTOS.length === 1) {
    widgetId = widgetDTOS[0].id;
  }

  const formatTimestamp = (timestamp) => {
    return dayjs(timestamp).format('hh:mm A');
  };
  const [selectedTime, setSelectedTime] = useState(null);
  const convertSelectedTime = formatTimestamp(selectedTime)
  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };



  const options = allTimeZone.map((zone) => ({ value: zone, label: zone }));

  const validationSchema = Yup.object({
    emailFrom: Yup.string().email("Invalid email format").required("Required"),
    emailTo: Yup.string().email("Invalid email format").required("Required"),
    subject: Yup.string().required("Required"),
    timeZone: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      emailFrom: "Notification@vivrn.com",
      emailTo: "",
      subject: "",
      timeZone: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values, "values")

      setShowInputs(false);

      const payload = {
        name: "students marks",
        description: "first schedule",
        createdDate: todayDate,
        createdBy: {
          id: orgId,
        },
        reportDTO: {
          id: id,
        },
        widgetDTO: {
          id: widgetId ? widgetId : null
        },
        workspaceDTO: {
          id: workspaceDTO.id,
        },
        scheduleType: frequency.toUpperCase(),
        frequency: {
          at: convertSelectedTime,
        },
        frequencyTimezone: values.timeZone,
        mailSubject: values.subject,
        emailRecipients: [values.emailTo],
        fileFormat: "PDF",
        active: true,
      };

      try {
        const response = await CreateScheduleApi(payload);
        if (response.httpStatusCode === 200) {
          Swal.fire({
            html: "<h4>Your report has been created successfully!</h4>",
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "NextButton", // Replace with your class name for the confirm button
            },
          });
        }
        setSubmitting(false);
        closeModal();
      } catch (error) {
        console.error("Error creating schedule:", error);
        setSubmitting(false);
      }
      
    },
  });
  const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const [selectedDay, setSelectedDay] = useState("Monday");

  const handleDayChange = (e) => {
    setSelectedDay(e.target.value);
  };
  const monthsSelection = ["Days of the Months", "On the"];
  const [monthSelectionData, setMonthSelectionData] = useState("Days of the Months");

  const handleMonthSelectionChange = (event) => {
    setMonthSelectionData(event.target.value);
  };
  const [value, setValue] = useState(dayjs(''));

  const startOfMonth = dayjs().startOf('month');
  const endOfMonth = dayjs().endOf('month');
  return (
    <Modal
      show={modalOpen}
      onHide={closeModal}
      centered
      dialogClassName="custom-modal"
      className="modalScheduleReport"
      size="lg"
    >
      <Modal.Body>
      <form onSubmit={formik.handleSubmit}>
        <div className="modalHeader">
          <h4 className="sheduleModal">Email As PDF</h4>
          <img src={icons.crossIcon} alt="close"  onClick={closeModal} className="cursor-pointer"/>
        </div>
      
          <div className="row">
            <div className="col-md-12">
              <div className="inputField">
                <TextInputWithLabel
                  placeholder="email address"
                  label="From"
                  name="emailFrom"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailFrom}
                  error={
                    formik.touched.emailFrom && formik.errors.emailFrom ? formik.errors.emailFrom : null
                  }
                  disabled={true}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="inputField">
                <TextInputWithLabel
                  placeholder="email"
                  label="To"
                  name="emailTo"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailTo}
                  error={
                    formik.touched.emailTo && formik.errors.emailTo ? formik.errors.emailTo : null
                  }
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="inputField">
                <TextInputWithLabel
                  placeholder="subject"
                  label="Subject"
                  name="subject"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                  error={
                    formik.touched.subject && formik.errors.subject ? formik.errors.subject : null
                  }
                />
              </div>
            </div>
            <div className="col-md-12 inputField">
              <label className="label-text-style" htmlFor="timezone">Time Zone</label>
              <Select
                value={options.find(
                  (option) => option.value === formik.values.timeZone
                )}
                onChange={(selectedOption) =>
                  formik.setFieldValue("timeZone", selectedOption.value)
                }
                options={options}
                placeholder="Time Zone"
                isSearchable
                id="timezone"
                name="timeZone"
                className="customSelect"
                // menuIsOpen={true}
                styles={{
                  placeholder: (provided) => ({
                    ...provided,
                    color: '#808080',
                    fontSize: 14
                  }),
                }}
              />
              {formik.touched.timeZone && formik.errors.timeZone && (
                <div className="ErrorMessage">{formik.errors.timeZone}</div>
              )}
            </div>
            <label className="label-text-style" htmlFor="time">Schedule</label>
            <div className="col-sm-6">
              <Dropdown onSelect={(e) => setFrequency(e)} className="commonDropdown">
                <Dropdown.Toggle variant="none" id="dropdown-basic">
                  {frequency
                    ? frequency.charAt(0).toUpperCase() + frequency.slice(1)
                    : "Select Schedule"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {frequencySelection.map((item, index) => (
                    <Dropdown.Item
                      key={index}
                      eventKey={item}
                      active={item === frequency}
                    >
                      {item.charAt(0).toUpperCase() + item.slice(1)}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {frequency == "hourly" ?
              null :
              <div className="col-sm-6">
                <LocalizationProvider dateAdapter={AdapterDayjs} className="customDatePicker">
                  <TimePicker
                    label=" Select Time"
                    value={selectedTime}
                    onChange={handleTimeChange}
                    ampm={true}
                    renderInput={(params) => (
                      <TextField {...params} className="customDatePicker" />
                    )}
                  />
                </LocalizationProvider>
              </div>
            }
            {frequency == "weekly" ?
              <div className="weekRadioButtom">
                {daysOfWeek?.map((day, index) => (
                  <Form.Check
                    key={index}
                    type="radio"
                    label={day}
                    name="dayPicker"
                    id={`dayPicker-${index}`}
                    value={day}
                    checked={selectedDay === day}
                    onChange={handleDayChange}
                  />
                ))}</div>
              : null}
            {frequency == "monthly" ?
              (<>
                <div className="monthlyRadioButtom">
                  {monthsSelection?.map((day, index) => (
                    <Form.Check
                      key={index}
                      type="radio"
                      label={day}
                      name="dayPicker"
                      id={`dayPicker-${index}`}
                      value={day}
                      checked={monthSelectionData === day}
                      onChange={handleMonthSelectionChange}
                    />
                  ))}
                </div>
                {monthSelectionData == "Days of the Months" ?
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                      sx={{
                        '& .MuiPickersCalendarHeader-switchViewButton': {
                          display: 'none',
                        },
                        '& .MuiPickersCalendarHeader-labelContainer': {
                          pointerEvents: 'none',
                        },
                        '& .MuiPickersCalendarHeader-iconButton': {
                          display: 'none',
                        },
                      }}
                    >
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={value}
                        shouldDisableDate={(date) =>
                          date.isBefore(startOfMonth) || date.isAfter(endOfMonth)
                        }
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </Box>
                  </LocalizationProvider> :
                  <div className="row paddingsetonmonth">
                    <div className="col-md-3 col-6">
                      <Dropdown onSelect={(e) => setOnTheMonth(e)} className="commonDropdown">
                        <Dropdown.Toggle variant="none" id="dropdown-basic">
                          {onTheMonth
                            ? onTheMonth.charAt(0).toUpperCase() + onTheMonth.slice(1)
                            : "Select Schedule"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {onTheMonthData.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              eventKey={item}
                              active={item === onTheMonth}
                            >
                              {item.charAt(0).toUpperCase() + item.slice(1)}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="col-md-3 col-6">
                      <Dropdown onSelect={(e) => setWeekNameMonthly(e)} className="commonDropdown">
                        <Dropdown.Toggle variant="none" id="dropdown-basic">
                          {weekNameMonthly
                            ? weekNameMonthly.charAt(0).toUpperCase() + weekNameMonthly.slice(1)
                            : "Select Schedule"}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {daysOfWeek?.map((item, index) => (
                            <Dropdown.Item
                              key={index}
                              eventKey={item}
                              active={item === weekNameMonthly}
                            >
                              {item.charAt(0).toUpperCase() + item.slice(1)}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                }


              </>)
              : null}
          </div>
        
        <div className="row modalFooter">
          <div className="col-6">
            <ApplicationButton btnText="Cancel" onClick={closeModal} className={"greyheight50"} />
          </div>
          <div className="col-6">
            <ApplicationButton btnText="Send" type="submit" className={"blueheight50"} />
          </div>
        </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleReportModal;
