import React, { useState } from 'react';
import AreaDoubleMain from './AreaDoubleMain';
import AreaDouble from './AreaDouble';

const AreaDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? 'Lower hierarchy' : 'Upper hierarchy'}
      </button>
      {showMainChart ? <AreaDoubleMain responseData={responseData}/> : <AreaDouble responseData={responseData}/>}
    </div>
  );
};

export default AreaDoubleToggle;