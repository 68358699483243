import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { excludedKeys } from "../../../dtos/MyContent.type";
import { userAuthToken } from "../../../dtos/Token.type";
import FileUploadButton from "../myContent/fileUploadButton/FileUploadButton";
import RefreshButton from "../myContent/refreshButton/RefreshButton";
import ViewToggleButton from "../myContent/viewToggleButton/ViewToggleButton";
import MoreInfoDropdown from "../myContent/moreInfoDropdown/MoreInfoDropdown";
import TableViewComponent from "../myContent/myContentTableView/TableView.component";
import TrashApiFetch from "../../../service/api/TrashApi";
import GetDataSetForTeamContentApi from "../../../service/api/GetTeamContentApi";
import GetTotalCountTeamContent from "../../../service/api/GetTotalCountTeamContentApi";
import ReactPaginate from "react-paginate";

export default function TeamContent() {
  const [uploadFiles, setUploadedFiles] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showCardContainer, setShowCardContainer] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [refreshApi, setRefreshApi] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 12;
  const [totalReportData, setTotalReportData] = useState("");
  const totalPages = Math.ceil(totalReportData / limit);
  const [afterDeleteRender, setAfterDeleteRender] = useState(false);
  const [allValue, setAllValue] = useState(false);
  const fetchGetDataSetteamContentApi = async (value) => {
    try {
      const result = await GetDataSetForTeamContentApi(value);
      if (result && result.response) {
        setUploadedFiles(result.response);
      } else {
        console.error("API response is undefined or null.");
      }
      setAfterDeleteRender(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGetDataSetteamContentApi(offset);
  }, [offset, allValue, afterDeleteRender]);

  useEffect(() => {
    if (Array.isArray(uploadFiles) && uploadFiles.length > 0) {
      const allKeys = Object?.keys(uploadFiles[0]);
      const filteredKeys = allKeys.filter((key) => !excludedKeys.includes(key));
      setKeys(filteredKeys);
    }
  }, [uploadFiles]);

  const toggleComponent = () => {
    setShowCardContainer(true);
  };

  const toggleComponentCard = () => {
    setShowCardContainer(false);
  };

  const handleRefresh = () => {
    fetchGetDataSetteamContentApi();
    Swal.fire("Data Refreshed!", "Table data has been updated.", "success");
    setRefreshApi(true);
  };

  const handleDelete = async (index, id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "NextButton",
        cancelButton: "CancelButton",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await TrashApiFetch(id, userAuthToken);
          const updatedFiles = uploadFiles.filter((file) => file.id !== id);
          setUploadedFiles(updatedFiles);
          Swal.fire("Deleted!", "The card has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete:", error);
        }
      }
    });
  };

  const handleMouseEnter = () => {
    setIsInfoVisible(true);
  };

  const handleMouseLeave = () => {
    setIsInfoVisible(false);
  };

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        const parsedData = convertCsvToJson(csvData);
        const date = new Date().toISOString();
        parsedData.forEach((obj) => {
          obj.date = date;
          obj.fileName = file.name;
        });
      };
      reader.readAsText(file);
    });
  };

  const convertCsvToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length === headers.length) {
        const obj = {
          fileName: currentLine[0].trim(),
          date: currentLine[1].trim(),
        };
        jsonData.push(obj);
      }
    }
    return jsonData;
  };
  const handlePageChange = ({ selected }) => {
    const newOffset = selected * limit;
    setOffset(newOffset);
  };
  const fetchCountTeamCntentApi = async () => {
    try {
      const data = await GetTotalCountTeamContent();
      if (data && data.response !== undefined && data.response !== null) {
        setTotalReportData(data.response);
      } else {
        console.log("data not found");
      }
    } catch (error) {
      console.error("Error fetching report count:", error);
    }
  };

  useEffect(() => {
    fetchCountTeamCntentApi();
  }, []);
  return (
    <div className="row">
      <div className="d-flex justify-content-end">
        <FileUploadButton onChange={handleFileChange} />
        <RefreshButton onClick={handleRefresh} />
        <ViewToggleButton
          onCardView={toggleComponent}
          onTableView={toggleComponentCard}
        />
      </div>
      {uploadFiles.length > 0 ? (
        showCardContainer ? (
          <div className="CardContainer">
            <div className="col-md-12">
              <div className="row">
                {uploadFiles?.map((item, index) => (
                  <div key={item.id} className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="Customcard">
                      <div className="card-body">
                        <div className="upload-data">
                          <MoreInfoDropdown
                            item={item}
                            onDelete={() => handleDelete(index, item.id)}
                            onMoreInfo={() => handleMouseEnter(index)}
                            isInfoVisible={isInfoVisible}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                          />
                          <p className="Analyticsupload-set">{item.baseName}</p>
                        </div>
                        <p className="card-text">
                          Upload data from excel csv, Html, xml, Json
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <TableViewComponent
            isInfoVisible={isInfoVisible}
            uploadFiles={uploadFiles}
            keys={keys}
            handleDelete={handleDelete}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        )
      ) : (
        <div className="row mt-2">
          <div className="col-md-12 d-flex justify-content-center">
            Data not found.
          </div>
        </div>
      )}
      <div className="CusPagination mt-5">
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          pageCount={totalPages}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}
