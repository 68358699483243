import React, { useState, useEffect } from "react";

function withSort(Component) {
  return function WithSortComponent({ data, updateApi }) {
    const { records, columnSchemaList } = data || {};
    const [sortColumn, setSortColumn] = useState(null);
    const [sortOrder, setSortOrder] = useState("asc");
    const [refreshTable, setRefreshTable] = useState(false);

    const handleSort = (columnName) => {
      if (sortColumn === columnName) {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      } else {
        setSortColumn(columnName);
        setSortOrder("asc");
      }
    };

    useEffect(() => {
      updateApi(refreshTable);
    }, [refreshTable]);

    const sortedRecords = () => {
      if (sortColumn && sortOrder) {
        return [...records].sort((a, b) => {
          const aValue =
            a[
              columnSchemaList.findIndex(
                (column) => column.columnName === sortColumn
              )
            ];
          const bValue =
            b[
              columnSchemaList.findIndex(
                (column) => column.columnName === sortColumn
              )
            ];

          if (sortOrder === "asc") {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
          } else {
            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0;
          }
        });
      }
      return records;
    };

    const refreshTables = () => {
      setRefreshTable(true);
    };

    return (
      <Component
        data={{ records: sortedRecords(), columnSchemaList }}
        updateApi={updateApi}
        handleSort={handleSort}
      />
    );
  };
}

export default withSort;
