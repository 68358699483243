import CryptoJS from "crypto-js";
import GetCookie from "../persistence/cookies/getCookie/GetCookie";

const getWorkspaceId = async () => {
  const userAuthToken = GetCookie('token');
  if (userAuthToken) {
    const [encodedHeader1, encodedPayload, signature1] = userAuthToken.split(".");

    // Base64 decoding
    const decodedHeader = CryptoJS.enc.Base64.parse(encodedHeader1).toString(CryptoJS.enc.Utf8);
    const decodedPayload = CryptoJS.enc.Base64.parse(encodedPayload).toString(CryptoJS.enc.Utf8);

    // Parse the JSON data
    const parsedHeader = JSON.parse(decodedHeader);
    const parsedPayload = JSON.parse(decodedPayload);

    // Access workspaceId property safely
    return parsedPayload?.workspaceId;
  }
  return null;
};

export default getWorkspaceId;


