import React, { useEffect, useState } from "react";
import { Formik, Form, ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import SignUpNavbar from "./SignUpNavbar";
import { useNavigate } from "react-router";
import PhoneNumberCode from "../../../dtos/CountryPhoneNumberCode.type";
import GoogleProtectedSignUp from "../../signUp/GoogleSignUpProtected";
import CryptoJS from "crypto-js";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";
import RemoveCookie from "../../../persistence/cookies/removeCookie/RemoveCookie";
import LoginApi from "../../../service/api/LoginApi";
import { LOGIN_ENDPOINT } from "../../../service/endPoints/Endpoint";
import GoogleLoginApi from "../../../service/api/GoogleLoginApi";
import { Link } from "react-router-dom";
var header = {
  alg: "HS256",
  typ: "JWT",
};

var secret = "My very confidential secret!!!";

function base64url(source) {
  var encodedSource = CryptoJS.enc.Base64?.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, "");
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");
  return encodedSource;
}
const SignUpToday = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("91");
  const [passwordValidation, setPasswordValidation] = useState({
    oneLowercase: false,
    oneUppercase: false,
    oneNumber: false,
    oneSpecialCharacter: false,
    eightMinCharacter: false,
  });
  const handleChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    phoneNumber: "",
    password: "",
    agreeToTerms: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(/^[0-9]{1,15}$/, "Please give valid phone number"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()])[A-Za-z\d!@#$%^&*()]{8,}$/,
        "Password must contain at least one lowercase, one uppercase, one number, one special character, and be at least 8 characters long"
      ),
  });

  const onSubmit = (values, { setSubmitting }) => {
    const { email, password, agreeToTerms, phoneNumber } = values;
    const fullPhoneNumber = `${selectedCountryCode}${phoneNumber}`;
    const data = {
      email,
      password,
      agreeToTerms,
      phoneNumber: fullPhoneNumber,
    };
    const stringfyValues = JSON.stringify(data);
    console.log(data, "data");
    localStorage.setItem("sign-up-today", stringfyValues);
    setSubmitting(false);
    navigate("/setUpAccount");
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;
    setPasswordValidation({
      oneLowercase: /[a-z]/.test(password),
      oneUppercase: /[A-Z]/.test(password),
      oneNumber: /[0-9]/.test(password),
      oneSpecialCharacter: /[!@#$%^&*()]/.test(password),
      eightMinCharacter: password.length >= 8,
    });
  };

  useEffect(() => {
    const parsedPayload = GetCookie("loginResponsecode");
    const organizationUrl = parsedPayload?.organizationUrl;
    let subdomain = null;
    if (parsedPayload && organizationUrl) {
      const parts = organizationUrl.split(".");
      if (parts.length >= 3) {
        subdomain = parts[0];
        parts.splice(0, 1);
      }
    }
    let newURL;
    const tokenlogin = GetCookie("token");
    if (tokenlogin) {
      if (window.location.hostname == "localhost") {
        navigate("/home");
      } else {
        newURL = `https://${subdomain}.vivrn.com/home`;
        window.location.href = newURL;
      }
    }
  }, []);
  useEffect(() => {
    const tokenlogin = GetCookie("token");
    if (window.location.hostname !== "localhost") {
      if (!tokenlogin) {
        // Check if the current domain is not www.abc.com
        if (window.location.hostname !== "www.vivrn.com") {
          // Construct the new URL with the original subdomain as a query parameter
          const redirectUrl = `https://www.vivrn.com/login?redirect_to=${window.location.hostname}`;
          // Redirect to www.abc.com with the original subdomain as a query parameter
          window.location.href = redirectUrl;
        }
      }
    }
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Please enter your email address")
        .matches(regex, "Enter a valid email"),
      password: Yup.string()
        .min(7, "Password must be at least 7 characters")
        .required("Please enter your password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (rememberMe) {
        SetCookie("email", values.email);
      } else {
        RemoveCookie("email");
      }
      // Prevent the default form submission behavior
      var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
      var encodedHeader = base64url(stringifiedHeader);

      const loginObj = {
        // emailId: "animeshchoudhary856@gmail.com",
        emailId: values.email,
        // password: "Passkey@9779",
        password: values.password,
        language: "en",
        country: "In",
      };

      var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(loginObj));
      var encodedData = base64url(stringifiedData);

      var signature = encodedHeader + "." + encodedData;
      signature = CryptoJS.HmacSHA256(signature, secret);
      signature = base64url(signature);

      const emailPasswordJWT =
        encodedHeader + "." + encodedData + "." + signature;
      console.log(emailPasswordJWT);
      var AuthToken = {
        authToken: emailPasswordJWT,
      };
      const AuthTokenStringyfyPayload = JSON.stringify(AuthToken);

      console.log(AuthTokenStringyfyPayload);

      const result = await LoginApi(AuthTokenStringyfyPayload, LOGIN_ENDPOINT);
      if (result === true) {
        resetForm();
        // decode loginresponse
        const token = GetCookie("token");
        // const token = JSON.parse(userAuthToken);
        console.log(token, "token");
        // Split the token into its three parts: header, payload, and signature
        const [encodedHeader1, encodedPayload, signature1] = token.split(".");
        // Base64 decoding
        const decodedHeader = CryptoJS.enc.Base64.parse(
          encodedHeader1
        ).toString(CryptoJS.enc.Utf8);
        const decodedPayload = CryptoJS.enc.Base64.parse(
          encodedPayload
        ).toString(CryptoJS.enc.Utf8);
        // Parse the JSON data
        const parsedHeader = JSON.parse(decodedHeader);
        // localStorage.setItem("orgId",parsedHeader)
        const parsedPayload = JSON.parse(decodedPayload);
        SetCookie("loginResponsecode", JSON.stringify(parsedPayload));
        // localStorage.setItem(
        //   "loginResponsecode",
        //   JSON.stringify(parsedPayload)
        // );
        // console.warn(JSON.parse(parsedPayload),"JSON.stringify(parsedPayload)");
        const organizationUrl = parsedPayload.organizationUrl;
        let subdomain = null;
        if (parsedPayload && organizationUrl) {
          const parts = organizationUrl.split(".");
          if (parts.length >= 3) {
            subdomain = parts[0];
            parts.splice(0, 1);
          }
        }
        let newURL;
        if (window.location.hostname == "localhost") {
          navigate("/workSpace");
        } else {
          newURL = `https://${subdomain}.vivrn.com/workSpace`;
          window.location.href = newURL;
        }
      } else {
        // Logout failed
      }

      // console.log(AuthTokenStringyfy,"AuthToken")
      //  LoginApi(AuthTokenStringyfy);
    },
  });
  useEffect(() => {
    const savedEmail = GetCookie("email");
    const savedPassword = GetCookie("password");
    if (savedEmail && savedPassword) {
      formik.setFieldValue("email", savedEmail);
      formik.setFieldValue("password", savedPassword);
      setRememberMe(true);
    }
  }, []);
  // googleLogout();
  const [googleCredential, setGoogleCredential] = useState(null);

  const handleLoginSuccess = (credentialResponse) => {
    console.log("Login successful in main parent:", credentialResponse);
    setGoogleCredential(credentialResponse);
  };
  console.log(googleCredential, "googleCredentialMain");
  const fetchDataGoogle = async () => {
    const payloadGoogle = {
      googleAuthToken: googleCredential?.credential,
    };
    console.log(payloadGoogle, "payloadGoogle");
    console.log(googleCredential, "googleCredentialChild");
    try {
      const AuthTokenGoogleStringifyPayload = JSON.stringify(payloadGoogle);
      console.log(AuthTokenGoogleStringifyPayload, "chcek");
      const result = await GoogleLoginApi(
        AuthTokenGoogleStringifyPayload,
        LOGIN_ENDPOINT
      );

      if (result === true) {
        const token = GetCookie("token");
        console.log(token, "token");

        // Split the token into its three parts: header, payload, and signature
        const [encodedHeader1, encodedPayload, signature1] = token.split(".");

        // Base64 decoding
        const decodedHeader = CryptoJS.enc.Base64.parse(
          encodedHeader1
        ).toString(CryptoJS.enc.Utf8);
        const decodedPayload = CryptoJS.enc.Base64.parse(
          encodedPayload
        ).toString(CryptoJS.enc.Utf8);

        // Parse the JSON data
        const parsedHeader = JSON.parse(decodedHeader);
        SetCookie("orgId", parsedHeader); // Note: Passing parsedHeader directly instead of stringifying it
        const parsedPayload = JSON.parse(decodedPayload);
        SetCookie("loginResponsecode", JSON.stringify(parsedPayload));

        const organizationUrl = parsedPayload.organizationUrl;
        let subdomain = null;

        if (parsedPayload && organizationUrl) {
          const parts = organizationUrl.split(".");
          if (parts.length >= 3) {
            subdomain = parts[0];
            parts.splice(0, 1);
          }
        }

        let newURL;
        if (window.location.hostname === "localhost") {
          navigate("/workSpace"); // Navigate to "/home" if running locally
        } else {
          newURL = `https://${subdomain}.vivrn.com/workSpace`;
          window.location.href = newURL; // Redirect to the generated URL
        }
      } else {
        setGoogleCredential(null);
        // Logout failed
      }
    } catch (error) {
      console.error("Error:", error);
      setGoogleCredential(null);
      // Handle error
    }
  };

  // fetchData();
  useEffect(() => {
    if (googleCredential) {
      fetchDataGoogle();
    }
  }, [googleCredential]);
  return (
    <>
      <div className="container-fluid backgroundColorBody p-0">
        <SignUpNavbar />
        <div className="row w-100">
          <div className="col-md-12">
            <div className="SignUpRight">
              <h3 className="text-center">Sign up with free trial</h3>
              <p className="text-center subHeading mb-2">
                Empower your experience, sign up for a free account today
              </p>
              <div style={{display: "flex",justifyContent: "center"
}}>
              <GoogleProtectedSignUp onLoginParentSucess={handleLoginSuccess} />
              </div>

              <div className="signUpFormContainer">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {(formik) => (
                    <Form>
                      <div className="inputField">
                        <label>Work email*</label>
                        <input
                          type="text"
                          name="email"
                          className="customInput"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error mt-2"
                        />
                      </div>
                      <div
                        className="inputField"
                        style={{ position: "relative" }}
                      >
                        <label>Phone number*</label>
                        <div className="d-flex position-relative" >
                          <div className="inputField">
                            <select
                              className="form-select customInput"
                              value={selectedCountryCode}
                              onChange={handleChange}
                            >
                              {PhoneNumberCode?.map((option, index) => (
                                <option key={index} value={option.value}>
                                  +{option.value}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="w-100 phone-number-align">
                            <input
                              type="tel"
                              name="phoneNumber"
                              className="customInput"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.phoneNumber}
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component="div"
                              className="error mt-2"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inputField">
                        <label>Password*</label>
                        <input
                          type="password"
                          name="password"
                          className="customInput"
                          onChange={(event) => {
                            handlePasswordChange(event);
                            formik.handleChange(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error mt-2"
                        />
                      </div>
                      <div className="PasswordCharacterVerify">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="one-lowercase"
                                name="one-lowercase"
                                value="one-lowercase"
                                className="form-check-input"
                                checked={passwordValidation.oneLowercase}
                                readOnly
                              />
                              <label htmlFor="one-lowercase" className="ms-2 mt-2">
                                One lowercase character
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="one-number"
                                name="one-number"
                                value="one-number"
                                className="form-check-input"
                                checked={passwordValidation.oneNumber}
                                readOnly
                              />
                              <label htmlFor="one-number" className="ms-2 mt-2">One number</label>
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="one-uppercase"
                                name="one-uppercase"
                                value="one-uppercase"
                                className="form-check-input"
                                checked={passwordValidation.oneUppercase}
                                readOnly
                              />
                              <label htmlFor="one-uppercase" className="ms-2 mt-2">
                                One uppercase character
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="one-special-character"
                                name="one-special-character"
                                value="one-special-character"
                                className="form-check-input"
                                checked={passwordValidation.oneSpecialCharacter}
                                readOnly
                              />
                              <label htmlFor="one-special-character" className="mt-2 ms-2">
                                One special character
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6 mt-2">
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                id="eight-min-character"
                                className="form-check-input"
                                name="eight-min-character"
                                value="eight-min-character"
                                checked={passwordValidation.eightMinCharacter}
                                readOnly
                              />
                              <label htmlFor="eight-min-character" className="ms-2 mt-2">
                                8 characters minimum
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-check form-check-inline checkToSendingEmail">
                        <input
                          type="checkbox"
                          id="agreeToTerms"
                          name="agreeToTerms"
                          className="form-check-input"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          checked={formik.values.agreeToTerms}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="agreeToTerms"
                        >
                          Please exclude me from any future emails regarding
                          Triosale and related Intuit product and feature
                          updates, marketing best practices, and promotions.
                        </label>
                        <label className="second">
                          By registering for an account, you are consenting to
                          our Terms of Service and confirming that you have
                          reviewed and accepted the Global Privacy Statement.
                        </label>
                        <ErrorMessage
                          name="agreeToTerms"
                          component="div"
                          className="error"
                        />
                      </div>
                      <button type="submit" disabled={formik.isSubmitting}>
                        Get started free
                      </button>
                    </Form>
                  )}
                </Formik>
                <div className="d-flex align-items-center loginInAccount">
                  <p>Already have an account?</p>
                  <Link to="/login" className="text-decoration-none login">Login</Link>
                </div>
                <div className="d-flex align-items-center loginInAccount">
                  <p>Or Sign in using </p>
                  <Link to="/login" className="text-decoration-none login">Google</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpToday;
