import React, { useState } from "react";
import { GoogleImage, SignUpbg } from "../../constants/pictures/Pictures";
import HeaderSignUp from "../../component/header/HeaderSignUp";
import "./SignUp.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SignUpApi from "../../service/api/SignUpApi";

function SignUp() {
  const navigate = useNavigate();
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const regexphone =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email:"",
      company:"",
      mobileNumber:"",
      country:"",
    },
    validationSchema: yup.object({
        firstName:yup.string().required("Please enter your first name"),
        lastName:yup.string().required("Please enter your last name"),
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter your email address")
        .matches(regex, "Enter a valid email"),
      company:yup.string().required("Please enter your company name"),
        mobileNumber:yup.string().required("Please enter your mobile number")
        .matches(regexphone, "Enter a valid mobile number"),
        country:yup.string().required("Please enter your country name"),
    }),
    onSubmit: async (values, {setSubmitting, resetForm }) => {
      console.log(values,"signup")
      const date = new Date().getTime();
    const SignUpObj = {
      "firstName": values.firstName,
      "lastName": values.lastName,
      "email": values.email,
      "contactNumber": values.mobileNumber,
      "subject": null,
      "message":null,
      "createdDate": date,
      "country": values.country,
    "company": values.company,
    "signUp": true
    };
    // Your form submission logic goes here
    console.log(values);
    const result = await SignUpApi(SignUpObj);
    if (result === true) {
      resetForm();
      navigate("/");
    } else {
      // api failed
    }
    setSubmitting(false);
    },
  });
  return (
    <>
      <HeaderSignUp />
      <div className="SignUpContainer">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-md-12">
              <div className="InputBoxVivrn">
                <div className="VivranBOxLeft">
                  <h5 className="mb-0">Vivrn</h5>
                </div>
                <div className="VivrnBoxCenter"></div>
                <div className="VivranBOxRight">
                  <h5 className="mb-0">
                    Empowering Data Driven Decision With AI Integration
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="BusinessIntelligence">
                <h3>Business Intelligence, Reporting and Analytics Software</h3>
                <p>
                  Business Intelligence, Reporting, and Analytics software
                  enables organizations to collect, analyze, and present data
                  for informed decision-making, providing insights and
                  empowering growth.
                </p>
                <img src={SignUpbg} alt="business" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="SignUpForm">
                <form onSubmit={formik.handleSubmit}>
                  <div className="FormSignUp">
                    <div className="row">
                      <div className="col-sm-6">
                        {/* first name */}
                        <div className="emailAdress m-0">
                          <div className="UserIcon">
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            {...formik.getFieldProps("firstName")}
                          />
                          {formik.touched.firstName &&
                            formik.errors.firstName && (
                              <p className="error-message">
                                {formik.errors.firstName}
                              </p>
                            )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        {/* last name */}
                        <div className="emailAdress m-0">
                          <div className="UserIcon">
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            {...formik.getFieldProps("lastName")}
                          />
                          {formik.touched.lastName &&
                            formik.errors.lastName && (
                              <p className="error-message">
                                {formik.errors.lastName}
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="emailAdress">
                      <div className="UserIcon">
                      <i className="fa-solid fa-briefcase"></i>
                      </div>
                      <input
                        type="text"
                        name="email"
                        placeholder="Work Email"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <p className="error-message">{formik.errors.email}</p>
                      )}
                    </div>
                    {/* company */}
                    <div className="emailAdress">
                      <div className="UserIcon">
                      <i className="fa-solid fa-building"></i>
                      </div>
                      <input
                        type="text"
                        name="company"
                        placeholder="Company"
                        {...formik.getFieldProps("company")}
                      />
                      {formik.touched.company && formik.errors.company && (
                        <p className="error-message">{formik.errors.company}</p>
                      )}
                    </div>
                    {/* mobile no */}
                    <div className="emailAdress">
                      <div className="UserIcon">
                      <i className="fa-solid fa-mobile-screen-button"></i>
                      </div>
                      <input
                        type="text"
                        name="mobileNumber"
                        placeholder="Mobile Number"
                        {...formik.getFieldProps("mobileNumber")}
                      />
                      {formik.touched.mobileNumber && formik.errors.mobileNumber && (
                        <p className="error-message">{formik.errors.mobileNumber}</p>
                      )}
                    </div>
                    {/* country */}
                    <div className="emailAdress">
                      <div className="UserIcon">
                      <i className="fa-solid fa-globe"></i>
                      </div>
                      <input
                        type="text"
                        name="country"
                        placeholder="Country"
                        {...formik.getFieldProps("country")}
                      />
                      {formik.touched.country && formik.errors.country && (
                        <p className="error-message">{formik.errors.country}</p>
                      )}
                    </div>
                    <div className="btnSubmit">
                    <button className="submitBtn" type="submit">
                    SIGN UP FOR FREE
                    </button>
                  </div>
                  </div>

                  
                  <div className="SignUpAccept">
                    <p>By signing up, I accept the Vivrn <a>Terms of Service</a> and <a>Privacy Notice</a>.</p>
                    {/* <div className="gsignUpbyGoogle">
                        <p>Or Sign In using </p>
                        <img src={GoogleImage} alt="google"/>
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
