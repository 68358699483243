import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import getWorkspaceId from "../../util/AttributeUtil";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

export default async function GetReportCount() {
    const userAuthToken = GetCookie("token");
    const workspaceId = await getWorkspaceId();
    if (userAuthToken !== undefined && userAuthToken !== null) {
        try {
            const response = await fetch(
                getApiUrl() + apiUrls.report.getReportCount(workspaceId),
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "authToken": userAuthToken,
                    },
                }
            );
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    } else {
        console.error("User auth token is undefined or null");
        // Handle this case as per your application logic
        // For example, you might want to redirect the user to the login page or display an error message
    }
}
