import React from "react";
import "./Register.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("Please enter your first name"),
      lastName: yup.string().required("Please enter your last name"),
      email: yup
        .string()
        .email("Please enter valid email")
        .required("Please enter your email address ")
        .matches(regex, "Enter a valid email "),
      password: yup
        .string()
        .min(7, "Password must be at least 7 characters")
        .required("Please enter your password"),
      confirmPassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: (values, { resetForm }) => {
      resetForm();
      console.log(values);
    },
  });
  return (
    <div className="logIn">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10 col-sm-12">
            <div className="registerContainer">
              <h1 className="Register">Create Your Mountaindrop account</h1>
              <div className="LogoLogin">
                <form id="user-form" onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="FormLogIn">
                        <div className="emailAdressRegister">
                          <div className="UserIcon">
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            {...formik.getFieldProps("firstName")}
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <p
                              style={{
                                color: "#ff0000ab",
                                fontSize: "15px",
                                textAlign: "left",
                              }}
                            >
                              {formik.errors.firstName}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="FormLogIn">
                        <div className="emailAdressRegister">
                          <div className="UserIcon">
                            <i className="fa-regular fa-user"></i>
                          </div>
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            {...formik.getFieldProps("lastName")}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <p
                              style={{
                                color: "#ff0000ab",
                                fontSize: "15px",
                                textAlign: "left",
                              }}
                            >
                              {formik.errors.lastName}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="emailAdressRegister">
                        <div className="UserIcon">
                          <i className="fa-regular fa-envelope"></i>
                        </div>
                        <input
                          type="text"
                          name="email"
                          placeholder="Enter email Address"
                          {...formik.getFieldProps("email")}
                          autoComplete="off"
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <p
                            style={{
                              color: "#ff0000ab",
                              fontSize: "15px",
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.email}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="emailAdressRegister">
                        <div className="UserIcon">
                          <i className="fa-solid fa-lock"></i>
                        </div>
                        <input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          {...formik.getFieldProps("password")}
                          autoComplete="off"
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <p
                            style={{
                              color: "#ff0000ab",
                              fontSize: "15px",
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.password}
                          </p>
                        ) : null}
                        <div
                          className="ShowPassword"
                          onClick={handleTogglePassword}
                        >
                          <i
                            className={
                              showPassword
                                ? "fa-regular fa-eye"
                                : "fa-regular fa-eye-slash"
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="emailAdressRegister">
                        <div className="UserIcon">
                          <i className="fa-solid fa-lock"></i>
                        </div>
                        <input
                          type={showConfirmPass ? "text" : "password"}
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          {...formik.getFieldProps("confirmPassword")}
                        />
                        {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword ? (
                          <p
                            style={{
                              color: "#ff0000ab",
                              fontSize: "15px",
                              textAlign: "left",
                            }}
                          >
                            {formik.errors.confirmPassword}
                          </p>
                        ) : null}
                        <div
                          className="ShowPassword"
                          onClick={handleToggleConfirmPassword}
                        >
                          <i
                            className={
                              showConfirmPass
                                ? "fa-regular fa-eye"
                                : "fa-regular fa-eye-slash"
                            }
                          ></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="btnSubmit">
                    <button className="submitBtn" type="submit">
                      Create my Account
                    </button>
                  </div>
                  <div className="AlreadyAccount">
                    <span>
                      Already have an account?
                      <Link to="/login" className="anchor-line-remove ms-2">
                        Sign In
                      </Link>
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
