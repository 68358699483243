import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import SmartTableHeader from "./TableHeader";
import TableControl from "./TableControl";
import './DynamicTable.css';

const DynamicalTable = ({ dataApi }) => {
  console.log(dataApi, "file report")
  const { columnSchemaList, records } = dataApi || {};

  if (!columnSchemaList || !records) {
    console.error("DataApi response is undefined or missing properties.");
    return null;
  }
  const [editableRows, setEditableRows] = useState(
    records && Array.isArray(records)
      ? records?.map((record, index) =>
        record.reduce(
          (obj, value, columnIndex) => {
            obj[columnSchemaList[columnIndex].columnName] = value;
            return obj;
          },
          { id: index + 1 }
        )
      )
      : []
  );

  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [checkedColumns, setCheckedColumns] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [allData, setAllData] = useState([]);
  const [columnSchemaLists, setColumnSchemaList] = useState([
    ...(columnSchemaList || []),
  ]);
  const [newColumnName, setNewColumnName] = useState("");
  const [hiddenRows, setHiddenRows] = useState([]);
  useEffect(() => {
    setAllData(editableRows);
  }, [editableRows]);

  const handleSort = (field, order) => {
    if (!editableRows || editableRows.length === 0) {
      return;
    }
    const sortedData = [...editableRows].sort((a, b) => {
      if (order === "asc") {
        return a[field] - b[field];
      } else {
        return b[field] - a[field];
      }
    });
    setEditableRows(sortedData);
    setSortField(field);
    setSortOrder(order);
  };

  const handleDataTypeChange = (columnIndex, dataType) => {
    if (!columnSchemaList || columnSchemaList.length === 0) {
      return;
    }
    const updatedColumnSchemaList = [...columnSchemaList];
    updatedColumnSchemaList[columnIndex].displayType = dataType;
    setColumnSchemaList(updatedColumnSchemaList);
  };

  const handleColumnSelection = (columnIndex, isSelected) => {
    if (isSelected) {
      setCheckedColumns([...selectedColumns, columnIndex]);
    } else {
      setCheckedColumns(
        selectedColumns.filter((index) => index !== columnIndex)
      );
    }
  };
  const columns =
    Array.isArray(columnSchemaLists) && columnSchemaLists.length > 0
      ? columnSchemaLists
        .filter((c) => !selectedColumns.includes(c.columnName))
        .map((column) => ({
          dataField: column.columnName,
          text: column.columnName,
          headerFormatter: (c, colIndex, { sortElement, filterElement }) => (
            <SmartTableHeader
              column={column}
              c={c}
              columnIndex={colIndex}
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={handleSort}
              onChangeDataType={handleDataTypeChange}
              onSelectColumn={handleColumnSelection}
            />
          ),
          formatter: (cell, row) => cell,
          editable: true,
          sort: true,
        }))
      : [];

  const addRow = () => {
    if (!columnSchemaList || columnSchemaList.length === 0) {
      return;
    }

    const newRowId = Math.random().toString(36).substring(7);
    const newRow = {};
    columnSchemaList.forEach((column) => {
      newRow[column.columnName] = "";
    });
    newRow.id = newRowId;
    setEditableRows([...editableRows, newRow]);
  };

  const deleteSelectedRows = () => {
    const updatedRows = editableRows.filter(
      (row) => !selectedRowIds.includes(row.id)
    );
    setEditableRows(updatedRows);
    setSelectedRowIds([]);
  };

  const deleteSelectedColumns = () => {
    // Check if columnSchemaList is defined and not null
    if (!columnSchemaList || columnSchemaList.length === 0) {
      // Handle the case where columnSchemaList is undefined or empty
      return;
    }

    const updatedColumnSchemaList = columnSchemaList.filter(
      (_, index) => !checkedColumns.includes(index)
    );

    // Check if editableRows is defined and not null
    if (!editableRows) {
      // Handle the case where editableRows is undefined or null
      return;
    }

    const updatedEditableRows = editableRows.map((row) => {
      const newRow = {};
      updatedColumnSchemaList.forEach((column) => {
        newRow[column.columnName] = row[column.columnName];
      });
      newRow.id = row.id;
      return newRow;
    });

    setColumnSchemaList(updatedColumnSchemaList);
    setEditableRows(updatedEditableRows);
    setCheckedColumns([]);
  };

  const handleRowSelection = (row, isSelected) => {
    const id = row.id;
    if (isSelected) {
      setSelectedRowIds([...selectedRowIds, id]);
    } else {
      setSelectedRowIds(selectedRowIds.filter((rowId) => rowId !== id));
    }
  };

  const [columnOptionsnew, setColumnOptions] = useState([...columnSchemaLists]);

  const handleColumnChange = (selectedOptions) => {
    const selectedColumnNames = selectedOptions?.map((option) => option.value);
    setSelectedColumns(selectedColumnNames);
    const filteredColumnOptions = columnOptionsnew.filter(
      (column) => selectedColumnNames.includes(column.value) || column.value
    );
    setColumnOptions(filteredColumnOptions);
  };

  const addColumn = () => {
    const newColumnName = prompt("Enter the name for the new column:");
    if (!newColumnName || newColumnName.trim() === "") {
      alert("Column name cannot be empty. Please provide a valid column name.");
      return;
    }
    let dataType = prompt(
      "Enter the data type for the new column (e.g., text, number, date):"
    );
    if (!dataType || dataType.trim() === "") {
      alert("Data type cannot be empty. Please provide a valid data type.");
      return;
    }

    dataType = dataType.toUpperCase();
    const newColumn = {
      columnName: newColumnName,
      displayType: dataType,
    };
    setColumnSchemaList([...columnSchemaLists, newColumn]);
    const updatedEditableRows = editableRows?.map((row) => {
      return {
        ...row,
        [newColumnName]: "",
      };
    });
    setEditableRows(updatedEditableRows);
    setColumnOptions([
      ...columnOptions,
      { label: newColumnName, value: newColumnName },
    ]);
  };

  const { ExportCSVButton } = CSVExport;
  const columnOptions = columnSchemaList?.map((column) => ({
    label: column.columnName,
    value: column.columnName,
  }));

  const cellEditOptions = {
    mode: "click",
    blurToSave: true,
    afterAll(oldValue, newValue, row, column) {
      const updatedRows = editableRows?.map((erow) => {
        if (erow.id === row.id) {
          return { ...erow, [column.dataField]: newValue };
        }
        return erow;
      });
      setEditableRows(updatedRows);
      return true;
    },
  };

  return (
    <ToolkitProvider
      keyField={columns.length > 0 ? columns[0].dataField : ""}
      data={editableRows}
      columns={columns}
      search
      exportCSV={{
        fileName: "vivrn.csv",
        ignoreHeader: false,
        noAutoBOM: true,
        headerStyle: "bold",
      }}
    >
      {(props) => (
        <div className="overflow-auto">
          <div className="row">
            <div className="col-md-12">
              <TableControl
                addRow={addRow}
                deleteSelectedRows={deleteSelectedRows}
                deleteSelectedColumns={deleteSelectedColumns}
                handleColumnChange={handleColumnChange}
                addColumn={addColumn}
                selectedColumns={selectedColumns}
                columnOptions={columnOptions}
              />
            </div>
            <div className="col-md-12">
              <div className="exportCSVBox">
                <ExportCSVButton
                  {...props.csvProps}
                  className="export-csv PreviousButton"
                  style={{ background: "#58b957", color: "#ffff" }}
                >
                  <i className="fa-solid fa-file-export ml-2"></i> Export CSV!!
                </ExportCSVButton>

                <Search.SearchBar
                  {...props.searchProps}
                  className="searchTable"
                />
              </div>
            </div>
            {columns.length > 0 && (
              <div className="col-md-12">
                <BootstrapTable
                  {...props.baseProps}
                  keyField="id"
                  columns={columns}
                  data={editableRows}
                  pagination={paginationFactory()}
                  cellEdit={cellEditFactory(cellEditOptions)}
                  hover={{ color: "#58b957" }}
                  defaultSorted={[{ dataField: sortField, order: sortOrder }]}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </ToolkitProvider>
  );
};

export default DynamicalTable;
