import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Landing.css";
import {
  AILanding,
  AddressLanding,
  Bars,
  CollaborationLanding,
  DataBlendingLanding,
  DataVisualizationLanding,
  IntegrationLanding,
  LogoLanding,
  NaturalLandLanding,
  NaturalLanguageLanding,
  PhoneLanding,
  PridctiveLanding,
  PridictiveAnalysisLanding,
  RealTimeAccessLanding,
  RealTimeLanding,
  SmartAnalysisLanding,
  contactFooterLanding,
  emailFooterLanding,
  locationFooterLanding,
  macBook,
  vivranDashboard,
  vivranLogo_123,
} from "../../constants/pictures/Pictures";
import LandingContactApi from "../../service/api/LandingContactApi";

export default function Landing() {
  const [isHeaderSticky, setHeaderSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setHeaderSticky(scrollPosition > 100); 
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // start scrolling
  const [activeSection, setActiveSection] = useState("Home");
  const contentRef = useRef(null);
  const handleSectionClick = (sectionId, event = undefined) => {
    if (event) {
      event.preventDefault();
    }
    setActiveSection(sectionId);
    const sectionElement = document.getElementById(sectionId);
    const yOffset = -100; 
    const y =
      sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const handleScroll = () => {
    const contentSections =
      contentRef.current.getElementsByClassName("content-section");
    for (let i = 0; i < contentSections.length; i++) {
      const section = contentSections[i];
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setActiveSection(section.id);
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // end scrolling
  // contact form validation start
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const regexphone =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/i;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(regexphone, "Invalid phone number")
      .required("Phone is required"),
    project: Yup.string()
      .required("Project details are required")
      .max(500, "Project details not be more than 500 characters"),
  });

  const initialValues = {
    name: "", // Initial value for name field
    email: "", // Initial value for email field
    phone: "", // Initial value for phone field
    project: "", // Initial value for project field
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const date = new Date().getTime();
    const ContactLandingObj = {
      firstName: values.name,
      lastName: null,
      email: values.email,
      contactNumber: values.phone,
      subject: "check",
      message: values.project,
      createdDate: date,
    };
    console.log(values);
    const result = await LandingContactApi(ContactLandingObj);
    if (result === true) {
      resetForm();
    } else {
      // api failed
    }
    setSubmitting(false);
  };

  return (
    <>
      <section className="CusBoxShadow">
        <div className="container ps-0 pe-0">
          <div className="row">
            <div className="col-12">
              <div className="CusLapHeader">
                <div
                  className={`header-container ${
                    isHeaderSticky ? "sticky" : "nonsticky"
                  }`}
                >
                  {/* <header className="d-flex flex-wrap justify-content-center align-items-center position-relative pt-2" > */}
                  <header className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="me-auto">
                      <Link
                        path="/"
                        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
                      >
                        <img src={LogoLanding} className="logo" />
                      </Link>
                    </div>

                    <ul className="nav nav-pills navHeader">
                      <li className="nav-item">
                        <a
                          className={activeSection === "Home" ? "active" : ""}
                          onClick={(event) => handleSectionClick("Home", event)}
                        >
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeSection === "Feature" ? "active" : ""
                          }
                          onClick={(event) =>
                            handleSectionClick("Feature", event)
                          }
                        >
                          Feature
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeSection === "Overview" ? "active" : ""
                          }
                          onClick={(event) =>
                            handleSectionClick("Overview", event)
                          }
                        >
                          Overview
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            activeSection === "Contact" ? "active" : ""
                          }
                          onClick={(event) =>
                            handleSectionClick("Contact", event)
                          }
                        >
                          Contact Us
                        </a>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          activeclassname="active"
                          to="/Sign-up-today"
                          className="nav-content-bttn open-font"
                        >
                          <button className="buttontryNow">Try now</button>
                        </NavLink>
                      </li>
                    </ul>
                  </header>
                </div>
              </div>
              <div className="MobileSection">
                <div
                  className={`header-container ${
                    isHeaderSticky ? "sticky" : "nonsticky"
                  }`}
                >
                  <header className="d-flex flex-wrap justify-content-center align-items-center">
                    <div className="me-auto">
                      <Link path="/" className="d-flex align-items-center">
                        <img src={LogoLanding} className="logo" />
                      </Link>
                    </div>

                    <div className="text-end me-2 barIcondiv">
                      {!isOpen ? (
                        <img
                          src={Bars}
                          className={`bars hamburger-icon ${
                            isOpen ? "rotate-out" : "rotate-in"
                          }`}
                          onClick={toggleMenu}
                        />
                      ) : (
                        <button
                          className={`cross-btnLanding ${
                            isOpen ? "rotate-out" : "rotate-in"
                          }`}
                          onClick={toggleMenu}
                        >
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>
                    <div className={`mobilenav ${isOpen ? "open" : ""}`}>
                      <div
                        className={`mob-nav-content ${
                          isOpen ? "slide-up" : "slide-down"
                        }`}
                      >
                        <ul>
                          <li className="nav-item">
                            <a
                              className={
                                activeSection === "Home"
                                  ? "active nav-content-bttn open-font"
                                  : "nav-content-bttn open-font"
                              }
                              onClick={(event) =>
                                handleSectionClick("Home", event)
                              }
                            >
                              Home
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={
                                activeSection === "Feature"
                                  ? "active nav-content-bttn open-font"
                                  : "nav-content-bttn open-font"
                              }
                              onClick={(event) =>
                                handleSectionClick("Feature", event)
                              }
                            >
                              Feature
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={
                                activeSection === "Overview"
                                  ? "active nav-content-bttn open-font"
                                  : "nav-content-bttn open-font"
                              }
                              onClick={(event) =>
                                handleSectionClick("Overview", event)
                              }
                            >
                              Overview
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className={
                                activeSection === "Contact"
                                  ? "active nav-content-bttn open-font"
                                  : "nav-content-bttn open-font"
                              }
                              onClick={(event) =>
                                handleSectionClick("Contact", event)
                              }
                            >
                              Contact Us
                            </a>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              activeclassname="active"
                              to="/signup"
                              className="nav-content-bttn open-font"
                            >
                              <button className="buttontryNow">Try now</button>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div ref={contentRef}>
        <section className="FirstSectionLanding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="EmporingData content-section" id="Home">
                  Elevate Your Data Analysis with
                  <br />
                  Dynamic Data Visualization
                </h1>
                <p className="ourService">
                  Our service helps users make better decisions by utilizing
                  both data and AI technology
                </p>
                <div className="bg-imageLanding">
                  {/* <div className="d-flex justify-content-center BtnSec">
                    <button className="landingPrimaryBtn">Try now</button>
                    <button className="landingSecondaryBtn">Explore</button>
                  </div> */}
                  <div className="DashboardImageContainer">
                    <img src={macBook} alt="dashboard" className="img-fluid" />
                  </div>
                  {/* <div className="courierImageDiv">
                    <img
                      src={CourierAllocationlanding}
                      alt="courier"
                      className="CourierImg"
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="secondSectionLanding content-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div
                    className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding content-section"
                    id="Feature"
                  >
                    <img
                      src={SmartAnalysisLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Smart Analytics</p>
                    <p className="AutomaticGenerate">
                      Automatic generate insight and recommendation based on
                      data
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={NaturalLandLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Natural Language Querying</p>
                    <p className="AutomaticGenerate">
                      Natural search is the search in which the user interacts
                      with the system through regular spoken language, similar
                      to Google search
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={DataBlendingLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Data Blending</p>
                    <p className="AutomaticGenerate">
                      Data blending is the process of combining data from
                      multiple sources to create a unified view.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={DataVisualizationLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Data Visualization</p>
                    <p className="AutomaticGenerate">
                      Data visualization is the presentation of data in a
                      graphical or visual format to help users understand
                      complex information and identify patterns and insights.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={PridictiveAnalysisLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Predictive Analytics</p>
                    <p className="AutomaticGenerate">
                      Predictive analytics involves using statistical and
                      machine learning techniques to analyze historical data and
                      make predictions about future events or trends.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={CollaborationLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Collaboration</p>
                    <p className="AutomaticGenerate">
                      Data analytics refers to the ability for multiple users to
                      work together on the same data sets and projects, share
                      insights, and collaborate on decision-making.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={IntegrationLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Integration</p>
                    <p className="AutomaticGenerate">
                      Integration refers to the ability to connect and combine
                      data from different sources or systems to create a
                      comprehensive view for analysis and decision-making.
                    </p>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 text-center CardLanding">
                    <img
                      src={RealTimeLanding}
                      alt="samrtanalysis"
                      className="img-fluid LandingSmartImg"
                    />
                    <p className="SmartAnalytics">Realtime Analytics</p>
                    <p className="AutomaticGenerate">
                      Real-time analytics involves analyzing data as it is
                      generated or received, allowing for immediate insights and
                      decisions to be made.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div>
          <section className="thirdSectionLanding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12">
                  <h2 className="StartAnywhere content-section" id="Overview">
                    Start Anywhere and Go Everywhere with Vivrn
                  </h2>
                  <div className="row DataDriven align-items-center">
                    <div className="col-md-6">
                      <p className="fw500 fzres14">
                        Whether you're just starting your data journey or
                        looking to level up your existing analytics
                        capabilities, Vivrn provides a seamless and flexible
                        experience. With our user-friendly interface and
                        intuitive tools, you can dive into your data from
                        anywhere, explore it effortlessly, and go everywhere to
                        uncover hidden opportunities.
                      </p>
                      {/* <button className="landingPrimaryBtn shadowBtn Btnmt">
                        Explore Mountaindrop Analytics Software
                        <i className="fa-solid fa-right-long ms-2"></i>
                      </button> */}
                    </div>
                    <div className="col-md-6">
                      <img
                        src={vivranDashboard}
                        alt="explore"
                        className="img-fluid exploreAnalyImage"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="fourthSectionLanding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 FourthSecLeft">
                  <h2 className="StartAnywhere">
                    Expand Your analytics <br></br>footprint with Vivrn
                  </h2>
                  <p className="fw500 fzres14">
                    Where data-driven decisions meet business excellence.
                    Transform raw data into actionable insights and unlock new
                    opportunities for growth. With intuitive data analysis,
                    customizable dashboards, and advanced analytics
                    capabilities, our platform empowers your organization to
                    thrive in the competitive landscape. Collaborate seamlessly,
                    integrate data effortlessly, and make informed choices.
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="FourthSecRight">
                    <div className="pridctivediv">
                      <img
                        src={PridctiveLanding}
                        alt="pridictive"
                        className="img-fluid"
                      />
                      <h4>Predictive and prescriptive forecasting</h4>
                    </div>
                    <div className="pridctivediv">
                      <img
                        src={RealTimeAccessLanding}
                        alt="pridictive"
                        className="img-fluid"
                      />
                      <h4>Real-time access to your data</h4>
                    </div>
                    <div className="pridctivediv">
                      <img
                        src={NaturalLanguageLanding}
                        alt="pridictive"
                        className="img-fluid"
                      />
                      <h4>Natural Language Querying</h4>
                    </div>
                    <div className="pridctivediv">
                      <img
                        src={AILanding}
                        alt="pridictive"
                        className="img-fluid"
                      />
                      <h4>AI that streamlines, automates and integrate</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="FifthSectionLanding">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="d-flex">
                  {/* <img
                    src={LetsTalkLanding}
                    alt="Lets Talk"
                    className="LetsTalkImage"
                  /> */}
                  <div
                    className="LetsTalkContainer content-section"
                    id="Contact"
                  >
                    <h3>Get in Touch</h3>
                    {/* addeded extra paragraph */}
                    <p className="extra-para fw500">
                      Got questions or need assistance? We are here to help!
                      Fill out the form below, and we'll get back to you.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="LeftletsTalk">
                  {/* <p>
                    It's all about the humans behind a brand and those
                    experiencing it, we're right there. In the middle.
                  </p> */}
                  <div className="row">
                    <div className="col-6 border-rightCus">
                      <div className="Addressdiv">
                        <img
                          src={AddressLanding}
                          alt="Address"
                          className="AddressImage mb-3"
                        />
                        <p className="fw500">
                          Sai Okas <br />
                          19th Street, Anna Nagar <br />
                          Extension, Velachery, Chennai, <br />
                          Tamil Nadu 600042
                        </p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="Phonediv">
                        <img
                          src={PhoneLanding}
                          alt="Address"
                          className="AddressImage mb-3"
                        />
                        <p className="fw500">
                          +91 9931695258
                          <br />
                          +91 8101507363
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="FormDiv">
                  {/* <form>
                    <div className="FormInputDivLanding">
                      <input
                        type="text"
                        className="FormInputLanding"
                        placeholder="Name"
                      />
                      <input
                        type="text"
                        className="FormInputLanding"
                        placeholder="Email"
                      />
                      <input
                        type="text"
                        className="FormInputLanding"
                        placeholder="Phone"
                      />
                      <textarea
                        rows="4"
                        className="FormInputLanding"
                        placeholder="Tell us about project"
                      ></textarea>
                      <button className="buttontryNow">
                        Send Message<i className="fa-solid fa-paper-plane ms-2"></i>
                      </button>
                    </div>
                  </form> */}
                  <Formik
                    initialValues={initialValues} // Set initial values here
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="FormInputDivLanding">
                          <div className="MarginInputLanding">
                            <Field
                              type="text"
                              name="name"
                              className="FormInputLanding"
                              placeholder="Name"
                            />
                            <ErrorMessage
                              name="name"
                              component="div"
                              className="error"
                            />
                          </div>
                          <div className="MarginInputLanding">
                            <Field
                              type="text"
                              name="email"
                              className="FormInputLanding"
                              placeholder="Email"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="error"
                            />
                          </div>
                          <div className="MarginInputLanding">
                            <Field
                              type="text"
                              name="phone"
                              className="FormInputLanding"
                              placeholder="Phone"
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error"
                            />
                          </div>
                          <div className="MarginInputLanding">
                            <Field
                              as="textarea"
                              rows="4"
                              name="project"
                              className="FormInputLanding"
                              placeholder="Message"
                            />
                            <ErrorMessage
                              name="project"
                              component="div"
                              className="error"
                            />
                          </div>
                          <button
                            type="submit"
                            className="buttontryNow"
                            disabled={isSubmitting}
                          >
                            Send Message
                            <i className="fa-solid fa-paper-plane ms-2"></i>
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="Footersection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="LeftSecLandingCont">
                    <img src={vivranLogo_123} className="Footerlogo" />
                    <p>
                      "Empowering Data Driven Decision With AI Integration"
                      highlights the importance of using artificial intelligence
                      (AI) techniques in data analytics to gain insights and
                      make data-driven decisions that can help businesses grow
                      and succeed.
                    </p>
                    <div className="EmailFooter">
                      <input
                        type="text"
                        placeholder="Your Email"
                        className="FooterEmail"
                      />
                      <div className="RightArrow">
                        <i className="fa-solid fa-arrow-right-long"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 mt20cus">
                  <div className="LeftSecLandingCont">
                    <h5>Quick Links</h5>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6 mt20cus">
                  <div className="LeftSecLandingCont">
                    <h5>Contact Info</h5>
                    <div className="d-flex">
                      <img
                        src={locationFooterLanding}
                        alt="address"
                        className="FooterImage"
                      />
                      <p className="text-start">
                        Sai Okas
                        <br />
                        19th Street, Anna Nagar Extension, Velachery, Chennai,
                        Tamil Nadu 600042
                      </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={contactFooterLanding}
                        alt="address"
                        className="FooterImage"
                      />
                      <p className="text-start">
                        +91 9931695258
                        <br />
                        +91 8101507363
                      </p>
                    </div>
                    <div className="d-flex">
                      <img
                        src={emailFooterLanding}
                        alt="address"
                        className="FooterImage"
                      />
                      <p className="text-start mt-sm-4">vivrn0@gmail.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
