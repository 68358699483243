import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { formatDate } from "../../../../directives/date-formate";

const ReportHistory = (props) => {
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
               Report History
            </Button>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.reportData.reportLogDTOS?.map((item, index) => (
                        <div className="d-flex justify-content-between align-items-center" key={index}>
                            <p>{item.description}</p>
                            <p>{formatDate(item.createdDate * 1000)}</p>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReportHistory;
