import React from "react";
import { sortPng } from "../../../constants/pictures/Pictures";
import DataTypeDropDown from "./DataTypeDropdown";
import {
  ASCENDING,
  Ascending,
  DESCENDING,
  Descending,
} from "../../../dtos/Table.type";

const DataTypeIcon = ({ dataType, onSort, sort, onChangeDataType }) => {
  const toggleSort = () => {
    if (sort === ASCENDING) {
      onSort(Descending);
    } else {
      onSort(Ascending);
    }
  };

  return (
    <>
      <DataTypeDropDown
        sendDataType={dataType}
        onChangeDataType={onChangeDataType}
      />
      <div className="headingth">
        <div className="dropdown">
          <div
            data-bs-toggle="dropdown"
            id="shareDropdown"
            onClick={toggleSort}
          >
            <img src={sortPng} className="ms-1" alt="Sort" />
          </div>
          <ul className="dropdown-menu" aria-labelledby="shareDropdown">
            <li onClick={() => onSort(Ascending)}>{ASCENDING}</li>
            <li onClick={() => onSort(Descending)}>{DESCENDING}</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DataTypeIcon;
