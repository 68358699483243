import { RESTMethod } from "../../enums/enum";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const CreateScheduleApi= async (body) => {
    const userAuthToken = GetCookie('token');
    try {
      const response = await fetch(
        getApiUrl()+ apiUrls.schedule.createSchedule,
        {
          method: RESTMethod.POST, 
          headers: {
            "Content-Type": "application/json",
            "authToken": userAuthToken,
          },
          body: JSON.stringify(body),
        }
      );
      if (response) {
        const result = await response.json();
        return result;
      } else {
        console.error("Request failed. Please try again.");
      }
    } catch (error) {
      console.error(error, "Please Try Again");
    }
  };
export default CreateScheduleApi;