

import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl, loginApiUrl } from "../url/UrlConfig";

export default async function AddUserWorkspaceAPI(payload) {
    const userAuthToken = GetCookie("token");
    try {
        const response = await fetch(
            getApiUrl() +apiUrls.user.addUsersToWorkspace , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authToken": userAuthToken
            },
            body: payload,
        }
        );
        const result = await response.json();
        if (result.httpStatusCode === 200) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error.message || error);
        return null;
    }
}
