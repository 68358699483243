import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const VisualizeWidgetApi = async (payload) => {
    try {
      const token = GetCookie("token");
      const response = await fetch(getApiUrl() + apiUrls.widget.visualizeWidget, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authToken": token,
        },
        body: JSON.stringify(payload), 
      });
  
      const data = await response.json();
      // Check if data is null
      if (data === null) {
        console.warn("Received null data from the server.");
        return [];
      }
      return data.response;
    } catch (error) {
      console.error("Failed to fetch logical attributes:", error);
      return [];
    }
  };
  

  export default VisualizeWidgetApi;