import React from 'react';
import "./AsideNavbarSuperAdmin.css";
import { Link } from "react-router-dom";

export default function AsideNavbarSuperAdmin() {
  return (
    <div className="area">
    <nav className='main-menu mainMenuSuperAdmin'>
        <ul> 
          <li>
          <i className="fa-regular fa-clock"></i>
          </li>
          <li className="has-subnav">
             <Link to="/alluser">
            <i className="fa-solid fa-user-group"></i>
            </Link> 
          </li>
        </ul>
      </nav>
    </div>
  );
}