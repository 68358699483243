/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
import React from "react";
import "./ApplicationInput.css";

// create a component
const TextInputWithLabel = ({
  label,
  placeHolder,
  onChangeText = () => {},
  inputStyle = {},
  rightIcon,
  leftIcon,
  onPressRight,
  error,
  name,
  onBlur,
  isrequired,
  max,
  onkeypress,
  optional,
  disabled,
  ...props
}) => {
  return (
    <div className="input-container">
      {label ? (
        <label className="label-text-style">
          {label} {optional ? <span className="fontSize16 Gilroy500 greyColor ms-1"> (Optional) </span> : null}
          <span className="asterics">
            {isrequired != null ? (isrequired == true ? "*" : null) : null}
          </span>
        </label>
      ) : null}
      <div className="flex-view position-relative">
        <input
          name={name}
          max={max}
          placeholder={placeHolder}
          className={`inline-style hideButton ${rightIcon ? "rightIconPadding" : ""} ${leftIcon ? "leftIconPadding" : ""}`}
          onChange={onChangeText}
          style={inputStyle}
          onBlur={onBlur}
          required={isrequired != null ? isrequired : false}
          {...props}
          disabled={disabled}
        />
        {!!rightIcon && (
          <div className="right-icon-button" onClick={onPressRight}>
            <img className="img-fluid" src={rightIcon} alt="Right Icon" />
          </div>
        )}
        {!!leftIcon && (
          <span className="left-icon-span">
            <img className="left-icon" src={leftIcon} alt="left Icon" />
          </span>
        )}
      </div>
      {error && <div className="Inputerror">{error}</div>}
    </div>
  );
};

export default TextInputWithLabel;