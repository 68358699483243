import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { useDispatch } from 'react-redux';
import { focusModeClassData, resetfocusModeClassData } from '../../../redux/reduxToolkit/Reducer';

// Initialize exporting modules
exportingInit(Highcharts);
exportDataInit(Highcharts);


const StackedBarDouble = (props) => {
  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(
    props?.responseData?.responseData?.data
  );
  useEffect(() => {
    setFetchData(props?.responseData?.responseData?.data);
  }, [props, fetchData]);
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props?.responseData?.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus,props?.responseData?.responseData]);
  const toggleFocusMode = () => {
    setGetFocus(prev => !prev);
};
  const [selectedMetric, setSelectedMetric] = useState([]);
  const [drilldownCategory, setDrilldownCategory] = useState(null);
  const calculateMetricsSum = () => {
    const metricsSum = {};

    for (const category in fetchData) {
      metricsSum[category] = {};

      fetchData[category].forEach((item) => {
        for (const metricName in item.metric) {
          metricsSum[category][metricName] =
            (metricsSum[category][metricName] || 0) + item.metric[metricName];
        }
      });
    }

    return metricsSum;
  };

  const categoriesDrillDown = selectedMetric?.map((d) => d.sgb);

  const handleMetricClick = (category) => {
    const categoryData = fetchData[category];

    if (categoryData && categoryData.length > 0) {
      setSelectedMetric(categoryData);

      const subcategoriesDrillDown = Object.keys(categoryData[0]?.metric);
      setDrilldownCategory(subcategoriesDrillDown);
    } else {
      console.error(`No data found for category: ${category}`);
    }
  };

  const handleDrillUp = () => {
    setSelectedMetric([]);
    setDrilldownCategory(null);
  };

  const metricsSum = calculateMetricsSum();

  if (!metricsSum || Object.keys(metricsSum).length === 0) {
    return null;
  }

  const mainCategories = Object.keys(metricsSum);
  const subcategories = Object.keys(metricsSum[mainCategories[0]]);

  const options = {
    chart: {
      type: "bar",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: drilldownCategory ? categoriesDrillDown : mainCategories,
    },
    yAxis: {
      title: {
        text: "Y Axis Title",
      },
    },
    series: drilldownCategory
      ? drilldownCategory?.map((subcategory, i) => ({
          name: subcategory,
          data: selectedMetric?.map((d) => d.metric[subcategory]),
          stack: "metric",
          color: Highcharts.getOptions().colors[i % 10],
        }))
      : subcategories?.map((subcategory, i) => ({
          name: subcategory,
          data: mainCategories?.map(
            (category) => metricsSum[category][subcategory]
          ),
          stack: "metric",
          colorByPoint: true,
          events: {
            click: function (event) {
              handleMetricClick(event.point.category);
            },
          },
        })),
    plotOptions: {
      series: {
        stacking: "normal",
        point: {
          events: {
            click: function () {
              if (drilldownCategory) {
                handleDrillUp();
              }
            },
          },
        },
      },
    },
    tooltip: {
      formatter: function () {
        const point = this.point;
        let tooltip = `<b>${point.series.name}</b>: ${point.y}<br/>`;
        tooltip += `<b>(${point.category})</b><br/>`;
        if (drilldownCategory) {
          tooltip += `<b><a href="#" id="drillup" class="mt-2" style="color:blue;">Drill Up</a></b>`;
        } else {
          tooltip += `<b><a href="#" id="drilldown" class="mt-2" style="color:blue;">Drill Down</a></b>`;
        }

        return tooltip;
      },
    },
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.container.addEventListener(
        "click",
        function (event) {
          const targetId = event.target.id;

          if (targetId === "drilldown") {
            handleMetricClick(event.target.parentElement.innerText.trim());
          } else if (targetId === "drillup") {
            handleDrillUp();
          }
        }
      );

      chartRef.current.chart.redraw();
    }
  }, [drilldownCategory, fetchData]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default StackedBarDouble;
