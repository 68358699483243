// TableComponent.js
import React from "react";
import MoreInfoDropdown from "../moreInfoDropdown/MoreInfoDropdown";
import { formatDate } from "../../../../directives/date-formate";

const TableViewComponent = ({ isInfoVisible,uploadFiles, keys, handleDelete, handleMouseEnter, handleMouseLeave }) => {
  return (
    <div className="col-md-12">
      <div className="content-set">
        <table className="table set-table-content">
          <thead>
            <tr>
              {keys?.map((key, index) => (
                <th className="table-color-content" key={index}>{key}</th>
              ))}
              <th className="table-color-content">More Info</th> 
            </tr>
          </thead>
          <tbody>
            {uploadFiles?.map((item, index) => (
              <tr key={item.id}>
                {keys?.map((key, innerIndex) => (
                  <td key={innerIndex}>
                    {key === "createdDate" ? formatDate(item[key]) : (item[key] ? item[key].toString() : '')}
                  </td>
                ))}
                <td>
                  
                  <MoreInfoDropdown
                        item= {item}
                    onDelete={() => handleDelete(index)}
                    onMoreInfo={() => handleMouseEnter(index)}
                    isInfoVisible={isInfoVisible}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableViewComponent;
