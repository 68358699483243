import React from "react";
import { RingLoader } from "react-spinners";
import "./Spinner.css";

const Spinner = ({ isLoading }) => {
  return (
    <div className="sweet-loading">
      <RingLoader
        color={"#123abc"}
        loading={isLoading}
        size={150}
        className="overRide"
      />
    </div>
  );
};

export default Spinner;
