import Layout from "../../component/layout/Layout";
import "./Dashboard.css";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {resetPreviousDataWidgetData,resetWidgetIdClickedData,resetsetData,resetReportIdData,resetwidgetIdResponseData,IdOfReportData, resetWidgetResponseData} from "../../redux/reduxToolkit/Reducer";
import { useDispatch } from "react-redux";
import { formatDate } from "../../directives/date-formate";
import DataNotFound from "../../component/dataNotFound/DataNotFound";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import getAllReport from "../../service/api/getAllReportApi";
import { excludedKeys } from "../../dtos/MyContent.type";
import GetReportCount from "../../service/api/GetReportCountApi";
import GetMatchingReportApi from "../../service/api/GetMatchingReportApi";
import DeleteReportDropDown from "./DeleteReportDropdown";
import TableViewDashboard from "./TableViewDashboard";
import SearchBar from "./SearchBar";
import SortDropdown from "./SortDropDown";
import ViewButtons from "./ViewButtons";

export default function DashBoard() {
  //............localstorage removed.............
  localStorage.removeItem("previousData");
  localStorage.removeItem("previousPayload");
  localStorage.removeItem("previousDataWidget1");
  const dispatch = useDispatch();
  dispatch(resetPreviousDataWidgetData());
  dispatch(resetwidgetIdResponseData());
  dispatch(resetReportIdData());
  dispatch(resetsetData());
  dispatch(resetWidgetIdClickedData());
  dispatch(resetWidgetResponseData());
  localStorage.removeItem("widgetIdResponseState");
  localStorage.removeItem("widgetIdClickedState");
  localStorage.removeItem("previousData");
  //........declare hooks. and variable........
  const [sortOrder, setSortOrder] = useState(null);
  const [cardColors, setCardColors] = useState([]);
  const [sortOrderDate, setSortOrderDate] = useState(null);
  const [totalReportData, setTotalReportData] = useState("");
  const [offset, setOffset] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [allReportData, setAllReportData] = useState([]);
  const [showCardContainer, setShowCardContainer] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const limit = 12;
  const totalPages = Math.ceil(totalReportData / limit);
  const [allValue, setAllValue] = useState(false);
  const navigate = useNavigate();
  const [afterDeleteRender, setAfterDeleteRender] = useState(false);
  const [tableHeaders, setTableHeaders] = useState([]);
  //created api call here
  const fetchGetAllReportApi = async (value) => {
    try {
      const result = await getAllReport(value);
      if (result && result.response) {
        setAllReportData([...result.response, sortedData]);
      } else {
        console.error("API response is undefined or null.");
      }
      setAfterDeleteRender(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGetAllReportApi(offset);
  }, [offset, allValue, afterDeleteRender]);

  const handlePageChange = ({ selected }) => {
    const newOffset = selected * limit;
    setOffset(newOffset);
  };

  const sortedData = useMemo(() => {
    if (allReportData && allReportData.length > 0) {
      return allReportData
        .filter((item) => item.title)
        .sort((a, b) => {
          if (sortOrder === "asc") {
            return a.title.localeCompare(b.title);
          } else if (sortOrder === "desc") {
            return b.title.localeCompare(a.title);
          } else if (sortOrderDate === "asc") {
            return a.createdDate - b.createdDate;
          } else if (sortOrderDate === "desc") {
            return b.createdDate - a.createdDate;
          }
          return 0;
        })
        .slice(0, 12);
    } else {
      console.log("data not available");
      return [];
    }
  }, [sortOrder, sortOrderDate, allReportData]);

  // Reset the sort order for dates
  const handleSortByName = (order) => {
    setSortOrder(order);
    setSortOrderDate(null);
  };

  // Reset the sort order for names
  const handleSortByDate = (order) => {
    setSortOrderDate(order);
    setSortOrder(null);
  };

  // Function to toggle between CardContainer and DashBoardTable
  const toggleComponent = () => {
    setShowCardContainer(true);
  };

  const toggleComponentCard = () => {
    setShowCardContainer(false);
  };

  //.....hover on three dot show details..........
  const handleMouseEnter = () => {
    setIsInfoVisible(true);
  };

  const handleMouseLeave = () => {
    setIsInfoVisible(false);
  };

  const handleCardClick = (cardId, bol) => {
    
    dispatch(resetPreviousDataWidgetData());
    dispatch(resetwidgetIdResponseData());
    navigate(`/createreport/${cardId}/${bol}`);
  };

  //......getReport delete by id..............
  const handleDelete = (reportId, index) => {
    const userAuthToken = GetCookie("token");
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "NextButton",
        cancelButton: "CancelButton",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Make the DELETE request to the API
        fetch(
          `https://server.vivrn.com:8080/analytics/api/report/trashReport/${reportId}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              authToken: userAuthToken,
            },
          }
        )
          .then((response) => {
            if (response.ok) {
              // Successful deletion
              const updatedData = [...filteredData];
              updatedData.splice(index, 1);
              setFilteredData(updatedData);
              setAfterDeleteRender(true);
              Swal.fire("Deleted!", "The report has been deleted.", "success");
            } else {
              // Handle errors here, e.g., display an error message
              Swal.fire("Error", "Failed to delete the report.", "error");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire(
              "Error",
              "An error occurred while deleting the report.",
              "error"
            );
          });
      }
    });
  };
  //..............total Report count..............

  const getreportCountApi = async () => {
    try {
      const data = await GetReportCount();
      if (data && data.response !== undefined && data.response !== null) {
        setTotalReportData(data.response);
      } else {
        console.log("data not found");
      }
    } catch (error) {
      console.error("Error fetching report count:", error);
    }
  };

  useEffect(() => {
    getreportCountApi();
  }, []);

  //..............debounce on input.............
  const debounce = (func, timeout = 500) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(context, args);
      }, timeout);
    };
  };
  //........matching report api............
  const searchReport = async (value) => {
    try {
      if (value) {
        const data = await GetMatchingReportApi(value);
        setAllReportData(data.response);
        setAllValue(false);
      } else {
        setAllValue(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //......call debounce...............
  const optmisedVersion = useCallback(debounce(searchReport), []);
  useEffect(() => {
    if (allReportData.length > 0) {
      const keys = Object.keys(allReportData[0]).filter(
        (key) => !excludedKeys.includes(key)
      );
      setTableHeaders(keys);
    }
  }, [allReportData]);
  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-5 hide-it mt-3">
                <SearchBar optmisedVersion={optmisedVersion} />
                <div className="d-flex justify-content-end m-0">
                  <SortDropdown
                    handleSortByName={handleSortByName}
                    handleSortByDate={handleSortByDate}
                  />
                  <ViewButtons
                    toggleComponent={toggleComponent}
                    toggleComponentCard={toggleComponentCard}
                  />
                </div>
              </div>
            </div>
            {showCardContainer ? (
              <div className="CardContainer">
                <div className="col-md-12 mt-sm-4">
                  {Array.isArray(sortedData ? sortedData : allReportData) ? (
                    <div className="row">
                      {allReportData &&
                        sortedData?.map((item, index) => (
                          <div
                            className="col-xl-3 col-lg-4 col-sm-6"
                            key={index}
                          >
                            {formatDate(item?.createdDate)}
                            <div
                              className="Customcard"
                              style={{ backgroundColor: cardColors[index] }}
                            >
                              <div className="card-body">
                                <DeleteReportDropDown
                                  item={item}
                                  index={index}
                                  handleDelete={handleDelete}
                                  handleMouseEnter={handleMouseEnter}
                                  handleMouseLeave={handleMouseLeave}
                                  isInfoVisible={isInfoVisible}
                                />
                                <div
                                  className="upload-data"
                                  onClick={() => handleCardClick(item.id, true)}
                                >
                                  <label htmlFor="file-input"></label>
                                  <p className="Analyticsupload-set">
                                    {item?.title}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <DataNotFound />
                  )}
                  <div className="CusPagination mt-5">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      pageCount={totalPages}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-12 p-2">
                <div className="set-dashboard">
                  <TableViewDashboard
                    tableHeaders={tableHeaders}
                    allReportData={allReportData}
                    sortedData={sortedData}
                    handleDelete={handleDelete}
                    handleMouseEnter={handleMouseEnter}
                    handleMouseLeave={handleMouseLeave}
                    isInfoVisible={isInfoVisible}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}
