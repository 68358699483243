import React from "react";

const TrashHeader = ({
  selectedIds,
  handleRestore,
  handleDelete,
  search,
  setSearch,
}) => {
  return (
    <div className="d-flex mt-3">
      <div className="d-flex align-items-center">
        <h5>
          <i className="fa-solid fa-trash-can "></i>
        </h5>
        <h4 className="ms-2">Trash</h4>
      </div>

      <div className="d-flex justify-content-end align-items-center ms-auto">
        {selectedIds.length > 0 ? (
          <>
            <button
              className="btn btn-outline-secondary me-2"
              onClick={handleRestore}
            >
              Restore
            </button>
            <button
              className="btn btn-outline-secondary me-2"
              onClick={handleDelete}
            >
              Delete
            </button>
          </>
        ) : null}
        <input
          type="text"
          id="searchInput"
          className="form-control me-2"
          placeholder="Search your files here..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

export default TrashHeader;
