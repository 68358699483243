import DataNotFound from "../../component/dataNotFound/DataNotFound";
import React from "react";
import { formatDate } from "../../directives/date-formate";
import DeleteReportDropDown from "./DeleteReportDropdown";
const TableViewDashboard = ({
  tableHeaders,
  allReportData,
  sortedData,
  handleDelete,
  handleMouseEnter,
  handleMouseLeave,
  isInfoVisible,
}) => {
  return (
    <>
      <table className="table set-table-dashboard">
        <thead>
          <tr>
            {tableHeaders?.map((header) => (
              <th className="set-capital table-color-trash" key={header}>
                {header}
              </th>
            ))}
            <th className="set-capital table-color-trash">Actions</th>
          </tr>
        </thead>
        <tbody>
          {allReportData && sortedData?.length > 0 ? (
            allReportData &&
            sortedData?.map((item, index) => (
              <tr key={index}>
                <td>{item.id}</td>
                <td>{formatDate(item?.createdDate)}</td>
                <td>{formatDate(item?.lastModifiedDate)}</td>
                <td>{item?.title}</td>
                <td>{item?.createdBy?.firstName}</td>
                <td>
                  <div className="d-flex">
                    <DeleteReportDropDown
                      item={item}
                      index={index}
                      handleDelete={handleDelete}
                      handleMouseEnter={handleMouseEnter}
                      handleMouseLeave={handleMouseLeave}
                      isInfoVisible={isInfoVisible}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <DataNotFound />
          )}
        </tbody>
      </table>
    </>
  );
};

export default TableViewDashboard;
