import React from "react";
import { Button } from "react-bootstrap";

const ViewButtons = ({ toggleComponent, toggleComponentCard }) => {
    return (
      <div className="d-flex justify-content-end m-0">
        <Button variant="outline-secondary" className="me-2" onClick={toggleComponent}>
          <i className="fa-solid fa-grip"></i>
        </Button>
        <Button variant="outline-secondary" onClick={toggleComponentCard}>
          <i className="fa-solid fa-bars"></i>
        </Button>
      </div>
    );
  };

  export default ViewButtons;