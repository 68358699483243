

export const apiUrls = {
  prefix: "/api/",
  user: {
    createUser: "/user/createUser",
    loginUser: "/userAuth/login",
    getAllUser: (id) => `/user/getAllUsers/${id}`,
    addUsersToWorkspace:"/user/addUsersToWorkspace"
  },
  dataTable: {
    getTableNames: "/dataModel/getTableNames",
    previewTable: (table) => `/dataModel/previewTable/${table}`,
    dataModal: `/dataModel/connectToDataSource`
  },
  widget: {
    deleteWidget: (id) => `/widget/${id}`,
    getWidgetById: (id) => `/widget/getWidget/${id}`,
    visualizeWidget: `/widget/visualizeWidget`,
    createWidget: `/widget/createWidget`,
  },
  report: {
    getAllReport: (id, limit, offset) => `/report/getAllReports/${id}?isDeleted=true&limit=${limit}&offset=${offset}&sortBy=LAST_MODIFIED_DATE&sortOrder=DESC`,
    getReportById: (id) => `/report/getReport/${id}`,
    trashReport: (reportId) => `/report/trashReport/${reportId}`,
    getReportCount: (id) => `/report/getReportCount/${id}?isDeleted=false`,
    createReport: `/report/createReport`,
    syncDataSourceAndReport: `/report/syncDataSourceAndReport`,
    getAllReportWithWId:(workspaceId,limit,offset)=>`/report/getAllReports/${workspaceId}?isDeleted=false&limit=${limit}&offset=${offset}`,
    getMachingreport:(value)=>`/report/getMatchingReportByName/${value?value:null }?isDeleted=false`,
    getRestoreReport:(id)=>`/report/restoreReport/${id}`
  },
  dataWarehouse: {
    dataSourceManager: `/dataSourceManager/`,
    dataSourceManagerPatch: (id) => `/dataSourceManager/${id}`,
  },
  uploadFiles: {
    visualizeWidget: `/widget/visualizeWidget`,
    addUploadedFileMetaData: `/liveConnection/uploadFile`,
    uploadFile: `/liveConnection/getSchema`,
    deleteUploadedFile:`/dataset/deleteUploadedFile`,
    saveSchema:`/liveConnection/saveSchema`
  },
  signIn: {
    signUp: `/forms/signUp`,
    contactUs: `/forms/contactUs`
  },
  organization: {
    organization: (id) => `/organization/${id}`
  },
  dataSet: {
    delete: (id) => `/dataset/${id}`,
    restoreDataSet: (id) => `dataset/restoreDataset/${id}`,
    getDataSet: (workspaceId, limit, offset) => `/dataset/workspaceId/${workspaceId}?limit=${limit}&offset=${offset}&sortBy=CREATED_DATE&sortOrder=DESC&userFilter=true&isDeleted=true`,
    getDataSetTeamContent: (workspaceId, limit, offset) => `/dataset/workspaceId/${workspaceId}?limit=${limit}&offset=${offset}&sortBy=CREATED_DATE&sortOrder=DESC&userFilter=false&isDeleted=true`,
    getDataSetForMyContent: (workspaceId, limit, offset) => `/dataset/workspaceId/${workspaceId}?limit=${limit}&offset=${offset}&sortBy=CREATED_DATE&sortOrder=DESC&userFilter=true&isDeleted=false`,
    getDataSetForTeamContent: (workspaceId, limit, offset) => `/dataset/workspaceId/${workspaceId}?limit=${limit}&offset=${offset}&sortBy=CREATED_DATE&sortOrder=DESC&userFilter=false&isDeleted=false`,
    trashDataset: (id) => `/dataset/trashDataset/${id}`,
    getTotalCountMyContent: (workspaceId) => `/dataset/count/workspaceId/${workspaceId}/?userFilter=true&isDeleted=false`,
    getTotalCountTeamContent: (workspaceId) => `/dataset/count/workspaceId/${workspaceId}/?userFilter=false&isDeleted=false`,
  },
  logicalAttribute: {
    getlogicalResource: (workspaceId) => `/logicalResource/${workspaceId}?isDeleted=false`,
    getlogicalAttribute: (id) => `/logical/getLogicalAttribute/${id}`,
  },
  dataSource: {
    getDataSourceTable:(workspaceId)=>`/dataSource/getDataSourceTables/${workspaceId}`
  },
  recent: {
    recentUplodedFiles: (limit, offset) => `/recent/reportAndFile?limit=${limit}&offset=${offset}`
  },
  signUp: {
    verifyEmail: `/signup/verify-email`,
    verfiyToken: `/signup/verify-token`,
    checkUrl:`/signup/check/url`,
    googleSignUp:`/signup/google-signup`,
    verifyInvitation:`/signup/verify-invitation`
  },
  workSpace: {  
    workspaceLogin: `/workspace/login`,
    createWorkspace: `/workspace/createWorkspace`,
    updateWorkspace:`/workspace/updateWorkspace`,
    addUsersToWorkspace:"/workspace/addUsersToWorkspace",
    getUserWorkspaces:"/workspace/getUserWorkspaces"
  },
  logOut:{
    logout:`/api/logout`
  },
  trash:{
    getAllReportWithTrue: (id, limit, offset) => `/report/getAllReports/${id}?isDeleted=true&limit=${limit}&offset=${offset}&sortBy=LAST_MODIFIED_DATE&sortOrder=DESC`,
  },
 schedule:{
  createSchedule:`/schedule/create`
 }
};
