import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import "./SuperAdmin.css";
import AddUserWorkspaceAPI from "../../../service/api/AddUsersToWorkspaceApi";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import Swal from "sweetalert2";

export const CreateUser = () => {
  const [showModal, setShowModal] = useState(false);

  const handleProfileClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const validationSchema = Yup.object().shape({
    workEmail: Yup.string().email("Invalid email").required("Email is required"),
  });

  const selectedWorkspaceId = GetCookie("selectedWorkspaceId");
  console.log(selectedWorkspaceId);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = [
      {
        userDTO: {
          emailId: values.workEmail,
        },
        workspaceDTO: {
          id: "selectedWorkspaceId",
        },
      },
    ];

    try {
      const response = await AddUserWorkspaceAPI(JSON.stringify(payload));

      if (response && response.httpStatusCode === 200) { // Assuming a successful status code is 200
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `The email ${values.workEmail} has been added successfully.`,
        });
      } else {
        throw new Error("Response is null or undefined");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `There was an error adding the email ${values.workEmail}. Please try again.`,
      });
    } finally {
      setSubmitting(false);
      resetForm();
      setShowModal(false);
    }
  };

  return (
    <div>
      <div className="profile-icon" onClick={handleProfileClick}>
        <button className="btnPrimaryCus">
          add user<i className="fa-solid fa-plus ms-2"></i>
        </button>
      </div>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        centered
        className="PresentDataModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{ workEmail: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="form-group col-md-12">
                  <label htmlFor="workEmail">Work Email</label>
                  <div className="input-group CusModal">
                    <Field
                      type="email"
                      className="CusModalInput"
                      id="workEmail"
                      name="workEmail"
                    />
                  </div>
                  <ErrorMessage
                    name="workEmail"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="row d-flex justify-content-end">
                  <div className="col-md-2">
                    <div className="btnSubmit">
                      <button
                        className="btn btn-primary submitBtn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="btnSubmit">
                      <button
                        className="btn btn-secondary cancelBtn"
                        type="button"
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};
