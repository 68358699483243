import React, { useState } from 'react';

export default function Files() {
  const [folderStates, setFolderStates] = useState({});
  const [expandAll, setExpandAll] = useState(false);
  const folderData = [
    {
      name: 'folder1',
      files: ['File 1', 'File 2'],
    },
    {
      name: 'folder2',
      files: ['File 3'],
    },
    {
      name: 'folder3',
      files: ['File 4', 'File 5', 'File 6'],
    },
  ];
  
  const toggleFolder = (folderIndex) => {
    setFolderStates((prevFolderStates) => ({
      ...prevFolderStates,
      [folderIndex]: !prevFolderStates[folderIndex],
    }));
  };

  const toggleExpandAll = () => {
    setExpandAll(!expandAll);
    setFolderStates({});
  };

  const toggleAllFolders = () => {
    if (expandAll) {
      setFolderStates({});
    } else {
      const newFolderStates = {};
      for (let i = 0; i < folderData.length; i++) {
        newFolderStates[i] = true;
      }
      setFolderStates(newFolderStates);
    }
    setExpandAll(!expandAll);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">
            <input
              type="checkbox"
              className="me-2"
              checked={expandAll}
              onChange={toggleAllFolders}
            />
            <i
              className={`fa-regular fa-square-plus me-2 ${
                expandAll ? 'fa-minus' : ''
              }`}
              onClick={toggleAllFolders}
            ></i>
            Expand/Collapse All
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <i
              className={`fa-regular fa-square-plus me-2 ${
                folderStates[0] ? 'fa-minus' : ''
              }`}
              onClick={() => toggleFolder(0)}
            ></i>
            <i className="fa-solid fa-folder me-2"></i>folder
            <i className="fa-solid fa-star me-2"></i>
            <ul style={{ display: folderStates[0] ? 'block' : 'none' }}>
              <li>
                <i className="fa-regular fa-square-plus me-2"></i>
                <i className="fa-solid fa-file me-2"></i>File 1
              </li>
              <li>
                <i className="fa-regular fa-square-plus me-2"></i>
                <i className="fa-solid fa-file me-2"></i>File 2
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <i
              className={`fa-regular fa-square-plus me-2 ${
                folderStates[1] ? 'fa-minus' : ''
              }`}
              onClick={() => toggleFolder(1)}
            ></i>
            <i className="fa-solid fa-folder me-2"></i>folder
            <ul style={{ display: folderStates[1] ? 'block' : 'none' }}>
              <li>
                <i className="fa-regular fa-square-plus me-2"></i>
                <i className="fa-solid fa-file me-2"></i>File 3
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>
            <i
              className={`fa-regular fa-square-plus me-2 ${
                folderStates[2] ? 'fa-minus' : ''
              }`}
              onClick={() => toggleFolder(2)}
            ></i>
            <i className="fa-solid fa-folder me-2"></i>folder
            <ul style={{ display: folderStates[2] ? 'block' : 'none' }}>
              <li>
                <i className="fa-regular fa-square-plus me-2"></i>
                <i className="fa-solid fa-file me-2"></i>File 4
              </li>
              <li>
                <i className="fa-regular fa-square-plus me-2"></i>
                <i className="fa-solid fa-file me-2"></i>File 5
              </li>
              <li>
                <i className="fa-regular fa-square-plus me-2"></i>
                <i className="fa-solid fa-file me-2"></i>File 6
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
