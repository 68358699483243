import React, { useState } from "react";
import { ToastContainer } from "react-bootstrap";

export default function ModalComponent({
  projectName,
  setProjectName,
  fileType,
  setFileType,
  acceptedFileType,
  handleFileChange,
}) {
  const [isProjectNameFilled, setIsProjectNameFilled] = useState(false);

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
    setIsProjectNameFilled(!!event.target.value);
  };

  const handleFileTypeChange = (event) => {
    setFileType(event.target.value);
  };

  return (
    <>
      <div>
        <form>
          <div className="form-group">
            <label htmlFor="projectName">Project Name*</label>
            <input
              type="text"
              id="projectName"
              className="form-control"
              value={projectName}
              onChange={handleProjectNameChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="fileType">File Type:</label>
            <select
              id="fileType"
              className="form-select"
              value={fileType}
              onChange={handleFileTypeChange}
              disabled={!isProjectNameFilled}
            >
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
              <option value="html">HTML</option>
              <option value="xml">XML</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="fileUpload">File:</label>
            <div className="FileInputCont">
              <input
                id="file-input"
                type="file"
                accept={acceptedFileType}
                onChange={handleFileChange}
                multiple
                disabled={!isProjectNameFilled}
              />
            </div>
            <ToastContainer />
          </div>
        </form>
      </div>
    </>
  );
}
