import React, { useState, useRef, useEffect } from "react";
import { saveAs } from "file-saver";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { toPng } from "html-to-image";
import "./Report.css";
import GetCookie from "../../../../persistence/cookies/getCookie/GetCookie";
import workspaceId from "../../../../util/AttributeUtil";
const LogicalResources = async (userAuthToken, setUsers) => {
  try {
    const response = await fetch(
      `https://server.vivrn.com:8080/analytics/api/logicalResource/${workspaceId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authToken: userAuthToken,
        },
      }
    );
    const data = await response.json();
    console.log(data.response, "gggggggggggggg");
    setUsers(data.response);
  } catch (error) {
    console.error("Failed to fetch users:", error);
  }
};
const LogicalAttributes = async (
  userAuthToken,
  setLogicalAttribute,
  userId
) => {
  try {
    const response = await fetch(
      `https://server.vivrn.com:8080/analytics/api/logical/getLogicalAttribute/${userId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authToken: userAuthToken,
        },
      }
    );
    const data = await response.json();
    console.log(data.response, "dataattributes");
    setLogicalAttribute(data.response);
  } catch (error) {
    console.error("Failed to fetch users:", error);
  }
};
export default function Report({ id, isActive }) {
  const [users, setUsers] = useState([]);
  const [logicalAttribute, setLogicalAttribute] = useState([]);
  const userAuthToken = GetCookie("token");
  const [searchInput, setSearchInput] = useState("");
  useEffect(() => {
    LogicalResources(userAuthToken, setUsers);
  }, [userAuthToken]);

  const handleUserClick = (userId) => {
    LogicalAttributes(userAuthToken, setLogicalAttribute, userId);
    console.log(`API request for user ID: ${userId}`);
  };
  const reportRef = useRef(null);

  const generatePDF = async () => {
    // Capture screenshot of the report component
    const reportElement = reportRef.current;
    const reportScreenshot = await toPng(reportElement); // Use the imported function

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage();

    // Embed the screenshot in the PDF
    const img = await pdfDoc.embedPng(reportScreenshot);
    const { width, height } = img.scale(0.46);
    const margin = 10; // Set the desired margin width in points
    const x = margin;
    const y = margin;
    const pageWidth = page.getSize().width - margin * 2;
    const pageHeight = page.getSize().height - margin * 2;

    page.drawImage(img, {
      x,
      y: pageHeight - height - y,
      width,
      height,
    });

    // Add text to the PDF
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    page.setFont(font);
    const textX = x;
    const textY = y;
    const text = "Generated Report";

    const textSize = font.widthOfTextAtSize(text, 12);
    const textHeight = font.heightAtSize(12);
    const textWidth = textSize + margin * 2;

    page.drawText(text, {
      x: (pageWidth - textWidth) / 2 + x, // Center the text horizontally
      y: textY + textHeight, // Adjust the y-coordinate as per your preference
      size: 12,
    });

    // Save the PDF as a Blob
    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });

    // Download the PDF
    saveAs(pdfBlob, "report.pdf");
  };

  const [isFullScreen, setIsFullScreen] = useState(false);
  const handleButtonClick = () => {
    setIsFullScreen(!isFullScreen);
  };
  const [isFullScreenLeft, setIsFullScreenLeft] = useState(false);
  const handleButtonLeftClick = () => {
    setIsFullScreenLeft(!isFullScreenLeft);
  };
  const [fullPageChart, setFullPageChart] = useState(false);
  const [selectedChartLeft, setSelectedChartLeft] = useState("piechart");
  const [selectedChartRight, setSelectedChartRight] = useState("columnchart");
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [selectedChart, setSelectedChart] = useState(null);
  const handleChartSelection = (chart) => {
    if (selectedCharts.includes(chart)) {
      setSelectedCharts(selectedCharts.filter((c) => c !== chart));
    } else {
      setSelectedCharts([...selectedCharts, chart]);
    }
  };
  const handleChartSelectionLeft = (chart) => {
    setSelectedChartLeft(chart);
  };
  const handleChartSelectionRight = (chart) => {
    setSelectedChartRight(chart);
  };
  const openFullPageChart = () => {
    setFullPageChart(true);
  };
  const fullLeftChart = () => {
    const chartDiv = document.querySelector(".chart-div");
    const leftChartCol = document.querySelector(".col-6.mt-3");

    // Show only the chart-div and hide other elements
    chartDiv.style.display = "block";
    leftChartCol.style.display = "block";
    document.querySelector(".col-6.mt-3:not(.chart-div)").style.display =
      "none";
  };
  const handleTabClick = () => {
    // alert(`Clicked tab with ID: ${id}`);
    // Add your custom logic here when a tab is clicked
  };

  return (
    <>
      <div
        className={`tab ${isActive ? "active" : "inactive"}`}
        id={id}
        onClick={handleTabClick}
      >
        <div className="FileDataPreview">
          <div className="d-flex align-items-center justify-content-between mb-5 hide-it">
            <div className="d-flex">
              <button className="btn btn-outline-secondary me-2">
                <i className="fa-solid fa-filter me-1"></i>Filter
              </button>
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="shareDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-calendar-days me-2"></i> Date
                </button>
                <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                  <li>
                    <span className="dropdown-item">
                      <input type="date"></input>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="d-flex justify-content-end m-0">
              {/* getapistart */}
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle me-2"
                  type="button"
                  id="shareDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Uploaded Files
                </button>
                <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                  {users?.map((user, index) => (
                    <li key={index} onClick={() => handleUserClick(user.id)}>
                      <p className="dropdown-item">{user.label}</p>
                    </li>
                  ))}
                </ul>
              </div>
              {/* getapiend */}

              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle me-2"
                  type="button"
                  id="shareDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Widget
                </button>
                <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="pie"
                        checked={selectedCharts.includes("pie")}
                        onChange={() => handleChartSelection("pie")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> PieChart
                      </span>
                    </label>
                  </li>

                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="ring"
                        checked={selectedCharts.includes("ring")}
                        onChange={() => handleChartSelection("ring")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> Ring
                        chart
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="column"
                        checked={selectedCharts.includes("column")}
                        onChange={() => handleChartSelection("column")}
                        hidden
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> column
                        chart
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="heatmap"
                        checked={selectedCharts.includes("heatmap")}
                        onChange={() => handleChartSelection("heatmap")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> heatmap
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="gantt"
                        checked={selectedCharts.includes("gantt")}
                        onChange={() => handleChartSelection("gantt")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> Gantt
                        Chart
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="scatter"
                        checked={selectedCharts.includes("scatter")}
                        onChange={() => handleChartSelection("scatter")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> Scatter
                        Chart
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="bar"
                        checked={selectedCharts.includes("bar")}
                        onChange={() => handleChartSelection("bar")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i> Bar Chart
                      </span>
                    </label>
                  </li>
                  <li>
                    <label className="dropdown-item">
                      <input
                        type="checkbox"
                        value="groupbarChart"
                        checked={selectedCharts.includes("groupbarChart")}
                        onChange={() => handleChartSelection("groupbarChart")}
                      />
                      <span>
                        <i className="fa-solid fa-chart-pie me-2"></i>{" "}
                        GroupbarChart Chart
                      </span>
                    </label>
                  </li>
                </ul>
              </div>
              <button className="btn btn-outline-secondary me-2">
                Present report
              </button>
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle me-2"
                  type="button"
                  id="shareDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-solid fa-share-nodes me-2"></i> Share
                </button>
                <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                  <li>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-solid fa-envelope me-2"></i> Email
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-brands fa-whatsapp me-2"></i> WhatsApp
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-brands fa-facebook me-2"></i> Facebook
                    </span>
                  </li>
                </ul>
              </div>
              {/* Upload button */}
              <div className="dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle me-2"
                  type="button"
                  id="shareDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-arrow-up"></i>
                </button>
                <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                  <li>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-solid fa-file-csv me-2"></i> As CSV
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-solid fa-file-excel me-2"></i> As Excel
                    </span>
                  </li>
                  <li onClick={generatePDF}>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-solid fa-file-pdf me-2"></i> As PDF
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item cursor-pointer">
                      <i className="fa-brands fa-html5 me-2"></i> As HTML
                    </span>
                  </li>
                </ul>
              </div>

              {/* Chat icon */}
              <button className="btn btn-outline-secondary">
                <i className="far fa-comment-dots"></i>
              </button>
            </div>
          </div>
        </div>
        <div ref={reportRef}>
          <div>
            {/* hfjg */}
            <div className="row hide-it">
              <div className="col-3 CardWidgetCol">
                <div className="card CardWidget">
                  <div className="d-flex justify-content-end">
                    <i className="fa-solid fa-up-right-and-down-left-from-center mt-2 me-3"></i>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <p className="text-light">Average amount</p>
                    <span className="text-light">0.42k</span>
                  </div>
                </div>
              </div>
              <div className="col-3 CardWidgetCol">
                <div className="card CardWidget">
                  <div className="d-flex justify-content-end">
                    <i className="fa-solid fa-up-right-and-down-left-from-center mt-2 me-3"></i>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <p className="text-light">Average amount</p>
                    <span className="text-light">0.42k</span>
                  </div>
                </div>
              </div>
              <div className="col-3 CardWidgetCol">
                <div className="card CardWidget">
                  <div className="d-flex justify-content-end">
                    <i className="fa-solid fa-up-right-and-down-left-from-center mt-2 me-3"></i>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <p className="text-light">Average amount</p>
                    <span className="text-light">0.42k</span>
                  </div>
                </div>
              </div>
              <div className="col-3 CardWidgetCol">
                <div className="card CardWidget">
                  <div className="d-flex justify-content-end">
                    <i className="fa-solid fa-up-right-and-down-left-from-center mt-2 me-3"></i>
                  </div>
                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                    <p className="text-light">Average amount</p>
                    <span className="text-light">0.42k</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-4">
                {/* left chart */}
                <div
                  className={`map-component ${
                    isFullScreen ? "full-screen" : "nFullScn"
                  }`}
                >
                  <div className="pie-chart-border">
                    <p>Date wise report</p>
                    <div className="d-flex">
                      <i className="fa-solid fa-share-nodes me-3"></i>
                      <div className="dropdown">
                        <i
                          className="fa-solid fa-chart-bar me-3 dropdown-toggle"
                          id="shareDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        ></i>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="shareDropdown"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                handleChartSelectionLeft("piechart")
                              }
                            >
                              <i className="fa-solid fa-chart-pie me-2"></i>{" "}
                              PieChart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleChartSelectionLeft("ring")}
                            >
                              <i className="fa-solid fa-chart-pie me-2"></i>{" "}
                              Ring Chart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleChartSelectionLeft("bar")}
                            >
                              <i className="fa-solid fa-chart-simple me-2"></i>{" "}
                              BarChart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleChartSelectionLeft("line")}
                            >
                              <i className="fa-solid fa-chart-column me-2"></i>{" "}
                              LineChart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                handleChartSelectionLeft("scatter")
                              }
                            >
                              <i className="fa-solid fa-chart-line me-2"></i>{" "}
                              ScatterChart
                            </a>
                          </li>
                        </ul>
                      </div>
                      <i
                        className="fa-solid fa-up-right-and-down-left-from-center me-2"
                        onClick={handleButtonClick}
                      ></i>
                    </div>
                  </div>
                  {isFullScreen ? (
                    <div className="SideNavbarZoom Cussidenavbar">
                      <ul>
                        <li>
                          <i className="fa-solid fa-filter"></i>
                          <span className="d-block">Filter</span>
                        </li>
                        <li>
                          <i className="fa-solid fa-chart-bar"></i>
                          <span className="d-block">
                            Chart <br />
                            type
                          </span>
                        </li>
                        <li>
                          <span className="d-block">
                            <i className="fa-solid fa-tv"></i>
                          </span>
                          Present <br />
                          report
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div>{selectedChartLeft === "piechart" && <PieChart />}</div> */}
                </div>
              </div>
              {/* right-chart */}
              <div className="col-6 mt-4">
                <div
                  className={`map-component ${
                    isFullScreenLeft ? "full-screen" : "nFullScn"
                  }`}
                >
                  <div className="pie-chart-border">
                    <p>Date wise report</p>
                    <div className="d-flex">
                      <i className="fa-solid fa-share-nodes me-3"></i>
                      <div className="dropdown">
                        <i
                          className="fa-solid fa-chart-bar me-3 dropdown-toggle"
                          id="shareDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        ></i>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="shareDropdown"
                        >
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                handleChartSelectionRight("newbarchart")
                              }
                            >
                              <i className="fa-solid fa-chart-pie me-2"></i> bar
                              chart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleChartSelectionRight("bar")}
                            >
                              <i className="fa-solid fa-chart-simple me-2"></i>{" "}
                              BarChart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => handleChartSelectionRight("line")}
                            >
                              <i className="fa-solid fa-chart-column me-2"></i>{" "}
                              LineChart
                            </a>
                          </li>
                          <li>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() =>
                                handleChartSelectionRight("scatter")
                              }
                            >
                              <i className="fa-solid fa-chart-line me-2"></i>{" "}
                              ScatterChart
                            </a>
                          </li>
                        </ul>
                      </div>
                      <i
                        className="fa-solid fa-up-right-and-down-left-from-center me-2"
                        onClick={handleButtonLeftClick}
                      ></i>
                    </div>
                  </div>
                  {isFullScreenLeft ? (
                    <div className="SideNavbarZoom Cussidenavbar">
                      <ul>
                        <li>
                          <i className="fa-solid fa-filter"></i>
                          <span className="d-block">Filter</span>
                        </li>
                        <li>
                          <i className="fa-solid fa-chart-bar"></i>
                          <span className="d-block">
                            Chart <br />
                            type
                          </span>
                        </li>
                        <li>
                          <span className="d-block">
                            <i className="fa-solid fa-tv"></i>
                          </span>
                          Present <br />
                          report
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="d-block chart-div">
                    {/* {selectedChartRight === "columnchart" && <NewBarChart />} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* {selectedChart === "pie" && <NewPieChart />} */}
          {/* {selectedCharts.includes("pie") && <PieChartCircle />} */}
          {/* {selectedCharts.includes("bar") && <BarChart />} */}
          {/* {selectedCharts.includes("line") && <LineChart />} */}
          {/* {selectedCharts.includes("ring") && <NewPieChart />} */}
          {/* {selectedCharts.includes("scatter") && <ScatterChart />}
          {selectedCharts.includes("gantt") && <GanntChartGraph />}
          {selectedCharts.includes("groupbarChart") && <GroupBarChart />}
          {selectedCharts.includes("heatmap") && <HeatMap />} */}

          {/* {fullPageChart && selectedChart === "pie" && (
            <NewPieChart onClose={() => setFullPageChart(false)} />
          )} */}
        </div>
      </div>
    </>
  );
}

export function TabsComponent() {
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [sheetIndex, setSheetIndex] = useState(1);

  useEffect(() => {
    addTab();
  }, []);

  const addTab = () => {
    const newTab = {
      id: Date.now(),
      title: `Page ${sheetIndex}`,
      content: "Tab Content",
    };
    setTabs([...tabs, newTab]);
    setSheetIndex(sheetIndex + 1);
    setActiveTab(newTab.id);
  };

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  const handleTabRightClick = (id) => (event) => {
    event.preventDefault();
    setActiveTab(id);
    const contextMenu = document.getElementById("context-menu");
    contextMenu.style.top = `calc(${event.clientY}px - 100px)`;
    contextMenu.style.left = `calc(${event.clientX}px - 43px)`;
    contextMenu.classList.add("visible");
  };

  const removeTab = (id) => {
    const updatedTabs = tabs.filter((tab) => tab.id !== id);
    setTabs(updatedTabs);
    if (id === activeTab) {
      if (updatedTabs.length > 0) {
        setActiveTab(updatedTabs[0].id);
      } else {
        setActiveTab(null);
      }
    }
  };

  const handleRename = () => {
    const newTitle = prompt("Enter a new tab name:", getActiveTab().title);
    if (newTitle) {
      const updatedTabs = tabs.map((tab) =>
        tab.id === activeTab ? { ...tab, title: newTitle } : tab
      );
      setTabs(updatedTabs);
    }
  };

  const getActiveTab = () => {
    return tabs.find((tab) => tab.id === activeTab);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const contextMenu = document.getElementById("context-menu");
      if (contextMenu && !contextMenu.contains(event.target)) {
        contextMenu.classList.remove("visible");
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div>
      {tabs.map((tab, index) => (
        <div
          key={tab.id}
          className={`tab-content ${
            tab.id === activeTab ? "active" : "inactive"
          }`}
        >
          <Report {...tab} isActive={index === 0} />
          {index === 0 && (
            <button className="add-sheet-button" onClick={addTab}>
              sheet1
            </button>
          )}
        </div>
      ))}
      <div className="tab-container">
        <button className="add-sheet-button button-class" onClick={addTab}>
          Add<i className="fa-solid fa-plus ms-2"></i>
        </button>
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`tab-button  button-class  ${
              tab.id === activeTab ? "active" : ""
            }`}
            onClick={() => handleTabClick(tab.id)}
            onContextMenu={handleTabRightClick(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div id="context-menu" className="context-menu">
        <div className="context-menu-option" onClick={handleRename}>
          Rename Tab
        </div>
        <div
          className="context-menu-option"
          onClick={() => removeTab(activeTab)}
        >
          Delete Tab
        </div>
      </div>
    </div>
  );
}
