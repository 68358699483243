import React, { useEffect, useState } from "react";
import "./FileReport.css";
import { Tabs, Tab } from "react-bootstrap";
import { NavDropdown } from "react-bootstrap";
import Files from "./tabs/files/Files";
import { TabsComponent } from "./tabs/report/Report";
import ReportDashboard from "./tabs/reportDashboard/ReportDashboard";
import ReportData from "./tabs/reportData/ReportData";
import Layout from "../../component/layout/Layout";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ImportedDataModal from "../../component/modal/ImportedDataModal";
import Spinner from "../../component/loader/Spinner";
import DynamicalTable from "../../component/table/dynamicTable/DynamicalTable";
import DataNotFound from "../../component/dataNotFound/DataNotFound";
export default function FileReport({ handleTabChangeA, result }) {
  const [isLoading, setIsLoading] = useState(true);
  const [dataCsv, setDataCsv] = useState(null);
  const storedDataWithKeys = localStorage.getItem("myDataWithKeys");
  const dataWithKeys = JSON.parse(storedDataWithKeys);
  console.log(dataWithKeys,"dataWithKeys")
  let getCSVKey = [];
  const projectName = dataWithKeys.projectName;
  const [activeTab, setActiveTab] = useState(projectName);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDataCsv(result);
      setIsLoading(false);
    }, 2000);
  }, []);
  if (result && result.uploadedData) {
    getCSVKey = result.uploadedData;
  }
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleTabSelect = (tabKey) => {
    handleTabChangeA(tabKey);
  };
  return (
    <Layout>
      <>
        <div className="AnalyticsContainer">
          <div className="container-fluid">
            <Tabs
              activeKey={activeTab}
              onSelect={handleTabChange}
              justify="fill"
              className="CustomTabsFile borderNone"
            >
              <Tab eventKey={projectName} title={projectName}>
                <div className="FileDataPreview">
                  <div className="row">
                    <div className="col-md-12 mt-2 p-2">
                      <div className="dropdown">
                        <button
                          className="btn btn-outline-secondary dropdown-toggle me-2"
                          type="button"
                          id="shareDropdown"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <i className="fa-solid fa-upload me-2"></i>Data
                          Blending
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="shareDropdown"
                        >
                          <Link to="/connect-wire-house">
                            <li>
                              <span className="dropdown-item cursor-pointer">
                                Import data into this table
                              </span>
                            </li>
                          </Link>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {dataWithKeys.jsonData ? (
                        <DynamicalTable dataApi={dataWithKeys.jsonData } />
                      ) : (
                        <DataNotFound />
                      )}
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="autogenerate" title="Autogenerate">
                <NavDropdown
                  title="AutoGenerate"
                  id="dropdown-autogenerate"
                  show={dropdownOpen}
                  onToggle={toggleDropdown}
                >
                  <NavDropdown.Item eventKey="autogenerateTab1">
                    With auto analysis
                  </NavDropdown.Item>
                  <NavDropdown.Item eventKey="autogenerateTab2">
                    Ask Kia
                  </NavDropdown.Item>
                </NavDropdown>
              </Tab>
              <Tab eventKey="files" title="Files">
                <Files />
              </Tab>
              <Tab eventKey="reportTab" title="Report">
                <TabsComponent />
              </Tab>
              <Tab eventKey="reportDashboard" title="Dashboard">
                <ReportDashboard handleTabChange={handleTabChange} />
              </Tab>
              <Tab eventKey="reportData" title="Data">
                <ReportData />
              </Tab>
            </Tabs>
            <ImportedDataModal
              dataApi={dataWithKeys ? dataWithKeys.jsonData : {}}
            />
          </div>
        </div>
        <ToastContainer />
        <Spinner isLoading={isLoading} />
      </>
    </Layout>
  );
}
