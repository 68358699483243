import React, { useEffect, useState } from "react";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import { formatDate } from "../../../directives/date-formate";
import Swal from "sweetalert2";
import { excludedKeys } from "../../../dtos/MyContent.type";
import { Dropdown } from "react-bootstrap";
import RecentUploadedFileApi from "../../../service/api/RecentUplodedFileApi";

const Recents = () => {
    const [recentUplodedFiles, setRecentUplodedFiles] = useState([]);
    const userAuthToken = GetCookie('token');
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [keys, setKeys] = useState([]);
    const fetchRecentUplodedFilesApi = async () => {
        try {
            const data = await RecentUploadedFileApi();
            if (data && data.response) {
                setRecentUplodedFiles(data.response);
            } else {
                console.error("Recent uploaded files response is null or undefined.");
            }
        } catch (error) {
            console.error("Error fetching recent uploaded files:", error);
        }
    };

    useEffect(() => {
        try {
            fetchRecentUplodedFilesApi();
        } catch (error) {
            console.error("Error fetching and processing data:", error);
        }
    }, [userAuthToken]);

    useEffect(() => {
        try {
            if (Array.isArray(recentUplodedFiles) && recentUplodedFiles.length > 0) {
                const allKeys = Object?.keys(recentUplodedFiles[0]);
                const filteredKeys = allKeys.filter((key) => !excludedKeys.includes(key));
                setKeys(filteredKeys);
            } else {
                console.error("else condtion :");
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }, [recentUplodedFiles]);

    const handleDelete = (index) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action cannot be undone.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
            customClass: {
                confirmButton: "NextButton",
                cancelButton: "CancelButton",
            },
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedData = [...recentUplodedFiles];
                updatedData.splice(index, 1);
                setRecentUplodedFiles(updatedData);
                Swal.fire("Deleted!", "The card has been deleted.", "success");
            }
        });
    };

    const handleMouseEnter = () => {
        setIsInfoVisible(true);
    };

    const handleMouseLeave = () => {
        setIsInfoVisible(false);
    };
    return (
        <div className="col-md-12">
            <div className=" set-content">
                {recentUplodedFiles?.length > 0 ? (
                    <table className="table set-table-content">
                        <thead className="tableHead">
                            <tr>
                                {keys?.map((key, index) => (
                                    <th className="set-capital fourteen600" key={index}>
                                        {key.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                    </th>
                                ))}
                                <th className="MoreInFoTable fourteen600">More Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            {recentUplodedFiles?.map((file, index) => (
                                <tr key={file.id}>
                                    <td className="fourteen400">
                                        {formatDate(file.createdDate)}
                                    </td>
                                    <td className="fourteen400">{formatDate(file.lastModifiedDate)}</td>
                                    <td className="fourteen400">{file.title}</td>
                                    <td className="fourteen400">{file.createdBy?.firstName} {file.createdBy?.lastName}</td>
                                    <td className="MoreInFoTable fourteen400">
                                        <Dropdown className="tableRecent">
                                            <Dropdown.Toggle variant="none" id={`shareDropdown${index}`} className="px-2">
                                                <i className="fa-solid fa-ellipsis-vertical"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleDelete(index)} title="Delete">
                                                    <i className="fa-solid fa-trash-can me-2"></i>Delete
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    onMouseEnter={() => handleMouseEnter(index)}
                                                    onMouseLeave={() => handleMouseLeave(index)}
                                                    style={{ position: "relative" }}
                                                    key={index}
                                                >
                                                    <span>
                                                        <i className="fa-solid fa-circle-info me-2 cursor-pointer"></i>More Info
                                                    </span>
                                                    {isInfoVisible && (
                                                        <div className="info-card">
                                                            <div className="card-header">
                                                                <h5>More Information</h5>
                                                            </div>
                                                            <div className="card-content1">
                                                                <p>Title: Your Title</p>
                                                                <p>Content: Your Content</p>
                                                                <p>Created Date: {formatDate(file?.createdDate)}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No data found.</p>
                )}
            </div>

            <form role="search">
                <div className="SearchIconConta">
                </div>
            </form>
        </div>
    )
}
export default Recents;