import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StepFormForNewUser from "./StepFormForNewUser";
import useJWT from "../../../util/Encode";
import verifyInvitation from "../../../service/api/VerifyInvitationApi";
import SignUpNavbar from "../../register/signUp/SignUpNavbar";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import RemoveCookie from "../../../persistence/cookies/removeCookie/RemoveCookie";
import WorkspaceApi from "../../../service/api/WorkspaceLoginApi";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";
import decodeJWT from "../../../util/Decode";

const SignUpAccountForNewUser = () => {
  const navigate = useNavigate();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const step1Schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    userRoleInCompany: Yup.string().required("Job Position is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      userRoleInCompany: "",
    },
    validationSchema: step1Schema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        const userEmailDetails = localStorage.getItem("sign-up-today-new-user");
        const userEmailDetailsParse = JSON.parse(userEmailDetails);
        const mergedObject = { ...values, ...userEmailDetailsParse };
        const verifyEmailToken = {
          emailId: mergedObject.email,
          password: mergedObject.password,
          firstName: mergedObject.firstName,
          lastName: mergedObject.lastName,
          userRoleInCompany: mergedObject.userRoleInCompany,
        };

        const token = useJWT(verifyEmailToken);
        const jwtToken = localStorage.getItem("jwt-token-new-user");
        console.log(jwtToken,"jwtToken")
        let decodeToken=decodeJWT(jwtToken)
        console.log(decodeToken,"decodeToken")
        let orgURL=decodeToken.payload.organizationUrl;
       console.log(orgURL,"orgURL")
        const tokenPayload = {
          jwtToken: jwtToken,
          userToken: token,
        };
        const verifyTokenResponse = await verifyInvitation(tokenPayload);
        console.log(verifyTokenResponse ,"verifyTokenResponse")
        if (
          verifyTokenResponse &&
          verifyTokenResponse?.response
        ) {
          const authToken = verifyTokenResponse?.response?.authToken;
          console.log(authToken ,"authToken  workpspace")
          SetCookie("token", authToken);
          if (verifyTokenResponse?.response) {
              let subdomain = null;
              if (orgURL) {
                const parts = orgURL.split('.');
                if (parts.length >= 3) {
                  subdomain = parts[0];
                  parts.splice(0, 1);
                }
              }
              let newURL;
              const tokenlogin = GetCookie('token');
              console.log(tokenlogin ,"token login 91")
              if (tokenlogin) {
                if (window.location.hostname == "localhost") {
                  navigate("/home");
                } else {
                  newURL = `https://${subdomain}.vivrn.com/home`;
                  window.location.href = newURL;
                }
              }
              toast.success("Login successfully");
            } else {
              const tokenlogin = GetCookie('token');
              console.log(tokenlogin,"tokenlogin 103")
              let newURL;
              if (tokenlogin) {
                if (window.location.hostname == "localhost") {
                  navigate("/login");
                } else {
                  newURL = `https://vivrn.com/login`;
                  window.location.href = newURL;
                }
              }
              RemoveCookie("token");
              RemoveCookie("userDTO");
              RemoveCookie("workSpaceDTO");
            }
          
         
        } else {
          toast.error(`invalid token`);
        }
      } catch (error) {
        toast.error(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="container-fluid backgroundColorBody p-0">
        <SignUpNavbar />
        <div className="row w-100">
          <div className="col-md-12">
            <div className="setUpAccountContainer">
              <form onSubmit={formik.handleSubmit}>
                <StepFormForNewUser
                  handleChange={formik.handleChange}
                  values={formik.values}
                  errors={formik.errors}
                  touched={formik.touched}
                />
                <div className="ButtonContainer">
                  <button
                    type="submit"
                    className="BlueButton"
                    disabled={formik.isSubmitting || isSubmitDisabled}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};

export default SignUpAccountForNewUser;
