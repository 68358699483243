
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import getWorkspaceId from "../../util/AttributeUtil";
import { apiUrls } from "../endPoints/ApiUrls";
import {loginApiUrl } from "../url/UrlConfig";

const GetAllUserWorksapce = async () => {
  const userAuthToken = GetCookie('token');
  try {
    const response = await fetch(
      loginApiUrl() + apiUrls.workSpace.getUserWorkspaces,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authToken": userAuthToken,
        },
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      console.error("Request failed. Please try again.");
      throw new Error("Request failed");
    }
  } catch (error) {
    console.error(error, "Please Try Again");
    return [];
  }
};
export default GetAllUserWorksapce;


