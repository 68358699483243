const RoutesEnum = {
  HOME: "home",
  LOGIN: "login",
  FORGOT_PASSWORD: "forgotpassword",
  NEW_PASSWORD: "newpassword",
  REGISTER: "register",
  VERIFICATION: "verification",
  SIGNUP: "signup",
  PREPARE_DATA: "prepareData",
  CONNECT_WIREHOUSE: "connect-wirehouse",
  UPLOAD_DATA: "uploadData",
  DASHBOARD: "dashboard",
  FILE_REPORT: "file-report",
  CONTENT_TAB: "content-tab",
  UPLOAD: "upload",
  CONNECT_WIRE_HOUSE: "connect-wire-house",
  RECENT: "recent",
  ROW_COLUMN_SUMMARY: "row-column-summary",
  SUPER: "super",
  ALL_USER: "allUser",
  CREATE_REPORT: "createReport",
  TRASH: "trash",
  ANALYTICS: "analytics",
  COLLAB: "collab",
  SET_UP_ACCOUNT: "setUpAccount",
  SET_UP_ACCOUNT_FOR_NEW_USER: "setUpAccountNewUser",
  SIGN_UP_WITH_GOOGLE: "sign-up-with-google",
  SIGNUPTODAY: "Sign-up-today",
  VERIFYEMAILTOACTIVATE: "verify-email-to-activate",
  WORKSPACE: "workSpace",
  DEFAULT_WORKSPACE: "default-workSpace",
  CREATE_WORKSPACE: "Rahulcreate-workSpace",
  LOGIN_ACCOUNT_VERIFY: "login-account-verify",
  QUERYPROMPTSDETAILS: "query-prompt-details",
  ADD_TEAM:"add-team",
  MEMBER_WORKSAPCE:"member-workspace",
  CREATEWORKSPACE: "create-workspace",
  WORKSPACE_NEW_USER:"workspace-new-user"
};

export default RoutesEnum;
