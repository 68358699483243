import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ImportedDataModal.css";
import DataSetDropdown from "../../pages/createReport/dataPreview/DataSetDropDown";
import {
  InnerJoin,
  LeftJoin,
  OuterJoin,
  RightJoin,
} from "../../constants/pictures/Pictures";
import { useDispatch } from "react-redux";
import {
  handleModalJoinOpenData,
} from "../../redux/reduxToolkit/Reducer";
import fetchLogicalResources from "../../service/api/FetchLogicalResourcesApi";
import fetchLogicalAttributes from "../../service/api/FetchLogicalAtrributeApi";
import {
  JOIN_COND,
  JOIN_HEADING,
  PARAGRAPH_JOIN_INFO,
} from "../../dtos/Join.type";
import { userAuthToken } from "../../dtos/Token.type";

function JoinDataSetModal() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("option1");
  const [selectedImage, setSelectedImage] = useState(LeftJoin);
  const dispatch = useDispatch();
  const closeModal = () => {
    setModalOpen(false);
  };
  const handleOptionChange = (event, image) => {
    setSelectedOption(event.target.value);
    setSelectedImage(image);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setModalOpen(false);
  };

  const handleModalJoinOpen = () => {
    setModalOpen(true);
  };
  dispatch(handleModalJoinOpenData(handleModalJoinOpen));
  const [tableData, setTableData] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserNameTable2, setSelectedUserNameTable2] = useState("");
  useEffect(() => {
    const fetchUsers = async () => {
      const usersData = await fetchLogicalResources(userAuthToken);
      setTableData(usersData);
    };
    fetchUsers();
  }, [userAuthToken]);
  const handleUserClick = async (user, userName) => {
    const userId = user.id;
    const attributesData = await fetchLogicalAttributes(userAuthToken, userId);
    setSelectedUserName(userName);
  };
  const handleUserClickTable2 = async (user, userName) => {
    const userId = user.id;
    setSelectedUserNameTable2(userName);
  };

  return (
    <>
      <Modal
        show={modalOpen}
        onHide={closeModal}
        centered
        dialogClassName="custom-modal"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="mt-4">Join Configuration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>{JOIN_HEADING}</h3>
              </div>
              <div className="col-md-12">
                <p>{PARAGRAPH_JOIN_INFO}</p>
                <form>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-check">
                        <label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="leftjoin"
                            value="leftjoin"
                            checked={selectedOption === "leftjoin"}
                            onChange={(event) =>
                              handleOptionChange(event, LeftJoin)
                            }
                            style={{ display: "none" }}
                          />
                          <img
                            src={LeftJoin}
                            alt="leftjoin"
                            className="img-fluid"
                            style={{
                              border:
                                selectedImage === LeftJoin
                                  ? "2px solid black"
                                  : "2px solid grey",
                              position: "relative",
                              display: "block",
                              margin: "auto",
                            }}
                            onClick={(event) =>
                              handleOptionChange(event, LeftJoin)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="rightjoin"
                            value="rightjoin"
                            checked={selectedOption === "rightjoin"}
                            onChange={(event) =>
                              handleOptionChange(event, RightJoin)
                            }
                            style={{ display: "none" }}
                          />
                          <img
                            src={RightJoin}
                            alt="rightjoin"
                            className="img-fluid"
                            style={{
                              border:
                                selectedImage === RightJoin
                                  ? "2px solid black"
                                  : "2px solid grey",
                              position: "relative",
                              display: "block",
                              margin: "auto",
                            }}
                            onClick={(event) =>
                              handleOptionChange(event, RightJoin)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="innerjoin"
                            value="innerjoin"
                            checked={selectedOption === "innerjoin"}
                            onChange={(event) =>
                              handleOptionChange(event, InnerJoin)
                            }
                            style={{ display: "none" }}
                          />
                          <img
                            src={InnerJoin}
                            alt="innerjoin"
                            className="img-fluid"
                            style={{
                              border:
                                selectedImage === InnerJoin
                                  ? "2px solid black"
                                  : "2px solid grey",
                              position: "relative",
                              display: "block",
                              margin: "auto",
                            }}
                            onClick={(event) =>
                              handleOptionChange(event, InnerJoin)
                            }
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-check">
                        <label>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="outerjoin"
                            value="outerjoin"
                            checked={selectedOption === "outerjoin"}
                            onChange={(event) =>
                              handleOptionChange(event, OuterJoin)
                            }
                            style={{ display: "none" }}
                          />
                          <img
                            src={OuterJoin}
                            alt="outerjoin"
                            className="img-fluid"
                            style={{
                              border:
                                selectedImage === OuterJoin
                                  ? "2px solid black"
                                  : "2px solid grey",
                              position: "relative",
                              display: "block",
                              margin: "auto",
                            }}
                            onClick={(event) =>
                              handleOptionChange(event, OuterJoin)
                            }
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-12 mt-2 mb-2">
                <h2>Join Condition</h2>
                <p>{JOIN_COND}</p>
              </div>
              <div className="col-4 col-md-4 mt-2 mb-2">
                <DataSetDropdown
                  selectedUserName={selectedUserName}
                  users={tableData}
                  handleUserClick={handleUserClick}
                  isCustomBehavior={true}
                  uniqueIdentifier="3"
                />
              </div>
              <div className="col-4 col-md-4 mt-2 mb-2 d-flex justify-content-center">
                <i className="fa-solid fa-link"></i>
              </div>
              <div className="col-4 col-md-4 mt-2 mb-2">
                <DataSetDropdown
                  selectedUserName={selectedUserNameTable2}
                  users={tableData}
                  handleUserClick={handleUserClickTable2}
                  isCustomBehavior={true}
                  uniqueIdentifier="2"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            className="CancelButton btn btn-secondary"
          >
            Close
          </Button>
          <button
            type="submit"
            className="btn btn-primary mt-2 ms-2"
            onClick={handleSubmit}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default JoinDataSetModal;
