// SearchInput.js
import React, { useState } from "react";

function SearchInput({ onSearch }) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    e.preventDefault(); // Prevent the form from submitting in a traditional way
    onSearch(searchTerm);
  };

  return (
    <form role="search" onSubmit={handleSearch}>
        <div className="position-relative">
          <div className="SearchIconData">
            <i className="fa-solid fa-magnifying-glass"></i>
          </div>
          <input
            className="CustomCommonCss"
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </form>
  );
}

export default SearchInput;
