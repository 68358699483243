import Layout from "../../../component/layout/Layout";
import "./RowColumnSummary.css";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css"
import { acceptedFileTypes } from "../../../dtos/import.type";
export default function RowColumnSummary() {
    const [show, setShow] = useState(false);
    const [fileType, setFileType] = useState("csv"); // Default file type is CSV
    const [jsonData, setJsonData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [showTable, setShowTable] = useState(false); // Track if table should be shown
    const [showUploadSection, setShowUploadSection] = useState(true); // Track if upload section should be shown
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const [projectName, setProjectName] = useState("");
    const handleProjectNameChange = (event) => {
        setProjectName(event.target.value);
    };
    const keysData = Object.keys(jsonData[0] || []);


    // localStorage.setItem("dataFilter", JSON.stringify(jsonData));
    const handleFileChange = (event) => {
        const filesArray = Array.from(event.target.files);
        const results = [];
        if (!projectName) {
            toast.error('Please enter a project name.');
            event.target.value = ""; // Reset the file input value
            return;
        }
        filesArray.forEach((file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!acceptedFileType.includes(fileExtension)) {
                toast.error(`Invalid file type: ${file.name}`);
                return;
            }
            const reader = new FileReader();
            reader.onload = () => {
                const csvData = reader.result;
                const jsonData = convertCsvToJson(csvData);
                // Add date property to each object in JSON data
                const date = new Date().toISOString();
                jsonData.data.forEach((obj) => {
                    obj.date = date;
                    obj.fileName = file.name;
                });

                results.push(...jsonData.data);
                setJsonData(results);
                setShowTable(true); // Show the table
                setShowUploadSection(false); // Hide the upload section

                toast.success('File uploaded successfully.'); // Show success notification
            };
            reader.onerror = () => {
                toast.error(`Failed to upload file: ${file.name}`);
            };
            reader.readAsText(file);
        });
    };
 

    const acceptedFileType = acceptedFileTypes[fileType];

    const convertCsvToJson = (csvData) => {
        const lines = csvData.split("\n");
        const headers = lines[0].split(",");
        const jsonData = [];
        for (let i = 1; i < lines.length; i++) {
            const currentLine = lines[i].split(",");
            if (currentLine.length === headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    const value = currentLine[j].trim();
                    obj[headers[j]] = value;
                }

                jsonData.push(obj);
            }
        }
        return {
            data: jsonData,
            rowCount: jsonData.length,
        };
    };
    const handleFileTypeChange = (event) => {
        setFileType(event.target.value);
    };
    // console.log(filteredData,"jjfsafs")
    const handlePrevious = () => {
        setShowTable(false); // Hide the table
        setShowUploadSection(true); // Show the upload section
        setJsonData([]); // Clear the uploaded data
        setProjectName(""); // Clear the project name input
        document.getElementById("file-input").value = ""; // Clear the file upload input
        document.getElementById("fileType").selectedIndex = 0; // Reset the file type dropdown to the first option
    };


    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const handleColumnToggle = (columnKey) => {
        if (hiddenColumns.includes(columnKey)) {
            setHiddenColumns(hiddenColumns.filter((key) => key !== columnKey));
        } else {
            setHiddenColumns([...hiddenColumns, columnKey]);
        }
    };

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const handleApply = () => {
        setHiddenColumns(hiddenColumns);
        // Implement the logic to apply the changes made in the dropdown (e.g., update the table display)
        // You can access the selected columns from the 'hiddenColumns' state array
    };

    const handleCancel = () => {
        // Implement the logic to cancel the changes and reset the dropdown state
        // You may need to restore the original visibility of columns
        setHiddenColumns([]);
    };
    const handleNext = () => {
        // Handle the logic for the next action
    };
    // Get the combined object from local storage
    const [selectedValues, setSelectedValues] = useState({});

    const handleChange = (key, value) => {
        setSelectedValues((prevSelectedValues) => ({
            ...prevSelectedValues,
            [key]: value,
        }));
    };
    const [columns, setColumns] = useState([]);
    const [tableData, setTableData] = useState([]);
    const storedDataWithKeys = localStorage.getItem("myDataWithKeysRowcolumn");
    const [hoveredColumn, setHoveredColumn] = useState(null);
    // Parse the stored combined object back into an object
    const dataWithKeys = JSON.parse(storedDataWithKeys);

    // Extract the data and keys from the combined object
    const data = dataWithKeys.jsonData;
    const keys = dataWithKeys.keys;
    const filteredValues = data.map((item) => Object.values(item));

    console.log(filteredValues);
    console.log(data, "value data")
    return (
        <Layout>
            <div className="AnalyticsContainer">
                <div className="container-fluid">
                    <div >
                        <div  className="row ">
                            <div className="change-bg">
                                <h2 className="modal-title">Import your Data</h2>
                                <div>
                                    <p>
                                        Import data from Excel, CSV, HTML, XML, JSON, Statistical & MS
                                        Access files
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <h3>  Report Name</h3>
                            </div>
                            <div className="col-md-6">
                                <label className="label-bold mt-2">First Row contain Column Name?</label>
                                <div className="dropdown mt-2">
                                    <button className="btn btn-outline-secondary dropdown-toggle me-2" type="button" id="shareDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        column Row
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                                        <li><span className="dropdown-item cursor-pointer"> Column</span></li>
                                        <li><span className="dropdown-item cursor-pointer"> Row</span></li>
                                    </ul>
                                </div>
                                <label className="label-bold mt-2">Formate of Date & Time columns</label>
                                <div className="dropdown mt-2">
                                    <button className="btn btn-outline-secondary dropdown-toggle me-2" type="button" id="shareDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                        column Row
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                                        <li><span className="dropdown-item cursor-pointer"> Column</span></li>
                                        <li><span className="dropdown-item cursor-pointer"> Row</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label>Column summary</label>
                                <div>
                                    <table className="table">
                                        <thead>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Total Columns</td>
                                                <td>34</td>
                                            </tr>
                                            <tr>
                                                <td>Selected for import</td>
                                                <td>34</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <label>Row Summary</label>
                                <div>
                                    <table className="table">
                                        <thead>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Total Rows</td>
                                                <td>34</td>
                                            </tr>
                                            <tr>
                                                <td>Selected for import</td>
                                                <td>34</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col-md-12">

                                <div className="DataPreview">
                                    <h3>Data Preview(Top 10 rows shown)</h3>
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    {keys.map((key) => (
                                                        <th key={key}>
                                                            {key}
                                                            <div className="dropdown">
                                                            </div>
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredValues.slice(0, 50).map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        {keys.map((key) => (
                                                            <td key={key}>{item[key]}</td>
                                                        ))}
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-12">
                                <button 
                                    className="PreviousButton" style={{ float: 'left' }}
                                >
                                    Previous
                                </button>
                                <button 
                                    className="NextButton"
                                    style={{ margin: '0 10px' }}
                                >
                                    Next
                                </button>
                                <button 
                                    className="PreviousButton" style={{ float: 'right' }}
                                >
                                    Cancel
                                </button>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </Layout>
    )
}