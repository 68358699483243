import React from "react";
import Header from "../header/Header";
import AsidenavBar from "../asideNavbar/AsideNavbar";
import { SidebarProvider } from "./SideBarContext";
import PropTypes from "prop-types";

const Layout = ({ children }) => {
  return (
    <SidebarProvider>
      <Header />
      <AsidenavBar />
      {children}
    </SidebarProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
