import React, { useState, useEffect } from "react";
import { OFFSET } from "../../../dtos/TrashType";
import TrashTable from "../trashTable/TrashTable.component";
import GetDataSetTeamContent from "../../../service/api/GetDataSetTeamContentApi";
import DataNotFound from "../../../component/dataNotFound/DataNotFound";
import { formatDate } from "../../../directives/date-formate";
import { excludedKeys } from "../../../dtos/MyContent.type";

function TeamTrashPage() {
  const [selectAll, setSelectAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allReportData, setAllReportData] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);

  const fetchGetAllReportApi = async () => {
    try {
      const teamContentDataSet = await GetDataSetTeamContent();
      if (teamContentDataSet && teamContentDataSet.response) {
        setAllReportData(teamContentDataSet.response);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGetAllReportApi();
  }, [OFFSET]);
  
  const handleSelectAll = () => {
    const updatedCheckboxItems = filteredData.map((item) => ({
      ...item,
      checked: !selectAll,
    }));
    setCheckboxItems(updatedCheckboxItems);
    setSelectAll(!selectAll);
    setIndeterminate(false);

    // Update the selectedIds based on the new checked state
    const selectedIdsArray = !selectAll
      ? updatedCheckboxItems.map((item) => item.id)
      : [];
    setSelectedIds(selectedIdsArray);
  };

  useEffect(() => {
    const filtered = allReportData?.filter(
      (item) =>
        item.baseName &&
        item.baseName.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  }, [search, allReportData]);

  useEffect(() => {
    if (allReportData.length > 0) {
      const keys = Object.keys(allReportData[0]).filter(
        (key) => !excludedKeys.includes(key)
      );
      setTableHeaders(keys);
    }
  }, [checkboxItems, allReportData]);

  const handleCheckboxChange = (event, id) => {
    const updatedCheckboxItems = filteredData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: event.target.checked,
        };
      }
      return item;
    });
    setCheckboxItems(updatedCheckboxItems);

    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }

    const allChecked = updatedCheckboxItems.every((item) => item.checked);
    const someChecked = updatedCheckboxItems.some((item) => item.checked);

    setSelectAll(allChecked);
    setIndeterminate(someChecked && !allChecked);
  };
  const [checkedRows, setCheckedRows] = useState({});

  useEffect(() => {
    const initialCheckedRows = filteredData.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: selectAll,
      }),
      {}
    );
    setCheckedRows(initialCheckedRows);
  }, [filteredData, selectAll]);

  const handleRowCheckboxChange = (event, id) => {
    const isChecked = event.target.checked;
    setCheckedRows((prevCheckedRows) => ({
      ...prevCheckedRows,
      [id]: isChecked,
    }));
    handleCheckboxChange(event, id);

    const allChecked = Object.values(checkedRows).every(Boolean);
    const someChecked = Object.values(checkedRows).some(Boolean);

    setSelectAll(allChecked);
    setIndeterminate(someChecked && !allChecked);
  };
  return (
    <>
      <div className="col-md-12">
        <table className="table tableCustomTrash set-table">
          <thead>
            <tr>
              <th className="table-color-trash">
                <input
                  className="me-2"
                  type="checkbox"
                  name="select-header"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Select All
              </th>
              <th className="table-color-trash">Id</th>
              {tableHeaders?.map((header) => (
                <th className="set-capital table-color-trash" key={header}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredData && filteredData.length > 0 ? (
              filteredData.map((item) => (
                <tr key={item?.id}>
                  <td>
                    <input
                      name="table-row"
                      type="checkbox"
                      checked={checkedRows[item.id] || false}
                      onChange={(event) =>
                        handleRowCheckboxChange(event, item.id)
                      }
                    />
                  </td>
                  <td>{item.id}</td>
                  <td>{formatDate(item.createdDate)}</td>
                  <td>{formatDate(item.lastModifiedDate)}</td>
                  <td>{item.baseName}</td>
                </tr>
              ))
            ) : (
              <DataNotFound />
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default TeamTrashPage;
