import React from "react";
import "./TableComponent.css";
import DynamicalTable from "../../../component/table/dynamicTable/DynamicalTable";
export default function TableComponent({ jsonData }) {
  return (
    <>
      {(Array.isArray(jsonData) && jsonData.length > 0) ||
      (typeof jsonData === "object" &&
        jsonData !== null &&
        Object.keys(jsonData).length > 0) ? (
        <div>
          <h3>Data Preview (Top 20 rows shown)</h3>
          <DynamicalTable dataApi={jsonData} />
        </div>
      ) : (
        <h1>Table data not found</h1>
      )}
    </>
  );
}
