import React from "react";
import GraphController from "./GraphController";
import './TableMetaDataList.css'; // Import the CSS file for styling
const QueryPlaygroundGraph = ({ responseData, chartType }) => {
    return (
        <>
        <div className="row mt-4">
        <div className="col-md-1">
          <span className="chart-title-q">Chart</span>
        </div>
        <div className="col-md-4 text-right">
          <button className="btn download-chart">
            <i className="fas fa-download"></i> Download chart
          </button>
        </div>
      </div>
        <div
            id={responseData?.uuid}
            style={{
                // width: responseData.layout?.width,
                // height: responseData.layout?.height,
                width:500,
                height:500,
                transform: responseData.layout?.x != null && responseData.layout?.y != null ? `translate(${responseData.layout.x}px, ${responseData.layout.y}px)` : null
            }}

        >
            <GraphController responseData={responseData} chartType={chartType} />
        </div>
        </>
    )
}
export default QueryPlaygroundGraph;