import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { BASE_URL } from "../endPoints/Endpoint";

const PromptCellDataApi = async (payload) => {
    const userAuthToken = GetCookie("token");
    try {
        const response = await fetch(
            `${BASE_URL}/api/q2g/fetch/cellData`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "authToken": userAuthToken,
                },
                body: payload,
            }
        );

        const result = await response.json();
        console.log(result)
        if (result.httpStatusCode == '200') {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error, "Please Try Again");
        return null;
    }
};

export default PromptCellDataApi;
