import React from "react";
import { notFoundImage } from "../../constants/pictures/Pictures";
import "./notFound.css";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/home", { replace: true });
  };
  return (
    <div className="container">
      <div className="row notFoundContainer">
        <div className="col-sm-7">
          <img src={notFoundImage} alt="Not Found" className="img-fluid p30" />
        </div>
        <div className="col-sm-5">
          <div className="notFoundBox">
            <div className="text-center">
              <h1>404 !</h1>
              <h2>Page not found</h2>
              <p>
                Uh-oh! It seems like the data you're looking for has gone on
                vacation. Our analytics gurus are out searching for it. In the
                meantime, you can:
              </p>
              <button onClick={handleOnClick}>Home</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound;
