import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { ImageChartSelectionData } from "../../../redux/reduxToolkit/Reducer";
import { enumChartTypes } from "../../../enums/enum";
import { icons } from "../../../constants/icons/Icons";

const ChartSelection = ({ handleChartSelection }) => {
  const selecteddChart = useSelector((state) => state.selecteddChart);
  const [selectedChart, setSelectedChart] = useState(null);

  const dispatch = useDispatch();

  const handleChartClick = (chartType) => {
    dispatch(ImageChartSelectionData(chartType));
    setSelectedChart(chartType);
    handleChartSelection(chartType);
  };

  useEffect(() => {
    setSelectedChart(selecteddChart);
  }, [selecteddChart]);

  const options = Object.values(enumChartTypes).map(chartType => ({
    value: chartType,
    label: (
      <div className="dropdown-item">
        <img src={icons[chartType]} className="chartDropdownIcon" />
        <span>{chartType.replaceAll('_', ' ')}</span>
      </div>
    )
  }));

  const customDropdownValue = selectedChart
    ? {
      value: selectedChart,
      label: (
        <div className="selected-option">
          <img src={icons[selectedChart]} className="chartDropdownIcon" />
          <span>{enumChartTypes[selectedChart].replaceAll('_', ' ')}</span>
        </div>
      )
    }
    : null;

  return (
    <div className="chart-selection-dropdown">
      <Dropdown>
        <div className="d-flex align-items-center">
          <Dropdown.Toggle variant="none" id="dropdown-basic">
            {customDropdownValue ? (
              <span className="selected-option">
                <span>{enumChartTypes[selectedChart].replaceAll('_', ' ')}</span>
              </span>
            ) : (
              <span className="selected-option">
              <span>{enumChartTypes.CLUSTERED_COLUMN.replaceAll('_', ' ')}</span>
              </span>
            )}
          </Dropdown.Toggle>
          {customDropdownValue ? (
          <div className="selectedChartShowingImage">
            <img src={icons[selectedChart]} className="img-fluid" />
          </div>
          ) : (
            <div className="selectedChartShowingImage">
            <img src={icons.CLUSTERED_COLUMN} className="img-fluid" />
          </div>
          )}
        </div>

        <Dropdown.Menu>
          {options.map(option => (
            <Dropdown.Item
              key={option.value}
              onClick={() => handleChartClick(option.value)}
            >
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ChartSelection;
