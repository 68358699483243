import SetCookie from "../../persistence/cookies/setCookie/SetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getSignUp } from "../url/UrlConfig";


export default async function SignUpWithGoggle(payload) {
  try {
    const response = await fetch(getSignUp() + apiUrls.signUp.googleSignUp, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const result = await response.json();
    if (result.httpStatusCode=='200') {
      const token = result.response;
      const authToken = token?.authToken;
      SetCookie('token', authToken);
      SetCookie('userDTO', JSON.stringify(result.response?.userDTO));
      const workSpaceDTO = JSON.stringify(token?.userDTO?.workspaceDTOS);
      SetCookie('workSpaceDTO', workSpaceDTO);
    }else{
      return result;
    }
  } catch (error) {
    console.error(error.message || error);
    return error.message;
  }
}


