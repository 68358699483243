import React from "react";
import Layout from "../../../../component/layout/Layout"
import CsvTable from "../csvTable/CsvTable";
import "./UploadData.css";

export function UploadData(){
    return(
        <Layout>
    <div className="AnalyticsContainer">
      <div className="container-fluid">
        <div className="row">
          <div className="uploadedDataCustom">
            <div className="col-md-12 ">
            <CsvTable/>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
    )
}

