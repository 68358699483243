import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { SidebarContext } from '../layout/SideBarContext';
import TooltipAnalytics from '../toolTip/ToolTip';
import "./AsideNavbar.css";
import { icons } from '../../constants/icons/Icons';

const navItems = [
  { to: '/home', text: 'Home', icon: icons.homeTab, activeIcon: icons.homeTabBlue },
  { to: '/upload', text: 'Upload', icon: icons.reportTab, activeIcon: icons.reportTabBlue },
  { to: '/dashboard', text: 'Dashboard', icon: icons.dashboardTab, activeIcon: icons.dashboardTabBlue },
  { to: '/content-tab', text: 'Data', icon: icons.dataTab, activeIcon: icons.dataTabBlue },
  { to: '/recent', text: 'Settings', icon: icons.settingsTab, activeIcon: icons.settingsTabBlue },
  { to: '/connect-wire-house', text: 'Data Source', icon: icons.dataSourceTab, activeIcon: icons.dataSourceTabBlue },
  { to: '/trash', text: 'Trash', icon: icons.trashTab, activeIcon: icons.trashTabBlue },
];

const AsidenavBar = () => {
  const { isOpen } = useContext(SidebarContext);

  return (
    <div className="area">
      <nav className={isOpen ? 'main-menu main-menuopen' : 'main-menu'}>
        <ul>
          {navItems.map((item, index) => (
            <li key={index} className="has-subnav">
              <NavLink to={item.to} activeclassname="active">
                <TooltipAnalytics text={item.text}>
                  <div className='navsidebar'>
                    <div className='navImagebox'>
                      <img src={item.activeIcon} className='img-fluid navbarActiveIcon' />
                      <img src={item.icon} className='img-fluid navbarIcon' />
                    </div>
                    <div className="nav-text ten500">{item.text}</div>
                  </div>
                </TooltipAnalytics>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default AsidenavBar;
