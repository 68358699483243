import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Layout from "../../component/layout/Layout";
import "./Trash.css";
import { excludedKeys } from "../../dtos/MyContent.type";
import TrashHeader from "./trashHeader/Trashheader.component";
import { API_URL, OFFSET, TRASH_INFO } from "../../dtos/TrashType";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import GetAllReportOnTrashApi from "../../service/api/GetAllReportOnTrashApi";
import TrashTabSection from "./tab/TrashTabSection";

function Trash() {
  const [selectAll, setSelectAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allReportData, setAllReportData] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const fetchGetAllReportApi = async () => {
    try {
      const getReport = await GetAllReportOnTrashApi();
      // const teamContentDataSet=await GetDataSetTeamContent()
      // let mergedDataSet = [];
      // let mergedGetReport = [];
      // let mergedTeamDataSet = [];
  
      if (getReport && getReport.response) {
           setAllReportData(getReport.response);
      } 
        
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  

  
  useEffect(() => {
    fetchGetAllReportApi();
  }, [OFFSET]);

  useEffect(() => {
    const filtered = allReportData?.filter((item) =>
      item.title && item.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  }, [search, allReportData]);
  

  const handleSelectAll = () => {
    const updatedCheckboxItems = filteredData.map((item) => ({
      ...item,
      checked: !selectAll,
    }));
    setCheckboxItems(updatedCheckboxItems);
    setSelectAll(!selectAll);
    setIndeterminate(false);

    // Update the selectedIds based on the new checked state
    const selectedIdsArray = !selectAll
      ? updatedCheckboxItems.map((item) => item.id)
      : [];
    setSelectedIds(selectedIdsArray);
  };

  const handleCheckboxChange = (event, id) => {
    const updatedCheckboxItems = filteredData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: event.target.checked,
        };
      }
      return item;
    });
    setCheckboxItems(updatedCheckboxItems);

    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }

    const allChecked = updatedCheckboxItems.every((item) => item.checked);
    const someChecked = updatedCheckboxItems.some((item) => item.checked);

    setSelectAll(allChecked);
    setIndeterminate(someChecked && !allChecked);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: "#d33",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Deleted!", "Your items have been deleted!", "success");
      }
    });
  };

  const restoreReport = async (id) => {
    try {
      const userAuthToken = GetCookie("token");
      const response = await fetch(`${API_URL}/restoreReport/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authToken: userAuthToken,
        },
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result, "Report Restored");
      } else {
        console.error("Request to restore report failed. Please try again.");
      }
    } catch (error) {
      console.error(error, "Please Try Again");
    }
  };

  const handleRestore = () => {
    if (selectedIds.length === 0) {
      return;
    }
    Swal.fire({
      title: "Are you sure?",
      text: "Once restored, you will not be able to undo this action.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, restore",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let completedRequests = 0;
        selectedIds.forEach((id) => {
          restoreReport(id)
            .then(() => {
              completedRequests++;
              if (completedRequests === selectedIds.length) {
                Swal.fire({
                  icon: "success",
                  title: "Reports Restored Successfully",
                  showConfirmButton: false,
                  timer: 1500,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            })
            .catch((error) => {
              console.error("Error restoring report:", error);
              completedRequests++;
            });
        });
        setSelectedIds([]);
      }
    });
  };

  useEffect(() => {
    if (allReportData.length > 0) {
      const keys = Object.keys(allReportData[0]).filter(
        (key) => !excludedKeys.includes(key)
      );
      setTableHeaders(keys);
    }
  }, [checkboxItems, allReportData]);

  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <TrashHeader
            selectAll={selectAll}
            indeterminate={indeterminate}
            handleSelectAll={handleSelectAll}
            search={search}
            setSearch={setSearch}
            selectedIds={selectedIds}
            handleRestore={handleRestore}
            handleDelete={handleDelete}
          />
          
          <p className="ReportsText">
            <i className="fa-solid fa-triangle-exclamation me-2"></i>{" "}
            {TRASH_INFO}
          </p>
          <TrashTabSection  tableHeaders={tableHeaders}
            filteredData={filteredData}
            handleCheckboxChange={handleCheckboxChange}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            checkboxItems={checkboxItems}
            setSelectAll={setSelectAll}
            setIndeterminate={setIndeterminate}/>
         
        </div>
      </div>
    </Layout>
  );
}

export default Trash;
