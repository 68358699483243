import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { steps } from "../../../dtos/Database.type";
import StepOneForm from "../stepsForm/StepOne";
import StepTwoForm from "../stepsForm/StepTwo";
import StepThreeForm from "../stepsForm/StepThree";
import "./SetUpAccount.css";
import SignUpNavbar from "./SignUpNavbar";
import { useNavigate } from "react-router";
import useJWT from "../../../util/Encode";
import VerifyEmail from "../../../service/api/VerifyEmailApi";
import CheckUrlApi from "../../../service/api/CheckUrlApi";

const SignUpAccount = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1); //1
  const [completedSteps, setCompletedSteps] = useState([]);
  // const [messageApi, setMessageApi] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const step1Schema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    businessName: Yup.string().required("Business Name is required"),
    jobOption: Yup.string().required("Job Position is required"),
  });

  const step2Schema = Yup.object().shape({
    companySize: Yup.string().required("Please select a company size"),
  });

  const step3Schema = Yup.object().shape({
    cwebsite: Yup.string()
      .matches(/^[a-zA-Z0-9-]+$/, "Company Website should not contain special characters")
      .required("Company Website is required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      businessName: "",
      jobOption: "",
      companySize: "",
      cwebsite: "",
    },
    validationSchema:
      currentStep === 1
        ? step1Schema
        : currentStep === 2
          ? step2Schema
          : step3Schema,
    onSubmit: async (values, { setSubmitting }) => {
      if (currentStep === 3) {
        const userEmailDetails = localStorage.getItem("sign-up-today");
        const userEmailDetailsParse = JSON.parse(userEmailDetails);
        const mergedObject = { ...values, ...userEmailDetailsParse };
        const verifyEmailToken = {
          emailId: mergedObject.email,
          password: mergedObject.password,
          firstName: mergedObject.firstName,
          lastName: mergedObject.lastName,
          userRoleInCompany: mergedObject.jobOption,
          receivePromotionalEmails: mergedObject.agreeToTerms,
          organizationUrl: mergedObject.cwebsite + ".vivrn.com",
          phoneNumber: mergedObject.phoneNumber,
          organizationName: mergedObject.businessName,
        };
        const token = useJWT(verifyEmailToken);
        const ecodeToken = {
          token: token,
        };
        VerifyEmail(ecodeToken);
        navigate("/verify-email-to-activate");
      } else {
        setCompletedSteps([...completedSteps, currentStep]);
        setCurrentStep(currentStep + 1);
      }
    },
  });

  const validateStep = async (stepIndex) => {
    try {
      let validationSchema;
      switch (stepIndex) {
        case 1:
          validationSchema = step1Schema;
          break;
        case 2:
          validationSchema = step2Schema;
          break;
        case 3:
          validationSchema = step3Schema;
          break;
        default:
          break;
      }
      await validationSchema.validate(formik.values, { abortEarly: false });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleStepClick = async (stepIndex) => {
    if (stepIndex < currentStep) {
      setCurrentStep(stepIndex);
    } else {
      const validationPassed = await validateStep(currentStep);

      if (validationPassed) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };
  const totalSteps = steps.length;
  const progress = Math.ceil((completedSteps.length / totalSteps) * 100);

  const [messageApi, setMessageApi] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(true);
  console.log(formik.values.cwebsite, "formik.values.cwebsite")
  const handleCheckUrl = async () => {
    const url = formik.values.cwebsite + ".vivrn.com";
    try {
      if (formik.values.cwebsite === "") {
        return null;
      }
      else {
        const apiResponse = await CheckUrlApi(url);
        if (apiResponse === true) {
          setMessageApi("Your subdomain is correct");
          setSubmitButtonDisable(false);
        } else {
          setMessageApi("Please try another subdomain");
          setSubmitButtonDisable(true);
        }
      }
    } catch (error) {
      console.error("Error occurred while checking URL:", error);
    }
  };
  useEffect(() => {
    setSubmitButtonDisable(true);
    const timer = setTimeout(() => {
      handleCheckUrl();
      console.log("Function called after 2 seconds");
    }, 2000);

    // Clear the timer to avoid memory leaks
    return () => clearTimeout(timer);
  }, [formik.values.cwebsite]);

  useEffect(() => {
    if (currentStep === 3 && formik.values.businessName) {
      formik.setFieldValue('cwebsite', formik.values.businessName);
    }
  }, [currentStep, formik.values.businessName, formik.setFieldValue]);

  return (
    <>
      <div className="container-fluid backgroundColorBody p-0">
        <SignUpNavbar />
        <div className="row w-100">
          <div className="col-md-12">
            <div className="setUpAccountContainer">
              <div className="AccountSetUp">
                <p>Account set up</p>
                <p>
                  {currentStep}/{totalSteps}
                </p>
              </div>
              <div className="setUpAccount">
                <div className="ProgressBarContainer">
                  <div
                    className="ProgressBar"
                    style={{ width: `${progress}%` }}
                  />
                </div>

                {steps.map((step, index) => (
                  <div
                    key={index}
                    className={`progress-step ${completedSteps.includes(index + 1) ? "completed" : ""
                      } ${index + 1 === currentStep ? "active" : ""}`}
                    onClick={() => handleStepClick(index + 1)}
                    style={{ width: "33.3%" }}
                  >
                    <div className="Index"></div>
                    <div className="borderBottom" />
                  </div>
                ))}
              </div>

              <form onSubmit={formik.handleSubmit}>
                {currentStep === 1 && (
                  <>
                    <StepOneForm
                      formik={formik}
                      handleChange={formik.handleChange}
                      value={formik.values}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                  </>
                )}

                {currentStep === 2 && (
                  <StepTwoForm
                    formik={formik}
                    handleChange={formik.handleChange}
                    value={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                  />
                )}
                {currentStep === 3 && (
                  <>
                    <StepThreeForm
                      formik={formik}
                      handleChange={formik.handleChange}
                      value={formik.values}
                      errors={formik.errors}
                      touched={formik.touched}
                    />
                    <div className="success-message">
                      <p style={{ marginLeft: "125px" }}>{messageApi}</p>
                    </div>
                  </>
                )}
                <div className="ButtonContainer">
                  {currentStep > 1 && (
                    <button
                      type="button"
                      className="whiteButton"
                      onClick={handlePrevious}
                    >
                      Back
                    </button>
                  )}

                  {currentStep < 3 && (
                    <button
                      type="submit"
                      className="BlueButton"
                      disabled={!formik.isValid}
                    >
                      Next
                    </button>
                  )}
                  {currentStep === 3 && (
                    <button
                      type="submit"
                      className="BlueButton"
                      disabled={submitButtonDisable}
                    // onClick={apiUrlCheck}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpAccount;
