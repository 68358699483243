import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import Layout from "../../component/layout/Layout";
import Mycontent from "./myContent/Mycontent";
import TeamContent from "./teamContent/TeamContent";
import "./ContentTab.css";
import TooltipAnalytics from "../../component/toolTip/ToolTip";
import TableContent from "./table/tableContent";

export default function ContentTab() {
  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="content">
          <div className="container-fluid">
            <div className="d-flex justify-content-between">
              <h2>Content</h2>
              <div className="d-flex align-items-center">
                <div className="position-relative">
                  <TooltipAnalytics text="Upload" className="BottomTextTooltip">
                    <button className="btn btn-outline-secondary me-2">
                      <i className="fa-solid fa-file-arrow-up"></i>
                    </button>
                  </TooltipAnalytics>
                </div>
                <div className="position-relative">
                  <TooltipAnalytics text="New" className="BottomTextTooltip">
                    <button className="btn customBtnRed">
                      New<i className="fa-solid fa-plus ms-2"></i>
                    </button>
                  </TooltipAnalytics>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Tabs className="CustomTabsFile ">
            <Tab eventKey="myContent" title="MyContent">
              <Mycontent />
            </Tab>
            <Tab eventKey="TeamContent" title="TeamContent">
              <TeamContent />
            </Tab>
            <Tab eventKey="TableContent" title="TableData">
              <TableContent />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Layout>
  );
}
