import React, { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { useDispatch } from 'react-redux';
import { focusModeClassData, resetfocusModeClassData } from '../../../redux/reduxToolkit/Reducer';

// Initialize exporting modules
exportingInit(Highcharts);
exportDataInit(Highcharts);


const PieDouble = (props) => {
  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(
    props?.responseData?.responseData?.data
  );
  useEffect(() => {
    setFetchData(props?.responseData?.responseData?.data);
  }, [props, fetchData]);
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props?.responseData?.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus,props?.responseData?.responseData]);
  const toggleFocusMode = () => {
    setGetFocus(prev => !prev);
};
  const [selectedMetric, setSelectedMetric] = useState([]);
  const [drilldownCategory, setDrilldownCategory] = useState(null);
  const [drilldownSeries, setDrilldownSeries] = useState([]);
  const calculateMetricsSum = () => {
    const metricsSum = {};

    for (const category in fetchData) {
      metricsSum[category] = {};

      fetchData[category].forEach((item) => {
        for (const metricName in item.metric) {
          metricsSum[category][metricName] =
            (metricsSum[category][metricName] || 0) + item.metric[metricName];
        }
      });
    }

    return metricsSum;
  };

  const handleMetricClick = (category) => {
    const categoryData = fetchData[category];
    if (categoryData && categoryData.length > 0) {
      setSelectedMetric(categoryData);
      setDrilldownCategory(category);
    } else {
      console.error(`No data found for category: ${category}`);
    }
  };

  const handleDrillUp = () => {
    setSelectedMetric([]);
    setDrilldownCategory(null);
  };

  const metricsSum = calculateMetricsSum();

  if (!metricsSum || Object.keys(metricsSum).length === 0) {
    return null;
  }

  const mainCategories = Object.keys(metricsSum);
  const subcategories = Object.keys(metricsSum[mainCategories[0]]);
  const seriesData = [
    {
      name: "Metrics",
      colorByPoint: true,
      data: mainCategories.flatMap((category) =>
        Object.entries(metricsSum[category]).map(
          ([metricName, metricValue]) => ({
            name: `${category} - ${metricName}`,
            y: metricValue,
            drilldown: category,
          })
        )
      ),
    },
  ];

  const drilldownSeriesOptions =
    drilldownCategory &&
    fetchData[drilldownCategory]?.reduce((acc, item, index) => {
      const sgb = item.sgb;
      const total = Object.values(item.metric).reduce(
        (sum, value) => sum + value,
        0
      );
      Object.entries(item.metric).forEach(([metricKey, metricValue]) => {
        acc.push({
          name: `${sgb} - ${metricKey}`,
          y: (metricValue / total) * 100,
          color: Highcharts.getOptions().colors[index % 10],
          value: metricValue,
        });
      });
      return acc;
    }, []);

  const options = {
    chart: {
      type: "pie",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    series: drilldownCategory
      ? [{ name: drilldownCategory, data: drilldownSeriesOptions }]
      : seriesData,
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.y}",
        },
        point: {
          events: {
            click: function (event) {
              if (drilldownCategory) {
                handleDrillUp();
              } else {
                handleMetricClick(event.point.drilldown);
              }
            },
          },
        },
      },
    },
    legend: {
      enabled: true,
      itemClick: function (event) {
        const category = event.currentTarget.textContent.split(" - ")[0];
        if (drilldownCategory !== category) {
          handleMetricClick(category);
        }
      },
    },
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [drilldownCategory, fetchData]);

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default PieDouble;
