import React from "react";
import Select from "react-select";
import { TableActions } from "../../../enums/enum";

const TableControl = (props) => {
  return (
    <div>
      <div className="d-flex flex-wrap">
        <div className="tableButtonBox">
          <button
            onClick={() => props.addRow(TableActions.ADD_ROW)}
            className="PreviousButton "
            style={{ background: "#56c0e0", color: "#ffff" }}
          >
            <i className="fa-solid fa-plus me-2"></i> Add Row
          </button>
        </div>
        <div className="tableButtonBox">
          <button
            className="PreviousButton "
            onClick={() =>
              props.deleteSelectedRows(TableActions.DELETE_SELECTED_ROWS)
            }
            style={{ background: "#f2ae43", color: "#ffff" }}
          >
            <i className="fa-solid fa-trash-can me-2"></i> Delete Rows
          </button>
        </div>
        <div className="tableButtonBox">
          <button
            className="PreviousButton "
            onClick={() =>
              props.deleteSelectedColumns(TableActions.DELETE_SELECTED_COLUMN)
            }
            style={{ background: "#f2ae43", color: "#ffff" }}
          >
            <i className="fa-solid fa-trash-can me-2"></i> Delete Column
          </button>
        </div>
        <div className="tableButtonBox">
          <button
            onClick={() => props.addColumn(TableActions.ADD_COLUMN)}
            className="PreviousButton"
            style={{ background: "#56c0e0", color: "#ffff" }}
          >
            <i className="fa-solid fa-plus me-2"></i> Add Column
          </button>
        </div>
        <div className="tableButtonBox">
          <Select
            isMulti
            value={props.selectedColumns.map((col) => ({
              label: col,
              value: col,
            }))}
            onChange={props.handleColumnChange}
            options={props.columnOptions}
            placeholder="Select columns to hide"
            className="md"
          />
        </div>
      </div>
    </div>
  );
};

export default TableControl;
