
import React, { useState, useRef } from "react";
import "./SuperAdmin.css";
import LayoutSuperAdmin from "../../../component/layout/LayoutSuperAdmin";
import { CreateUser } from "./CreateUser";
export default function SuperAdminComponent() {
  //upload a company logo
  const fileInputRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const handleLogoUpload = (event) => {
    const selectedFile = event.target.files[0];
    setLogo(URL.createObjectURL(selectedFile));
  };
  const handleProfileLogoClick = () => {
    fileInputRef.current.click();
  };
  //edit company name
  const [editing, setEditing] = useState(false);
  const [editingCountry, setEditingCountry] = useState(false);
  const [companyName, setCompanyName] = useState("VIVARN Pvt Limited");

  const [phoneNumber, setPhoneNumber] = useState("+917278023168");
  const [updatedPhoneNumber, setUpdatedPhoneNumber] = useState("");

  const [countryName, setCountryName] = useState("India");
  const [updatedCountryName, setUpdatedCountryName] = useState("");
  const handleEditClick = (value) => {
    if(value==='phone'){
      setUpdatedPhoneNumber(phoneNumber);
      setEditing(true)
    }else if(value==='country'){
      setUpdatedCountryName(countryName);
      setEditingCountry(true)
    }

  };

  const handleSaveClick = () => {
    setPhoneNumber(updatedPhoneNumber);
    setCountryName(updatedCountryName);
    setEditing(false);
    // Perform save operation or update the company name in your data structure or API
  };

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
  };

  // edit a phone number
  const handlePhoneNumberChange = (event) => {
    setUpdatedPhoneNumber(event.target.value);
  };
  //edit company name
  const handleCountryNameChange = (event) => {
    setUpdatedCountryName(event.target.value);
  };
  return (
    <LayoutSuperAdmin>
      <div className="AnalyticsContainer AnalyticsContainerSuperAdmin">
        <div className="container-fluid">
          <div className="row w-100 m-0">
            <div className="col-md-12 p-0">
              <div className="d-flex justify-content-between SuperAdminDiv">
                <h4 className="mb-0">SuperAdmin</h4>
                <div className="text-center">
                  <h6>Free Trial</h6>
                  <h6 className="mb-0">10 Days Left</h6>
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0">
              <div className="d-flex justify-content-between SuperAdminDiv">
                <h5 className="mb-0">
                  <span className="profile-icon">
                    <i className="fa-solid fa-circle-user"></i>
                  </span>
                  <span className="ms-2">undefined</span>
                </h5>
                <div className="text-center">
                  <CreateUser />
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0">
              <div className="d-flex justify-content-between SuperAdminDiv">
                <h6 className="mb-0">
                  <i className="fa-solid fa-envelope"></i>
                  <span className="ms-2">undefind@gmail.com</span>
                </h6>
              </div>
            </div>
            <div className="col-md-12 p-0">
              <div className="d-flex justify-content-between SuperAdminDiv">
                <h6 className="mb-0">
                  <i className="fa-solid fa-mobile"></i>
                  {editing ? (
                    <input
                      type="text"
                      value={updatedPhoneNumber}
                      onChange={handlePhoneNumberChange}
                      className="edit-phone-number"
                    />
                  ) : (
                    <span className="ms-2">{phoneNumber}</span>
                  )}
                </h6>
                <div className="text-center">
                  {editing ? (
                    <span className="save-icon" onClick={handleSaveClick}>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  ) : (
                    <span className="edit-icon" onClick={() => handleEditClick('phone')}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0">
              <div className="d-flex justify-content-between SuperAdminDiv">
                <h6 className="mb-0">
                  <i className="fa-solid fa-globe"></i>
                  {editingCountry ? (
                    <input
                      type="text"
                      value={updatedCountryName}
                      onChange={handleCountryNameChange}
                      className="edit-country-name"
                    />
                  ) : (
                    <span className="ms-2">{countryName}</span>
                  )}
                </h6>
                <div className="text-center">
                  {editing ? (
                    <span className="save-icon" onClick={handleSaveClick}>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  ) : (
                    <span className="edit-icon" onClick={() => handleEditClick('country')}>
                      <i className="fa-solid fa-pen-to-square"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12 p-0 mb-4">
              <div className="d-flex justify-content-between SuperAdminDiv">
                <div>
                  <div className="card-header">
                    <h4>My Account</h4>
                    <p className="AccountsOrganization-p">
                      All the accounts in this organization that you have access
                      to. Click to open.
                    </p>
                  </div>
                  <div className="profile-icon ProfileIconDiv">
                    <div
                      className="ProfileLogo"
                      onClick={handleProfileLogoClick}
                    >
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleLogoUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                      />
                      {logo ? (
                        <img src={logo} alt="logo" />
                      ) : (
                        <p>Upload organization logo</p>
                      )}
                    </div>
                    <div className="ms-3">
                      {editing ? (
                        <input
                          type="text"
                          value={companyName}
                          onChange={handleCompanyNameChange}
                          className="edit-company-name"
                        />
                      ) : (
                        <h4 className="company-name">{companyName}</h4>
                      )}
                      <p>Edit Organizational Information</p>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  {editing ? (
                    <span className="save-icon" onClick={handleSaveClick}>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  ) : (
                    <span className="edit-icon" onClick={handleEditClick}>
                      <i className="fa-solid fa-edit"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutSuperAdmin>
  );
}

