import React from "react";
import './SignUp.css';
import OwlCarousel from "../owlCarousel/OwlCarousel";

const SignUpNavbar = () => {
    return(
        <div className="SignUpNavbar">
            <div className="signUpUpperSection">
                <div>
                <h1>Start your remarkable journey with us!</h1>
                <p className="coldEmail">Our cold email automation helps you send personalized cold emails at scale with high email deliverability.</p>
                </div>
                <OwlCarousel/>
            </div>
        </div>
    )
}
export default SignUpNavbar;