import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./WorkSpace.css";
import CreateWorkSpace from "./CreateWorkSpace";
import CreateWorkspaceAPI from "../../../service/api/CreateWorkspaceApi";
import WorkspaceApi from "../../../service/api/WorkspaceLoginApi";
import { todayDate } from "../../../util/todayDate";
import { logo } from "../../../constants/pictures/Pictures";
import RemoveCookie from "../../../persistence/cookies/removeCookie/RemoveCookie";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import { orgId } from "../../../util/Utils";

const StepCreateWorkSpace = () => {
    const navigate = useNavigate();
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

    const step1Schema = Yup.object().shape({
        UserWorkSpaceName: Yup.string().required("Please enter workspace name"),
    });

    const formik = useFormik({
        initialValues: {
            UserWorkSpaceName: "",
        },
        validationSchema: step1Schema,
        onSubmit: async (values, { setSubmitting }) => {
            const createWorkspacePayload = {
                name: values.UserWorkSpaceName,
                createdDate: todayDate,
                organizationDTO: {
                    id: orgId,
                },
            };
            try {
                const createWorkspaceapi = await CreateWorkspaceAPI(JSON.stringify(createWorkspacePayload));
                if (createWorkspaceapi && createWorkspaceapi.response && createWorkspaceapi.response.workspaceDTOS) {
                    const workspaceDTOS = createWorkspaceapi.response.workspaceDTOS;
                    const recentWorkspace = workspaceDTOS[workspaceDTOS.length - 1]; // Get the most recently created workspace
                    const payloadworkspaceLogin = {
                        id: recentWorkspace.id,
                    };
                    const result = await WorkspaceApi(payloadworkspaceLogin);
                    if (result === true) {
                        navigate("/home");
                    } else {
                        const userAuthToken = GetCookie("token");
                        if (userAuthToken) {
                            navigate("/login");
                        } else {
                            window.location.href = `https://www.vivrn.com/login`;
                        }
                        RemoveCookie("token");
                        RemoveCookie("userDTO");
                        RemoveCookie("workSpaceDTO");
                    }
                }
            } catch (error) {
                console.error("Error creating workspace or logging in:", error);
            }
            setSubmitting(false);
        },
    });

    const validateStep = async () => {
        try {
            await formik.validateForm();
            setIsSubmitDisabled(false);
            return true;
        } catch {
            setIsSubmitDisabled(true);
            return false;
        }
    };

    const handleNext = async () => {
        const isValid = await validateStep();
        if (isValid) {
            formik.handleSubmit(); 
        }
    };

    useEffect(() => {
        validateStep();
    }, [formik.values]);

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 pt-5">
                    <img src={logo} width={217} height={71} alt="Logo" />
                    <div className="formConatinerStepCreate">
                        <form onSubmit={formik.handleSubmit}>
                            <CreateWorkSpace
                                formik={formik}
                                handleChange={formik.handleChange}
                                value={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                onNext={handleNext}
                            />
                            <div className="button-group">
                                <button type="button" onClick={handleNext} className="next-button-workspace">
                                    Next
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepCreateWorkSpace;
