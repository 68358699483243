import Swal from "sweetalert2";
import { BASE_URL } from "../endPoints/Endpoint";
import SetCookie from "../../persistence/cookies/setCookie/SetCookie";


const LoginApi = async (payload, endpoint) => {
 
  try {
    const response = await fetch(
      `${BASE_URL}/userAuth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: payload,
      }
    );

    const result = await response.json();
    console.log(result)
    if (result.httpStatusCode=='200') {
      const token = result.response;
      SetCookie('userDTO', JSON.stringify(result.response?.userDTO));
      // localStorage.setItem("token", token);

      const authToken = token?.authToken;
      SetCookie('token', authToken);
      // localStorage.setItem("token", jsonString);
      console.log("Login Successfully", result);
      Swal.fire({
        title: "Success!",
        text: "Welcome to Your Vivrn",
        icon: "success",
        showConfirmButton: false,
        timer: 1000, // Delay of 1 seconds (1000 milliseconds)
        allowOutsideClick: false,
      }).then(() => {
        // navigate("/home");
      });
      return result.response;
    } else {
      console.log("Login Failed", result);
      Swal.fire({
        html: "<h4>Please check Email and Password</h4>",
       icon: "error",
        confirmButtonText: "Continue",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "NextButton", // Replace with your class name for the confirm button
          },
      });
      return null;
    }
  } catch (error) {
    console.error(error, "Please Try Again");
    return null;
  }
};

export default LoginApi;
