import React from "react";

const StepFormForNewUser = ({ handleChange, values, errors, touched }) => {
  const jobOptions = [
    {
      label: "Developer",
      value: "Developer"
    },
    {
      label: "Analyst",
      value: "Analyst"
    },
    {
      label: "Data Engineer",
      value: "DataEngineer"
    },
    {
      label: "Data Analyst",
      value: "DataAnalyst"
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="signUpFormContainer">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p className="tellUsBit">Tell us a bit about you</p>
              <p className="helpUs inputField">That will help us better set up your account</p>
              <div className="row">
                <div className="col-sm-6">
                  <div className="inputField">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="customInput"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    {touched.firstName && errors.firstName && (
                      <div className="ErrorMessage">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="inputField">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="customInput"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    {touched.lastName && errors.lastName && (
                      <div className="ErrorMessage">
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputField">
                <label>Select your job role*</label>
                <select
                  id="userRoleInCompany"
                  className="form-select customInput"
                  value={values.userRoleInCompany}
                  name="userRoleInCompany"
                  onChange={handleChange}
                >
                  <option value="" disabled>Select</option>
                  {jobOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
                {touched.userRoleInCompany && errors.userRoleInCompany && (
                  <div className="ErrorMessage">
                    {errors.userRoleInCompany}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepFormForNewUser;
