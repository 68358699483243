export const acceptedFileTypes = {
    csv: '.csv',
    excel: '.xlsx, .xls',
    html: '.html',
    xml: '.xml'
};

export const dataTypeOptions = [
    { label: 'Text', value: 'TEXT' },
    { label: 'Date', value: 'DATE' },
    { label: 'Number', value: 'NUMBER' },
    { label: 'Decimal', value: 'DECIMAL' },
];
