import { RESTMethod } from "../../enums/enum";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const UplodedFileApi = async (formdata) => {
    try {
      const userAuthToken = GetCookie('token');
      const response = await fetch(
        getApiUrl() + apiUrls.uploadFiles.uploadFile, {
        method: RESTMethod.POST,
        headers: {
          "authToken": userAuthToken,
        },
        body:formdata
      });
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error("Request to restore report failed. Please try again.");
      }
    } catch (error) {
      console.error(error, "Please Try Again");
    }
};

  export default UplodedFileApi;