import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { useDispatch } from "react-redux";
import { focusModeClassData, resetfocusModeClassData } from "../../redux/reduxToolkit/Reducer";

exportingInit(Highcharts);
exportDataInit(Highcharts);

const TableChart = ({ responseData }) => {
    const chartRef = useRef(null);
    const tabledata = responseData.rawData;
    const [getFocus, setGetFocus] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        if (getFocus) {
            dispatch(focusModeClassData(responseData));
        }else{
            dispatch(resetfocusModeClassData());
        }
    }, [getFocus,responseData]);
    
    const toggleFocusMode = () => {
        setGetFocus(prev => !prev);
    };
    useEffect(() => {
        const chart = Highcharts.chart('container', {
            chart: {
                height: '100%',
            },
            title: {
                text: null,
            },
            subtitle: {
                text: null,
            },
            credits: {
                enabled: false
            },
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            "viewFullscreen",
                            "printChart",
                            "separator",
                            "downloadPNG",
                            "downloadJPEG",
                            "downloadPDF",
                            "downloadSVG",
                            "customItem" // Add a reference to the custom item
                        ],
                        menuItemDefinitions: {
                            customItem: {
                                text: getFocus ? 
                                '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                                '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                                onclick: toggleFocusMode,
                                useHTML: true // Enable HTML rendering for the menu item text
                            }
                        }
                    },
                    customButton: {
                        text: getFocus ? 
                        '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                        '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                        onclick: toggleFocusMode,
                        align: 'right',
                        verticalAlign: 'top',
                        x: -30,
                        y: 5,
                        useHTML: true // Enable HTML rendering for the button text
                    }
                }
            }
        });

        const renderTable = () => {
            const table = document.createElement('div');
            const fields = tabledata.fields.map(field => field.name);
            const records = tabledata.records;

            let tableHTML = '<table>';
            tableHTML += '<thead><tr>';
            fields.forEach(field => {
                tableHTML += `<th>${field}</th>`;
            });
            tableHTML += '</tr></thead><tbody>';
            records.forEach(record => {
                tableHTML += '<tr>';
                record.forEach(cell => {
                    tableHTML += `<td>${cell}</td>`;
                });
                tableHTML += '</tr>';
            });
            tableHTML += '</tbody></table>';
            table.innerHTML = tableHTML;

            const tableElement = table.querySelector('table');
            if (tableElement) {
                Highcharts.css(tableElement, {
                    'border-collapse': 'collapse',
                    'border-spacing': 0,
                    background: 'white',
                    'min-width': '100%',
                    'font-family': 'sans-serif',
                    'font-size': '14px'
                });

                [].forEach.call(tableElement.querySelectorAll('td, th, caption'), function (elem) {
                    Highcharts.css(elem, {
                        border: '1px solid silver',
                        padding: '0.5em'
                    });
                });

                const caption = tableElement.querySelector('caption');
                if (caption) {
                    Highcharts.css(caption, {
                        'border-bottom': 'none',
                        'font-size': '1.1em',
                        'font-weight': 'bold'
                    });
                }

                [].forEach.call(tableElement.querySelectorAll('caption, tr'), function (elem, i) {
                    if (i % 2) {
                        Highcharts.css(elem, {
                            background: '#f8f8f8'
                        });
                    }
                });
            }

            return table.innerHTML;
        };

        chart.update({
            subtitle: {
                text: renderTable(),
                useHTML: true
            }
        });

    }, [tabledata]);

    return (
        <div id="container" ref={chartRef}></div>
    );
};

export default TableChart;




// import React from "react";


// const TableData = ({responseData}) => {
//     const tabledata = responseData.rawData;
//     const columns = tabledata.fields.map(field => field.name);
//     const rows = tabledata.records;

//     return (
//         <table border="1">
//             <thead>
//                 <tr>
//                     {columns.map((column, index) => (
//                         <th key={index}>{column}</th>
//                     ))}
//                 </tr>
//             </thead>
//             <tbody>
//                 {rows.map((row, rowIndex) => (
//                     <tr key={rowIndex}>
//                         {row.map((cell, cellIndex) => (
//                             <td key={cellIndex}>{cell}</td>
//                         ))}
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
//     );
// };

// export default TableData;



