export const excludedKeys = [
  "id",
  "dataType",
  "organizationId",
  "referredName",
  "storageFilePath",
  "uuid",
  "organizationDTO",
  "publicAccess",
  "widgetDTOS",
  "layout",
  "deleted",
  "workspaceDTO",
  "uploadedDataTypeEnum",
  "uploadedBy"
  
];