export const enumChartTypes = {
  DONUT: "DONUT",
  LINE: "LINE",
  PIE: "PIE",
  TEXT: "TEXT",
  CLUSTERED_COLUMN: "CLUSTERED_COLUMN",
  STACKED_COLUMN: "STACKED_COLUMN",
  STACKED_COLUMN_100: "STACKED_COLUMN_100",
  AREA: "AREA",
  STACKED_BAR: "STACKED_BAR",
  STACKED_BAR_100: "STACKED_BAR_100",
  SCATTER: "SCATTER",
  TABLE: "TABLE",
};

export const enumFilterDataTypes = {
  CONTAINS: "CONTAINS",
  NOT_CONTAINS: "NOT_CONTAINS",
  STARTS_WITH: "STARTS_WITH",
  IS: "IS",
};

export const enumPreviewTable = {
  TABLE_REVIEW: "table-preview",
  COLUMN_PREVIEW: "column-preview",
  DATA_PREVIEW: "data-preview",
};

export const enumDataType = {
  NUMBER: "NUMBER",
  TEXT: "TEXT",
  DATE: "DATE",
  DATETIME: "DATETIME",
  TIME: "TIME",
  STRING: "STRING",
  DECIMAL: "DECIMAL",
  Long:"Long"
};

export const TableActions = {
  ADD_ROW: "ADD_ROW",
  DELETE_SELECTED_ROWS: "DELETE_SELECTED_ROWS",
  ADD_COLUMN: "ADD_COLUMN",
  DELETE_SELECTED_COLUMN: "DELETE_SELECTED_COLUMN",
};
export const RESTMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  OPTIONS: 'OPTIONS',
  HEAD: 'HEAD'
};