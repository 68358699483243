import React, { useState } from "react";
import ScatterDoubleMain from "./ScatterMain";
import ScatterDouble from "./ScatterDouble";

const ScatterDoubleToggle = () => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? <ScatterDoubleMain /> : <ScatterDouble />}
    </div>
  );
};

export default ScatterDoubleToggle;
