import React from "react";
import { useSelector } from "react-redux";

const TextGraph = ({ responseData, index }) => {
  const allKeys = Array.from(new Set(responseData.data?.flatMap((e) => Object.keys(e.metric))));

  return (
    <>
      {responseData?.groupByMapDTOS?.length === 0 ? (
        <div className="col-md-6">
          <p>Text graph</p>
          <div className="GraphContainer">
            {responseData.data?.map((dataPoint, index) => (
              <div key={index}>
                {allKeys?.map((key) => (
                  <p key={`${index}-${key}`}>
                    {key}: {dataPoint.metric[key]}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default TextGraph;
