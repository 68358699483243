import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { useDispatch } from 'react-redux';
import { focusModeClassData, resetfocusModeClassData } from '../../../redux/reduxToolkit/Reducer';

// Initialize exporting modules
exportingInit(Highcharts);
exportDataInit(Highcharts);

export default function AreaChartSingle(props) {
    const chartRef = useRef(null);
    const [fetchData, setFetchData] = useState([]);
    const [getFocus, setGetFocus] = useState(false);
    console.log(getFocus,"getFocus")
    console.log(props, "responseData pie");
    console.log(fetchData, "fetch data pie")
    useEffect(() => {
        setFetchData(props?.responseData?.data);
    }, [props?.responseData]);
    const dispatch = useDispatch();
    useEffect(() => {
        if (getFocus) {
            dispatch(focusModeClassData(props.responseData));
        }else{
            dispatch(resetfocusModeClassData());
        }
    }, [getFocus, props.responseData]);
    
    const toggleFocusMode = () => {
        setGetFocus(prev => !prev);
    };
    const chartOptions = {
        chart: {
            type: 'area', // Change the chart type to 'area'
            height: '100%',
        },
        credits: {
            enabled: false
          },
          title: {
            text: null,
          },
        xAxis: {
            categories: fetchData.map(item => item.sgb),
        },
        yAxis: {
            title: {
                text: 'Values',
            },
        },
        series: fetchData.map((item, index) => ({
            name: item.sgb,
            data: Object.values(item.metric),
            color: Highcharts.getOptions().colors[index % 10],
            id: item.sgb, // add id for each series
        })),
        tooltip: {
            formatter: function () {
                const metricKeys = Object.keys(fetchData[0].metric);
                const metricIndex = this.series.data.indexOf(this.point);
                const metricKey = metricKeys[metricIndex];
                const metricValue = this.y;
                const metricName = this.series.name;

                return `
                    <b>${metricName}</b><br>
                    ${metricKey}: ${metricValue}
                `;
            },
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: [
                        "viewFullscreen",
                        "printChart",
                        "separator",
                        "downloadPNG",
                        "downloadJPEG",
                        "downloadPDF",
                        "downloadSVG",
                        "customItem" // Add a reference to the custom item
                    ],
                    menuItemDefinitions: {
                        customItem: {
                            text: getFocus ?
                                '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>' :
                                '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                            onclick: toggleFocusMode,
                            useHTML: true // Enable HTML rendering for the menu item text
                        }
                    }
                },
                customButton: {
                    text: getFocus ?
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>' :
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                    onclick: toggleFocusMode,
                    align: 'right',
                    verticalAlign: 'top',
                    x: -30,
                    y: 5,
                    useHTML: true,
                    id: 'customButtonId',
                    className: `${props.responseData.uuid}`, 
                  }
            }
        },
        
 
    };
    useEffect(() => {
        if (chartRef.current) {
            chartRef.current.chart.redraw();
        }
    }, [fetchData]);

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            ref={chartRef}
        />
    );
}
