/* eslint-disable react/prop-types */
import React from "react";
import './ApplicationButton.css';
import ButtonLoader from "../loader/ButtonLoader";

// Common button
const ApplicationButton = ({
    btnText,
    className = {},
    disabled,
    isLoading,
    type,
    onClick = () => { },
    icon
}) => {
    return (
        <button onClick={onClick} className={className} disabled={disabled} type={type}>
            {isLoading ? <ButtonLoader /> : null} {icon ? <img src={icon} className="img-fluid me-1" /> : null}
            {btnText}
        </button>
    );
};

export default ApplicationButton;