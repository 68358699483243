import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { focusModeClassData, resetfocusModeClassData } from "../../../redux/reduxToolkit/Reducer";
import { useDispatch } from "react-redux";
exportingInit(Highcharts);
exportDataInit(Highcharts);

export default function DonutChartSingle(props) {

  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState([]);
  console.log(props, "responseData pie");
  console.log(fetchData, "fetch data pie");
  useEffect(() => {
    setFetchData(props?.responseData?.data);
  }, [props?.responseData]);
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus, props.responseData]);
  
  const toggleFocusMode = () => {
      setGetFocus(prev => !prev);
  };
  const chartOptions = {
    chart: {
      type: "pie",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format:
            "<b>{point.name}</b>: {point.percentage:.2f}% ({point.value})",
        },
        pointPadding: 0,
        groupPadding: 0.1,
        innerSize: "60%", 
      },
    },
    series: [
      {
        name: "Metric Values",
        colorByPoint: true,
        data: fetchData.reduce((acc, item, index) => {
          const sgb = item.sgb;
          const total = Object.values(item.metric).reduce(
            (sum, value) => sum + value,
            0
          );
          Object.entries(item.metric).forEach(([metricKey, metricValue]) => {
            acc.push({
              name: `${sgb} - ${metricKey}`,
              y: (metricValue / total) * 100,
              color: Highcharts.getOptions().colors[index % 10],
              value: metricValue,
            });
          });
          return acc;
        }, []),
      },
    ],
    tooltip: {
      formatter: function () {
        return `
                    <b>${this.point.name}</b>: ${this.point.percentage.toFixed(
          2
        )}%<br>
                    Exact Value: ${this.point.value}
                `;
      },
    },
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [fetchData]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartRef}
    />
  );
}
