import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { focusModeClassData, resetfocusModeClassData } from "../../../redux/reduxToolkit/Reducer";
import { useDispatch } from "react-redux";
exportingInit(Highcharts);
exportDataInit(Highcharts);


export default function ScatterChartSingle(props) {

  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(props?.responseData?.data);
  useEffect(() => {
    setFetchData(props?.responseData?.data);
  }, [props?.responseData]);
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus, props.responseData]);
  
  const toggleFocusMode = () => {
      setGetFocus(prev => !prev);
  };
  const chartOptions = {
    chart: {
      type: "scatter",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: fetchData.map((item) => item.sgb),
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    series: fetchData.map((item, index) => ({
      name: item.sgb,
      data: Object.entries(item.metric).map(([key, value]) => ({
        x: index,
        y: value,
        name: key,
      })),
      color: Highcharts.getOptions().colors[index % 10],
      id: item.sgb,
    })),
    tooltip: {
      formatter: function () {
        return `
                    <b>${this.point.name}</b><br>
                    Value: ${this.point.y}
                `;
      },
    },
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [fetchData]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      ref={chartRef}
    />
  );
}
