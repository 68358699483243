const decodeJWT = (token) => {
  const [header, payload, signature] = token ? token.split('.') : [];

  const base64Decode = (str) => {
    if (!str) return null;
    const base64 = str.replace(/_/g, '/').replace(/-/g, '+');
    const padding = '='.repeat((4 - base64.length % 4) % 4);
    try {
      const decoded = atob(base64 + padding);
      return JSON.parse(decoded);
    } catch (e) {
      console.error('Error decoding base64 or parsing JSON:', e);
      return null;
    }
  };

  const decodedHeader = base64Decode(header);
  const decodedPayload = base64Decode(payload);

  return {
    header: decodedHeader,
    payload: decodedPayload,
    signature: signature || null
  };
};

export default decodeJWT;
