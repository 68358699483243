import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import {
  LogoLogin,
  reportPerksLogin,
} from "../../../constants/pictures/Pictures";
import { useFormik } from "formik";
import * as yup from "yup";
import "./Login.css";
import LoginApi from "../../../service/api/LoginApi";
import { LOGIN_ENDPOINT } from "../../../service/endPoints/Endpoint";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import GoogleProtected from "../googleLogin/GoogleProtected";
import GoogleLoginApi from "../../../service/api/GoogleLoginApi";
import RemoveCookie from "../../../persistence/cookies/removeCookie/RemoveCookie";
import { googleLogout } from '@react-oauth/google';
import TextInputWithLabel from "../../../component/applicationInput/ApplicationInput";
import { icons } from "../../../constants/icons/Icons";
import ApplicationButton from "../../../component/applicationButton/ApplicationButton";
import OwlCarousel from "../../register/owlCarousel/OwlCarousel";
import { useDispatch } from "react-redux";
import { workspaceDTOData } from "../../../redux/reduxToolkit/Reducer";

var header = {
  alg: "HS256",
  typ: "JWT",
};

var secret = "My very confidential secret!!!";

function base64url(source) {
  var encodedSource = CryptoJS.enc.Base64?.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, "");
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");
  return encodedSource;
}

export default function LogIn() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  useEffect(() => {
    const parsedPayload = GetCookie('loginResponsecode');
    const organizationUrl = parsedPayload?.organizationUrl;
    let subdomain = null;
    if (parsedPayload && organizationUrl) {
      const parts = organizationUrl.split('.');
      if (parts.length >= 3) {
        subdomain = parts[0];
        parts.splice(0, 1);
      }
    }
    let newURL;
    const tokenlogin = GetCookie('token');
    if (tokenlogin) {
      if (window.location.hostname == "localhost") {
        navigate("/home");
      } else {
        newURL = `https://${subdomain}.vivrn.com/home`;
        window.location.href = newURL;
      }
    }
  }, []);
  useEffect(() => {
    const tokenlogin = GetCookie('token');
    if (window.location.hostname !== "localhost") {
      if (!tokenlogin) {
        // Check if the current domain is not www.abc.com
        if (window.location.hostname !== 'www.vivrn.com') {
          // Construct the new URL with the original subdomain as a query parameter
          const redirectUrl = `https://www.vivrn.com/login?redirect_to=${window.location.hostname}`;
          // Redirect to www.abc.com with the original subdomain as a query parameter
          window.location.href = redirectUrl;
        }
      }
    }
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const handleRememberMe = (event) => {
    setRememberMe(event.target.checked);
  };
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Please enter a valid email")
        .required("Please enter your email address")
        .matches(regex, "Enter a valid email"),
      password: yup
        .string()
        .min(7, "Password must be at least 7 characters")
        .required("Please enter your password"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (rememberMe) {
        SetCookie('email', values.email);
        SetCookie('password', values.password);
      } else {
        RemoveCookie('email');
        RemoveCookie('password');
      }
      // Prevent the default form submission behavior
      var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
      var encodedHeader = base64url(stringifiedHeader);

      const loginObj = {
        emailId: values.email,
        password: values.password,
        language: "en",
        country: "In",
      };

      var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(loginObj));
      var encodedData = base64url(stringifiedData);

      var signature = encodedHeader + "." + encodedData;
      signature = CryptoJS.HmacSHA256(signature, secret);
      signature = base64url(signature);

      const emailPasswordJWT =
        encodedHeader + "." + encodedData + "." + signature;
      console.log(emailPasswordJWT);
      var AuthToken = {
        authToken: emailPasswordJWT,
      };
      const AuthTokenStringyfyPayload = JSON.stringify(AuthToken);

      const result = await LoginApi(AuthTokenStringyfyPayload, LOGIN_ENDPOINT);
      if (result !=null) {
        resetForm();
        const token = GetCookie('token');
        console.log(token, "token")
        const [encodedHeader1, encodedPayload, signature1] = token.split(".");
        const decodedHeader = CryptoJS.enc.Base64.parse(
          encodedHeader1
        ).toString(CryptoJS.enc.Utf8);
        const decodedPayload = CryptoJS.enc.Base64.parse(
          encodedPayload
        ).toString(CryptoJS.enc.Utf8);
        const parsedHeader = JSON.parse(decodedHeader);
        const parsedPayload = JSON.parse(decodedPayload);
        SetCookie('loginResponsecode', JSON.stringify(parsedPayload));
        const organizationUrl = parsedPayload.organizationUrl;
        let subdomain = null;
        if (parsedPayload && organizationUrl) {
          const parts = organizationUrl.split('.');
          if (parts.length >= 3) {
            subdomain = parts[0];
            parts.splice(0, 1);
          }
        }
        let newURL;
        if (window.location.hostname == "localhost") {
          navigate("/workSpace");
       
        } else {
          newURL = `https://${subdomain}.vivrn.com/workSpace`;
          window.location.href = newURL;
        }

      } else {
        // Logout failed
      }
    },
  });
  useEffect(() => {
    const savedEmail = GetCookie('email');
    const savedPassword = GetCookie('password');
    if (savedEmail && savedPassword) {
      formik.setFieldValue("email", savedEmail);
      formik.setFieldValue("password", savedPassword);
      setRememberMe(true);
    }
  }, []);
  // googleLogout();
  const [googleCredential, setGoogleCredential] = useState(null);

  const handleLoginSuccess = (credentialResponse) => {
    console.log('Login successful in main parent:', credentialResponse);
    setGoogleCredential(credentialResponse);
  };
  console.log(googleCredential, "googleCredentialMain")
  const fetchDataGoogle = async () => {
    const payloadGoogle = {
      "googleAuthToken": googleCredential?.credential
    }
    console.log(payloadGoogle, "payloadGoogle");
    console.log(googleCredential, "googleCredentialChild")
    try {
      const AuthTokenGoogleStringifyPayload = JSON.stringify(payloadGoogle);
      console.log(AuthTokenGoogleStringifyPayload, "chcek");
      const result = await GoogleLoginApi(AuthTokenGoogleStringifyPayload, LOGIN_ENDPOINT);

      if (result === true) {
        const token = GetCookie('token');
        console.log(token, "token")

        const [encodedHeader1, encodedPayload, signature1] = token.split(".");

        const decodedHeader = CryptoJS.enc.Base64.parse(encodedHeader1).toString(CryptoJS.enc.Utf8);
        const decodedPayload = CryptoJS.enc.Base64.parse(encodedPayload).toString(CryptoJS.enc.Utf8);

        const parsedHeader = JSON.parse(decodedHeader);
        SetCookie('orgId', parsedHeader);
        const parsedPayload = JSON.parse(decodedPayload);
        SetCookie('loginResponsecode', JSON.stringify(parsedPayload));

        const organizationUrl = parsedPayload.organizationUrl;
        let subdomain = null;

        if (parsedPayload && organizationUrl) {
          const parts = organizationUrl.split('.');
          if (parts.length >= 3) {
            subdomain = parts[0];
            parts.splice(0, 1);
          }
        }

        let newURL;
        if (window.location.hostname === "localhost") {
          navigate("/workSpace");
        } else {
          newURL = `https://${subdomain}.vivrn.com/workSpace`;
          window.location.href = newURL;
        }
        setGoogleCredential(null)
      } else {
        setGoogleCredential(null)
        // Logout failed
      }
    } catch (error) {
      console.error('Error:', error);
      setGoogleCredential(null)
      // Handle error
    }
  };

  useEffect(() => {
    if (googleCredential) {
      fetchDataGoogle();
    }
  }, [googleCredential]);


  return (
    <div className="logIn">
      <div className="container-fluid ps-0">
        <div className="row">
          <div className="col-sm-6">
            <div className="leftLogIn">
              <div className="logoBox">
                <img src={LogoLogin} alt="logo" className="img-fluid" />
              </div>
              <h3 className="LoginText">Sign in</h3>
              <div className="googleBox">
                <GoogleProtected onLoginParentSucess={handleLoginSuccess} />
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="FormLogIn">
                  <div className="emailAdress">
                    <TextInputWithLabel
                      placeholder="Username or email address"
                      label="Enter your username or email address"
                      name="email"
                      leftIcon={icons.username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      error={
                        formik.touched.email && formik.errors.email ? formik.errors.email : null
                      }
                    />
                  </div>
                  <div className="password">
                    <TextInputWithLabel
                      placeholder="Password"
                      label="Enter your Password"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      leftIcon={icons.passwordInput}
                      rightIcon={showPassword ? icons.eyeClosed : icons.eyeOpen}
                      onPressRight={() => setShowPassword(!showPassword)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      error={
                        formik.touched.password && formik.errors.password ? formik.errors.password : null
                      }
                    />
                  </div>
                </div>
                <div className="form-checkLog">
                  <div className="d-flex justify-content-between">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMe}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckChecked"
                      >
                        Remember
                      </label>
                    </div>
                    <div>
                      <Link
                        to="/forgotpassword"
                        className="anchor-line-remove"
                      >
                        <p>Forgot Password?</p>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="btnSubmit">
                  <ApplicationButton className="blueheight50" type="submit" btnText="Sign in" disabled={formik.isSubmitting} isLoading={formik.isSubmitting} />
                </div>
              </form>
              <div className="signUpBox">
                <p className="sign-up-text">Don’t have an account?
                  <a className="anchor-sign-up" href="/Sign-up-today">
                    Sign up
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="RightLogIn">
              <div className="carouselConatiner">
                <OwlCarousel />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="signUpAccount">

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
