import React, { useEffect, useState } from "react";
import './TableMetaDataList.css';

const TableMetaDataList = ({ responseData }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [tableListData, setTableListData] = useState([]);
  const [showDownloadButton, setShowDownloadButton] = useState(false);

  const toggleEditor = () => {
    setIsCollapsed(!isCollapsed);
    if (isCollapsed) {
      setShowDownloadButton(true);
    } else {
      setShowDownloadButton(false);
    }
  };

  const downloadTable = () => {
    const csvData = tableListData.map(row => headers.map(header => {
      if (header === "metric") {
        return row[header]["SUM(MARKS)"];
      }
      return row[header];
    }).join(",")).join("\n");
    const csvHeaders = headers.join(",") + "\n";
    const csvContent = "data:text/csv;charset=utf-8," + csvHeaders + csvData;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    console.log(responseData.data, "props.data ");
    if (responseData.data && Array.isArray(responseData.data)) {
      setTableListData(responseData.data);
    }
  }, [responseData.data]);

  // Extract headers dynamically from the data
  const headers = tableListData.length > 0 ? Object.keys(tableListData[0]) : [];

  return (
    <>
      <div className="row button-row">
        <div className="col-md-6">
          <button onClick={toggleEditor} className="btn toggle-button">
            {isCollapsed ? (
              <span>
                Show me the table <i className="fa-solid fa-angle-up"></i>
              </span>
            ) : (
              <span>
                Hide the table <i className="fa-solid fa-angle-down"></i>
              </span>
            )}
          </button>
        </div>
        <div className="col-md-6 text-right">
          {showDownloadButton && (
            <button onClick={downloadTable} className="btn download-button">
              <i className="fas fa-download"></i> Download table
            </button>
          )}
        </div>
      </div>
      {!isCollapsed && tableListData.length > 0 && (
        <div className="row">
          <div className="col-md-12">
            <div className="table-container">
              <table className="table table-bordered">
                <thead className="thead-dark-d">
                  <tr>
                    <th className="table-listHeadercaps"></th>
                    {headers.map((header) => (
                      <th key={header} className="table-listHeadercaps">{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {tableListData.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{rowIndex + 1}</td>
                      {headers.map((header) => (
                        <td key={header}>
                          {header === "metric" ? row[header]["SUM(MARKS)"] : row[header]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TableMetaDataList;
