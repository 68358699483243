import React, { useState } from "react";
const CompanyDetailsForm = ({ touched, handleChange, value, errors }) => {
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="signUpFormContainer">
            <div className="row justify-content-center sizeCompany">
              <div className="col-md-8">
                <p className="tellUsBit">
                  What is the size of your company?
                </p>
                <p className="helpUs inputField">
                  we can personilized suggest better place according to company's size
                </p>

                <div className="row">
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size1to10" name="companySize" value="1 to 10" onChange={handleChange}
                      checked={value.companySize === '1 to 10'} />
                    <label htmlFor="size1to10">1 to 10</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size10to25" name="companySize" value="10 to 25" onChange={handleChange}
                      checked={value.companySize === '10 to 25'} />
                    <label htmlFor="size10to25">10 to 25</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size25to50" name="companySize" value="25 to 50" onChange={handleChange}
                      checked={value.companySize === '25 to 50'} />
                    <label htmlFor="size25to50">25 to 50</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size51to100" name="companySize" value="51 to 100" onChange={handleChange}
                      checked={value.companySize === '51 to 100'} />
                    <label htmlFor="size51to100">51 to 100</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size101to200" name="companySize" value="101 to 200" onChange={handleChange}
                      checked={value.companySize === '101 to 200'} />
                    <label htmlFor="size101to200">101 to 200</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size201to500" name="companySize" value="201 to 500" onChange={handleChange}
                      checked={value.companySize === '201 to 500'} />
                    <label htmlFor="size201to500">201 to 500</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size501to1000" name="companySize" value="501 to 1000" onChange={handleChange}
                      checked={value.companySize === '501 to 1000'} />
                    <label htmlFor="size501to1000">501 to 1000</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size1001to10000" name="companySize" value="1001 to 10,000" onChange={handleChange}
                      checked={value.companySize === '1001 to 10,000'} />
                    <label htmlFor="size1001to10000">1001 to 10,000</label>
                  </div>
                  <div className="col-md-4 mt-3">
                    <input type="radio" id="size10000andmore" name="companySize" value="10,000 to more" onChange={handleChange}
                      checked={value.companySize === '10,000 to more'} />
                    <label htmlFor="size10000andmore">10,000 and more</label>
                  </div>
                </div>
                {touched.companySize && errors.companySize && (
                  <div className="ErrorMessage">
                    {errors.companySize}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsForm;
