// StepOneForm.js

import React from "react";
import { useFormik } from 'formik';

const StepOneForm = ({ handleChange, value, errors, touched }) => {
  const jobOptions = [{
    label: "Developer",
    value: "Developer"
  },
  {
    label: "Analyst",
    value: "Analyst"
  },
  {
    label: "Data Engineer",
    value: "DataEngineer"
  },
  {
    label: "Data Analyst",
    value: "DataAnalyst"
  },
  ];
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="signUpFormContainer">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p className="tellUsBit">Tell us a bit about you</p>
              <p className="helpUs inputField">That will help us better account set up for up</p>
              <div className="row">
                <div className="col-sm-6">
                  <div className="inputField">
                    <label>First Name</label>
                    <input
                      type="text"
                      className="customInput"
                      name="firstName"
                      onChange={handleChange}
                      value={value.firstName} // Ensure value is always defined
                    />
                    {touched.firstName && errors.firstName && (
                      <div className="ErrorMessage">
                        {errors.firstName}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="inputField">
                    <label>Last Name</label>
                    <input
                      type="text"
                      name="lastName"
                      className="customInput"
                      onChange={handleChange}
                      value={value.lastName} // Ensure value is always defined
                    />
                    {touched.lastName && errors.lastName && (
                      <div className="ErrorMessage">
                        {errors.lastName}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="inputField">
                <label>Business Name</label>
                <input
                  type="text"
                  name="businessName"
                  className="customInput"
                  onChange={handleChange}
                  value={value.businessName}
                />
                {touched.businessName &&
                  errors.businessName && (
                    <div className="ErrorMessage">
                      {errors.businessName}
                    </div>
                  )}
              </div>
              <div className="inputField">
                <label>Select your job role*</label>
                <select
                  id="jobOption"
                  className="form-select customInput"
                  value={value.jobOption}
                  name="jobOption"
                  onChange={handleChange}
                >
                  <option value="" disabled>Select</option>
                  {jobOptions.map((option) => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}

                </select>
                {touched.jobOption &&
                  errors.jobOption && (
                    <div className="ErrorMessage">
                      {errors.jobOption}
                    </div>
                  )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default StepOneForm;

