// api.js
import { API_LIMIT, OFFSET } from "../../dtos/TrashType";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import getWorkspaceId from "../../util/AttributeUtil";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const GetDataSetForMyContentApi= async (offset) => {
  try {
    const userAuthToken = GetCookie('token');
    const workspaceId = await getWorkspaceId();
    const response = await fetch(
        getApiUrl()+apiUrls.dataSet.getDataSetForMyContent(workspaceId,API_LIMIT,offset),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "authToken": userAuthToken,
        },
      }
    );

    const data = await response.json();

    if (data.response === null) {
      return []; 
    } else {
      return data;
    }
  } catch (error) {
    console.error("Failed to fetch uploaded files:", error);
    throw error;
  }
};


export default GetDataSetForMyContentApi;