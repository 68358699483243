// RefreshButton.js
import React from "react";
import Swal from "sweetalert2";
import TooltipAnalytics from "../../../../component/toolTip/ToolTip";

const RefreshButton = ({ onClick }) => {
  return (
    <div className="position-relative">
      <TooltipAnalytics text="Refresh" className="BottomTextTooltip">
        <button className="btn btn-outline-secondary me-2" onClick={onClick}>
          <i className="fa-solid fa-arrows-rotate"></i>
        </button>
      </TooltipAnalytics>
    </div>
  );
};

export default RefreshButton;
