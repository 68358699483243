import React from "react";
import "./Verification.css";
import { Link } from "react-router-dom";

export default function Verification() {
  return (
    <div className="logIn">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12">
            <div className="ForgotPasswordContainer">
              <div className="LogoLogin">
                <div className="ForgotPass">
                  <div className="arrow-corner">
                    <Link to="/forgotpassword" className="anchor-line-remove">
                      <i className="fa-solid fa-chevron-left"></i>
                    </Link>
                  </div>
                  <div>
                    <h1 className="advertise">Verification</h1>
                  </div>
                </div>
                <form id="user-form">
                  <div className="FormLogIn">
                    <div className="emailAdressForgot">
                      <div className="UserIcon">
                        <i className="fa-solid fa-lock"></i>
                      </div>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter verification code"
                      />
                    </div>
                    <div className="BackSignIn">
                      If you didn’t receive code?
                      <Link to="/" className="anchor-line-remove ms-2">
                        <span>Resend</span>
                      </Link>
                    </div>
                  </div>
                  <div className="btnSubmit">
                    <button className="submitBtn" type="submit">
                      Verify
                    </button>
                    <div className="AlreadyAccount">
                      <span>
                        Do you have an account?
                        <Link
                          to="/register"
                          className="anchor-line-remove ms-2"
                        >
                          Sign Up
                        </Link>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
