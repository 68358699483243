import React, { useState } from "react";
import "./ReportSchedule.css";
import ScheduleReportModal from "../../component/modal/ScheduleReportModal";
import { sheduleIon } from "../../constants/pictures/Pictures";

export default function ReportSchedule({ reportData }) {
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  if (!reportData) {
    return "Data not found";
  }

  return (
    <div>
      <button onClick={openModal} style={{"border":"none"}}>
        <img src={sheduleIon} alt="shedule-icon" className="img-fluid"/>
      </button>
      {modalOpen && (
        <ScheduleReportModal 
          reportDataResponse={reportData} 
          modalOpen={modalOpen} 
          closeModal={closeModal} 
        />
      )}
    </div>
  );
}
