import React, { useState, useEffect, useRef } from "react";
import "./SidebarContent1.css";
import { useDispatch } from "react-redux";
import { checkedData, checkedKeysData, clearChartData, clearCheckedData, draggedData, fetchLogicalAttributesData, resetReportByIdApiResponseData, resetsetData } from "../../../redux/reduxToolkit/Reducer";
import { useSelector } from "react-redux";
import { FilterDataTypeDate, FilterDataTypeText, FilterDataTypeTextDate, FilterDataTypeTextLong, FilterDataTypeTextString, optionsDateDropdown, optionsSeconDropdown } from "../../../dtos/create-report.type";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import JoinButton from "../dataPreview/JoinButton";
import ColumnPreview from "../dataPreview/ColumnPreview";
import DataSetDropdown from "../dataPreview/DataSetDropDown";
import SearchColPreview from "../dataPreview/SearchColPreview";
import fetchLogicalResources from "../../../service/api/FetchLogicalResourcesApi";
import fetchLogicalAttributes from "../../../service/api/FetchLogicalAtrributeApi";
import RemoveCookie from "../../../persistence/cookies/removeCookie/RemoveCookie";
import ImportData from "../../../component/modal/ImportData";
import ColumnDropdown from "../dataPreview/ColumnDropdown";
import ActualValueDropDown from "../dataPreview/ActualValueDropdown";
import TextLongDropdown from "../dataPreview/TextLongDropdown";
import FilterDropdown from "../dataPreview/FilterDropdown";
import FilterStringDropdown from "../dataPreview/FilterStringDropdown";



export default function SidebarContent1({
  onSetCheckedKeys,
  onSetOperation,
  onSetOperationString,
  onSetUserValue,
  onSetOperatorValue,
}) {
  const dispatch = useDispatch();
  const [selectedTable, setSelectedTable] = useState("");
  const [logicalResourceData, setLogicalResourceData] = useState([]);
  const userAuthToken = GetCookie("token");
  const [logicalAttributes, setLogicalAttributes] = useState([]);
  const [selectedTables, setSelectedTables] = useState(null);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [filterInput, setFilterInput] = useState("");
  const [filterInputLong, setFilterInputLong] = useState("");
  const [filterInputDate, setFilterInputDate] = useState("");
  const [checkedValues, setCheckedValues] = useState([]);
  const [secondDropdownValue, setSecondDropdownValue] = useState("");
  const [dateDropdownValue, setDateDropdownValue] = useState("");
  const [operatorValue, setOperatorValue] = useState("");
  const [secondDropDownOpen, setSecondDropDownOpen] = useState(false);
  const [dateDropDownOpen, setDateDropDownOpen] = useState(false);
  const uniqueFilterDataTypeText = [...new Set(FilterDataTypeText)];
  const uniqueFilterDataTypeTextLong = [...new Set(FilterDataTypeTextLong)];
  const uniqueFilterDataTypeTextDate = [...new Set(FilterDataTypeDate)];
  const [selectedFilterTexts, setSelectedFilterTexts] = useState({});
  const [selectedFilterTextsLong, setSelectedFilterTextsLong] = useState({});
  const [selectedFilterTextsDate, setSelectedFilterTextsDate] = useState({});
  const [selectedLabel, setSelectedLabel] = useState({
    label: "",
    logicalDataType: "",
  });

  const [openAccordionIndexes, setOpenAccordionIndexes] = useState(
    Array.from({ length: checkedKeys?.length || 500 }, (_, index) => index)
  );
  const [hasFilterBeenAddedString, setFilterAddedString] = useState({});
  const [isButtonVisibleString, setButtonVisibleString] = useState({});
  const [hasFilterBeenAddedLong, setFilterAddedLong] = useState(false);
  const [hasFilterBeenAddedDate, setFilterAddedDate] = useState(false);
  const [isButtonVisibleLong, setButtonVisibleLong] = useState(true);
  const [isButtonVisibleDate, setButtonVisibleDate] = useState(true);
  const [cardInputValues, setCardInputValues] = useState({});
  const [cardItems, setCardItems] = useState({});
  const [userInputValue, setUserInputValue] = useState("");
  // Define the additional values you want to add for each item
  const additionalData = [
    {
      value: userInputValue,
      operation: selectedFilterTextsLong?.marks,
      operator: "ALL",
    },
  ];
  const removeCheckeditem = useSelector((state) => state.removeCheckeditem)

  useEffect(() => {
    if (removeCheckeditem) {
      const syntheticEvent = {
        target: {
          checked: false,
        },
      };
      handleCheckboxChange(
        syntheticEvent,
        removeCheckeditem.label,
        removeCheckeditem.logicalDataType,
        removeCheckeditem.id
      );
    }
  }, [removeCheckeditem]);
  const mergedFilterOperator = {
    ...selectedFilterTexts,
    ...secondDropdownValue,
  };
  const checkedkey = useSelector((state) => state.checkedkey);
  useEffect(() => {
    setCheckedKeys(checkedkey);
  }, [checkedkey]);

  //redux for checkedkeys
  dispatch(checkedData(checkedKeys));
  //string operator show
  const [selectedTextString, setSelectedTextSrtring] = useState({});
  const widgetIdClickedData = useSelector((state) => state.WidgetIdClicked);
  const [previousLabel, setPreviousLabel] = useState(null);
  const filteredKeysRef = useRef([...checkedKeys]);
  const selectedtablename = useSelector((state) => state.databaseSelectedTable);
  const newUploadTableNameStringfy = GetCookie("newUploadTableData");
  const newUploadTableName = JSON.parse(newUploadTableNameStringfy)

  console.log(newUploadTableName, "newUploadTableName");
  // useEffect(() => {
  //   if (newUploadTableName) {
  //     setSelectedTable(newUploadTableName?.label)
  //   }
  // }, [newUploadTableName])

  //this was calling the api of logical attributes whenever we upload the files
  const reportByIdApiRespone = useSelector((state) => state.reportByIdApiResponse);
  console.log(reportByIdApiRespone, "reportByIdApiResponeee");
  useEffect(() => {
    const fetchData = async () => {

      if (reportByIdApiRespone) {
        const updatedKeys = {
          label: reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.label,
          logicalDataType: reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.logicalDataType,
        };

        if (updatedKeys) {
          const currentLabel = reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.label;
          const selectedUserId = reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.id;

          const fetchAttributesForUser = async (userId) => {
            try {
              const attributesData = await fetchLogicalAttributes(userId);
              console.log(attributesData + "attributesData");

              setLogicalAttributes(attributesData);
              setSelectedTables(
                reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.label
                  ? reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.label
                  : selectedtablename.selectedTableName
              );
              setSelectedTable(
                reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.label
                  ? reportByIdApiRespone.widgetDTOS[0]?.logicalResourceDTOS[0]?.label
                  : selectedtablename.selectedTableName
              );
              if (attributesData && attributesData.length > 0) {
                // localStorage.removeItem('reportByIdApiRespone')
                dispatch(resetReportByIdApiResponseData());
              }
            } catch (error) {
              console.error(`Error fetching attributes for user ${userId}:`, error);
            }
          };

          if (selectedUserId) {
            fetchAttributesForUser(selectedUserId);
          }
        }
      }
    };
    if (reportByIdApiRespone) {
      fetchData();
    }
  }, [reportByIdApiRespone]);
  useEffect(() => {
    const fetchAttributesForUser = async (userId) => {
      try {
        const attributesData = await fetchLogicalAttributes(userId);
        console.log(attributesData, "attributesData");

        setLogicalAttributes(attributesData);
        setSelectedTables(newUploadTableName?.label);
        setSelectedTable(newUploadTableName?.label);

        if (attributesData && attributesData.length > 0) {
          RemoveCookie("newUploadTableData");
        }
      } catch (error) {
        console.error(`Error fetching attributes for user ${userId}:`, error);
      }
    };
    if (newUploadTableName && newUploadTableName.id) {
      fetchAttributesForUser(newUploadTableName.id);
    }
  }, [newUploadTableName?.id]);
  useEffect(() => {
    const fetchLogicalResource = async () => {
      const fetchLogicalResourceData = await fetchLogicalResources(userAuthToken);
      setLogicalResourceData(fetchLogicalResourceData);
    };
    fetchLogicalResource();
  }, [userAuthToken]);
  //data send fro m here operation data:-sum....
  useEffect(() => {
    onSetOperation(selectedFilterTextsLong);
  }, [selectedFilterTextsLong]);

  useEffect(() => {
    onSetOperation(selectedFilterTextsDate);
  }, [selectedFilterTextsDate]);

  //data send fro m here value data:-20,30,ram ,kumar....
  useEffect(() => {
    onSetUserValue(cardItems);
  }, [cardItems]);

  useEffect(() => {
    onSetCheckedKeys(checkedKeys);
  }, [checkedKeys]);

  //prop send in sidebar 2
  useEffect(() => {
    onSetOperatorValue(mergedFilterOperator);
  }, [selectedFilterTexts, secondDropdownValue, dateDropdownValue]);

  useEffect(() => {
    onSetOperationString(selectedTextString);
  }, [selectedTextString]);

  const clearCheckedKey = () => {
    setCheckedKeys([]);
  };
  useEffect(() => {
    dispatch(clearCheckedData(clearCheckedKey));
  }, []);

  useEffect(() => {
    const updatedKeys = widgetIdClickedData?.metricDTOS?.map((element) => ({
      label: element?.selectColumn?.label,
      logicalDataType: element?.selectColumn?.logicalDataType,
    }));

    if (updatedKeys) {
      const currentLabel = updatedKeys[0]?.label;

      const selectedUserId = widgetIdClickedData?.logicalResourceDTO?.id;

      const fetchAttributesForUser = async (userId) => {
        try {
          const attributesData = await fetchLogicalAttributes(
            userId
          );
          console.log(attributesData + "attributesData");

          setLogicalAttributes(attributesData);
          setSelectedTables(
            widgetIdClickedData?.logicalResourceDTO?.label
              ? widgetIdClickedData?.logicalResourceDTO?.label
              : selectedtablename.selectedTableName
          );
          setSelectedTable(
            widgetIdClickedData?.logicalResourceDTO?.label
              ? widgetIdClickedData?.logicalResourceDTO?.label
              : selectedtablename.selectedTableName
          );
        } catch (error) {
          console.error(`Error fetching attributes for user ${userId}:`, error);
        }
      };

      if (selectedUserId) {
        fetchAttributesForUser(selectedUserId);
      }
      if (currentLabel !== previousLabel) {
        filteredKeysRef.current = updatedKeys;
        setCheckedKeys([...filteredKeysRef.current]);
        setPreviousLabel(currentLabel);
      }
    }
  }, [widgetIdClickedData]);

  //empty data
  useEffect(() => {
    clearCheckedKey();
    setSelectAllChecked(false);
    dispatch(resetsetData());
  }, [selectedTable]);

  //..........End the hooks start section.............

  const handleUserClick = async (user, userId, userName, dropDownType) => {
    const selectedUserLabel = user.label;
    if (selectedTable && selectedUserLabel !== selectedTable) {
      const confirmed = window.confirm(
        "Are you sure you want to switch to another Table? This may affect the current chart(s)., make sure to save the current charts to avoid data loss before switching"
      );

      if (!confirmed) {
        alert("Operation cancelled. Table not changed");
        return;
      } else {
        dispatch(resetsetData());
        dispatch(clearChartData(true));
      }
    }

    const attributesData = await fetchLogicalAttributes(userId);
    const logicalResourceUserId = localStorage.setItem(
      "logicaluserId",
      JSON.stringify(user)
    );
    dispatch(fetchLogicalAttributesData(attributesData));
    setLogicalAttributes(attributesData);
    setSelectedTables(userId);
    setSelectedTable(userName);
  };

  const uniqueKeys = logicalAttributes.reduce((keys, obj) => {
    Object.keys(obj).forEach((key) => keys.add(key));
    return keys;
  }, new Set());

  const keysArray = Array.from(uniqueKeys);

  const handleCheckboxChange = (event, label, logicalDataType, id) => {
    const key = { label, logicalDataType, id };
    const { checked } = event.target;
    if (checked) {
      setCheckedKeys((prevCheckedKeys) => [...prevCheckedKeys, key]);
      setButtonVisibleString((prevButtonVisibleString) => {
        return { ...prevButtonVisibleString, [label]: false };
      });
    } else {
      setCheckedKeys((prevCheckedKeys) =>
        prevCheckedKeys.filter(
          (item) => item.label !== key.label || item?.logicalDataType !== key?.logicalDataType
        )
      );
    }
    // Check if all individual checkboxes are checked and update "Select All" accordingly
    const allChecked = filteredKeysArray.every((key) =>
      checkedKeys.some(
        (checkedKey) =>
          checkedKey.label === key.label &&
          checkedKey?.logicalDataType === key?.logicalDataType
      )
    );
    setSelectAllChecked(allChecked);
  };

  const filteredKeysArray = logicalAttributes.filter((key) =>
    key.label.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleSelectAll = (event) => {
    const { checked } = event.target;
    setSelectAllChecked(checked);
    if (checked) {
      // Extracting labels from filteredKeysArray
      const attributeLabels = filteredKeysArray.map((key) => ({
        label: key.label,
        logicalDataType: key?.logicalDataType,
      }));
      setCheckedKeys(attributeLabels);
    } else {
      setCheckedKeys([]);
    }
  };

  const handleRemoveKey = (keyToRemove) => {
    setCheckedKeys((prevCheckedKeys) =>
      prevCheckedKeys.filter((item) => item !== keyToRemove)
    );
  };

  // search column name as card
  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleSecondDropdownClick = (label, option) => {
    setOperatorValue(option);
    setSecondDropdownValue((prevValues) => ({
      ...prevValues,
      [label]: option,
    }));

    setSecondDropDownOpen((prevOpenStates) => ({
      ...prevOpenStates,
      [label]: false,
    }));
  };

  //.......date.....
  const handleDateDropdownClick = (label, option) => {
    setOperatorValue(option);
    setDateDropdownValue((prevValues) => ({
      ...prevValues,
      [label]: option,
    }));

    setDateDropDownOpen((prevOpenStates) => ({
      ...prevOpenStates,
      [label]: false,
    }));
  };

  // Function to handle operation data {marks:"sum"}
  const handleFilterChange = (key, filter) => {
    setOperatorValue(filter);
    setSelectedFilterTexts((prevSelectedFilterTexts) => ({
      ...prevSelectedFilterTexts,
      [key]: filter,
    }));
  };

  const handleFilterChangeLong = (key, filter) => {
    setSelectedFilterTextsLong((prevSelectedFilterTextsLong) => ({
      ...prevSelectedFilterTextsLong,
      [key]: filter,
    }));
  };

  const handleFilterChangeDate = (key, filter) => {
    setSelectedFilterTextsDate((prevSelectedFilterTextsDate) => ({
      ...prevSelectedFilterTextsDate,
      [key]: filter,
    }));
  };
  // Function to handle "Select All" checkbox change for a specific item

  const isChecked = (key) => {
    return checkedKeys.some(
      (checkedKey) =>
        checkedKey.label === key.label && checkedKey?.logicalDataType === key.logicalDataType
    );
  };

  const uncheckKey = (key) => {
    setCheckedKeys((prevCheckedKeys) =>
      prevCheckedKeys.filter(
        (item) => item.label !== key.label || item?.logicalDataType !== key?.logicalDataType
      )
    );
  };

  const handleCheckboxChange2 = (event, label, logicalDataType) => {
    const key = { label, logicalDataType };
    const { checked } = event.target;
    if (isChecked(key)) {
      uncheckKey(key);
    } else {
      setCheckedKeys((prevCheckedKeys) => [...prevCheckedKeys, key]);
    }
  };

  const toggleAccordion = (index) => {
    if (openAccordionIndexes.includes(index)) {
      // Item is already open, close it
      setOpenAccordionIndexes(openAccordionIndexes.filter((i) => i !== index));
    } else {
      // Item is closed, open it
      setOpenAccordionIndexes([...openAccordionIndexes, index]);
    }
  };

  const handleAddFilterClickString = (label) => {
    // console.log(label,"filterkeys clicked")
    setButtonVisibleString((prevState) => ({
      ...prevState,
      [label]: true,
    }));

    setFilterAddedString((prevState) => ({
      ...prevState,
      [label]: false,
    }));
  };

  const handleAddFilterClickLong = (label) => {
    setButtonVisibleLong((prevState) => ({
      ...prevState,
      [label]: true,
    }));

    setFilterAddedLong((prevState) => ({
      ...prevState,
      [label]: false,
    }));
  };
  //..........date......
  const handleAddFilterClickDate = (label) => {
    setButtonVisibleDate((prevState) => ({
      ...prevState,
      [label]: true,
    }));
    setFilterAddedDate((prevState) => ({
      ...prevState,
      [label]: false,
    }));
  };
  //user input value
  const handleInputChange = (event, cardLabel) => {
    const newValue = event.target.value;
    setUserInputValue(newValue);
    setCardInputValues((prevCardInputValues) => ({
      ...prevCardInputValues,
      [cardLabel]: newValue,
    }));
  };

  const handleInputEnter = (event, cardLabel) => {
    const inputValueForCard = cardInputValues[cardLabel] || "";

    if (event.key === "Enter" && inputValueForCard.trim() !== "") {
      setCardItems((prevCardItems) => ({
        ...prevCardItems,
        // cardLabel: {...(prevCardItems[cardLabel] ||{}), inputValueForCard}
        [cardLabel]: [...(prevCardItems[cardLabel] || []), inputValueForCard],
      }));
      setCardInputValues((prevCardInputValues) => ({
        ...prevCardInputValues,
        [cardLabel]: "",
      }));
    } else if (
      event.key === "Backspace" &&
      inputValueForCard === "" &&
      cardItems[cardLabel]?.length > 0
    ) {
      const newItems = [...cardItems[cardLabel]].slice(
        0,
        cardItems[cardLabel].length - 1
      );
      setCardItems((prevCardItems) => ({
        ...prevCardItems,
        [cardLabel]: newItems,
      }));
    }
  };

  const handleDragStart = (event, item) => {
    dispatch(draggedData(JSON.stringify(item)));
    const updateCheckedKeys = () => {
      const isChecked = checkedKeys?.some(
        (checkedKey) =>
          checkedKey.label === item.label &&
          checkedKey.logicalDataType === item.logicalDataType
      );
      setCheckedKeys((prevCheckedKeys) => {
        if (isChecked) {
          // If it's already checked, remove it from the state
          return prevCheckedKeys.filter(
            (checkedKey) =>
              !(
                checkedKey.label === item.label &&
                checkedKey.logicalDataType === item.logicalDataType
              )
          );
        } else {
          // If it's not checked, add it to the state
          return [
            ...prevCheckedKeys,
            { label: item.label, logicalDataType: item.logicalDataType },
          ];
        }
      });
    };
    dispatch(checkedKeysData(updateCheckedKeys));
  };
  const updatedCheckedKeys = logicalAttributes?.map((item, index) => {
    return {
      ...item,
      ...additionalData[index],
    };
  });

  return (
    <>
      <div className=" position-relative">
        <div className="sidebarPadding">
            <DataSetDropdown
              selectedTable={selectedTable}
              logicalResourceData={logicalResourceData}
              handleUserClick={handleUserClick}
              isCustomBehavior={true}
              uniqueIdentifier="1"
            />
          </div>
       {selectedTables && (
         <ColumnPreview
              filteredKeysArray={filteredKeysArray}
              checkedKeys={checkedKeys}
              handleCheckboxChange={handleCheckboxChange}
              handleDragStart={handleDragStart}
              selectAllChecked={selectAllChecked}
              handleSelectAll={handleSelectAll}
              selectedTable={selectedTable}
            />
         )}
        {/* <SearchColPreview
          searchInput={searchInput}
          handleSearchInputChange={handleSearchInputChange}
        /> */}
        {/* {checkedKeys?.length > 0 && (
          <div className="col-md-12">
            {checkedKeys?.map((keyChecked, index) => (
              <div
                className={`card b-0 mt-3 ${openAccordionIndexes.includes(index) ? "active" : ""
                  }`}
                key={`${keyChecked.label}_${keyChecked?.logicalDataType}`}
              >
                <div className="p-0 customCardBodySidebar">
                  <div
                    className="cardHeaderCus CustomcardHeader"
                    onClick={() => toggleAccordion(index)}
                  >
                    {keyChecked.label && (
                      <h6 className="mb-0">{keyChecked.label}</h6>
                    )}
                    <h6
                      className="mb-0"
                      key={`${keyChecked.label}_${keyChecked?.logicalDataType}`}
                    >
                      {selectedLabel.label}
                    </h6>
                    <div
                      className="CrossMarks"
                      onClick={(event) => {
                        event.stopPropagation(); // This prevents the click from reaching the parent div
                        handleRemoveKey(keyChecked);
                      }}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  </div>
                  {openAccordionIndexes.includes(index) ? (
                    <div className="card-bodyCustom">
                      <div className="row CardPadding">
                        <div className="col-sm-6 p-0">
                        <ColumnDropdown  keyChecked={keyChecked}
                          filteredKeysArray={filteredKeysArray}
                          checkedKeys={checkedKeys}
                          handleCheckboxChange2={handleCheckboxChange2}
                          datatypeNumber={datatypeNumber}
                          datatypeString={datatypeString}
                          dateDataType={dateDataType}/>
                        </div>
                        {(keyChecked?.logicalDataType === "String" ||
                          keyChecked.logicalDataType === "TEXT") && (
                            <ActualValueDropDown   checkedValues={checkedValues}
                            selectedTextString={selectedTextString}
                            keyChecked={keyChecked}
                            FilterDataTypeTextString={FilterDataTypeTextString}/>
                          )}
                        {(keyChecked?.logicalDataType === "Long" ||
                          keyChecked.logicalDataType == "NUMBER" ||
                          keyChecked.logicalDataType === "DECIMAL") && (
                            <TextLongDropdown 
                            checkedValues={checkedValues}
                            selectedFilterTextsLong={selectedFilterTextsLong}
                            keyChecked={keyChecked}
                            FilterDataTypeTextLong={FilterDataTypeTextLong}
                            filterInputLong={filterInputLong}
                            setFilterInputLong={setFilterInputLong}
                            uniqueFilterDataTypeTextLong={uniqueFilterDataTypeTextLong}
                            handleFilterChangeLong={handleFilterChangeLong} />
                          )}
                        {keyChecked?.logicalDataType === "DATE" && (
                         <FilterDropdown   checkedValues={checkedValues}
                         selectedFilterTextsDate={selectedFilterTextsDate}
                         keyChecked={keyChecked}
                         FilterDataTypeTextDate={FilterDataTypeTextDate}
                         filterInputDate={filterInputDate}
                         setFilterInputDate={setFilterInputDate}
                         uniqueFilterDataTypeTextDate={uniqueFilterDataTypeTextDate}
                         handleFilterChangeDate={handleFilterChangeDate}
                         />
                        )}
                      </div>
                      <FilterStringDropdown 
                       keyChecked={keyChecked} 
                       uniqueFilterDataTypeText={uniqueFilterDataTypeText}
                        optionsSecondDropdown={optionsSeconDropdown}
                         optionsDateDropdown={optionsDateDropdown}
                         cardItems={cardItems}
                          cardInputValues={cardInputValues}
                         isButtonVisibleString={isButtonVisibleString}
                         handleAddFilterClickString={handleAddFilterClickString}
                         hasFilterBeenAddedString={hasFilterBeenAddedString}
                         checkedValues={checkedValues}
                         selectedFilterTexts={selectedFilterTexts}
                         filterInput={filterInput}
                         setFilterInput={setFilterInput}
                         handleFilterChange={handleFilterChange}
                         handleInputChange={handleInputChange}
                         handleInputEnter={handleInputEnter}
                         isButtonVisibleLong={isButtonVisibleLong}
                         handleAddFilterClickLong={handleAddFilterClickLong}
                         hasFilterBeenAddedLong={hasFilterBeenAddedLong}
                         secondDropdownValue={secondDropdownValue}
                         setSecondDropDownOpen={setSecondDropDownOpen}
                         secondDropDownOpen={secondDropDownOpen}
                         handleSecondDropdownClick={handleSecondDropdownClick}
                         isButtonVisibleDate={isButtonVisibleDate}
                         handleAddFilterClickDate={handleAddFilterClickDate}
                         hasFilterBeenAddedDate={hasFilterBeenAddedDate}
                         dateDropdownValue={dateDropdownValue}
                         setDateDropDownOpen={setDateDropDownOpen}
                         dateDropDownOpen={dateDropDownOpen}
                         handleDateDropdownClick={handleDateDropdownClick}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        )} */}
      </div>
    </>
  );
}
