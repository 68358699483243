import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import GetCookie from "../persistence/cookies/getCookie/GetCookie";
function Login(props) {
  let Cmp = props.Cmp;
  const navigate = useNavigate();
  const token = GetCookie("token");
  useEffect(() => {
    let newURL;
    if (!token) {
      if (window.location.hostname == "localhost") {
        navigate("/login");
      } else {
        newURL = `https://www.vivrn.com/login`;
        window.location.href = newURL;
      }
    }
  }, [token]);
  return (
    <>
      <Cmp />
    </>
  );
}
Login.propTypes = {
  Cmp: PropTypes.elementType.isRequired,
};

export default Login;
