import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { focusModeClassData, resetfocusModeClassData } from "../../../redux/reduxToolkit/Reducer";
import { useDispatch } from "react-redux";
exportingInit(Highcharts);
exportDataInit(Highcharts);

export default function StackedBarSingle(props) {
  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(props?.responseData?.data);
  useEffect(() => {
    setFetchData(props?.responseData?.data);
  }, [props?.responseData]);
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus, props.responseData]);
  
  const toggleFocusMode = () => {
      setGetFocus(prev => !prev);
  };
  const categories = fetchData?.map((d) => d.sgb);
  const subcategories = Object.keys(fetchData[0]?.metric);

  const uniqueMetrics = Array.from(
    new Set(fetchData.flatMap((data) => Object.keys(data.metric)))
  );

  const metricColors = generateRandomColors(uniqueMetrics.length);

  const seriesData = subcategories?.map((subcategory, i) => ({
    name: subcategory,
    data: fetchData?.map((d) => ({
      y: d.metric[subcategory],
      legendColor: metricColors[i],
    })),
  }));

  const chartOptions = {
    chart: {
      type: "bar",
      height: "100%",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    legend: {
      enabled: true,
      labelFormatter: function () {
        const index = categories.indexOf(this.name);
        return `<span style="color: ${metricColors[index]};">${this.name}</span>`;
      },
    },
    plotOptions: {
      bar: {
        stacking: "normal",
      },
    },
    series: seriesData,
    tooltip: {
      shared: false,
      formatter: function () {
        const stackIndex = this.point.index;
        const stackName = this.series.name;
        const metricName = this.x;
        const value = this.y;
        const color = this.point.color;

        return `<span style="color: ${color}">${stackName} - ${metricName}: ${value}</span>`;
      },
    },
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [fetchData]);
  function generateRandomColors(count) {
    const colors = [];
    for (let i = 0; i < count; i++) {
      const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
        16
      )}`;
      colors.push(randomColor);
    }
    return colors;
  }

  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
    </>
  );
}
