// store.js

// import { configureStore } from '@reduxjs/toolkit';
// import responseReducer from './Reducer';
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // or any other storage engine you prefer

// const persistConfig = {
//   key: 'root', // this key is used to store the data in local storage
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, responseReducer);

// const store = configureStore({
//   reducer: {
//     response: persistedReducer,
//   },
// });

// const persistor = persistStore(store);

// export { store, persistor };
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './Reducer'; // Replace with the actual path

const store = configureStore({
  reducer: rootReducer,
  // other store configuration options if needed
});

export default store;
