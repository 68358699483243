import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { loginApiUrl } from "../url/UrlConfig";

export default async function UpdateWorkspaceAPI(payload) {
    const token = GetCookie('token');
    try {
        const response = await fetch(
           "https://server.vivrn.com:8080/analytics/workspace/updateWorkspace", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "authToken": token
            },
            body: payload,
        }
        );
        const result = await response.json();
        if (result.httpStatusCode === 200) {
            return result;
        } else {
            return null;
        }
    } catch (error) {
        console.error(error.message || error);
        return null;
    }
}
