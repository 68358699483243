import Layout from "../../component/layout/Layout";
import React, { useState, useEffect } from "react";
import "./CreateReport.css";
import SidebarContent1 from "./sidebar/SidebarContent1";
import SidebarContent2 from "./sidebar/SidebarContent2";
import SelectedCharts from "./playground/SelectedCharts ";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReportIdData, WidgetResponseData, reportByIdApiResponseData, resetSelectedChartData, resetSelectedWidgetUuidData, resetSelecteddChartData, showOneWidgetResponseData } from "../../redux/reduxToolkit/Reducer";
import { enumChartTypes } from "../../enums/enum";
import SidebarHeader from "./sidebar/SidebarHeader";
import JoinDataSetModal from "../../component/modal/JoinDatasetModal";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import GetReportByIdApi from "../../service/api/GetReportByIdApi";
import GetWidgetByIdApi from "../../service/api/GetWidgetByIdApi";
import CreateReportApi from "../../service/api/CreateReportApi";
import getWorkspaceId from "../../util/AttributeUtil";
import ReportHistory from "./sidebar/reportHistory/ReportHistory";
import ReportSchedule from "../schedule/ReportSchedule";
import TableData from "../../graphHeatMap/table/TableData";
import ImportData from "../../component/modal/ImportData";

export default function CreateReport() {
  const [isDataSidebarOpen, setDataSidebarOpen] = useState(true);
  const [isVisualizationSidebarOpen, setVisualizationSidebarOpen] =
    useState(false);
  const [selectedCharts, setSelectedCharts] = useState([]);
  const [apiSelectedChart, setApiSelectedChart] = useState(
    enumChartTypes.CLUSTERED_COLUMN
  );
  const [chartData, setChartData] = useState([]);
  const [chartMap, setChartDataMap] = useState([]);
  const dispatch = useDispatch();
  const [reportEditing, setReportEditing] = useState(false);
  const [reportName, setYourReportName] = useState("Untitled");
  const [receivedData, setReceivedData] = useState([]);
  const [receivedOperation, setOperation] = useState([]);
  const [receivedOperationString, setOperationString] = useState([]);
  const [receivedUserInputValue, setUserInputValue] = useState("");
  const [receivedOperatorValue, setOperatorValue] = useState([]);
  const lresponse = GetCookie("loginResponsecode");
  const parserOrgId = JSON.parse(lresponse);
  const orgId = parserOrgId?.organizationId;
  const userId = parserOrgId?.userId;
  const date = new Date().getTime();
  const userJSON = localStorage.getItem("logicaluserId");
  const user = JSON.parse(userJSON);
  const logicalUserId = user?.id;
  const datasetId = user?.dataSetAttributeDTO?.id;
  const totalWidgetData = useSelector((state) => state.totalWidgetData); //create report || save report
  const [isSaving, setIsSaving] = useState(false);
  const checkedKeysData = useSelector((state) => state.checked);
  const getWidgetId = useSelector((state) => state.ReportId); //get widgetREPORT BY ID
  const getWidget = useSelector((state) => state.widgetIdResponse); //get widgetREPORT BY ID
  const { cardId, show } = useParams();
  const [widgetDataArray, setWidgetDataArray] = useState([]);
  const [defaultGraph, setDefaultGraph] = useState("");
  const userAuthToken = GetCookie("token");
  const [reGetData, setReGetData] = useState([]);
  const [showwidget, setShowwidget] = useState(false);
  const [reportData, setReportData] = useState('');

  const fetchGetReportByIdApi = async (id) => {

    try {
      const data = await GetReportByIdApi(id);
      // Check if files is not undefined and not null
      if (data && data.response) {
        dispatch(ReportIdData(data.response));
        console.log(data.response, "GetReportByIdApi");
        setReportData(data.response);
        dispatch(reportByIdApiResponseData(data.response));
      } else {
        console.error("Received undefined or null files data");
      }
    } catch (error) {
      console.error("Failed to fetch uploaded files:", error);
    }
  };
  const fetchGetWidgetByIdApi = async (id) => {
    try {
      const data = await GetWidgetByIdApi(id);
      if (data && data.response) {
        dispatch(WidgetResponseData(data.response));
      } else {
        console.error("Received undefined or null files data");
      }
    } catch (error) {
      console.error("Failed to fetch uploaded files:", error);
    }
  };
  const url = window.location.href;
  const parts = url.split("/");
  let cardId1 = null;

  if (parts.length >= 5) {
    cardId1 = parts[parts.length - 2];
  }
  const IdOfReport = useSelector((state) => state.IdOfReport);
  const ReportIdVisual = IdOfReport ? IdOfReport : cardId1;
  useEffect(() => {
    if (ReportIdVisual) {
      fetchGetReportByIdApi(ReportIdVisual);
    }
  }, [ReportIdVisual]);

  useEffect(() => {
    if (getWidgetId?.widgetDTOS) {
      getWidgetId.widgetDTOS.forEach((id) => {
        console.log(id?.id, "iddnf");
        fetchGetWidgetByIdApi(id?.id);
      });
    }
  }, [getWidgetId]);

  const [visualizeWidgetShow, setVisualizeWidgetShow] = useState(false);
  //dashboard report data get
  const widgetResponseDashboard = useSelector(
    (state) => state.widgetIdResponse
  );
  const xAxisData = widgetResponseDashboard?.data?.xAxis;
  const clearCheckedKey = useSelector((state) => state.clearChecked);
  const handleToggleSidebar1 = () => {
    setDataSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };
  const handleToggleSidebar2 = () => {
    setVisualizationSidebarOpen((prevIsSidebarOpen) => !prevIsSidebarOpen);
  };
  // Function to update selectedCharts state
  const updateSelectedCharts = (charts) => {
    setSelectedCharts(charts);
  };
  //chart data
  const updateChartsData = (data) => {
    setChartData(data);
  };
  const updatedApiChartType = (data) => {
    setApiSelectedChart(data);
  };
  const handleCompanyNameChange = (event) => {
    setYourReportName(event.target.value);
  };
  const handleSaveClick = () => {
    setYourReportName(reportName);
    setReportEditing(false);
  };
  const handleEditClick = () => {
    setYourReportName(reportName);
    setReportEditing(true);
  };
  const handleDataFromChild = (data) => {
    setReceivedData(data);
  };
  const handleOperation = (data) => {
    setOperation(data);
  };
  const handleOperationString = (data) => {
    setOperationString(data);
  };
  const handleUserInputValue = (data) => {
    setUserInputValue(data);
  };
  const handleOperatorValue = (data) => {
    setOperatorValue(data);
  };
  //save report api || create report

  const saveReport = async () => {
    const workspaceId = await getWorkspaceId();
    const payload = {
      title: reportName, // title of report
      description: "first report",
      createdDate: date,
      workspaceDTO: {
        id: workspaceId,
      },
      organizationDTO: {
        id: orgId, // organization id of the logged-in user
      },
      isDeleted: false, // false by default
      createdBy: {
        id: userId, // user id of the logged-in user
      },
      widgetDTOS: totalWidgetData,
      publicAccess: true, // private - false , non-private - true
    };

    CreateReportApi(payload);
    setIsSaving(true);
  };

  useEffect(() => {
    dispatch(showOneWidgetResponseData(true));
    setShowwidget(false);
  }, [showwidget, checkedKeysData]);
  const databaseDataUpdated = useSelector(
    (state) => state.databaseSelectedTable
  );
  const [userDesireSelection, setUserDesireSelection] = useState("");
  const updateChartUserDesire = (selectedChart) => {
    setUserDesireSelection(selectedChart);
    console.log(selectedChart, "main");
  };

  return (
    <Layout>
      <div className="AnalyticsContainer position-relative">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 p-0">
              <div
                className={`CraeteReportHeader ${isDataSidebarOpen && isVisualizationSidebarOpen
                    ? "paddingChange"
                    : ""
                  } ${isDataSidebarOpen || isVisualizationSidebarOpen
                    ? "paddingChangeOne"
                    : ""
                  }`}
              >
                <div className="Untitled">
                  {reportEditing ? (
                    <input
                      type="text"
                      value={
                        Object.keys(getWidgetId).length
                          ? getWidgetId.title
                          : reportName
                      }
                      onChange={handleCompanyNameChange}
                      className="edit-company-name"
                      placeholder="enter your project Name"
                    />
                  ) : (
                    <h4 className="company-name mb-0">
                      {Object.keys(getWidgetId).length
                        ? getWidgetId.title
                        : reportName}
                    </h4>
                  )}
                  {reportEditing ? (
                    <span className="save-icon p-2" onClick={handleSaveClick}>
                      <i className="fa-solid fa-check"></i>
                    </span>
                  ) : (
                    <span className="edit-icon p-2" onClick={handleEditClick}>
                      <i className="fa-solid fa-edit"></i>
                    </span>
                  )}
                </div>
                {visualizeWidgetShow ? (
                  <div className="">
                    <button
                      className="btn btn-outline-secondary"
                      disabled={isSaving}
                      onClick={() => saveReport()}
                    >
                      Save report
                    </button>
                  </div>
                ) : null}
                {reportData? <ReportHistory reportData={reportData}/>: null}
                <div
                  onClick={() => {
                    setShowwidget(true);
                    dispatch(resetSelectedWidgetUuidData());
                    dispatch(resetSelectedChartData(true));
                    dispatch(resetSelecteddChartData());
                  }}
                >
                  <button onClick={clearCheckedKey}>CreateNewWidget</button>
                </div>
                  <div>
                <ReportSchedule reportData={reportData}/>
                  </div>
                <div className="autoGenerateRepo">
                  <ImportData hideText={true} />
                </div>
              </div>

              {/* First Right-Side Sidebar */}
              <div
                className={`sidebar ${isDataSidebarOpen ? "open Sidebar1open" : "Sidebar1closed"
                  }`}
              >
                <div
                  className="toggle-button-container"
                  onClick={handleToggleSidebar1}
                >
                  <SidebarHeader isOpen={isDataSidebarOpen} label="Data" />
                </div>
                <div
                  className={`sidebar-content ${isDataSidebarOpen ? "fade-in" : "fade-out"
                    }`}
                >
                  {/* Add content for Sidebar 1 here */}
                  <SidebarContent1
                    onSetCheckedKeys={handleDataFromChild}
                    onSetOperation={handleOperation}
                    onSetOperationString={handleOperationString}
                    onSetUserValue={handleUserInputValue}
                    onSetOperatorValue={handleOperatorValue}
                    selectedCharts={selectedCharts}
                    defaultGraph={defaultGraph}
                  />
                </div>
              </div>
              {/* Second Right-Side Sidebar */}
              <div
                className={`sidebar ${isVisualizationSidebarOpen
                    ? `open Sidebar2open ${isDataSidebarOpen ? "" : "with-sidebar1closed"
                    }`
                    : `Sidebar2closed ${isDataSidebarOpen ? "" : "with-sidebar1closed"
                    }`
                  }`}
              >
                <div
                  className="toggle-button-container"
                  onClick={handleToggleSidebar2}
                >
                  <SidebarHeader
                    isOpen={isVisualizationSidebarOpen}
                    label="Visualization"
                  />
                </div>
                <div
                  className={`sidebar-content ${isVisualizationSidebarOpen ? "fade-in" : "fade-out"
                    }`}
                >
                  {/* Add content for Sidebar 2 here */}
                  <SidebarContent2
                    updateSelectedCharts={updateSelectedCharts}
                    updatedApiChartType={updatedApiChartType}
                    projectName={reportName}
                    receivedData={receivedData}
                    updateChartsData={updateChartsData}
                    receivedOperation={receivedOperation}
                    receivedUserInputValue={receivedUserInputValue}
                    receivedOperatorValue={receivedOperatorValue}
                    setVisualizeWidgetShow={setVisualizeWidgetShow}
                    receivedOperationString={receivedOperationString}
                    setDefaultGraph={setDefaultGraph}
                    updateChartUserDesire={updateChartUserDesire}
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className={`col-md-${isDataSidebarOpen && isVisualizationSidebarOpen ? "12" : "12"
                }`}
            >
              <div
                className={`CustomCharts ${isDataSidebarOpen && isVisualizationSidebarOpen
                    ? "Sidebar1openandsideb2open"
                    : ""
                  } ${isDataSidebarOpen || isVisualizationSidebarOpen
                    ? "Sidebar1closeandsideb2open"
                    : ""
                  }`}
              >
                {/* <SelectedCharts selectedCharts={selectedCharts} /> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div
                className={`CustomCharts ${isDataSidebarOpen && isVisualizationSidebarOpen
                    ? "Sidebar1openandsideb2open"
                    : ""
                  } ${isDataSidebarOpen || isVisualizationSidebarOpen
                    ? "Sidebar1closeandsideb2open"
                    : ""
                  }`}
              >
                <div className={visualizeWidgetShow || show ? "" : null}>
                  <SelectedCharts
                    selectedCharts={selectedCharts}
                    userDesireSelection={userDesireSelection}
                  />
                  {/* <TableData/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
