import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./ImportedDataModal.css";

function ImportedDataModal({ dataApi }) {
  const { columnSchemaList, records } = dataApi.response || {};
  if (!columnSchemaList || !records) {
    console.error("DataApi response is undefined or missing properties.");
    return null;
  }
  const [modalOpen, setModalOpen] = useState(true);
  const closeModal = () => {
    setModalOpen(false);
  };
  const totalRows = records?.length || 0;
  const totalColumns = columnSchemaList?.length || 0;
  return (
    <>
      <Modal
        show={modalOpen}
        onHide={closeModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Imported Data Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Column Summary</h5>
          <hr />
          <div className="d-flex justify-content-between">
            <p>Total Columns</p>
            <p>{totalColumns}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Successfully Imported</p>
            <p>{totalColumns}</p>
          </div>
          <div className="secondRow">
            <h5>Row Summary</h5>
            <hr />
            <div className="d-flex justify-content-between">
              <p>Total Rows</p>
              <p>{totalRows}</p>
            </div>
            <div className="d-flex justify-content-between">
              <p>Successfully Imported</p>
              <p>{totalRows}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            className="CancelButton btn btn-secondary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ImportedDataModal;
