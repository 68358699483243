import React, { useState } from "react";
import { connect } from "react-redux";
import "./MyContent.css";
import { uploadCloud } from "../../../constants/pictures/Pictures";
import CsvTable from "./csvTable/CsvTable";
import { uploadFile } from "../../../redux/redux/action";

function MyContent(props) {
  localStorage.removeItem('previousData');
  const { uploadedFiles, uploadFile } = props;
  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);

    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        const parsedData = convertCsvToJson(csvData);

        // Add date property to each object in JSON data
        const date = new Date().toISOString();
        parsedData.forEach((obj) => {
          obj.date = date;
          obj.fileName = file.name;
        });

        uploadFile(parsedData);
      };
      reader.readAsText(file);
    });
  };

  const convertCsvToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const jsonData = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");

      if (currentLine.length === headers.length) {
        const obj = {
          fileName: currentLine[0].trim(),
          date: currentLine[1].trim(),
        };

        jsonData.push(obj);
      }
    }

    return jsonData;
  };

  return (
    <div className="dashboard">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <div className="uploadWrapper">
                <div className="upload-icon">
                  <input
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    id="folder"
                    multiple
                  />
                  <label htmlFor="folder">
                    <img src={uploadCloud} alt="Upload" className="img-fluid" />
                    <p>
                      <span className="ClickUpload">Click to Upload </span>
                      <span className="DragDrop">or Drag and drop</span>
                    </p>
                  </label>
                </div>
              </div>
            </div>
            <CsvTable />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    uploadedFiles: state.uploadedFiles,
  };
};

const mapDispatchToProps = {
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyContent);
