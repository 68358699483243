import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import {
  ShareIcon,
  ShaveIcon,
  columnPreview,
  tableModal,
  tablePreview,
  table_preview,
} from "../../../constants/pictures/Pictures";
import { renderTabTitle } from "../../../directives/image-directive";
import SingleColumnTable from "./SingleTable";
import SmartTable from "../../../component/table/smartTable/SmartTable";
import SearchInput from "../../../directives/SearchInput";
import { userAuthToken } from "../../../dtos/Token.type";
import { apiUrls } from "../../../service/endPoints/ApiUrls";
import { getApiUrl } from "../../../service/url/UrlConfig";
import TableColumn from "./TableColumn";
import { useDispatch } from "react-redux";
import { databaseSelectedTableData, fetchLogicalAttributesData } from "../../../redux/reduxToolkit/Reducer";
import { useNavigate } from "react-router";
import { todayDate } from "../../../util/todayDate";
import { orgId } from "../../../util/Utils";
import ModalTableApi from "../../../service/api/ModalTableApi";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import decodeJWT from "../../../util/Decode";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";

function ConnectData({ getpayload }) {
  console.log(getpayload, "getpayload")
  const [activeTab, setActiveTab] = useState("column");
  const [tablePreviewData, setTablePreview] = useState([]);
  const [tablePreviewColumn, setPreviewColumn] = useState([]);
  const [selectedTable, setSelectedTable] = useState(false);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [firstTableName, setFirstTableName] = useState("");
  const [updateSelect, setupdateSelect] = useState({});
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchColumnData = async (value) => {
    try {
      const response = await fetch(
        getApiUrl() +
        apiUrls.dataTable.previewTable(value ? value : firstTableName),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: userAuthToken,
          },
          body: JSON.stringify(getPayload),
        }
      );
      const data = await response.json();
      if (data.httpStatusCode === 200) {
        setPreviewColumn(data.response);
      } else {
        setPreviewColumn([]);
        console.error("Request failed. Please try again.");
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  useEffect(() => {
    if (selectedTableName) {
      fetchColumnData(selectedTableName);
      setSelectedTable(true);
      setActiveTab("column");
    }
  }, [selectedTableName]);

  const updateSelectedValue = (value) => {
    setSelectedTableName(value);
  };

  const getPayload = {
    "host": getpayload.host,
    "port": getpayload.port,
    "username": getpayload.username,
    "password": getpayload.password,
    "dataSourceType": getpayload.dataSourceType,
    "databaseName": getpayload.databaseName,
    "organizationDTO": {
      "id": orgId
    },
    "createdDate": todayDate,
  };

  const fetchTableData = async () => {
    try {
      const response = await fetch(
        getApiUrl() + apiUrls.dataTable.getTableNames,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authToken: userAuthToken,
          },
          body: JSON.stringify(getPayload),
        }
      );
      const data = await response.json();
      if (data.httpStatusCode === 200) {
        setTablePreview(data.response);
        setFirstTableName(data.response[0]);
        console.log(data, "congratualtions ");
      } else {
        console.error("Request failed. Please try again.");
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const updateApi = (bool) => {
    console.log(bool);
  };

  const updateSelectedData = (value) => {
    setupdateSelect(value);
  };
  const createReport = {
    existingData: tablePreviewColumn,
    selectedTableName: selectedTableName,
  };
  const CreateBlankReport = async () => {
    const checkedTable = localStorage.getItem('checkedTable');
    const checkedTableParse = checkedTable ? JSON.parse(checkedTable) : [];
    const DetailsOfDataSource = localStorage.getItem('DetailsOfDataSource');
    const DetailsOfDataSourceParse = checkedTable ? JSON.parse(DetailsOfDataSource) : [];
    console.log(DetailsOfDataSourceParse, "checkedTableParse");
    const token = GetCookie('token');
    const decodeToken = decodeJWT(token);
    console.log(decodeToken, "tokenn")
    const modalTablepayload = {
      "dataSourceDTO": {
        "host": DetailsOfDataSourceParse.host,
        "port": DetailsOfDataSourceParse.port,
        "username": DetailsOfDataSourceParse.username,
        "password": DetailsOfDataSourceParse.password,
        "dataSourceType": DetailsOfDataSourceParse.dataSourceType,
        "databaseName": DetailsOfDataSourceParse.databaseName,
        "workspaceDTO": {
          "id": decodeToken.payload.workspaceId
        },
        "createdDate": todayDate
      },
      "tables": checkedTableParse
    }
    const modalTableResult = await ModalTableApi(JSON.stringify(modalTablepayload));
    if (modalTableResult != null) {
      dispatch(databaseSelectedTableData(modalTableResult));
      console.log(modalTableResult, "modalTableResulttt");
      navigation("/createReport");
      localStorage.removeItem('checkedTable');
      localStorage.removeItem('DetailsOfDataSource');
      SetCookie("newUploadTableData", JSON.stringify(modalTableResult[0]));
      localStorage.setItem("logicaluserId", JSON.stringify((modalTableResult[0])));
      dispatch(fetchLogicalAttributesData(modalTableResult[0]));
    }


  };
  return (
    <>
      <div className="ThirdTableContainer">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-end align-items-center">
              <img
                src={ShareIcon}
                alt="share icon"
                className="img-fluid shareIcon"
              />
              <img
                src={ShaveIcon}
                alt="share icon"
                className="img-fluid ShaveIcon"
              />
              <SearchInput />
              {/* <SearchInput onSearch={handleSearch} /> */}
            </div>
          </div>
          <div className="col-md-12 bg-table">
            <div className="row">
              <div className="col-sm-2 p-0">
                <div className="d-flex align-items-center CustomHeadingBox">
                  <img src={table_preview} className="tabelImage" alt="DATE" />
                  <h6 className="mb-0 ms-2">Table</h6>
                </div>
                <SingleColumnTable
                  data={tablePreviewData}
                  updateSelectedValue={updateSelectedValue}
                  firstTableName={firstTableName}
                />
              </div>

              <div className="col-sm-10 p-0">
                <Tabs
                  activeKey={activeTab}
                  onSelect={handleTabChange}
                  justify="fill"
                  className=" borderNone CustomTableDesign"
                >
                  {selectedTable ? (
                    <Tab
                      eventKey="column"
                      title={
                        <span className="fw-bold,text-dark">
                          {renderTabTitle(tableModal, "Column")}
                        </span>
                      }
                    >
                      <TableColumn
                        data={tablePreviewColumn}
                        updateApi={updateApi}
                        selectedTableName={selectedTableName}
                        updateSelectedData={updateSelectedData}
                      />
                    </Tab>
                  ) : null}

                  <Tab
                    eventKey="datatable"
                    title={renderTabTitle(columnPreview, "DataPreview")}
                  >
                    <SmartTable
                      data={tablePreviewColumn}
                      updateApi={updateApi}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex justify-content-end align-items-center ButtonGroupCustom">
              <button
                type="button"
                className="whiteButton"
                onClick={CreateBlankReport}
              >
                Create a blank report
              </button>
              <button type="button" className="BlueButton">
                Genrate report
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectData;
