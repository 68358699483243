import Swal from "sweetalert2";
import { loginApiUrl } from "../url/UrlConfig";
import { apiUrls } from "../endPoints/ApiUrls";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";

const LogOutApi = async (payload) => {
  const userAuthToken = GetCookie('token');
 try {
    const response = await fetch(
      loginApiUrl()+apiUrls.logOut.logout,{
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "authToken": userAuthToken
      },
      body: payload,
      }
    );
   const result = await response.json();
    if (result.httpStatusCode===200) {
     localStorage.removeItem('token');
     localStorage.removeItem('loginResponsecode');
     localStorage.removeItem('previousData');
     localStorage.removeItem('previousPayload');
     localStorage.removeItem('previousDataWidget1');
     localStorage.removeItem('logicaluserId');
     localStorage.clear();
     return true;
    } else {
      Swal.fire({
        title: "Error",
        text: "Logout failed",
        icon: "error",
        confirmButtonText: "Continue",
     customClass: {
        confirmButton: "NextButton", 
        },
       allowOutsideClick: false,
      });
      return false;
    }
  } catch (error) {
    console.error(error, "Failed API from logout");
    return false;
  }
  
}

export default LogOutApi;