import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Layout from "../../component/layout/Layout";
import "./ConnectWireHouse.css";
import { AmazonAthena, AmazonRDS, AmazonRedShift, Dropbox, Excel, GoogleDrive, AzureData, MicrosoftAzure, GoogleBigQuery, MongoDB, image11, DigitalOcean, Orancle, IBMCloud, Image7, Image6, SalesForce, HubSpot, GoogleAnalytics, Semrush, facebbook, Instagram, Youtube, Linkdln, Bing, GoogleAds, ShipStation, WooCommerce, BigCommerce, Shopify, mysql} from "../../constants/pictures/Pictures";
import { useFormik } from "formik";
import * as Yup from "yup";
import { dataWireName } from "./Connect.type";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";

export default function ConnectWireHouse() {
  localStorage.removeItem("previousData");
  const [activeSection, setActiveSection] = useState("Files&Feed");
  const contentRef = useRef(null);
  const [imageName1, setImageName1] = useState("");
  const [showModal, setShowModal] = useState(false);
  const handleSectionClick = (sectionId, event = undefined) => {
    if (event) {
      event.preventDefault();
    }
    setActiveSection(sectionId);
    const sectionElement = document.getElementById(sectionId);
    const yOffset = -60; 
    const y =
      sectionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const handleScroll = () => {
    const contentSections =
      contentRef.current.getElementsByClassName("content-section");
    for (let i = 0; i < contentSections.length; i++) {
      const section = contentSections[i];
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setActiveSection(section.id);
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  const openModal = (imageName) => {
    // Handle modal open for the specific image
    console.log(`Modal opened for image: ${imageName}`);
    setImageName1(imageName);
  };
  

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 ps-0">
              <div className="Connect Wires">
                <div className="d-flex justify-content-between">
                  <div className="ImportDataCus">
                    <h2>Import your Data</h2>
                    {/* <DatabaseTypeModal/> */}
                    <p>
                      Import your data from a wide variety of sources to create
                      insightful reports & dashboards.
                    </p>
                  </div>
                  <div className="position-relative">
                    <div className="SearchIcon">
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <input
                      className="Customform-control"
                      type="search"
                      placeholder="search"
                      aria-label="Search"
                    />
                  </div>
                </div>
                <div className="row ConnectionImageContainerConnect">
                  <div className="col-2">
                    <div className="SidebarDataWare">
                      <div className="SideDataWareDiv">
                        <h5>Category</h5>
                        <ul>
                          <li>
                            <a
                              className={
                                activeSection === "Files&Feed" ? "active" : ""
                              }
                              onClick={(event) =>
                                handleSectionClick("Files&Feed", event)
                              }
                            >
                              Files & Feed
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                activeSection === "Database&Feed"
                                  ? "active"
                                  : ""
                              }
                              onClick={(event) =>
                                handleSectionClick("Database&Feed", event)
                              }
                            >
                              Database
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                activeSection === "SalesAndCRM" ? "active" : ""
                              }
                              onClick={(event) =>
                                handleSectionClick("SalesAndCRM", event)
                              }
                            >
                              Sales & CRM
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                activeSection === "Marketing" ? "active" : ""
                              }
                              onClick={(event) =>
                                handleSectionClick("Marketing", event)
                              }
                            >
                              Marketing
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                activeSection === "E-commerce" ? "active" : ""
                              }
                              onClick={(event) =>
                                handleSectionClick("E-commerce", event)
                              }
                            >
                              E-commerce
                            </a>
                          </li>
                          <li>
                            <a
                              className={
                                activeSection === "SocialMedia" ? "active" : ""
                              }
                              onClick={(event) =>
                                handleSectionClick("SocialMedia", event)
                              }
                            >
                              Social Media
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-10">
                    <div className="row" ref={contentRef}>
                      <div className="col-12">
                        <div className="row boxShadow">
                          <div
                            className="col-12 content-section"
                            id="Files&Feed"
                          >
                            <h4>Files & Feed</h4>
                          </div>
                          {/* Content 1 */}
                          <div className="col-xl-2 col-lg-3 col-4">
                            <ConnectServer
                              showModal={showModal}
                              handleCloseModal={handleCloseModal}
                              imageName={imageName1}
                            />
                            <img
                              className="img-fluid"
                              src={GoogleDrive}
                              alt="Google Drive"
                              onClick={() => {
                                openModal("Google Drive");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Excel}
                              alt="Excel"
                              onClick={() => {
                                openModal("Excel");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Dropbox}
                              alt="Dropbox"
                              onClick={() => {
                                openModal("Dropbox");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={AzureData}
                              alt="Azure Data"
                              onClick={() => {
                                openModal("Azure Data");
                                handleOpenModal();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row boxShadow">
                          <div
                            className="col-12 content-section"
                            id="Database&Feed"
                          >
                            <h4>Database & Feed</h4>
                          </div>
                          {/* Content 2 */}
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={AmazonRDS}
                              alt="Amazon RDS"
                              onClick={() => {
                                openModal("Amazon RDS");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={AmazonRedShift}
                              alt="Amazon RedShift"
                              onClick={() => {
                                openModal("Amazon RedShift");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={AmazonAthena}
                              alt="Amazon Athena"
                              onClick={() => {
                                openModal("Amazon Athena");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={MicrosoftAzure}
                              alt="Microsoft Azure"
                              onClick={() => {
                                openModal("Microsoft Azure");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={GoogleBigQuery}
                              alt="Google Big Query"
                              onClick={() => {
                                openModal("Google Big Query");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Image6}
                              alt="Image 6"
                              onClick={() => {
                                openModal("Image 6");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Image7}
                              alt="Image 7"
                              onClick={() => {
                                openModal("Image 7");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={IBMCloud}
                              alt="IBM Cloud"
                              onClick={() => {
                                openModal("IBM Cloud");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Orancle}
                              alt="Orancle"
                              onClick={() => {
                                openModal("Orancle");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={DigitalOcean}
                              alt="Digital Ocean"
                              onClick={() => {
                                openModal("Digital Ocean");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={image11}
                              alt="image 11"
                              onClick={() => {
                                openModal("image 11");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={MongoDB}
                              alt="MongoDB"
                              onClick={() => {
                                openModal("MongoDB");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={mysql}
                              alt="MYSQL"
                              onClick={() => {
                                openModal("MYSQL");
                                handleOpenModal();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Add your images here */}
                      <div className="col-12">
                        <div className="row boxShadow">
                          <div
                            className="col-12 content-section"
                            id="SalesAndCRM"
                          >
                            <h4>Sales & CRM</h4>
                          </div>
                          {/* Content 3 */}
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={SalesForce}
                              alt="Sales Force"
                              onClick={() => {
                                openModal("Sales Force");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={HubSpot}
                              alt="Hub Spot"
                              onClick={() => {
                                openModal("Hub Spot");
                                handleOpenModal();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Add your images here */}
                      <div className="col-12">
                        <div className="row boxShadow">
                          <div
                            className="col-12 content-section"
                            id="Marketing"
                          >
                            <h4>Marketing</h4>
                          </div>
                          {/* Content 4 */}
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={GoogleAnalytics}
                              alt="Google Analytics"
                              onClick={() => {
                                openModal("Google Analytics");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Semrush}
                              alt="Semrush"
                              onClick={() => {
                                openModal("Semrush");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={facebbook}
                              alt="facebbook"
                              onClick={() => {
                                openModal("facebbook");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Instagram}
                              alt="Instagram"
                              onClick={() => {
                                openModal("Instagram");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Youtube}
                              alt="Youtube"
                              onClick={() => {
                                openModal("Youtube");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Bing}
                              alt="Bing"
                              onClick={() => {
                                openModal("Bing");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Linkdln}
                              alt="Linkdln"
                              onClick={() => {
                                openModal("Linkdln");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={GoogleAds}
                              alt="Google Ads"
                              onClick={() => {
                                openModal("Google Ads");
                                handleOpenModal();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Add your images here */}
                      <div className="col-12">
                        <div className="row boxShadow">
                          <div
                            className="col-12 content-section"
                            id="E-commerce"
                          >
                            <h4>E-commerce</h4>
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={Shopify}
                              alt="Shopify"
                              onClick={() => {
                                openModal("Shopify");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={BigCommerce}
                              alt="Big Commerce"
                              onClick={() => {
                                openModal("Big Commerce");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={WooCommerce}
                              alt="Woo Commerce"
                              onClick={() => {
                                openModal("Woo Commerce");
                                handleOpenModal();
                              }}
                            />
                          </div>
                          <div className="col-xl-2 col-lg-3 col-4">
                            <img
                              className="img-fluid"
                              src={ShipStation}
                              alt="Ship Station"
                              onClick={() => {
                                openModal("Ship Station");
                                handleOpenModal();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row boxShadow">
                          <div className="col-12" id="SocialMedia">
                            <h4>Social Media</h4>
                          </div>
                        </div>
                      </div>

                      {/* Content 5 */}
                      {/* Add your images here */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const ConnectServer = ({ showModal, handleCloseModal, imageName }) => {

  const [hideModal, setShowModal] = useState(false);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const selectedValue = !dropdownClicked ? imageName:formData.databaseType;
  console.log(selectedValue,"JJJJHHJ")
  const lresponse = GetCookie('loginResponsecode');

  const parserOrgId = JSON.parse(lresponse)
  const orgId = parserOrgId?.organizationId
  const date = new Date().getTime();

  console.log(hideModal)
  //use formik
  const [formData, setFormData] = useState({
    host: "",
    databaseType: imageName,
    port: "",
    userName: "",
    password: "",
    databaseName: "",
    warehouse:"analytics_wh",
    schemaName:"CSV",
    createdDate:date,
    organization:{
      id:orgId
  }
  });

  const validationSchema = Yup.object({
    host: Yup.string().required("Host Name Required"),
    port: Yup.string().required("Port Required"),
    userName: Yup.string().required("UserName Required"),
    password: Yup.string().required("Password is Required"),
    databaseName: Yup.string().required("Database Name Required"),
  });

  const fetchData = async (body) => {
    const userAuthToken = GetCookie('token');
    console.log(userAuthToken,"sajiofisdjf")
    try {
      const response = await fetch(
        `https://server.vivrn.com:8080/analytics/api/dataSourceManager/`,
        {
          method: "POST", // Change to POST
          headers: {
            "Content-Type": "application/json",
            "authToken": userAuthToken,
          },
          body: JSON.stringify(body), // Move body here
        }
      );
      if (response) {
        const result = await response.json();
        console.log(result)
        // Handle the result as needed
      } else {
        console.error("Request failed. Please try again.");
      }
    } catch (error) {
      console.error(error, "Please Try Again");
    }
  };

  const onSubmit = (values) => {
    // Handle form submission logic here
    console.log("Form submitted with values:", values);
  
   const payload={
    "host" : "ds81299.ap-southeast-1.snowflakecomputing.com",
    "port" : 443,
    "username" : "animesh9779",
    "password": "Passkey@9779",
         "dataSourceType": "MYSQL",
    "createdDate":1687705954,
    "databaseName" : "VIVRN",
    "warehouse" : "analytics_wh",
    "schemaName" : "CSV",
    "organization":{
         "id":1
     }
 }
   fetchData(payload)
    // Close the modal
    handleCloseModal();

  };
 
  const formik = useFormik({
    initialValues: {
      host: "",
      databaseType: selectedValue,
      port: "",
      userName: "",
      password: "",
      databaseName: "",
      warehouse:"analytics_wh",
      schemaName:"CSV",
      createdDate:date,
      organization:{
        id:orgId
    }
    },
    validationSchema,
    onSubmit,
  });

  // const handleProfileClick = () => {
  //   setShowModal(true);
  // };

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedValue = dropdownClicked ? formData.databaseType : imageName;
    setFormData((prevData) => ({
      cloudServiceName: "",
      databaseType: selectedValue,
      port: "",
      userName: "",
      password: "",
      databaseName: "",
      position: "",
    }));
    console.log(formData, "sdjgdfgs");
    // Close the modal
    setShowModal(false);
  };


  const handleDropdownClick = () => {
    setDropdownClicked(true);
  };
  const handleClosedropdown = () => {
    setDropdownClicked(false);
  };
  return (
    <div>
      {/* <div className="profile-icon" onClick={handleProfileClick}>
        <img></img>
      </div> */}
      <Modal
        show={showModal}
        onHide={() => {
          handleCloseModal();
          handleClosedropdown();
        }}
        centered
      >
        <Modal.Header className="set-title" closeButton>
          <Modal.Title>Step1:Connect to Datasource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-12 mt-2">
                <label htmlFor="host">
                  Host Name <span className="red-asterisk">*</span>
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    formik.touched.host && formik.errors.host
                      ? "red-border"
                      : ""
                  }`}
                  id="host"
                  name="host"
                  value={formik.values.host}
                  onChange={formik.handleChange}
                />
                {formik.touched.host && formik.errors.host ? (
                  <div className="error">{formik.errors.host}</div>
                ) : null}
              </div>
              <div className="form-group   mt-2">
                <label htmlFor="databaseType">Database Type:</label>
                <select
                  id="databaseType"
                  name="databaseType"
                  value={formik.values.databaseType}
                  onChange={formik.handleChange}
                >
                  {!dropdownClicked ? (
                    <option value={imageName}>{imageName}</option>
                  ) : (
                    dataWireName.map((data) => (
                      <option key={data.id} value={data.name}>
                        {data.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="form-group col-md-12  mt-2">
                <label htmlFor="port">Port <span className="red-asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  id="port"
                  name="port"
                  value={formik.values.port}
                  onChange={formik.handleChange}
                />
                {formik.touched.port && formik.errors.port ? (
                  <div className="error">{formik.errors.port}</div>
                ) : null}
              </div>
            </div>
            <div className="form-group col-md-12  mt-2">
              <label htmlFor="userName">
                User Name <span className="red-asterisk">*</span>
              </label>
              <div className="input-group">
                <input
                  type="name"
                  className="form-control"
                  id="userName"
                  name="userName"
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                />
                {formik.touched.userName && formik.errors.userName ? (
                  <div className="error">{formik.errors.userName}</div>
                ) : null}
                <div className="input-group-append">
                  {/* <span className="input-group-text">
                    <i className="fa-solid fa-envelope"></i>
                  </span> */}
                </div>
              </div>
            </div>
            <div className="form-group col-md-12  mt-2">
              <label htmlFor="password">
                Password <span className="red-asterisk">*</span>
              </label>
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
                <div className="input-group-append">
                </div>
              </div>
            </div>
            <div className="form-group col-md-12  mt-2">
              <label htmlFor="databaseName">
                Database Name <span className="red-asterisk">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="databaseName"
                name="databaseName"
                value={formik.values.databaseName}
                onChange={formik.handleChange}
              />
              {formik.touched.databaseName && formik.errors.databaseName ? (
                <div className="error">{formik.errors.databaseName}</div>
              ) : null}
            </div>
            <div className="col-md-12 mt-3">
              <Button variant="primary" type="submit" className="ms-2">
                Submit
              </Button>
              <Button
                variant="gradient"
                type="cancel"
                className="CancelButton btn btn-secondary ms-2"
              >
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};
