import React, { useEffect, useState } from "react";
import { table_preview } from "../../../constants/pictures/Pictures";
import "./SingleTable.css";

function SingleColumnTable({ data, updateSelectedValue, firstTableName }) {
  const [selectedValue, setSelectedValue] = useState(firstTableName);
  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    updateSelectedValue(selectedValue ? selectedValue : firstTableName);
  }, [selectedValue, firstTableName, updateSelectedValue]);

  useEffect(() => {
    localStorage.setItem("checkedTable", JSON.stringify(checkedValues));
    console.log("Checked tables:", checkedValues);
}, [checkedValues]);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setCheckedValues((prevCheckedValues) =>
      prevCheckedValues.includes(value)
        ? prevCheckedValues.filter((item) => item !== value)
        : [...prevCheckedValues, value]
    );
  };

  return (
    <>
      <div className="LeftSideTable">
        {data?.map((item, index) => (
          <div key={index} className="TableItem">
            <label
              className={`switch-field TableContainerSwitch ${
                selectedValue === item ? "checked" : ""
              }`}
              htmlFor={`radio${index}`}
            >
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  value={item}
                  className="uk-checkbox me-2"
                  checked={checkedValues.includes(item)}
                  onChange={handleCheckboxChange}
                />
                <img
                  src={table_preview}
                  className="tabelRadioImage"
                  alt="DATE"
                />
                <input
                  className="uk-radio"
                  id={`radio${index}`}
                  type="radio"
                  value={item}
                  name="radio"
                  checked={selectedValue === item}
                  onChange={handleRadioChange}
                />
                <p className="mb-0 ms-1 ItemRendering">{item}</p>
              </div>
            </label>
          </div>
        ))}
      </div>
    </>
  );
}

export default SingleColumnTable;
