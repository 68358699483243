import React from "react";
import { Link } from "react-router-dom";
import "./NewPassword.css";
import * as yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
export default function NewPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConfirmPass(!showConfirmPass);
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmpassword: "",
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .min(7, "Password must be at least 7 characters")
        .required("Please enter your password"),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Please confirm your password"),
    }),
    onSubmit: (values, { resetForm }) => {
      resetForm();
      console.log(values);
    },
  });
  return (
    <div className="logIn">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12">
            <div className="ForgotPasswordContainer">
              <div className="LogoLogin">
                <div className="ForgotPass">
                  <div className="arrow-corner">
                    <Link to="/login" className="anchor-line-remove">
                      <i className="fa-solid fa-chevron-left"></i>
                    </Link>
                  </div>
                  <div>
                    <h1 className="advertise">New Password</h1>
                  </div>
                </div>
                <form id="user-form" onSubmit={formik.handleSubmit}>
                  <div className="FormLogIn">
                    <div className="emailAdressForgot">
                      <div className="UserIcon">
                        <i className="fa-solid fa-lock"></i>
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Enter new password"
                        {...formik.getFieldProps("password")}
                      />
                      {formik.touched.password && formik.errors.password ? (
                        <p
                          style={{
                            color: "#ff0000ab",
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.password}
                        </p>
                      ) : null}
                      <div
                        className="ShowPassword"
                        onClick={handleTogglePassword}
                      >
                        <i
                          className={
                            showPassword
                              ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                        ></i>
                      </div>
                    </div>
                    <div className="emailAdressForgot">
                      <div className="UserIcon">
                        <i className="fa-solid fa-lock"></i>
                      </div>
                      <input
                        type={showConfirmPass ? "text" : "password"}
                        name="confirmpassword"
                        placeholder="Confirm new password"
                        {...formik.getFieldProps("confirmpassword")}
                      />
                      {formik.touched.confirmpassword &&
                      formik.errors.confirmpassword ? (
                        <p
                          style={{
                            color: "#ff0000ab",
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.confirmpassword}
                        </p>
                      ) : null}
                      <div
                        className="ShowPassword"
                        onClick={handleToggleConfirmPassword}
                      >
                        <i
                          className={
                            showConfirmPass
                              ? "fa-regular fa-eye"
                              : "fa-regular fa-eye-slash"
                          }
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="btnSubmitNew">
                    <button className="submitBtn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
