import React, { useState, useEffect } from "react";
import { excludedKeys } from "../../../dtos/MyContent.type";
import FileUploadButton from "./fileUploadButton/FileUploadButton";
import RefreshButton from "./refreshButton/RefreshButton";
import ViewToggleButton from "./viewToggleButton/ViewToggleButton";
import MoreInfoDropdown from "./moreInfoDropdown/MoreInfoDropdown";
import TableViewComponent from "./myContentTableView/TableView.component";
import Swal from "sweetalert2";
import "./Mycontent.css";
import GetDataSetForMyContentApi from "../../../service/api/GetDataSetForMyContentApi";
import ReactPaginate from "react-paginate";
import GetTotalCountMyContent from "../../../service/api/GetTotalCountMyContentApi";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import { getApiUrl } from "../../../service/url/UrlConfig";
import { apiUrls } from "../../../service/endPoints/ApiUrls";

export default function Mycontent() {
  const [uploadFiles, setUploadedFiles] = useState([]);
  const [keys, setKeys] = useState([]);
  const [showCardContainer, setShowCardContainer] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 12;
  const [totalReportData, setTotalReportData] = useState("");
  const totalPages = Math.ceil(totalReportData / limit);
  const [afterDeleteRender, setAfterDeleteRender] = useState(false);
  const [allValue, setAllValue] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const fetchGetAllReportApi = async (value) => {
    try {
      const result = await GetDataSetForMyContentApi(value);
      if (result && result.response) {
        setUploadedFiles(result.response);
      } else {
        console.error("API response is undefined or null.");
      }
      setAfterDeleteRender(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchGetAllReportApi(offset);
  }, [offset, allValue, afterDeleteRender]);

  useEffect(() => {
    if (Array.isArray(uploadFiles) && uploadFiles.length > 0) {
      const allKeys = Object?.keys(uploadFiles[0]);
      const filteredKeys = allKeys.filter((key) => !excludedKeys.includes(key));
      setKeys(filteredKeys);
    }
  }, [uploadFiles]);

  const toggleComponent = () => {
    setShowCardContainer(true);
  };

  const toggleComponentCard = () => {
    setShowCardContainer(false);
  };
  const handleRefresh = () => {
    fetchGetAllReportApi();
    Swal.fire("Data Refreshed!", "Table data has been updated.", "success");
  };

  const handleDelete = (id, index) => {
    const userAuthToken = GetCookie("token");
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "NextButton",
        cancelButton: "CancelButton",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(getApiUrl() + apiUrls.dataSet.trashDataset(id), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authToken: userAuthToken,
          },
        })
          .then((response) => {
            if (response.ok) {
              // Successful deletion
              const updatedData = [...filteredData];
              updatedData.splice(index, 1);
              setUploadedFiles(updatedData);
              setAfterDeleteRender(true);
              Swal.fire("Deleted!", "The content has been deleted.", "success");
            } else {
              // Handle errors here, e.g., display an error message
              Swal.fire("Error", "Failed to delete the content.", "error");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            Swal.fire(
              "Error",
              "An error occurred while deleting the content.",
              "error"
            );
          });
      }
    });
  };
  const handleMouseEnter = () => {
    setIsInfoVisible(true);
  };

  const handleMouseLeave = () => {
    setIsInfoVisible(false);
  };

  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    filesArray.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const csvData = reader.result;
        const parsedData = convertCsvToJson(csvData);
        // Add date property to each object in JSON data
        const date = new Date().toISOString();
        parsedData.forEach((obj) => {
          obj.date = date;
          obj.fileName = file.name;
        });
      };
      reader.readAsText(file);
    });
  };

  const convertCsvToJson = (csvData) => {
    const lines = csvData.split("\n");
    const headers = lines[0].split(",");
    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");
      if (currentLine.length === headers.length) {
        const obj = {
          fileName: currentLine[0].trim(),
          date: currentLine[1].trim(),
        };
        jsonData.push(obj);
      }
    }
    return jsonData;
  };
  const handlePageChange = ({ selected }) => {
    const newOffset = selected * limit;
    setOffset(newOffset);
  };
  const fetchCountMyCountentApi = async () => {
    try {
      const data = await GetTotalCountMyContent();
      if (data && data.response !== undefined && data.response !== null) {
        setTotalReportData(data.response);
      } else {
        console.log("data not found");
      }
    } catch (error) {
      console.error("Error fetching report count:", error);
    }
  };

  useEffect(() => {
    fetchCountMyCountentApi();
  }, []);

  return (
    <div className="row">
      <div className="d-flex justify-content-end">
        <FileUploadButton onChange={handleFileChange} />
        <RefreshButton onClick={handleRefresh} />
        <ViewToggleButton
          onCardView={toggleComponent}
          onTableView={toggleComponentCard}
        />
      </div>
      {uploadFiles.length > 0 ? (
        showCardContainer ? (
          <div className="CardContainer">
            <div className="col-md-12">
              <div className="row">
                {uploadFiles?.map((item, index) => (
                  <div key={item.id} className="col-xl-3 col-lg-4 col-sm-6">
                    <div className="Customcard">
                      <div className="card-body">
                        <div className="upload-data">
                          <MoreInfoDropdown
                            item={item}
                            onDelete={() => handleDelete(item.id, index)}
                            onMoreInfo={() => handleMouseEnter(index)}
                            isInfoVisible={isInfoVisible}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={() => handleMouseLeave(index)}
                          />
                          <p className="Analyticsupload-set">{item.baseName}</p>
                        </div>
                        <p className="card-text">
                          Upload data from excel csv, Html, xml, Json
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <TableViewComponent
            isInfoVisible={isInfoVisible}
            uploadFiles={uploadFiles}
            keys={keys}
            handleDelete={handleDelete}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
          />
        )
      ) : (
        <div className="row mt-2">
          <div className="col-md-12 d-flex justify-content-center">
            Data not found.
          </div>
        </div>
      )}
      {uploadFiles.length > 0 && (
        <div className="CusPagination mt-5">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={totalPages}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </div>
  );
}
