import React, { useState } from "react";
import StackedBarPercentageDoubleMain from "./StackedBarPercentageDoubleMain";
import StackedBarPercentageDouble from "./StackedBarPercentageDouble";

const StackedBarPercentageDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? (
        <StackedBarPercentageDoubleMain responseData={responseData} />
      ) : (
        <StackedBarPercentageDouble responseData={responseData} />
      )}
    </div>
  );
};

export default StackedBarPercentageDoubleToggle;
