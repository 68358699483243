import React, { useState } from "react";
import DonutDoubleMain from "./DonutDoubleMain";
import DonutDouble from "./DonutDouble";

const DonutDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? (
        <DonutDoubleMain responseData={responseData} />
      ) : (
        <DonutDouble responseData={responseData} />
      )}
    </div>
  );
};

export default DonutDoubleToggle;
