import React from "react";
import TrashTableRow from "../trashTableRow/TrashTableRow.component";

const TrashTable = ({
  tableHeaders,
  filteredData,
  handleCheckboxChange,
  selectAll,
  handleSelectAll,
  checkboxItems,
  setSelectAll,
  setIndeterminate,
}) => {
  return (
    <div className="row trash-set">
      <div className="col-md-12">
        <table className="table tableCustomTrash set-table">
          {tableHeaders && tableHeaders.length > 0 && (
            <thead>
              <tr>
                <th className="table-color-trash">
                  <input
                    className="me-2"
                    type="checkbox"
                    name="select-header"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                  Select All
                </th>
                <th className="table-color-trash">Id</th>
                {tableHeaders.map((header) => (
                  <th className="set-capital table-color-trash" key={header}>
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            <TrashTableRow
              filteredData={filteredData}
              handleCheckboxChange={handleCheckboxChange}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              checkboxItems={checkboxItems}
              setSelectAll={setSelectAll}
              setIndeterminate={setIndeterminate}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrashTable;
