import React from "react";
import Layout from "../../component/layout/Layout";

import DynamicalTable from "../../component/table/dynamicTable/DynamicalTable";

export default function Recent(){
    localStorage.removeItem('previousData');

return(
    <Layout>
    <div className="AnalyticsContainer">
        <div className="container-fluid">
    <h1>Comming Soon</h1>
        </div>
    </div>
</Layout>
   
)
}