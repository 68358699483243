import Swal from "sweetalert2";
import { BASE_URL } from "../endPoints/Endpoint";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";

const WelcomePromptApi = async (payload) => {
 const token = GetCookie("token");
  try {
    const response = await fetch(
      `${BASE_URL}/api/q2g/generate/welcomePrompt`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authToken": token
        },
        body: payload,
      }
    );

    const result = await response.json();
    if (result.httpStatusCode=='200') {
      const welcomePromtData = result.response;
      
      return welcomePromtData;
    } else {
      return null;
    }
  } catch (error) {
    console.error(error, "Please Try Again");
    return null;
  }
};

export default WelcomePromptApi;
