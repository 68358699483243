// actions.js
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOADED_DATA='UPLOADED_DATA';
export const SAVE_RESULT='SAVE_RESULT';
export const CHART_DATA='CHART_DATA';
export const ADD_DATA='ADD_DATA';

// Upload file method
export function uploadFile(file) {
  return {
    type: UPLOAD_FILE,
    payload: file,
  };
}
export function uploadedData(data) {
  return {
    type: UPLOADED_DATA,
    payload: data,
  };
}

// Define an action creator
export const saveResult = (result) => {
  return {
    type: SAVE_RESULT,
    payload: result,
  };
}

// CHART CREATED DATA
export const chartData = (result) => {
  return {
    type: CHART_DATA,
    payload: result,
  };
}

//drag drop
// src/actions/dataActions.js
export const addData = (item) => {
  return {
    type: ADD_DATA,
    payload: item,
  };
};
