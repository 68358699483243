import React, { useState } from "react";
import { enumDataType } from "../../../enums/enum";
import {
  datatypeNumber,
  datatypeString,
  dateDataType,
  datePng,
} from "../../../constants/pictures/Pictures";

const DataTypeDropDown = ({ sendDataType, onChangeDataType }) => {
  let iconSrc, altText;
  if (
    sendDataType === enumDataType.NUMBER ||
    sendDataType === enumDataType.DECIMAL
  ) {
    iconSrc = datatypeNumber;
    altText = "Icon representing a numeric datatype";
  } else if (
    sendDataType === enumDataType.DATE ||
    sendDataType === enumDataType.DATETIME ||
    sendDataType === enumDataType.TIME
  ) {
    iconSrc = datePng;
    altText = "Icon representing a date datatype";
  } else if (sendDataType === enumDataType.TEXT) {
    iconSrc = datatypeString;
    altText = "Icon representing a text datatype";
  }

  const [dataTypes, setDataTypes] = useState([
    {
      type: enumDataType.NUMBER,
      src: datatypeNumber,
      alt: "Icon representing a numeric datatype",
    },
    {
      type: enumDataType.DECIMAL,
      src: datatypeNumber,
      alt: "Icon representing a numeric datatype",
    },
    {
      type: enumDataType.DATE,
      src: datePng,
      alt: "Icon representing a date datatype",
    },
    {
      type: enumDataType.DATETIME,
      src: datePng,
      alt: "Icon representing a date datatype",
    },
    {
      type: enumDataType.TIME,
      src: datePng,
      alt: "Icon representing a date datatype",
    },
    {
      type: enumDataType.TEXT,
      src: datatypeString,
      alt: "Icon representing a text datatype",
    },
  ]);

  const handleDataTypeChange = (type) => {
    onChangeDataType(type);
  };

  return (
    <div className="headingth">
      <div className="dropdown">
        <div data-bs-toggle="dropdown" id="shareDropdown">
          {iconSrc && (
            <img
              src={iconSrc}
              className="ms-1"
              alt={altText}
              width={20}
              height={20}
            />
          )}
        </div>
        <ul className="dropdown-menu" aria-labelledby="shareDropdown">
          {dataTypes.map((dataType, index) => (
            <li key={index} onClick={() => handleDataTypeChange(dataType.type)}>
              <img
                src={dataType.src}
                className="ms-1"
                alt={dataType.alt}
                width={30}
                height={30}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DataTypeDropDown;
