import React from "react";
import {
  datatypeNumber,
  datatypeString,
  dateDataType,
  sortPng,
} from "../../../constants/pictures/Pictures";
import { enumDataType } from "../../../enums/enum";
import withSort from "./with.sort";

function SmartTable({ data, handleSort }) {
  const { records, columnSchemaList } = data || {};

  return (
    <>
      <div className="overflow-auto maxHeightOverflow">
        <div className="d-flex justify-content-end">
          <i className="fa-solid fa-rotate cursor-pointer"></i>
          <i className="fa-solid fa-arrow-down cursor-pointer"></i>
        </div>
        {columnSchemaList && columnSchemaList.length > 0 ? (
          <table className="custom-table">
            <thead>
              <tr>
                {columnSchemaList.map((column, index) => (
                  <th key={index}>
                    <div className="headingth">
                      {column.columnName}
                      {column.displayType === enumDataType.NUMBER ? (
                        <img
                          src={datatypeNumber}
                          className="ms-1"
                          alt="Number"
                        />
                      ) : column.displayType === enumDataType.TEXT ? (
                        <img
                          src={datatypeString}
                          className="ms-1"
                          alt="String"
                        />
                      ) : (
                        <img src={dateDataType} className="ms-1" alt="DATE" />
                      )}

                      <div className="dropdown">
                        <div
                          data-bs-toggle="dropdown"
                          id={`shareDropdown${index}`}
                        >
                          <img src={sortPng} className="ms-1" alt="DATE" />
                        </div>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby={`shareDropdown${index}`}
                        >
                          <li onClick={() => handleSort(column.columnName)}>
                            Ascending
                          </li>
                          <li onClick={() => handleSort(column.columnName)}>
                            Descending
                          </li>
                        </ul>
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {records.map((record, rowIndex) => (
                <tr key={rowIndex}>
                  {record.map((value, columnIndex) => (
                    <td key={columnIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>data not found</p>
        )}
      </div>
    </>
  );
}

export default withSort(SmartTable);
