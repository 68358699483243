import React, { useEffect, useState, useRef, useCallback } from "react";
import * as Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import exportingInit from "highcharts/modules/exporting";
import exportDataInit from "highcharts/modules/export-data";
import { focusModeClassData, resetfocusModeClassData } from "../../../redux/reduxToolkit/Reducer";
import { useDispatch } from "react-redux";
import { icons } from "../../../constants/icons/Icons";
import EditTitleModal from "../../../component/modal/graphTitle/TitleModal";

// Initialize modules
exportingInit(Highcharts);
exportDataInit(Highcharts);

export default function ClusteredColumnSingle(props) {
  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(props?.responseData?.data);
  const [getFocus, setGetFocus] = useState(false);
  const [chartTitle, setChartTitle] = useState(props?.responseData?.name ? props?.responseData?.name : "Untitled");
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setFetchData(props?.responseData?.data);
  }, [props?.responseData?.data]);

  useEffect(() => {
    if (getFocus) {
      dispatch(focusModeClassData(props.responseData));
    } else {
      dispatch(resetfocusModeClassData());
    }
  }, [getFocus, dispatch, props.responseData]);

  const toggleFocusMode = useCallback(() => {
    setGetFocus((prev) => !prev);
  }, []);

  const handleTitleClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const saveTitle = (newTitle) => {
    setChartTitle(newTitle);
  };

  const categories = fetchData?.map((d) => d.sgb) || [];
  const subcategories = fetchData && fetchData.length > 0 ? Object.keys(fetchData[0].metric) : [];
  const seriesData = subcategories.map((subcategory, i) => ({
    name: subcategory,
    data: fetchData.map((d) => d.metric[subcategory]),
    colorByPoint: true,
    color: Highcharts.getOptions().colors[i % 10], // Using Highcharts color options here
  }));

  const chartOptions = {
    chart: {
      type: "column",
      height: "100%",
      events: {
        render: function () {
          const scrollbars = document.querySelectorAll('.highcharts-scrollbar');
          scrollbars.forEach((scrollbar) => {
            scrollbar.classList.add('no-drag');
          });
        },
      },
    },
    xAxis: {
      categories: categories,
      min: 0,
      max: 9, // Adjust this based on the number of categories you want to show initially
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "Values",
      },
    },
    legend: {
      enabled: true,
    },
    series: seriesData,
    tooltip: {
      shared: true,
    },
    credits: {
      enabled: false,
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "viewFullscreen",
            "printChart",
            "separator",
            "downloadPNG",
            "downloadJPEG",
            "downloadPDF",
            "downloadSVG",
            "customItem",
          ],
          menuItemDefinitions: {
            customItem: {
              text: getFocus
                ? '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>'
                : '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              useHTML: true,
            },
          },
        },
        customButton: {
          text: getFocus
            ? '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>'
            : '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
          onclick: toggleFocusMode,
          align: "right",
          verticalAlign: "top",
          x: -30,
          y: 5,
          useHTML: true,
        },
      },
    },
    title: {
      text: `<div class="chart-title">${chartTitle}</div>`,
      align: "left",
      x: 10,
      useHTML: true,
    },
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.update({
        title: {
          text: `<div class="chart-title">${chartTitle}</div>`,
        },
        xAxis: {
          categories: categories,
        },
        series: seriesData,
      }, true, true);
    }
  }, [chartTitle, fetchData, categories, seriesData]);

  return (
    <div style={{ position: "relative" }}>
      <div
        className="editTitleBox"
        onClick={handleTitleClick}
      >
        <img src={icons.widgetEdit} alt="edit" />
      </div>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
        ref={chartRef}
      />
      <EditTitleModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        chartTitle={chartTitle}
        saveTitle={saveTitle}
      />
    </div>
  );
}
