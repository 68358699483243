import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./TrashTab.css";
import TrashTable from "../trashTable/TrashTable.component";
import MyContentTrashPage from "../mycontentTrash/MyContentTrash";
import TeamTrashPage from "../teamContentForTrash/teamContentTrash";


export default function TrashTabSection({
  tableHeaders,
  filteredData,
  handleCheckboxChange,
  selectAll,
  handleSelectAll,
  checkboxItems,
  setSelectAll,
  setIndeterminate,
}) {
  return (
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <Tabs className="CustomTabsFile ">
            <Tab eventKey="report" title="Report Table">
            <TrashTable
            tableHeaders={tableHeaders}
            filteredData={filteredData}
            handleCheckboxChange={handleCheckboxChange}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            checkboxItems={checkboxItems}
            setSelectAll={setSelectAll}
            setIndeterminate={setIndeterminate}
          />
            </Tab>
            <Tab eventKey="myContent" title="MyContent Table">
            <MyContentTrashPage/>
            </Tab>
            <Tab eventKey="teamContent" title="Team content">
              <TeamTrashPage/>
            </Tab>
          </Tabs>
        </div>
      </div>
  );
}
