import React, { useState, useEffect } from "react";
import GetDataSetOnTrash from "../../../service/api/ContentApi";
import { API_URL, OFFSET } from "../../../dtos/TrashType";
import TrashTable from "../trashTable/TrashTable.component";
import TrashTabSection from "../tab/TrashTabSection";

function MyContentTrashPage() {
  const [selectAll, setSelectAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [allReportData, setAllReportData] = useState([]);
  const [checkboxItems, setCheckboxItems] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const fetchGetAllReportApi = async () => {
    try {
      const myContentDataSet = await GetDataSetOnTrash();
      if (myContentDataSet && myContentDataSet.response) {
        setAllReportData(myContentDataSet.response);
        console.log(myContentDataSet.response+"filteredData from my content")
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  
  useEffect(() => {
    fetchGetAllReportApi();
  }, [OFFSET]);
  const handleSelectAll = () => {
    const updatedCheckboxItems = filteredData.map((item) => ({
      ...item,
      checked: !selectAll,
    }));
    setCheckboxItems(updatedCheckboxItems);
    setSelectAll(!selectAll);
    setIndeterminate(false);

    // Update the selectedIds based on the new checked state
    const selectedIdsArray = !selectAll
      ? updatedCheckboxItems.map((item) => item.id)
      : [];
    setSelectedIds(selectedIdsArray);
  };

  useEffect(() => {
    const filtered = allReportData?.filter((item) =>
      item.title && item.title.toLowerCase().includes(search.toLowerCase())
    );
    setFilteredData(filtered);
  }, [search, allReportData]);

  useEffect(() => {
    if (allReportData.length > 0) {
      const keys = Object.keys(allReportData[0])
      console.log(keys+"keys")
      setTableHeaders(keys);
    }
  }, [checkboxItems, allReportData]);

  const handleCheckboxChange = (event, id) => {
    const updatedCheckboxItems = filteredData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          checked: event.target.checked,
        };
      }
      return item;
    });
    setCheckboxItems(updatedCheckboxItems);

    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedIds([...selectedIds, id]);
    } else {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    }

    const allChecked = updatedCheckboxItems.every((item) => item.checked);
    const someChecked = updatedCheckboxItems.some((item) => item.checked);

    setSelectAll(allChecked);
    setIndeterminate(someChecked && !allChecked);
  };

  return (
    <TrashTable
    tableHeaders={tableHeaders}
    filteredData={filteredData}
    handleCheckboxChange={handleCheckboxChange}
    selectAll={selectAll}
    handleSelectAll={handleSelectAll}
    checkboxItems={checkboxItems}
    setSelectAll={setSelectAll}
    setIndeterminate={setIndeterminate}
  />
  );
}

export default MyContentTrashPage;
