import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import GetDataSorceTable from "../../../service/api/GetDataSorceTable";
import getWorkspaceId from "../../../util/AttributeUtil";

const TableContent = () => {
  const [dataSorceTableData, setDataSorceTableData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const workspaceId = await getWorkspaceId();
        const data = await GetDataSorceTable(workspaceId);
        setDataSorceTableData(data.response || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          {dataSorceTableData.length > 0 ? (
            <Accordion>
              {dataSorceTableData.map((e, i) => (
                <Accordion.Item key={i} eventKey={i.toString()}>
                  <Accordion.Header>
                    {e?.dataSourceDTO?.databaseName}
                  </Accordion.Header>
                  <Accordion.Body>
                    {e?.logicalResourceDTOS.map((item, j) => (
                      <p key={j}>{item.label}</p>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <p>Data not found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableContent;
