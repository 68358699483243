
import { getApiUrl } from "../url/UrlConfig";
import { apiUrls } from "../endPoints/ApiUrls";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import RemoveCookie from "../../persistence/cookies/removeCookie/RemoveCookie";

const fetchLogicalAttributes = async (userId) => {
  // RemoveCookie("newUploadTableData");
    try {
      const userAuthToken = GetCookie('token');
      const response = await fetch(
        getApiUrl()+apiUrls.logicalAttribute.getlogicalAttribute(userId),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authToken: userAuthToken,
          },
        }
      );
      const data = await response.json();
      
      // Check if data is null
      if (data === null) {
        console.warn("Received null data from the server.");
        return [];
      }
      return data.response;
    } catch (error) {
      console.error("Failed to fetch logical attributes:", error);
      return [];
    }
  };

  export default fetchLogicalAttributes