import React from "react";
import { LogoVivrnWhite } from "../../constants/pictures/Pictures";
import { Link } from "react-router-dom";

export default function HeaderSignUp() {
    return (
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link to="/">
            <img src={LogoVivrnWhite} alt="logo" className="LogoCustom" />
          </Link>
          <div className="NavSearch">
            <ul className="navbaricons">
              <Link to="/login">
              <button className="landingSecondaryBtn">LogIn</button>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
  