import React from 'react';
import SignUpNavbar from './SignUpNavbar';
import { gmailIcon, outlookIcon } from '../../../constants/pictures/Pictures';
import { useNavigate } from 'react-router';

const VerifyEmailToActivate = () => {
    const navigate = useNavigate();
    const signUPTodayData = localStorage.getItem("sign-up-today");
    const parsedSignUpTodayData = JSON.parse(signUPTodayData);
    const emailIdData = parsedSignUpTodayData?.email
    console.log(emailIdData,"emailIdData");
    const handleReEnter = () => {
        navigate('/Sign-up-today');
    }
    return (
        <div className="container-fluid backgroundColorBody p-0">
            <SignUpNavbar />
            <div className="row w-100">
                <div className="col-md-12">
                    <div className="SignUpRight">
                        <h3>Check your email</h3>
                        <p className="subHeading">We’ve sent an email to {emailIdData} with a link to activate your account</p>
                        <div className="EmailContainer">
                            <div className='d-flex align-items-center EmailBox'>
                                <img src={gmailIcon} className='img-fluid' />
                                <a href="https://mail.google.com" target="_blank" rel="noopener noreferrer">Open Gmail</a>
                            </div>
                            <div className='d-flex align-items-center EmailBox'>
                                <img src={outlookIcon} className='img-fluid' />
                                <a href='https://www.microsoft.com/en-us/microsoft-365/outlook/log-in' target="_blank" rel="noopener noreferrer">Open Outlook</a>
                            </div>


                        </div>
                        <div className='reEnteremail'>
                            <h3>Didn’t get an email? Check your spam folder!</h3>
                            <p onClick={handleReEnter}>Re-enter your email and try again</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmailToActivate;