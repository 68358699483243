import React, { useState, useEffect } from "react";
import { Accordion, Form } from 'react-bootstrap';
import { enumDataType } from "../../../enums/enum";
import { datatypeNumber, datatypeString, datePng } from "../../../constants/pictures/Pictures";

const ColumnPreview = ({
  filteredKeysArray,
  checkedKeys,
  handleCheckboxChange,
  handleDragStart,
  selectAllChecked,
  handleSelectAll,
  selectedTable
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedKeys, setExpandedKeys] = useState({});
  const [dateChecked, setDateChecked] = useState({});
  const [selectedDateOption, setSelectedDateOption] = useState({});

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleToggleExpand = (key) => {
    setExpandedKeys((prev) => ({ ...prev, [key.id]: !prev[key.id] }));
  };

  const columnChecked = (event, key) => {
    if (key.logicalDataType === "DATE") {
      const { checked } = event.target;
      setDateChecked((prev) => ({ ...prev, [key.id]: checked }));
      if (!checked) {
        setExpandedKeys((prev) => ({ ...prev, [key.id]: false }));
        setSelectedDateOption((prev) => {
          const newState = { ...prev };
          delete newState[key.id];
          return newState;
        });
      }
    }
  };

  const handleDateOptionChange = (event, parentKey, child) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedDateOption((prev) => ({ ...prev, [parentKey.id]: child.logicalDataType }));
      handleCheckboxChange(event, child.label, child.logicalDataType, parentKey.id);
    }
    console.log('Checked:', checkedKeys);
    console.log('Date Option Checked:', { [parentKey.id]: child.logicalDataType });
  };

  const filterSelectedColumn = filteredKeysArray.filter((key) =>
    key.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const typeDATE = [
    { label: "DAY", logicalDataType: "DAY" },
    { label: "MONTH", logicalDataType: "MONTH" },
    { label: "YEAR", logicalDataType: "YEAR" }
  ];

  const mergedArray = filterSelectedColumn.map((obj) => {
    if (obj.logicalDataType === "DATE") {
      return {
        ...obj,
        children: typeDATE.map((type) => ({ ...type, parentId: obj.id }))
      };
    }
    return obj;
  });

  const renderCheckbox = (key) => (
    <Form.Check
      type="checkbox"
      key={`${key.id}_${key.logicalDataType}`}
      checked={checkedKeys.some(
        (checkedKey) =>
          checkedKey.label === key.label &&
          checkedKey.logicalDataType === key.logicalDataType
      )}
      onChange={(event) => {
        handleCheckboxChange(event, key.label, key.logicalDataType, key.id);
        columnChecked(event, key);
        console.log('Checked:', checkedKeys);
      }}
      value={`${key.label}_${key.logicalDataType}`}
      label={
        <span
          draggable
          onDragStart={(e) => handleDragStart(e, key)}
        >
          {key.logicalDataType === enumDataType.Long ||
            key.logicalDataType === enumDataType.NUMBER ||
            key.logicalDataType === enumDataType.DECIMAL ? (
            <>
              <img src={datatypeNumber} className="ms-1" alt="Number" />
              <span className="ms-2 label-text-transform">{key.label}</span>
            </>
          ) : key.logicalDataType === enumDataType.String ||
            key.logicalDataType === enumDataType.TEXT ? (
            <>
              <img src={datatypeString} className="ms-1" alt="String" />
              <span className="ms-2 label-text-transform">{key.label}</span>
            </>
          ) : key.logicalDataType === enumDataType.DATE ? (
            <>
              <img src={datePng} className="ms-1" alt="Date" />
              <span className="ms-2 label-text-transform">{key.label}</span>
            </>
          ) : (
            <span className="ms-2 label-text-transform">{`${key.label}_${key.logicalDataType}`}</span>
          )}
        </span>
      }
      className="CheckBoxSelect"
    />
  );

  const renderNestedCheckboxes = (parentKey) => (
    <>
      {parentKey.children && parentKey.children.map(child => (
        <Form.Check
          type="radio"
          key={`${parentKey.id}_${child.logicalDataType}_${child.parentId}`}
          checked={selectedDateOption[parentKey.id] === child.logicalDataType}
          onChange={(event) =>
            handleDateOptionChange(event, parentKey, child)
          }
          name={`radio-group-${parentKey.id}`} // Ensure all radios of the same parentKey are in the same group
          value={`${child.label}_${child.logicalDataType}`}
          label={
            <span className="ms-2 label-text-transform">{child.label}</span>
           }
          className="CheckBoxSelect pl50"
        />
      ))}
    </>
  );

  return (
    <Accordion className="mt-2">
      <Accordion.Item eventKey="0">
        <Accordion.Header className="accordionTable">{selectedTable}</Accordion.Header>
        <Accordion.Body className="customAccordionBody">
          <div className="ColumnPreviewCustom">
            <div className="position-relative">
              <div className="SearchSidebarContent">
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              <input
                className="WhiteSearchBox"
                type="search"
                placeholder="Search column"
                aria-label="Search"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>
            <Form.Check
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectAllChecked}
              label="Select all"
              className="mb-2 mt-2"
            />
            <div className="ScrollCheckbox">
              {mergedArray.map((key) => (
                <div key={key.id}>
                  {renderCheckbox(key)}
                  {key.logicalDataType === 'DATE' && dateChecked[key.id] && renderNestedCheckboxes(key)}
                </div>
              ))}
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default ColumnPreview;
