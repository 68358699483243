import { useDispatch } from "react-redux";
import { resetReportIdData, resetWidgetIdClickedData, resetWidgetResponseData, resetsetData } from "../../../redux/reduxToolkit/Reducer";
import { values } from "../../../dtos/Analytics.type";
import Layout from "../../../component/layout/Layout";
import React, { useState, useEffect } from "react";
import "./Analytics.css";
import PropTypes from 'prop-types';
import { icons } from "../../../constants/icons/Icons";
import { Tab, Tabs } from "react-bootstrap";
import QuickLaunch from "./QuickLaunchh";
import Recents from "./Recent";
import QueryPrompt from "../queryPrompt/QueryPrompt";

export default function Analytics({ handleTabChange }) {
  const dispatch = useDispatch();
  dispatch(resetsetData());
  dispatch(resetWidgetIdClickedData());
  dispatch(resetWidgetResponseData());
  dispatch(resetReportIdData())
  const [text, setText] = useState("");

  // useEffect(() => {
  //   let isMounted = true;
  //   let valueIndex = 0;
  //   let charIndex = 0;
  //   let isDeleting = false;

  //   const typewriter = () => {
  //     const currentValue = values[valueIndex];

  //     if (isDeleting) {
  //       setText(currentValue.substring(0, charIndex));
  //       charIndex--;

  //       if (charIndex < 0) {
  //         isDeleting = false;
  //         valueIndex = (valueIndex + 1) % values.length;
  //       }
  //     } else {
  //       setText(currentValue.substring(0, charIndex));
  //       charIndex++;

  //       if (charIndex > currentValue.length) {
  //         isDeleting = true;
  //         setTimeout(typewriter, 1500);
  //         return;
  //       }
  //     }

  //     if (isMounted) {
  //       setTimeout(typewriter, 20);
  //     }
  //   };

  //   typewriter();

  //   return () => {
  //     isMounted = false;
  //   };
  // }, []);
  //

  const handleSearchInputChange = (event) => {
    const searchString = event.target.value;
    setText(searchString);
  };

  return (
    <>
      <Layout>
        <div className="AnalyticsContainer">
          <div className="container homeSection">
            <div className="row">
              <div className="col-md-12 ">
                {/* <form role="search">
                  <div className="SearchIconMain">
                    <div className="position-relative">
                      <div className="SearchIcon">
                        <img src={icons.searchPrompt} />
                      </div>
                      <input
                        className="CustomSeach fourteen400 color939393"
                        type="text"
                        value={text}
                        onChange={handleSearchInputChange}
                      ></input>
                      <div className="Microphone">
                        <img src={icons.microphone} />
                      </div>
                    </div>
                  </div>
                </form> */}
                <QueryPrompt/>
              </div>
              <Tabs className="CustomTabsFile">
                <Tab eventKey="quicklaunch" title="Quick launch">
                  <QuickLaunch handleTabChange={handleTabChange} />
                </Tab>
                <Tab eventKey="recent" title="Recent">
                  <Recents />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

Analytics.propTypes = {
  handleTabChange: PropTypes.node.isRequired,
};