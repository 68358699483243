// Dropdown.js

import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const DataSetDropdown = ({
  selectedTable,
  logicalResourceData,
  handleUserClick,
  isCustomBehavior,
  dropdownType,
  uniqueIdentifier,
}) => {
  const dropdownId = `shareDropdown_${uniqueIdentifier}`;

  return (
    <Dropdown>
      <Dropdown.Toggle
        id="dropdown-basic"
        className="CustomButtonDropdownSidebar"
      >
        Saved Data
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-width-set">
        {logicalResourceData?.map((item) => (
          <Dropdown.Item
            key={item.id}
            onClick={() => handleUserClick(item, item.id, item.label)}
          >
            <p
              className={`dropdown-item${
                isCustomBehavior ? " custom-behavior" : ""
              }`}
            >
              {item.label ? item.label : "table"}
            </p>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    // <div className="dropdown">
    //   <div
    //     className="CustomButtonDropdownSidebar dropdown-toggle"
    //     data-bs-toggle="dropdown"
    //     aria-expanded="false"
    //   >
    //     {selectedUserName ? selectedUserName : "Uploaded Files"}
    //   </div>
    //   <ul className="dropdown-menu" aria-labelledby={dropdownId}>
    //     {users?.map((user) => (
    //       <li
    //         key={user.id}
    //         onClick={() => handleUserClick(user, user.id, user.label)}
    //       >
    //         <p className={`dropdown-item${isCustomBehavior ? ' custom-behavior' : ''}`}>
    //           {user.label ? user.label : "Data not found"}
    //         </p>
    //       </li>
    //     ))}
    //   </ul>

    // </div>
  );
};

export default DataSetDropdown;
