const SetCookie = (name, value) => {
    let domain;
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname.includes('localhost')) {
        domain = '.localhost';
    } else { 
        domain = '.vivrn.com';
    }

    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 9);
    currentDate.setMinutes(currentDate.getMinutes() + 55);
    const expirationDate = currentDate.toUTCString();
    const cookie = `${name}=${value};expires=${expirationDate};path=/;domain=${domain};`;
    document.cookie = cookie;
};

export default SetCookie;

