// const RemoveCookie = (name) => {
//     // Set the expiration date to a past date
//     const pastDate = new Date(0);
//     const expirationDate = pastDate.toUTCString();
//     // Get the current domain
//     const currentDomain = window.location.hostname;
//     // Set the cookie with the same name and an expired expiration date, and specify the domain as the root domain
//     document.cookie = `${name}=;expires=${expirationDate};path=/;domain=.${currentDomain};`;
// };

// export default RemoveCookie;

const RemoveCookie = (name) => {
    // Set the expiration date to a past date
    const pastDate = new Date(0);
    const expirationDate = pastDate.toUTCString();

    let domain;

    // Check if the hostname ends with ".local" (assuming you're using a custom domain like example.local)
    if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' || window.location.hostname.includes('localhost')) {
        domain = '.localhost';
    } else {
        // Set cookie for live domain and all its subdomains (replace "example.com" with your actual domain) 
        domain = '.vivrn.com';
    }

    // Construct the cookie string 
    const cookie = `${name}=;expires=${expirationDate};path=/;domain=${domain};`;
    // Set the cookie 
    document.cookie = cookie;
};

export default RemoveCookie;