import { API_LIMIT, OFFSET } from "../../dtos/TrashType";
import { RESTMethod } from "../../enums/enum";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";

const RecentUploadedFileApi = async () => {
  const userAuthToken = GetCookie("token");
  try {
    const response = await fetch(
      getApiUrl()+apiUrls.recent.recentUplodedFiles(API_LIMIT,OFFSET),
      {
        method: RESTMethod.GET,
        headers: {
          "Content-Type": "application/json",
          "authToken": userAuthToken,
        },
      }
    );

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      console.error("Request to restore report failed. Please try again.");
    }
  } catch (error) {
    console.error("Failed to fetch users:", error);
  }
};

export default RecentUploadedFileApi;
