import Swal from "sweetalert2";
import { apiUrls } from "../endPoints/ApiUrls";
import { loginApiUrl } from "../url/UrlConfig";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import SetCookie from "../../persistence/cookies/setCookie/SetCookie";

export default async function WorkspaceApi(payload) {
  const token = GetCookie("token");
  try {
    const response = await fetch(
      loginApiUrl() + apiUrls.workSpace.workspaceLogin,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "authToken": token,
        },
        body: JSON.stringify(payload),
      }
    );
    const result = await response.json();
    if (result.httpStatusCode == "200") {
      const token = result.response;
      SetCookie("token", token);
      Swal.fire({
        title: "Success!",
        text: "workspace login",
        icon: "success",
        showConfirmButton: false,
        timer: 1000,
        allowOutsideClick: false,
      });
      return true;
    } else {
      Swal.fire({
        html: "<h4>Please try again</h4>",
        icon: "error",
        confirmButtonText: "Continue",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "NextButton",
        },
      });
      return false;
    }
  } catch (error) {
    Swal.error(
      "Oops!",
      "Something went wrong, you should choose again!",
      "error"
    );
    console.error(error, "Please Try Again");
    return false;
  }
}
