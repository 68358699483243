import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import fetchLogicalResources from "../../../service/api/FetchLogicalResourcesApi";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import { icons } from "../../../constants/icons/Icons";
import './QueryPrompt.css';
import WelcomePromptApi from "../../../service/api/WelcomePromptApi";
import { Tab, Tabs } from "react-bootstrap";
import ApplicationButton from "../../../component/applicationButton/ApplicationButton";
import { Dropdown } from 'react-bootstrap';
import SpinnerData from "../../../component/loader/SpinnerData";
import PromptCellDataApi from "../../../service/api/PromptCellDataApi";
import decodeJWT from "../../../util/Decode";
import { useDispatch } from "react-redux";
import { cellDataApiData, resetcellDataApiData } from "../../../redux/reduxToolkit/Reducer";
import Swal from 'sweetalert2';
const QueryPrompt = () => {
    const [textAreaValue, setTextAreaValue] = useState('');
    const [logicalResourceData, setLogicalResourceData] = useState('');
    const [welcomePromptData, setWelcomePromptData] = useState('');
    const [selectedFile, setSelectedFile] = useState('');
    const [suggestionLoader, setSuggestionLoader] = useState(false);
    const [isInputDisabled, setIsInputDisabled] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        const userAuthToken = GetCookie('token');
        if (userAuthToken) {
            const fetchLogicalResource = async () => {
                const fetchLogicalResourceData = await fetchLogicalResources(userAuthToken);
                setLogicalResourceData(fetchLogicalResourceData);
            };
            fetchLogicalResource();
        }
    }, []);

    const handleFileSelected = async (item) => {
        setSuggestionLoader(true);
        setSelectedFile(item);
        setIsInputDisabled(false);
        setIsButtonDisabled(false);

        const WelcomePromptpayload = [{
            "id": item.id,
            "label": item.label,
            "physicalResourceDTO": {
                "id": item.physicalResourceDTO.id
            }
        }];
        const welcomePromptResult = await WelcomePromptApi(JSON.stringify(WelcomePromptpayload));
        if (welcomePromptResult != null) {
            setWelcomePromptData(welcomePromptResult);
            console.log(welcomePromptResult, "welcomePromptResultresponse");
            setSuggestionLoader(false);
            dispatch(resetcellDataApiData());
        }
    };

    const handleTextAreaChange = (event) => {
        if (!selectedFile) {
            Swal.fire({
                title: 'Error!',
                text: 'Please select a file first.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        } else {
            setTextAreaValue(event.target.value);
        }
    };

    const token = GetCookie("token");
    const tokenParsed = decodeJWT(token);
    console.log(tokenParsed, "tokenn");

    const handleTextPrompt = async () => {
        const promptPayload = {
            "name": "first_notebook",
            "createdBy": {
                "id": tokenParsed.payload.userId
            },
            "workspaceDTO": {
                "id": tokenParsed.payload.workspaceId
            },
            "cellDTOS": [
                {
                    "cellType": "MARKDOWN",
                    "cellContent": textAreaValue
                }
            ],
            "logicalResourceDTOS": [
                {
                    "label": selectedFile.label,
                    "id": selectedFile.id,
                    "physicalResourceDTO": {
                        "id": selectedFile.physicalResourceDTO.id
                    }
                }
            ]
        };
        const promptCellData = await PromptCellDataApi(JSON.stringify(promptPayload));
        if (promptCellData != null) {
            dispatch(cellDataApiData(promptCellData));
            navigate('/query-prompt-details');
        }
    };

    const suggestionQueryHandle = async (item) => {
        const promptPayload = {
            "name": "first_notebook",
            "createdBy": {
                "id": tokenParsed.payload.userId
            },
            "workspaceDTO": {
                "id": tokenParsed.payload.workspaceId
            },
            "cellDTOS": [
                {
                    "cellType": "MARKDOWN",
                    "cellContent": item
                }
            ],
            "logicalResourceDTOS": [
                {
                    "label": selectedFile.label,
                    "id": selectedFile.id,
                    "physicalResourceDTO": {
                        "id": selectedFile.physicalResourceDTO.id
                    }
                }
            ]
        };
        const promptCellData = await PromptCellDataApi(JSON.stringify(promptPayload));
        if (promptCellData != null) {
            console.log(promptCellData, "promptCellDataresponse");
            dispatch(cellDataApiData(promptCellData));
            navigate('/query-prompt-details');
        }
    };

    return (
        <div className="queryPromptContainer">
            <Tabs className="CustomTabsFile">
                <Tab eventKey="all" title="All">
                    <div className="promptContainer">
                        <div className="PromptContainerInput">
                            <input
                                placeholder="prompt"
                                value={textAreaValue}
                                onChange={handleTextAreaChange}
                            />
                            <div className="SearchIcon">
                                <img src={icons.searchPrompt} alt="Search" />
                            </div>
                        </div>
                        <div className="selectFileBox">
                            <div className="selectedFileNameRow">
                                <Dropdown>
                                    <Dropdown.Toggle variant="none" id="dropdown-basic">
                                        <img src={icons.attachment} className="img-fluid" alt="Select a file" />Select a file
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {logicalResourceData?.length > 0 ?
                                            logicalResourceData?.map((item, index) => (
                                                <Dropdown.Item
                                                    key={index}
                                                    onClick={() => handleFileSelected(item)}
                                                    className={selectedFile && selectedFile.id === item.id ? 'active' : ''}
                                                >
                                                    <div className="d-flex align-items-center">
                                                        <div className="iconBox">
                                                            {item.physicalResourceDTO.datasetDTO ? (
                                                                <img src={icons.csvIcon} className="img-fluid" alt="CSV Icon" />
                                                            ) : (
                                                                <img src={icons.tableIcon} className="img-fluid" alt="Table Icon" />
                                                            )}
                                                        </div>
                                                        <p className="fourteen500">{item.label}</p>
                                                    </div>
                                                </Dropdown.Item>
                                            ))
                                            : null}
                                    </Dropdown.Menu>
                                </Dropdown>
                                {selectedFile.label ?
                                    <div className="selectedFileNameBadge">
                                        <p className="selectedFileName">{selectedFile.label}</p>
                                    </div> : null}
                            </div>
                            <ApplicationButton
                                btnText="Generate"
                                onClick={handleTextPrompt}
                                disabled={isButtonDisabled}
                            />
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="recent" title="Recent">
                </Tab>
            </Tabs>
            <div className="row">
                <div className="col-12">
                    <div className="suggestionShow">
                        {suggestionLoader ? (
                            <SpinnerData color={"#001A72"} />
                        ) : null}
                        {welcomePromptData?.length > 0 ? (
                            welcomePromptData.map((item, index) => (
                                <div
                                    className="suggestionShowBadge"
                                    key={index}
                                    onClick={() => { suggestionQueryHandle(item); }}
                                >
                                    <p>{item}</p>
                                </div>
                            ))
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QueryPrompt;
