import React from "react";
import { logo } from "../../../constants/pictures/Pictures"; // Assuming logo is imported correctly
import "./WorkSpace.css";

const CreateWorkSpace = ({ formik, handleChange, value, errors, touched, onNext }) => {
  return (
    <>
      <div className="workSpaceRow-default">
        <p className="welcome-text mb-4">Now Create your workspace</p>
        <div className="row">
          <div className="col-6">
            <input
              type="text"
              name="UserWorkSpaceName"
              value={value.UserWorkSpaceName}
              onChange={handleChange}
              className="workspace-input"
              placeholder="Workspace Name"
            />
            {touched.UserWorkSpaceName && errors.UserWorkSpaceName && (
              <div className="ErrorMessage">
                {errors.UserWorkSpaceName}
              </div>
            )}
          </div>
          {/* <div className="col-12 d-flex justify-content-center mt-3">
                  <button
                    type="button"
                    className="next-button-workspace"
                    onClick={onNext}
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    Next
                  </button>
                </div> */}
        </div>
      </div>
    </>
  );
};

export default CreateWorkSpace;
