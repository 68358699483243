import { ToastContainer } from "react-bootstrap";
import React from "react"

const SearchBar = ({ optmisedVersion }) => {
    return (
        <div className="d-flex">
          <input
            type="text"
            id="searchInput"
            className="form-control"
            placeholder="Search your report here..."
            onChange={(e) => optmisedVersion(e.target.value)}
          />
          <ToastContainer />
      </div>
    );
  };

  export default SearchBar;