import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import Layout from "../../component/layout/Layout";
import GetCSVSchema from "../../service/api/GetCSVSchemaApi";
import { acceptedFileTypes } from "../../dtos/import.type";
import { convertCsvToJson } from "../../util/csvUtils";
import TableComponent from "./table/TableComponent";
import ModalComponent from "./modal/ModalComponent";
import Modalheader from "./modal/ModalHeader";
import ModalFooter from "./modal/ModalFooter.component";
import { getApiUrl } from "../../service/url/UrlConfig";
import { apiUrls } from "../../service/endPoints/ApiUrls";
import { userAuthToken } from "../../dtos/Token.type";
import { todayDate } from "../../util/todayDate";
import UplodedFileApi from "../../service/api/UploadedFileApi";
import DeleteUploadedFileApi from "../../service/api/DeleteUploadedFile";

export default function Upload() {
  localStorage.removeItem("previousData");
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState("CSV");
  const [jsonData, setJsonData] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [showTable, setShowTable] = useState(false);
  const [showUploadSection, setShowUploadSection] = useState(true);
  const [projectName, setProjectName] = useState("");
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleClose = async () => {
    try {
      const success = await DeleteUploadedFileApi(jsonData.datasetDTO);
      if (success) {
        setShow(false);
      } else {
        // Handle error if DeleteUploadedFileApi call fails
      }
    } catch (error) {
      // Handle any exceptions that occur during the API call
    }
  };
  const handleShow = () => setShow(true);

  const fetchUploadFile = async (file) => {
    if (!file) {
      console.error("No file provided.");
      return;
    }
    var data = {
      baseName: projectName,
      uploadedDataTypeEnum: fileType,
      createdDate: todayDate,
    };
    const dataStringyFY = JSON.stringify(data);
    const blob = new Blob([dataStringyFY], {
      type: "application/json",
    });

    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", blob);
    try {
      const tableData = await UplodedFileApi(formData)
      if (tableData &&tableData.response) {
          setJsonData(tableData.response);
          setShowTable(true);
      } else {
        console.error("Unexpected error occurred:");
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      fetchUploadFile(file);
    } else {
      console.error("No file uploaded.");
    }
  };

  const handlePrevious = async() => {
    setShowTable(false);
    setShowUploadSection(true);
    setJsonData([]);
    setProjectName("");
    document.getElementById("file-input").value = "";
    document.getElementById("fileType").selectedIndex = 0;
    await DeleteUploadedFileApi(jsonData.datasetDTO);
  };

  const handleNext = () => {
    const json_data = JSON.stringify(jsonData);
  };

  const acceptedFileType = acceptedFileTypes[fileType];

  const dataWithKeys = {
  "jsonData":jsonData,
    "projectName":projectName,
  };
  localStorage.setItem("myDataWithKeys", JSON.stringify(dataWithKeys));

  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <div className="UploadPage">
            <div size="lg" className="PresentDataModal">
              <div className="modal-header">
                <Modalheader />
              </div>
              <div>
                <ModalComponent
                  projectName={projectName}
                  setProjectName={setProjectName}
                  fileType={fileType}
                  setFileType={setFileType}
                  acceptedFileType={acceptedFileType}
                  handleFileChange={handleFileChange}
                />
                {showTable && (
                  <TableComponent
                    jsonData={jsonData}
                    setSearchQuery={setSearchQuery}
                    searchQuery={searchQuery}
                    setShowTable={setShowTable}
                    setHiddenColumns={setHiddenColumns}
                    hiddenColumns={hiddenColumns}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                  />
                )}
              </div>
              <ModalFooter
              jsonData={jsonData}
                showTable={showTable}
                handlePrevious={handlePrevious}
                handleNext={handleNext}
                handleClose={handleClose}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
