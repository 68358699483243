import GetCookie from "../../persistence/cookies/getCookie/GetCookie";

const GetMatchingReportApi = async (value) => {
    const userAuthToken = GetCookie("token");
    try {
    
        const response = await fetch(
          `https://server.vivrn.com:8080/analytics/api/report/getMatchingReportByName/${
            value ? value : null
          }?isDeleted=false`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              authToken: userAuthToken,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          throw new Error("Network response was not ok.");
        }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  export default GetMatchingReportApi;