import "./ReportDashboard.css";
import React, { useState, useEffect, useMemo } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export default function ReportDashboard({ handleTabChange }) {
  const [sortOrder, setSortOrder] = useState(null);
  const [cardColors, setCardColors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [sortOrderDate, setSortOrderDate] = useState(null);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const sortedData = useMemo(
    () =>
      [
        { name: "csvreport", date: new Date("2022-01-01") },
        { name: "report-puma", date: new Date("2022-03-15") },
        { name: "nike-report", date: new Date("2021-12-10") },
        { name: "addidas-report", date: new Date("2022-02-20") },
        { name: "campus-report", date: new Date("2021-11-05") },
        { name: "myntra-report", date: new Date("2022-04-30") },
        { name: "facebook-report", date: new Date("2022-01-15") },
        { name: "school-report", date: new Date("2021-10-01") },
        { name: "amazon-report", date: new Date("2022-05-20") },
        { name: "flipcart-report", date: new Date("2022-06-25") },
      ]
        .sort((a, b) => {
          if (sortOrder === "asc") {
            return a.name.localeCompare(b.name);
          } else if (sortOrder === "desc") {
            return b.name.localeCompare(a.name);
          } else if (sortOrderDate === "asc") {
            return a.date.getTime() - b.date.getTime();
          } else if (sortOrderDate === "desc") {
            return b.date.getTime() - a.date.getTime();
          }
          return 0;
        })
        .slice(0, 10),
    [sortOrder, sortOrderDate]
  );

  useEffect(() => {
    // Filter data based on search query
    const filtered = sortedData.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
    if (filtered.length === 0 && !isErrorDisplayed) {
      setIsErrorDisplayed(true);
      toast.error("Data not found", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (filtered.length > 0 && isErrorDisplayed) {
      setIsErrorDisplayed(false);
    }
  }, [searchQuery, sortedData, isErrorDisplayed]);

  useEffect(() => {
    // Retrieve colors from local storage or generate new colors
    const storedColors = localStorage.getItem("cardColors");
    if (storedColors) {
      setCardColors(JSON.parse(storedColors));
    } else {
      const colors = generateCardColors();
      setCardColors(colors);
      localStorage.setItem("cardColors", JSON.stringify(colors));
    }
  }, []);

  const generateCardColors = () => {
    const colors = [];
    for (let i = 0; i < sortedData.length; i++) {
      colors.push(getRandomColor());
    }
    return colors;
  };

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleClick = () => {
    handleTabChange("reportTab");
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortByName = (order) => {
    setSortOrder(order);
    setSortOrderDate(null);
  };

  const handleSortByDate = (order) => {
    setSortOrderDate(order);
    setSortOrder(null);
  };

  //list mode dashboard
  const [showCardContainer, setShowCardContainer] = useState(true);
  const toggleComponent = () => {
    setShowCardContainer(true);
  };
  const toggleComponentCard = () => {
    setShowCardContainer(false);
  };

  const handleDelete = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "NextButton", 
        cancelButton: "CancelButton", 
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // Create a copy of the filteredData array
        const updatedData = [...filteredData];
        // Remove the card at the specified index
        updatedData.splice(index, 1);
        // Update the filteredData state
        setFilteredData(updatedData);
        Swal.fire("Deleted!", "The card has been deleted.", "success");
      }
    });
  };
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsInfoVisible(true);
  };

  const handleMouseLeave = () => {
    setIsInfoVisible(false);
  };
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex align-items-center justify-content-between mb-5 hide-it">
          <div className="d-flex">
            <input
              type="text"
              id="searchInput"
              className="form-control"
              placeholder="Search your report here..."
              value={searchQuery}
              onChange={handleSearch}
            />
            <ToastContainer />
          </div>
          <div className="d-flex justify-content-end m-0">
            <div className="dropdown CustomIconCon">
              <button
                className="btn btn-outline-secondary me-2 rotate-button"
                type="button"
                id="shareDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-arrow-right-arrow-left"></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="shareDropdown">
                <li>
                  <a className="dropdown-item sort-name" href="#">
                    Sort by Name
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleSortByName("asc")}
                  >
                    Asce
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    onClick={() => handleSortByName("desc")}
                  >
                    Desc
                  </a>
                </li>
                <li>
                  <a className="dropdown-item sort-date" href="#">
                    Sort by Last modified Date
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleSortByDate("asc")}
                  >
                    Asce
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={() => handleSortByDate("desc")}
                  >
                    Desc
                  </a>
                </li>
              </ul>
            </div>
            <button
              className="btn btn-outline-secondary me-2"
              onClick={toggleComponent}
            >
              <i className="fa-solid fa-grip"></i>
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={toggleComponentCard}
            >
              <i className="fa-solid fa-bars"></i>
            </button>
          </div>
        </div>
      </div>
      {showCardContainer ? (
        <div className="CardContainer">
          <div className="col-md-12 mt-sm-4">
            <div className="row">
              {filteredData.map((item, index) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={index}>
                  <label>{item.date.toLocaleDateString()}</label>
                  <div
                    className="Customcard"
                    // onClick={handleClick}
                    style={{ backgroundColor: cardColors[index] }}
                  >
                    <div className="card-body">
                      <div className="dropdown dropdown-end">
                        <div
                          className="px-2"
                          data-bs-toggle="dropdown"
                          id="shareDropdown"
                        >
                          <i
                            className="fa-solid fa-ellipsis-vertical"
                            aria-expanded="false"
                          ></i>
                        </div>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="shareDropdown"
                        >
                          <li>
                            <span
                              className="dropdown-item cursor-pointer"
                              onClick={() => handleDelete(index)}
                            >
                              <i className="fa-solid fa-trash-can me-2"></i>{" "}
                              Delete
                            </span>
                          </li>
                          <li
                            onMouseEnter={() => handleMouseEnter(index)} // Pass index to handleMouseEnter
                            onMouseLeave={() => handleMouseLeave(index)} // Pass index to handleMouseLeave
                            style={{ position: "relative" }}
                          >
                            <span className="dropdown-item cursor-pointer">
                              <i className="fa-solid fa-circle-info me-2  cursor-pointer"></i>{" "}
                              More Info
                            </span>
                            {isInfoVisible && (
                              <div className="info-card">
                                <div className="card-header">
                                  <h5>More Information</h5>
                                </div>
                                <div className="card-content1">
                                  <p>Title: Your Title</p>
                                  <p>Content: Your Content</p>
                                  <p>Created Date: YYYY-MM-DD</p>
                                </div>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="upload-data">
                        <label htmlFor="file-input"></label>
                        <p className="Analyticsupload-set">{item.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="col-md-12">
          <div className="report-dashboard-set">
            <table className="table set-table-report-dashboard">
              <thead>
                <tr>
                  <th className="table-color-report-dashboard">#</th>
                  <th className="table-color-report-dashboard">File Name</th>
                  <th className="table-color-report-dashboard">Created Date</th>
                  <th className="table-color-report-dashboard">Created by</th>
                  <th className="table-color-report-dashboard">
                    Last modified by
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.date.toLocaleDateString()}</td>
                    <td>@undefined</td>
                    <td>
                      <div className="d-flex">
                        <p>3h</p>
                        <div className="dropdown ms-4">
                          <div
                            className="px-2"
                            data-bs-toggle="dropdown"
                            id="shareDropdown"
                          >
                            <i
                              className="fa-solid fa-ellipsis-vertical"
                              aria-expanded="false"
                            ></i>
                          </div>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="shareDropdown"
                          >
                            <li>
                              <span
                                className="dropdown-item cursor-pointer"
                                onClick={() => handleDelete(index)}
                              >
                                <i className="fa-solid fa-trash-can me-2"></i>{" "}
                                Delete
                              </span>
                            </li>
                            <li
                              onMouseEnter={() => handleMouseEnter(index)} // Pass index to handleMouseEnter
                              onMouseLeave={() => handleMouseLeave(index)} // Pass index to handleMouseLeave
                              style={{ position: "relative" }}
                            >
                              <span className="dropdown-item cursor-pointer">
                                <i className="fa-solid fa-circle-info me-2 cursor-pointer"></i>
                                More Info
                              </span>
                              {isInfoVisible && (
                                <div className="info-card">
                                  <div className="card-header">
                                    <h5>More Information</h5>
                                  </div>
                                  <div className="card-content1">
                                    <p>Title: Your Title</p>
                                    <p>Content: Your Content</p>
                                    <p>Created Date: YYYY-MM-DD</p>
                                  </div>
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
