import React, { useState } from "react";
import { enumChartTypes, enumDataType } from "../../../enums/enum";
import { useDispatch } from "react-redux";
import { removedCheckeditem } from "../../../redux/reduxToolkit/Reducer";
import { Dropdown, DropdownButton } from 'react-bootstrap';

const AxisConfiguration = ({ groupedData, changeName, receivedOperation }) => {
  const [data, setData] = useState(groupedData);
  const [sortOrders, setSortOrders] = useState({});
  const [aggregateEnums, setAggregateEnums] = useState({});
  const [sortOrdrersY, setSortOrdersY] = useState({})

  const handleSelect = (eventKey, index) => {
    setSortOrders((prev) => ({
      ...prev,
      [index]: eventKey,
    }));
    console.log(`Selected for item ${index}: ${eventKey}`);
  };
  const handleSelectY = (eventKey, index) => {
    setSortOrdersY((prev) => ({
      ...prev,
      [index]: eventKey,
    }));
    console.log(`Selected for item ${index}: ${eventKey}`);
  };
  const handleAggregateSelect = (eventKey, index) => {
    setAggregateEnums((prev) => ({
      ...prev,
      [index]: eventKey,
    }));
    console.log(`Selected for item ${index}: ${eventKey}`);
  };
  const dispatch = useDispatch()
  const handleRemoveItem = (item, dataType) => {
    dispatch(removedCheckeditem(item))
    // if (data[dataType]) {
    //   const newData = {
    //     ...data,
    //     [dataType]: data[dataType].filter(i => i !== item)
    //   };
    //   setData(newData);
    // }
  };
  return (
    <div className="axis">
      <label className="XandYaxis">
        {changeName === enumChartTypes.PIE ||
          changeName === enumChartTypes.DONUT
          ? "legend"
          : "X Axis"}
      </label>
      <ul className="list-unstyled XandYlistyle">
        {(
          groupedData[enumDataType.String] || groupedData[enumDataType.TEXT]
        )?.map((item, index) => (
          <li key={`${item.label}-${index}`}>
            <div className="labelContainer">
              <span>{item.label}</span>
              <span
                style={{ float: 'right', cursor: 'pointer' }}
                onClick={() => handleRemoveItem(item, enumDataType.String)}
              >
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
            <div className="d-flex align-items-center justify-content-end sortButton">
              <p className="sortText">Sort</p>
              <DropdownButton
                id={`sortButton-${index}`}
                title={sortOrders[index] || 'Ascending'}
                onSelect={(eventKey) => handleSelect(eventKey, index)}
              >
                <Dropdown.Item eventKey="Ascending">Ascending</Dropdown.Item>
                <Dropdown.Item eventKey="Descending">Descending</Dropdown.Item>
              </DropdownButton>
            </div>
          </li>
        ))}
      </ul>
      <ul className="list-unstyled XandYlistyle">
        {groupedData[enumDataType.DATE]?.map((item, index) => (
          <li key={`${item.label}-${index}`}>
            <div className="labelContainer">
              <span>{item.label}</span>
              <span style={{ "float": "right", "cursor": "pointer" }} onClick={() => handleRemoveItem(item, item, enumDataType.DATE)}>
                <i className="fa-solid fa-xmark"></i>
              </span>
            </div>
          </li>
        ))}
      </ul>
      <label className="XandYaxis">
        {changeName === enumChartTypes.PIE ||
          changeName === enumChartTypes.DONUT
          ? "values"
          : "Y Axis"}
      </label>
      <ul className="list-unstyled XandYlistyle">
        {(
          groupedData[enumDataType.Long] ||
          groupedData[enumDataType.NUMBER] ||
          groupedData[enumDataType.DECIMAL]
        )?.map((item, index) => (
          <li key={index}>
            <div className="yAxisLi">
              <DropdownButton
                id={`aggregateEnums-${index}`}
                title={aggregateEnums[index] || 'SUM'}
                onSelect={(eventKey) => handleAggregateSelect(eventKey, index)}
              >
                <Dropdown.Item eventKey="AVERAGE">Average</Dropdown.Item>
                <Dropdown.Item eventKey="MAX">Max</Dropdown.Item>
                <Dropdown.Item eventKey="MIN">Min</Dropdown.Item>
                <Dropdown.Item eventKey="SUM">Sum</Dropdown.Item>
                <Dropdown.Item eventKey="COUNT">Count</Dropdown.Item>
                <Dropdown.Item eventKey="DISTINCT_COUNT">Distint Count</Dropdown.Item>
              </DropdownButton>
              <div className="labelContainer">
                <span>{item.label}</span>
                {/* {receivedOperation?.[item.label]
              ? receivedOperation?.[item.label]
              : "SUM"} */}

                <span style={{ "float": "right", "cursor": "pointer" }} onClick={() => handleRemoveItem(item, enumDataType.Long)}>
                  <i className="fa-solid fa-xmark"></i>
                </span>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end sortButton">
              <p className="sortText">Sort</p>
              <DropdownButton
                id={`sortButtonY-${index}`}
                title={sortOrdrersY[index] || 'Ascending'}
                onSelect={(eventKey) => handleSelectY(eventKey, index)}
              >
                <Dropdown.Item eventKey="Ascending">Ascending</Dropdown.Item>
                <Dropdown.Item eventKey="Descending">Descending</Dropdown.Item>
              </DropdownButton>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AxisConfiguration;
