import React, { useEffect, useState } from "react";
import CheckUrlApi from "../../../service/api/CheckUrlApi";

const StepThreeForm = ({ touched, handleChange, value, errors, onUrlChecked, handleChangeInput }) => {
  const [inputValue, setInputValue] = useState("");
  const [specialCharError, setSpecialCharError] = useState("");

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="signUpFormContainer">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <p className="tellUsBit">What is your company's website?</p>
                <p className="helpUs inputField">Please share the website address of your company</p>
                <div className="inputField">
                  <label>Company Website*</label>
                  <div className="CommonInput position-relative">
                    <input
                      type="text"
                      name="cwebsite"
                      className="customInput paddingAdjustWeb"
                      value={value.cwebsite}
                      onChange={handleChange}
                    />
                    <div className="input-group-append rightinputCus">
                      <span>.vivrn.com</span>
                    </div>
                  </div>
                  
                  {touched.cwebsite && errors.cwebsite && (
                    <div className="ErrorMessage">
                      {errors.cwebsite}
                    </div>
                  )}
                  {specialCharError && (
                    <div className="ErrorMessage">
                      {specialCharError}
                    </div>
                  )}
                </div>
                <span>Please change it if it's not right. This was our best guess based on your email address.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepThreeForm;