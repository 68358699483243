export const jobOptions = [
    {
      label: "Developer",
      value: "Developer",
    },
    {
      label: "Analyst",
      value: "Analyst",
    },
    {
      label: "Data Engineer",
      value: "DataEngineer",
    },
    {
      label: "Data Analyst",
      value: "DataAnalyst",
    },
  ];