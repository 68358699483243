import React, { useState } from "react";
import DataTypeIcon from "./DataTypeIcon";

const SmartTableHeader = ({
  column,
  columnIndex,
  sortField,
  sortOrder,
  onSort,
  onChangeDataType,
  onSelectColumn,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [dropdownClicked, setDropdownClicked] = useState(false);

  const handleDataTypeChange = (dataType) => {
    onChangeDataType(columnIndex, dataType);
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onSelectColumn(columnIndex, !isChecked);
  };

  const handleSort = (order) => {
    if (dropdownClicked) {
      onSort(column.dataField, order);
    }
  };

  const handleDropdownClick = () => {
    setDropdownClicked(true);
  };

  return (
    <>
      <div className="tableheader">
        {/* <input
        name="hide-column"
        className="ms-2"
        type="checkbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
      /> */}
        {column.columnName}
        <div onClick={handleDropdownClick} className="tableDataType">
          {" "}
          <DataTypeIcon
            dataType={column.displayType}
            onSort={handleSort}
            sortOrder={sortOrder}
            onChangeDataType={handleDataTypeChange}
          />
        </div>
      </div>
    </>
  );
};

export default SmartTableHeader;
