import React, { useState } from 'react';
import { Tab, Tabs, TabContainer } from 'react-bootstrap';
import "./PrePareData.css"
import MyContent from './myContent/MyContent';
import Layout from '../../component/layout/Layout';
export default function PrepareData() {
  const [activeTab, setActiveTab] = useState('myContent');
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <Layout>
      <div className="AnalyticsContainer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 ContentCustom">
            <TabContainer id="my-tabs" activeKey={activeTab} onSelect={handleTabChange}>
              <Tabs>
                <Tab eventKey="myContent" title="My Content" className="custom-tab">
                  <h2>Files</h2>
                  <MyContent />
                </Tab>
                <Tab eventKey="dataServer" title="Data Server" >
                  <h2 className="custom-tab">Data Server Tab</h2>
                  <p>This is the content for the "Data Server" tab.</p>
                </Tab>
              </Tabs>
            </TabContainer>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
}


