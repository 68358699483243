import React, { useEffect, useState } from "react";
import "./SelectedChart.css";
import { useSelector, useDispatch } from "react-redux";
import PlayGround from "./playGround";
import Chart from "./chart";
import {
  SelecteddChartData,
  TotalWidgetDataa,
  checkedKeysData,
  playgroundTabIndexData,
  resetFalseSelectedChartData,
  resetSelectedChartData,
  resetSelectedWidgetUuidData,
  resetSelecteddChartData,
  selectedWidgetUuidData,
} from "../../../redux/reduxToolkit/Reducer";
import interact from "interactjs";
import { icons } from "../../../constants/icons/Icons";

const SelectedCharts = (props) => {
  const responseData2 = useSelector((state) => state.response);
  const ResetSelectedChart = useSelector((state) => state.ResetSelectedChart);
  const ClearCharts = useSelector((state) => state.ClearCharts);
  const ImageChartSelection = useSelector((state) => state.ImageChartSelection);
  const selecteddChart = useSelector((state) => state.selecteddChart);
  const SelectedWidgetUuid = useSelector((state) => state.SelectedWidgetUuid);
  const savedwidgetResponse = useSelector((state) => state.WidgetResponse);
  const focusModeOn = useSelector((state) => state.focusModeClass);
  const clearCheckedKey = useSelector((state) => state.clearChecked);
  const [charts, setCharts] = useState([]);
  const [finalCharts, setFinalCharts] = useState([]);
  const [selectedChartId, setSelectedChartId] = useState(null);
  const [widthWidget, setWidthWidget] = useState("");
  const [chartSizes, setChartSizes] = useState({}); // State to store sizes for each chart
  const dispatch = useDispatch();
  console.log(responseData2, "responseData2")
  const widgetNameUpdate = useSelector(
    (state) => state.titleWidget
  );

  const handleDrag = (event) => {
    const target = event.target;
    const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    target.style.transform = "translate(" + x + "px, " + y + "px)";

    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);

    // Update the layout object for the dragged chart only
    setCharts((prevCharts) =>
      prevCharts.map((chart) =>
        chart.uuid === target.id
          ? { ...chart, layout: { ...chart.layout, x, y } }
          : chart
      )
    );
  };

  const handleResize = (event) => {
    const target = event.target;

    // Calculate the new width and height
    let newWidth = event.rect.width;
    let newHeight = event.rect.height;

    // Update the styles for resizing
    target.style.width = newWidth + "px";
    target.style.height = newHeight + "px";

    // Update the layout object for the resized chart only
    setCharts((prevCharts) =>
      prevCharts.map((chart) =>
        chart.uuid === target.id
          ? { ...chart, layout: { ...chart.layout, width: newWidth, height: newHeight } }
          : chart
      )
    );
  };

  useEffect(() => {
    interact(".chart-container")
      .draggable({
        inertia: true,
        modifiers: [
          interact.modifiers.restrictRect({
            restriction: "parent",
          }),
        ],
        autoScroll: true,
        listeners: {
          move: handleDrag,
        },
        ignoreFrom: ".no-drag", // Add this line to ignore drag from elements with the 'no-drag' class
      })
      .resizable({
        edges: { left: true, right: true, bottom: true, top: true },
        listeners: {
          move: handleResize,
        },
        modifiers: [
          interact.modifiers.aspectRatio({
            ratio: "preserve", // Preserve aspect ratio during resizing
          }),
        ],
        // Minimum dimensions for the chart container
        restrictSize: {
          min: { width: 100, height: 100 },
        },
        ignoreFrom: ".no-drag", // Add this line to ignore resize from elements with the 'no-drag' class
      });
  }, [widthWidget]);
  

  useEffect(() => {
    if (responseData2) {
      setCharts((prevCharts) => {
        // Check if the chart already exists in the array
        const existingChartIndex = prevCharts.findIndex(
          (chart) => chart.uuid === responseData2.uuid
        );

        if (existingChartIndex !== -1) {
          // Update the specific chart with the new data
          return prevCharts.map((chart, index) => {
            if (index === existingChartIndex) {
              return responseData2;
            }
            return chart;
          });
        } else {
          // Add the new chart to the array
          return [...prevCharts, responseData2];
        }
      });

      // Initialize size for the new chart
      setChartSizes((prevSizes) => ({
        ...prevSizes,
        [responseData2.uuid]: { width: 200, height: 200 },
      }));
    }
  }, [responseData2]);

  useEffect(() => {
    if (ClearCharts === true) {
      setCharts([]);
      setChartSizes({});
    }
  }, [ClearCharts]);

  useEffect(() => {
    if (selecteddChart) {
      setCharts((prevCharts) => {
        return prevCharts.map((prevChart) => {
          if (prevChart.uuid === SelectedWidgetUuid) {
            return { ...prevChart, chartType: ImageChartSelection };
          }
          return prevChart;
        });
      });
    }
  }, [ImageChartSelection]);
  useEffect(() => {
    if (selecteddChart) {
      setCharts((prevCharts) => {
        return prevCharts.map((prevChart) => {
          if (prevChart.uuid === SelectedWidgetUuid) {
            return { ...prevChart, name: widgetNameUpdate };
          }
          return prevChart;
        });
      });
    }
  }, [widgetNameUpdate]);

  useEffect(() => {
    if (selecteddChart) {
      setCharts((prevCharts) => {
        return prevCharts.map((prevChart) => {
          if (prevChart.uuid === SelectedWidgetUuid) {
            const newLayout = {
              ...prevChart.layout,
              x: prevChart.layout.x,
              y: prevChart.layout.y,
              width: prevChart.layout.width,
              height: prevChart.layout.height,
            };
            return {
              ...prevChart,
              layout: newLayout,
            };
          }
          return prevChart;
        });
      });
    }
  }, [widthWidget]);

  useEffect(() => {
    if (savedwidgetResponse) {
      setCharts((prevCharts) => {
        // Filter out the chart with the same UUID as savedwidgetResponse
        const filteredCharts = prevCharts.filter(
          (chart) => chart.uuid !== savedwidgetResponse.uuid
        );

        // Add savedwidgetResponse to the filtered array
        return [...filteredCharts, savedwidgetResponse];
      });

      // Initialize size for the new chart
      setChartSizes((prevSizes) => ({
        ...prevSizes,
        [savedwidgetResponse.uuid]: { width: 200, height: 200 },
      }));
    }
  }, [savedwidgetResponse]);

  const handleClickCharts = (chart) => {
    dispatch(SelecteddChartData(chart?.chartType));
    const labelofGroupby = chart?.groupByMapDTOS?.map((item) => ({
      label: item.logicalAttributeDTO.label,
      logicalDataType: item.logicalAttributeDTO.logicalDataType,
      id: item.logicalAttributeDTO.id,
    }));

    const lebelOfMetric = chart?.metricDTOS?.map((item) => ({
      label: item.selectColumn.label,
      logicalDataType: item.selectColumn.logicalDataType,
      id: item.selectColumn.id,
    }));

    const totalselectedLabel = [...labelofGroupby, ...lebelOfMetric];
    dispatch(checkedKeysData(totalselectedLabel));
    dispatch(selectedWidgetUuidData(chart?.uuid));
    setSelectedChartId(chart?.uuid);
    dispatch(resetFalseSelectedChartData());
  };

  useEffect(() => {
    if (ResetSelectedChart) {
      setSelectedChartId(null);
    }
  }, [ResetSelectedChart]);

  useEffect(() => {
    const nonEmptyCharts = charts.filter(
      (chart) => Object.keys(chart).length > 0
    );
    setFinalCharts(nonEmptyCharts);
    dispatch(TotalWidgetDataa(nonEmptyCharts));
  }, [charts]);

  const groupedByTabIndex = finalCharts.reduce((acc, obj) => {
    const tabIndex = obj.layout.tabIndex;
    if (!acc[tabIndex]) {
      acc[tabIndex] = [];
    }
    acc[tabIndex].push(obj);
    return acc;
  }, {});

  // Initialize tabs based on groupedByTabIndex or default to one tab if empty
  const initialTabs = Object.keys(groupedByTabIndex).length
    ? Object.keys(groupedByTabIndex).map((key) => ({
      id: parseInt(key),
      name: `Tab ${parseInt(key)}`,
    }))
    : [{ id: 1, name: 'Tab 1' }];

  const [tabs, setTabs] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(initialTabs[0].id);
  useEffect(() => {
    dispatch(playgroundTabIndexData(activeTab))
  }, [activeTab]);
  useEffect(() => {
    const updatedTabs = Object.keys(groupedByTabIndex).length
      ? Object.keys(groupedByTabIndex).map((key) => ({
        id: parseInt(key),
        name: `Tab ${parseInt(key)}`,
      }))
      : [{ id: 1, name: 'Tab 1' }];

    setTabs(updatedTabs);
  }, [finalCharts]);

  const addTab = () => {
    const newTabId = tabs.length ? tabs[tabs.length - 1].id + 1 : 1;
    setTabs([...tabs, { id: newTabId, name: `Tab ${newTabId}` }]);
    setActiveTab(newTabId);
    clearCheckedKey()
  };

  const switchTab = (tabId) => {
    setActiveTab(tabId);
    dispatch(resetSelectedWidgetUuidData());
    dispatch(resetSelectedChartData(true));
    dispatch(resetSelecteddChartData());
    clearCheckedKey()
  };

  const deleteTab = (tabId) => {
    const newTabs = tabs.filter(tab => tab.id !== tabId);
    setTabs(newTabs);
    if (activeTab === tabId && newTabs.length) {
      setActiveTab(newTabs[0].id);
    } else if (!newTabs.length) {
      setTabs([{ id: 1, name: 'Tab 1' }]);
      setActiveTab(1);
    }
  };
  const renderChartsForTab = (tabIndex) => {
    const chartsForTab = groupedByTabIndex[tabIndex] || [];
    return chartsForTab.map((chart) => (
      <PlayGround key={chart?.uuid}>
        <div
          onClick={() => handleClickCharts(chart)}
          className={`chart-container ${selectedChartId === chart?.uuid ? "selected-chart" : ""}`}
          id={chart?.uuid}
          data-test={chart?.layout.tabIndex}
          style={{
            position: focusModeOn?.uuid === chart?.uuid ? "absolute" : "relative",
            zIndex: focusModeOn?.uuid === chart?.uuid ? 99 : 0,
            top: focusModeOn?.uuid === chart?.uuid ? 0 : 0,
            left: focusModeOn?.uuid === chart?.uuid ? 0 : 0,
            width: focusModeOn?.uuid === chart?.uuid ? 727 : chart.layout?.width,
            height: focusModeOn?.uuid === chart?.uuid ? 727 : chart.layout?.height,
            transform: chart.layout?.x != null && chart.layout?.y != null ? `translate(${chart.layout.x}px, ${chart.layout.y}px)` : null
          }}
        >
          <Chart responseData={chart} chartType={chart.chartType} />
        </div>
      </PlayGround>
    ));
  };
  console.log(finalCharts, "finalcharts");
  console.log(charts, "chartss")
  return (
    <>
      <div
        id="graph-page"
        className={"ChartContainerCustom"}
      >
        {renderChartsForTab(activeTab)}
      </div>
      {finalCharts?.length > 0 ?
        <div className="tabs chartCustomTabs">
          {tabs.map(tab => (
            <div key={tab.id} className="tab chartsRenderTab">
              <div className="position-relative">
                <button onClick={() => switchTab(tab.id)} className={tab.id === activeTab ? "active" : ""} id={tab.id}>
                  {tab.name}
                </button>
                {/* <div onClick={() => deleteTab(tab.id)} className="delete"><img src={icons.crossIcon} alt="cross" className="img-fluid"/></div> */}
              </div>
            </div>
          ))}
          <button onClick={addTab} className="add"><img src={icons.plusIcon} alt="add" /></button>
        </div>
        : null}
    </>
  );
};

export default SelectedCharts;
