import React from "react";
import Layout from "../../component/layout/Layout";
export default function Collabration() {
  localStorage.removeItem("previousData");
  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <h1>Coming soon</h1>
        </div>
      </div>
    </Layout>
  );
}
