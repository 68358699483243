import React from "react";
import "./Header.css";
// import { SidebarContext } from "../layout/SideBarContext";
import { logo } from "../../constants/pictures/Pictures";
import { Link, useNavigate } from "react-router-dom";
import TooltipAnalytics from "../toolTip/ToolTip";
import { icons } from "../../constants/icons/Icons";
import { Dropdown } from "react-bootstrap";
import { loginResponsecode } from "../../dtos/Token.type";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import LogOutApi from "../../service/api/LogoutApi";
import RemoveCookie from "../../persistence/cookies/removeCookie/RemoveCookie";
import { googleLogout } from "@react-oauth/google";
import Swal from "sweetalert2";

export default function Header() {
  const userDTOStringfy = GetCookie("userDTO");
  const userDTO = JSON.parse(userDTOStringfy);
  console.log(userDTO, "ResponseParsedd")

  const navigate = useNavigate();
  const logout = () => {
    // Show the SweetAlert confirmation modal
    Swal.fire({
      html: "<h4>Are you sure you want to logout?</h4>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Logout",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "NextButton", // Replace with your class name for the confirm button
        cancelButton: "CancelButton", // Replace with your class name for the cancel button
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleLogout();
      }
    });
  };
  const ResponseParsed = JSON.parse(loginResponsecode);
  const userAuthToken = GetCookie('token');
  const handleLogout = async () => {
    const AuthToken = {
      "authToken": userAuthToken,
    };
    const authTokenStringfy = JSON.stringify(AuthToken);
    const result = await LogOutApi(authTokenStringfy);
    console.log(result, "LogOutApi 1")
    if (result === true) {
      let newURL;
      if (userAuthToken) {
        if (window.location.hostname == "localhost") {
          navigate("/login");
        } else {
          newURL = `https://www.vivrn.com/login`;
          window.location.href = newURL;
        }
      }
      RemoveCookie("token");
      RemoveCookie("email");
      RemoveCookie("password");
      RemoveCookie("loginResponsecode");
      RemoveCookie("workSpaceDTO");
      RemoveCookie("userDTO");
      googleLogout();
    } else {
      // Logout failed
    }
  };
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link to="/home">
          <img src={logo} alt="logo" className="LogoCustom" />
        </Link>
        <div className="NavSearch">
          <form>
            <div className="customSearchContainer mr38">
              <div className="customSearchIcon">
                <i className="fa-solid fa-magnifying-glass fourteen400"></i>
              </div>
              <input
                className="CustomSearch-control fourteen400"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
            </div>
          </form>
          <ul className="navbaricons">
            <li>
              <div className="position-relative">
                <TooltipAnalytics text="wifi" className="BottomTextTooltip">
                  <div className="headerIcons">
                    <img src={icons.wiFi} className="img-fluid" />
                  </div>
                </TooltipAnalytics>
              </div>
            </li>
            <li>
              <div className="position-relative">
                <TooltipAnalytics text="Notification" className="BottomTextTooltip">
                  <div className="headerIcons">
                    <img src={icons.notification} className="img-fluid" />
                  </div>
                </TooltipAnalytics>
              </div>
            </li>
            <li className="set-margin">
              <div className="position-relative">
                <TooltipAnalytics text="setting" className="BottomTextTooltip">
                  <div className="headerIcons">
                    <img src={icons.settingsBlack} className="img-fluid" />
                  </div>
                </TooltipAnalytics>
              </div>
            </li>
            <li className="set-margin">
              <div className="position-relative">
                <TooltipAnalytics text="question" className="BottomTextTooltip">
                  <div className="headerIcons">
                    <img src={icons.help} className="img-fluid" />
                  </div>
                </TooltipAnalytics>
              </div>
            </li>

            {/* <Link to="/super" className="text-decoration-none"> */}
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="light" id="dropdown-basic" className="d-flex align-items-center">
                  <div className="position-relative">
                    <div className="headerProfileIcons">
                      <img src={icons.dummyProfile} className="img-fluid" alt="Profile" />
                    </div>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdownMenuContainer">
                  <div className="dropdownBox">
                    <div className="profileHeaderImage">
                      <img src={icons.dummyProfile} className="img-fluid" />
                    </div>
                    <div className="rightSideProfileDrop">
                      <h3 className="twenty500 color000000">{userDTO?.firstName}{" "}{userDTO?.lastName}</h3>
                      <p className="color001A72 ten500">{userDTO?.emailId}</p>
                      <div className="bottomProfile">
                        <Link to="/super" className="text-decoration-none">
                          <div className="bottomflex">
                            <div className="accountIconCont">
                              <img src={icons.accountIcon} />
                            </div>
                            <div className="profileIconText">
                              <p className="color001A72 twelve600">My Account</p>
                            </div>
                          </div>
                        </Link>
                        <div className="bottomflex" onClick={logout}>
                          <div className="accountIconCont">
                            <img src={icons.logOutIcon} />
                          </div>
                          <div className="profileLogoutIconText">
                            <p className="colorff0000 twelve600">Logout</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {/* </Link> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
