import React, { useEffect } from "react";
import VerifyToken from "../../../service/api/VerifyTokenApi";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const LoginAccountVerify = () => {
    const navigate = useNavigate();
    localStorage.removeItem("sign-up-today");

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const token = searchParams.get("token");

        async function fetchData() {
            if (!token) {
                // Handle case when token is not provided
                return;
            }
            const tokenPayload = {
                "token": token
            };
            console.log(tokenPayload, "tokenPayload");
            const result = await VerifyToken(tokenPayload);
            console.log(result, "resultVerifyToken")
            if (result === true) {
                console.log("login successful")
                const tokenn = GetCookie('token');
                console.log(tokenn, "token")
                // Split the token into its three parts: header, payload, and signature
                if (tokenn) {
                    // eslint-disable-next-line
                    const [encodedHeader1, encodedPayload, signature1] = tokenn?.split(".");
                    // Base64 decoding
                    const decodedHeader = CryptoJS.enc.Base64.parse(
                        encodedHeader1
                    ).toString(CryptoJS.enc.Utf8);
                    const decodedPayload = CryptoJS.enc.Base64.parse(
                        encodedPayload
                    ).toString(CryptoJS.enc.Utf8);
                    // Parse the JSON data
                    const parsedHeader = JSON.parse(decodedHeader);
                    console.log(parsedHeader, "parsedHeader")
                    const parsedPayload = JSON.parse(decodedPayload);
                    console.log(parsedPayload, "parsedPayload")
                    SetCookie('loginResponsecode', JSON.stringify(parsedPayload));
                    const organizationUrl = parsedPayload.organizationUrl;
                    let subdomain = null;
                    if (parsedPayload && organizationUrl) {
                        const parts = organizationUrl.split('.');
                        if (parts.length >= 3) {
                            subdomain = parts[0];
                            parts.splice(0, 1);
                        }
                    }
                    let newURL;
                    if (window.location.hostname == "localhost") {
                        // navigate("/workSpace");
                        window.location.href = 'http://localhost:3000/workSpace';
                    } else {
                        newURL = `https://${subdomain}.vivrn.com/workSpace`;
                        window.location.href = newURL;
                    }
                }

            }
            else {
                //
            }
        }

        fetchData();
    }, [navigate]);

    return (
        <div className="FullPageLoading">
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default LoginAccountVerify;
