import { Dropdown } from "react-bootstrap";
import { formatDate } from "../../directives/date-formate"
import React from "react";
import "./Dashboard.css";
const DeleteReportDropDown=({handleMouseLeave,index,item,handleDelete,handleMouseEnter,isInfoVisible})=>{
return(
    <><Dropdown align="center">
    <Dropdown.Toggle id="dropdown-basic">
      <i className="fa-solid fa-ellipsis-vertical"></i>
    </Dropdown.Toggle>
    
    <Dropdown.Menu>
      <Dropdown.Item onClick={() => handleDelete(item.id, index)}>
        <i className="fa-solid fa-trash-can me-2"></i>Delete
      </Dropdown.Item>
      <Dropdown.Item onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave(index)}>
        <span>
          <i className="fa-solid fa-circle-info me-2"></i>More Info
        </span>
        {isInfoVisible && (
          <div className="info-card">
            <div className="card-header">
              <h5>More Information</h5>
            </div>
            <div className="card-content1">
              <p>Title: {item?.title}</p>
              <p>Content: {item?.description}</p>
              <p>Created Date: {formatDate(item?.createdDate)}</p>
            </div>
          </div>
        )}
      </Dropdown.Item>
    </Dropdown.Menu>
    </Dropdown></>
)
}        
export default DeleteReportDropDown

           