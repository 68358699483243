import React from 'react';

function SidebarHeader({ isOpen, label }) {
  return (
    <div className={isOpen ? "Sidebaropenheader" : "Sidebarclosedheader"}>
      <i className={isOpen ? "fa-solid fa-arrow-right" : "fa-solid fa-arrow-left"}></i>
      <h6 className={isOpen ? "" : "transform90"}>{label}</h6>
    </div>
  );
}

export default SidebarHeader;