import React from "react";
import './SpinnerData.css';

const SpinnerData = (props) => {
    return (
        <div className="spinner-border" role="status" style={{color: props.color}}>
            <span className="sr-only"></span>
        </div>
    )
}
export default SpinnerData;