import "./ForgotPassword.css";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import React from "react";

export default function ForgotPassword() {
  let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Please enter valid email")
        .required("Please enter your email address ")
        .matches(regex, "Enter a valid email "),
    }),
    onSubmit: (values, { resetForm }) => {
      resetForm();
      console.log(values);
    },
  });
  return (
    <div className="logIn">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-10 col-sm-12">
            <div className="ForgotPasswordContainer">
              <div className="LogoLogin">
                <div className="ForgotPass">
                  <div className="arrow-corner">
                    <Link to="/login" className="anchor-line-remove">
                      <i className="fa-solid fa-chevron-left"></i>
                    </Link>
                  </div>
                  <div>
                    <h1 className="advertise">Forgot Password</h1>
                  </div>
                </div>
                <form id="user-form" onSubmit={formik.handleSubmit}>
                  <div className="FormLogIn">
                    <div className="emailAdressForgot">
                      <div className="UserIcon">
                        <i className="fa-regular fa-envelope"></i>
                      </div>
                      <input
                        type="text"
                        name="email"
                        placeholder="Enter email Address"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <p
                          style={{
                            color: "#ff0000ab",
                            fontSize: "15px",
                            textAlign: "left",
                          }}
                        >
                          {formik.errors.email}
                        </p>
                      ) : null}
                    </div>
                    <div className="BackSignIn">
                      Back to
                      <Link to="/login" className="anchor-line-remove ms-2">
                        <span>Sign In</span>
                      </Link>
                    </div>
                  </div>
                  <div className="btnSubmit">
                    <button className="submitBtn" type="submit">
                      Send
                    </button>
                    <div className="AlreadyAccount">
                      <span>
                        Do you have an account?
                        <Link
                          to="/register"
                          className="anchor-line-remove ms-2"
                        >
                          Sign Up
                        </Link>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
