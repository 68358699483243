import React from "react";
import Layout from "../../component/layout/Layout";

export default function Analytics() {
  localStorage.removeItem("previousData");
  return (
    <Layout>
      <div className="AnalyticsContainer">
        <div className="container-fluid">
          <p>Analytics coming soon</p>
        </div>
      </div>
    </Layout>
  );
}
