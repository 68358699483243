import React, { useState } from "react";
import PieDoubleMain from "./PieDoubleMain";
import PieDouble from "./PieDouble";

const PieDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? (
        <PieDoubleMain responseData={responseData} />
      ) : (
        <PieDouble responseData={responseData} />
      )}
    </div>
  );
};

export default PieDoubleToggle;
