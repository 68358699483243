import React from 'react';

const DataNotFound = () => {
  return (
    <tr>
      <td  style={{ textAlign: 'center' }}>Data not found</td>
    </tr>
  );
};

export default DataNotFound;
