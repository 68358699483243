import { Dropdown } from "react-bootstrap";
import React from "react"
const SortDropdown = ({ handleSortByName, handleSortByDate }) => {
    return (
        <div className="position-relative">
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" className="me-2 rotate-button">
              <i className="fa-solid fa-arrow-right-arrow-left"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item className="sort-name" onClick={() => handleSortByName("asc")}>
                Sort by Name
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSortByName("asc")}>
                Asce
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSortByName("desc")}>
                Desc
              </Dropdown.Item>
              <Dropdown.Item className="sort-date">
                Sort by Last modified Date
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSortByDate("asc")}>
                Asce
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSortByDate("desc")}>
                Desc
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
    );
  };

  export default SortDropdown;