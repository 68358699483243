export const dataWireName = [
    { id: 1, name: "Amazon Athena" },
    { id: 2, name: "Amazon RDS" },
    { id: 3, name: "Amazon Red Shift" },
    { id: 4, name: "Dropbox" },
    { id: 5, name: "Excel" },
    { id: 6, name: "Google Drive" },
    { id: 7, name: "Azure Data" },
    { id: 8, name: "Microsoft Azure" },
    { id: 9, name: "Google Big Query" },
    { id: 10, name: "MongoDB" },
    { id: 11, name: "Snow flakes" },
    { id: 12, name: "Digital Ocean" },
    { id: 13, name: "Oracle" },
    { id: 14, name: "IBM Cloud" },
    { id: 15, name: "Cloud sql" },
    { id: 16, name: "Square up" },
    { id: 17, name: "Sales Force" },
    { id: 18, name: "HubSpot" },
    { id: 19, name: "Google Analytics" },
    { id: 20, name: "Semrush" },
    { id: 21, name: "Facebook" },
    { id: 22, name: "Instagram" },
    { id: 23, name: "Youtube" },
    { id: 24, name: "LinkedIn" },
    { id: 25, name: "Bing" },
    { id: 26, name: "Google Ads" },
    { id: 27, name: "Ship Station" },
    { id: 28, name: "Woo Commerce" },
    { id: 29, name: "Big Commerce" },
    { id: 30, name: "Shopify" },
    { id: 31, name: "MYSQL" },
  ];