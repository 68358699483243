import React from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./ModalFooter.css";
import Dropdown from 'react-bootstrap/Dropdown';
import {
  AUTO_CREATE,
  BLANK_REPORT,
  CANCEL,
  LOAD_DATA,
  NEXT,
  PREVIOUS,
  TRANSFORM_DATA,
} from "../../../dtos/button.type";
import SaveSchemaApi from "../../../service/api/SaveSchemaApi";
import { useDispatch } from "react-redux";
import { fetchLogicalAttributesData, newUploadTableNameData } from "../../../redux/reduxToolkit/Reducer";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";
export default function ModalFooter({
  showTable,
  handlePrevious,
  handleNext,
  handleClose,
  jsonData,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(jsonData, "jsonDataaaa")
  const saveLoadSchema = async () => {
    const payload = {
      "columnSchemaList": jsonData.columnSchemaList,
      "datasetDTO": jsonData.datasetDTO
    };
  
    try {
      const response = await SaveSchemaApi(payload);
      if (response != null && response !== '') {
        SetCookie("newUploadTableData", JSON.stringify(response?.response));
        localStorage.setItem("logicaluserId",JSON.stringify((response?.response)));
        dispatch(fetchLogicalAttributesData(response?.response));
        navigate('/content-tab'); 
      } else {
        //
      }
    } catch (error) {
      // 
    }
  };
  const saveSchema = async () => {
    const payload = {
      "columnSchemaList": jsonData.columnSchemaList,
      "datasetDTO": jsonData.datasetDTO
    };
  
    try {
      const response = await SaveSchemaApi(payload);
      if (response != null && response !== '') {
        SetCookie("newUploadTableData", JSON.stringify(response?.response));
        localStorage.setItem("logicaluserId",JSON.stringify((response?.response)));
        dispatch(fetchLogicalAttributesData(response?.response));
        navigate('/createreport'); 
      } else {
        //
      }
    } catch (error) {
      // 
    }
  };
  return (
    <div className="modal-footer pt-2">
      {showTable && (
        <Button
          variant="primary"
          className="PreviousButton"
          onClick={handlePrevious}
        >
          {PREVIOUS}
        </Button>
      )}
      {showTable ? (
        <>
          {/* <Button
                variant="primary"
                className="NextButton"
                onClick={handleNext}
              >
                <Link to="/file-report" className="dropdown-item">
                  {TRANSFORM_DATA}
                </Link>
              </Button> */}

          <Dropdown>
            <Dropdown.Toggle variant="outline-primary" id="shareDropdown">
              {AUTO_CREATE}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={saveLoadSchema}>
                {LOAD_DATA}
              </Dropdown.Item>
              <Dropdown.Item onClick={saveSchema}>
                {BLANK_REPORT}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : (
        <Button variant="primary" className="NextButton" disabled>
          {NEXT}
        </Button>
      )}
      <Button
        variant="secondary"
        onClick={handleClose}
        className="CancelButton"
      >
        {CANCEL}
      </Button>
    </div>
  );
}
