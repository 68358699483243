let apiUrl='https://server.vivrn.com:8080/analytics/api';
let loginUrl='https://server.vivrn.com:8080/analytics';
let signUpUrl='https://server.vivrn.com:8080/analytics';
export function getApiUrl() {
    return apiUrl;
  }
export function loginApiUrl() {
    return loginUrl;
  }
export function getSignUp() {
    return signUpUrl;
  }