import React from "react";
import ImportData from "../../../component/modal/ImportData";
import DatabaseTypeModal from "../../../component/modal/DatabaseTypeModal";
import { Link } from "react-router-dom";
import { icons } from "../../../constants/icons/Icons";

const QuickLaunch = ({ handleTabChange }) => {
    const handleClickReportTab = () => {
        handleTabChange("fileReportTab");
    };
    return (
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="Customcard">
                    <ImportData  hideText={false}/>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-6">
                <DatabaseTypeModal />
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-6">
                <div className="Customcard mdMt">
                    <Link to="/createreport" >
                        <div className="card-body">
                            <div className="upload-data">
                                <img onClick={handleClickReportTab}
                                    src={icons.createReport}
                                    alt="Prepare Data"
                                ></img>
                                <p className="Analyticsupload-set">
                                    Create Report
                                </p>
                                <p className="card-text">Use data modules to connect data from multiple data warehouse</p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default QuickLaunch;