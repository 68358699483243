import React from "react";
import HeaderSuperAdmin from "../header/HeaderSuperAdmin";
import AsideNavbarSuperAdmin from "../asideNavbar/AsideNavbarSuperAdmin";
import PropTypes from "prop-types";
const LayoutSuperAdmin = ({ children }) => {
  return (
    <div>
      <HeaderSuperAdmin />
      <AsideNavbarSuperAdmin />
      {children}
    </div>
  );
};

LayoutSuperAdmin.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutSuperAdmin;
