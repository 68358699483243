import { createSlice, combineReducers } from '@reduxjs/toolkit';
import { enumChartTypes } from '../../enums/enum';
// Function to load state from local storage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('widgetIdResponseState');
    if (serializedState === null) {
      return {}; // Return the default initial state if no saved state is found
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return {}; // Return the default initial state if there's an error
  }
};


// Function to save state to local storage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('widgetIdResponseState', serializedState);
  } catch {
    // Handle errors while saving state (e.g., storage quota exceeded)
  }
};
const responseSlice = createSlice({
  name: 'response',
  initialState: {}, // Use initialState instead of initialData
  reducers: {
    setData: (state, action) => {
      return action.payload;
    },
    resetsetData: (state) => {
      // Reset the state to its initial value (empty object)
      return {};
    },
  },
});

const responseSliceChecked = createSlice({
  name: 'checkedkey',
  initialState: [],
  reducers: {
    checkedKeysData: (state, action) => {
      return action.payload;
    },
  },
});
const responseSliceDragged = createSlice({
  name: 'dragged',
  initialState: [],
  reducers: {
    draggedData: (state, action) => {
      return action.payload;
    },
  },
});
const responseSliceCheckedKeys = createSlice({
  name: 'checked',
  initialState: [],
  reducers: {
    checkedData: (state, action) => {
      return action.payload;
    },
  },
});
const responseSliceGetReportId = createSlice({
  name: 'ReportId',
  initialState: [],
  reducers: {
    ReportIdData: (state, action) => {
      return action.payload;
    },
    resetReportIdData: (state) => {
      // Reset the state to its initial value (empty array)
      return [];
    },
  },
});
const responseReportIdSlice = createSlice({
  name: 'IdOfReport',
  initialState: '',
  reducers: {
    IdOfReportData: (state, action) => {
      return action.payload;
    },
    ResetIdOfReportData: (state) => {
      // Reset the state to its initial value (empty array)
      return '';
    },
  },
});
const responseSliceWidgetIdResponse = createSlice({
  name: 'widgetIdResponse',
  initialState: loadState(), // Load initial state from local storage
  reducers: {
    widgetIdResponseData: (state, action) => {
      const { datasetName, data } = action.payload;
      state[datasetName] = data;

      // Save the updated state to local storage whenever it changes
      saveState(state);
    },
    resetwidgetIdResponseData: (state) => {
      // Reset the state to its initial value (empty object) and remove from local storage
      localStorage.removeItem('widgetIdResponseState');
      return {};
    },
  },
});
const responseSliceClearCheckedKey = createSlice({
  name: 'clearChecked',
  initialState: {},
  reducers: {
    clearCheckedData: (state, action) => {
      return action.payload;
    },
  },
});
const responseSlicepreviousDataWidget = createSlice({
  name: 'previousDataWidget',
  initialState: [],
  reducers: {
    previousDataWidgetData: (state, action) => {
      return action.payload; // Append action.payload to the existing state
    },
    resetPreviousDataWidgetData: (state) => {
      // Reset the state to its initial value (empty array)
      return [];
    },
  },
});
const responseSliceWidgetIdClicked = createSlice({
  name: 'WidgetIdClicked',
  initialState: [],
  reducers: {
    WidgetIdClickedData: (state, action) => {
      return action.payload; // Append action.payload to the existing state
    },
    resetWidgetIdClickedData: (state) => {
      // Reset the state to its initial value (empty array)
      return [];
    },
  },
});

//upload data
const responseSliceUploadFileData = createSlice({
  name: 'UploadFileData',
  initialState: [],
  reducers: {
    UploadFileData: (state, action) => {
      return action.payload; // Append action.payload to the existing state
    },
    resetUploadFileData: (state) => {
      // Reset the state to its initial value (empty array)
      return [];
    },
  },
});

//empty the selected data in dropdwon
const responseSliceEmptyData = createSlice({
  name: 'EmptyData',
  initialState: [],
  reducers: {
    EmptyData: (state, action) => {
      return action.payload;
    },
    resetEmptyData: (state) => {
      return [];
    },
  },
});

const ClusteredColumnChartIsFocusedDataSlice = createSlice({
  name: 'ClusteredColumnChartIsFocused',
  initialState: false,
  reducers: {
    ClusteredColumnChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});

const ClusteredColumnChartWidthDataSlice = createSlice({
  name: 'ClusteredColumnChartWidth',
  initialState: '',
  reducers: {
    ClusteredColumnChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const ClusteredColumnChartHeightDataSlice = createSlice({
  name: 'ClusteredColumnChartHeight',
  initialState: '',
  reducers: {
    ClusteredColumnChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});
const AreaChartIsFocusedDataSlice = createSlice({
  name: 'AreaChartIsFocused',
  initialState: false,
  reducers: {
    AreaChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const AreaChartWidthDataSlice = createSlice({
  name: 'AreaChartWidth',
  initialState: '',
  reducers: {
    AreaChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const AreaChartHeightDataSlice = createSlice({
  name: 'AreaChartHeight',
  initialState: '',
  reducers: {
    AreaChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});

const LineChartIsFocusedDataSlice = createSlice({
  name: 'LineChartIsFocused',
  initialState: false,
  reducers: {
    LineChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const LineChartWidthDataSlice = createSlice({
  name: 'LineChartWidth',
  initialState: '',
  reducers: {
    LineChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const LineChartHeightDataSlice = createSlice({
  name: 'LineChartHeight',
  initialState: '',
  reducers: {
    LineChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});

const PieChartIsFocusedDataSlice = createSlice({
  name: 'PieChartIsFocused',
  initialState: false,
  reducers: {
    PieChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const PieChartWidthDataSlice = createSlice({
  name: 'PieChartWidth',
  initialState: '',
  reducers: {
    PieChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const PieChartHeightDataSlice = createSlice({
  name: 'PieChartHeight',
  initialState: '',
  reducers: {
    PieChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});
const DonutChartIsFocusedDataSlice = createSlice({
  name: 'DonutChartIsFocused',
  initialState: false,
  reducers: {
    DonutChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const DonutChartWidthDataSlice = createSlice({
  name: 'DonutChartWidth',
  initialState: '',
  reducers: {
    DonutChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const DonutChartHeightDataSlice = createSlice({
  name: 'DonutChartHeight',
  initialState: '',
  reducers: {
    DonutChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});

const StackVerticalChartIsFocusedDataSlice = createSlice({
  name: 'StackVerticalChartIsFocused',
  initialState: false,
  reducers: {
    StackVerticalChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const StackVerticalChartWidthDataSlice = createSlice({
  name: 'StackVerticalChartWidth',
  initialState: '',
  reducers: {
    StackVerticalChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const StackVerticalChartHeightDataSlice = createSlice({
  name: 'StackVerticalChartHeight',
  initialState: '',
  reducers: {
    StackVerticalChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});
const StackColumnChartIsFocusedDataSlice = createSlice({
  name: 'StackColumnChartIsFocused',
  initialState: false,
  reducers: {
    StackColumnChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const StackColumnChartWidthDataSlice = createSlice({
  name: 'StackColumnChartWidth',
  initialState: '',
  reducers: {
    StackColumnChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const StackColumnChartHeightDataSlice = createSlice({
  name: 'StackColumnChartHeight',
  initialState: '',
  reducers: {
    StackColumnChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});

const StackVertical100ChartIsFocusedDataSlice = createSlice({
  name: 'StackVertical100ChartIsFocused',
  initialState: false,
  reducers: {
    StackVertical100ChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const StackVertical100ChartWidthDataSlice = createSlice({
  name: 'StackVertical100ChartWidth',
  initialState: '',
  reducers: {
    StackVertical100ChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const StackVertical100ChartHeightDataSlice = createSlice({
  name: 'StackVertical100ChartHeight',
  initialState: '',
  reducers: {
    StackVertical100ChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});
const StackColumn100ChartIsFocusedDataSlice = createSlice({
  name: 'StackColumn100ChartIsFocused',
  initialState: false,
  reducers: {
    StackColumn100ChartIsFocusedData: (state, action) => {
      return action.payload;
    },
  },
});
const StackColumn100ChartWidthDataSlice = createSlice({
  name: 'StackColumn100ChartWidth',
  initialState: '',
  reducers: {
    StackColumn100ChartWidthData: (state, action) => {
      return action.payload;
    },
  },
});

const StackColumn100ChartHeightDataSlice = createSlice({
  name: 'StackColumn100ChartHeight',
  initialState: '',
  reducers: {
    StackColumn100ChartHeightData: (state, action) => {
      return action.payload;
    },
  },
});
const WidgetResponseDataSlice = createSlice({
  name: 'WidgetResponse',
  initialState: {},
  reducers: {
    WidgetResponseData: (state, action) => {
      return action.payload;
    },
    resetWidgetResponseData : (state, action) => {
      return {};
    },
  },
});
const showOneWidgetSlice = createSlice({
  name: 'showOneWidgetResponse',
  initialState: true,
  reducers: {
    showOneWidgetResponseData: (state, action) => {
      return action.payload;
    },
  },
});
const databaseSelectedTableSlice = createSlice({
  name: 'databaseSelectedTable',
  initialState: {},
  reducers: {
    databaseSelectedTableData: (state, action) => {
      return action.payload;
    },
  },
});
const SelectedWidgetIdSlice = createSlice({
  name: 'SelectedWidgetId',
  initialState: '',
  reducers: {
    SelectedWidgetIdData: (state, action) => {
      return action.payload;
    },
  },
});
const selectedWidgetuuidSlice = createSlice({
  name: 'SelectedWidgetUuid',
  initialState: '',
  reducers: {
    selectedWidgetUuidData: (state, action) => {
      return action.payload;
    },
    resetSelectedWidgetUuidData: (state) => {
      return '';
    },
  },
});
const ResetSelectedChartSlice = createSlice({
  name: 'ResetSelectedChart',
  initialState: false,
  reducers: {
    resetSelectedChartData: (state, action) => {
      return action.payload;
    },
    resetFalseSelectedChartData: (state, action) => {
      return false;
    },
  },
});
const selecteddChartSlice = createSlice({
  name: 'selecteddChart',
  initialState: '',
  reducers: {
    SelecteddChartData: (state, action) => {
      return action.payload;
    },
    resetSelecteddChartData: (state, action) => {
      return '';
    },
  },
});
const ImageChartSelectionSlice = createSlice({
  name: 'ImageChartSelection',
  initialState: '',
  reducers: {
    ImageChartSelectionData: (state, action) => {
      return action.payload;
    },
  },
});

const ClearChartsSlice = createSlice({
  name: 'ClearCharts',
  initialState: false,
  reducers: {
    clearChartData: (state, action) => {
      return action.payload;
    }
  },
});
const totalWidgetdataSlice = createSlice({
  name: 'totalWidgetData',
  initialState: false,
  reducers: {
    TotalWidgetDataa: (state, action) => {
      return action.payload;
    }
  },
});
const fetchLogicalAttributesSlice = createSlice({
  name: 'fetchLogicalAttributes',
  initialState: '',
  reducers: {
    fetchLogicalAttributesData: (state, action) => {
      return action.payload;
    }
  },
});
const removeCheckeditemSlice = createSlice({
  name: 'removedCheckeditem',
  initialState: '',
  reducers: {
    removedCheckeditem: (state, action) => {
      return action.payload;
    }
  },
});
const handleModalJoinOpenSlice = createSlice({
  name: 'handleModalJoinOpen',
  initialState: false,
  reducers: {
    handleModalJoinOpenData: (state, action) => {
      return action.payload;
    }
  },
});
const reportByIdApiResponseSlice = createSlice({
  name: 'reportByIdApiResponse',
  initialState: '',
  reducers: {
    reportByIdApiResponseData: (state, action) => {
      return action.payload;
    },
    resetReportByIdApiResponseData: (state, action) => {
      return '';
    },
  },
});
const cellDataApiSlice = createSlice({
  name: 'cellDataApi',
  initialState: '',
  reducers: {
    cellDataApiData: (state, action) => {
      return action.payload;
    },
    resetcellDataApiData: (state, action) => {
      return '';
    },
  },
});
const focusModeClassSlice = createSlice({
  name: 'focusModeClass',
  initialState: '',
  reducers: {
    focusModeClassData: (state, action) => {
      return action.payload;
    },
    resetfocusModeClassData: (state, action) => {
      return '';
    },
  },
});
const playgroundtabActiveSlice = createSlice({
  name: 'playgoundTabIndex',
  initialState: '',
  reducers: {
    playgroundTabIndexData: (state, action) => {
      return action.payload;
    },
    resetPlaygroundTabIndexData: (state, action) => {
      return '';
    },
  },
});
const setTitleWidgetSlice = createSlice({
  name: 'titleWidget',
  initialState: '',
  reducers: {
    titleWidgetData: (state, action) => {
      return action.payload;
    },
    resetTitleWidgetData: (state, action) => {
      return '';
    },
  },
});


// Combine the reducers into a single reducer
const rootReducer = combineReducers({
  response: responseSlice.reducer,
  checkedkey: responseSliceChecked.reducer,
  dragged: responseSliceDragged.reducer,
  checked: responseSliceCheckedKeys.reducer,
  ReportId: responseSliceGetReportId.reducer,
  widgetIdResponse: responseSliceWidgetIdResponse.reducer,
  clearChecked: responseSliceClearCheckedKey.reducer,
  previousDataWidget: responseSlicepreviousDataWidget.reducer,
  WidgetIdClicked: responseSliceWidgetIdClicked.reducer,
  UploadFileData: responseSliceUploadFileData.reducer,
  EmptyData: responseSliceEmptyData.reducer,
  ClusteredColumnChartIsFocused: ClusteredColumnChartIsFocusedDataSlice.reducer,
  ClusteredColumnChartWidth: ClusteredColumnChartWidthDataSlice.reducer,
  ClusteredColumnChartHeight: ClusteredColumnChartHeightDataSlice.reducer,
  AreaChartIsFocused: AreaChartIsFocusedDataSlice.reducer,
  AreaChartWidth: AreaChartWidthDataSlice.reducer,
  AreaChartHeight: AreaChartHeightDataSlice.reducer,
  LineChartIsFocused: LineChartIsFocusedDataSlice.reducer,
  LineChartWidth: LineChartWidthDataSlice.reducer,
  LineChartHeight: LineChartHeightDataSlice.reducer,
  PieChartIsFocused: PieChartIsFocusedDataSlice.reducer,
  PieChartWidth: PieChartWidthDataSlice.reducer,
  PieChartHeight: PieChartHeightDataSlice.reducer,
  DonutChartIsFocused: DonutChartIsFocusedDataSlice.reducer,
  DonutChartWidth: DonutChartWidthDataSlice.reducer,
  DonutChartHeight: DonutChartHeightDataSlice.reducer,
  StackVerticalChartIsFocused: StackVerticalChartIsFocusedDataSlice.reducer,
  StackVerticalChartWidth: StackVerticalChartWidthDataSlice.reducer,
  StackVerticalChartHeight: StackVerticalChartHeightDataSlice.reducer,
  StackColumnChartIsFocused: StackColumnChartIsFocusedDataSlice.reducer,
  StackColumnChartWidth: StackColumnChartWidthDataSlice.reducer,
  StackColumnChartHeight: StackColumnChartHeightDataSlice.reducer,
  StackVertical100ChartIsFocused: StackVertical100ChartIsFocusedDataSlice.reducer,
  StackVertical100ChartWidth: StackVertical100ChartWidthDataSlice.reducer,
  StackVertical100ChartHeight: StackVertical100ChartHeightDataSlice.reducer,
  StackColumn100ChartIsFocused: StackColumn100ChartIsFocusedDataSlice.reducer,
  StackColumn100ChartWidth: StackColumn100ChartWidthDataSlice.reducer,
  StackColumn100ChartHeight: StackColumn100ChartHeightDataSlice.reducer,
  WidgetResponse: WidgetResponseDataSlice.reducer,
  showOneWidgetResponse: showOneWidgetSlice.reducer,
  databaseSelectedTable: databaseSelectedTableSlice.reducer,
  SelectedWidgetId: SelectedWidgetIdSlice.reducer,
  SelectedWidgetUuid: selectedWidgetuuidSlice.reducer,
  ResetSelectedChart: ResetSelectedChartSlice.reducer,
  ClearCharts: ClearChartsSlice.reducer,
  selecteddChart: selecteddChartSlice.reducer,
  ImageChartSelection: ImageChartSelectionSlice.reducer,
  totalWidgetData: totalWidgetdataSlice.reducer,
  IdOfReport: responseReportIdSlice.reducer,
  fetchLogicalAttributes: fetchLogicalAttributesSlice.reducer,
  handleModalJoinOpen: handleModalJoinOpenSlice.reducer,
  reportByIdApiResponse: reportByIdApiResponseSlice.reducer,
  cellDataApi: cellDataApiSlice.reducer,
  focusModeClass: focusModeClassSlice.reducer,
  playgoundTabIndex: playgroundtabActiveSlice.reducer,
  removeCheckeditem: removeCheckeditemSlice.reducer,
  titleWidget: setTitleWidgetSlice.reducer,
});

export const { setData } = responseSlice.actions;
export const { updateObjectInArray } = responseSlice.actions;
export const { resetsetData } = responseSlice.actions;
export const { checkedKeysData } = responseSliceChecked.actions;
export const { draggedData } = responseSliceDragged.actions;
export const { checkedData } = responseSliceCheckedKeys.actions;
export const { ReportIdData } = responseSliceGetReportId.actions;
export const { resetReportIdData } = responseSliceGetReportId.actions;
export const { widgetIdResponseData } = responseSliceWidgetIdResponse.actions;
export const { resetwidgetIdResponseData } = responseSliceWidgetIdResponse.actions;
export const { clearCheckedData } = responseSliceClearCheckedKey.actions;
export const { previousDataWidgetData } = responseSlicepreviousDataWidget.actions;
export const { resetPreviousDataWidgetData } = responseSlicepreviousDataWidget.actions;
export const { WidgetIdClickedData } = responseSliceWidgetIdClicked.actions;
export const { resetWidgetResponseData } = WidgetResponseDataSlice.actions;
export const { resetWidgetIdClickedData } = responseSliceWidgetIdClicked.actions;
export const { resetUploadFileData } = responseSliceUploadFileData.actions;
export const { resetEmptyData } = responseSliceEmptyData.actions;
export const { ClusteredColumnChartIsFocusedData } = ClusteredColumnChartIsFocusedDataSlice.actions;
export const { ClusteredColumnChartWidthData } = ClusteredColumnChartWidthDataSlice.actions;
export const { ClusteredColumnChartHeightData } = ClusteredColumnChartHeightDataSlice.actions;
export const { AreaChartIsFocusedData } = AreaChartIsFocusedDataSlice.actions;
export const { AreaChartWidthData } = AreaChartWidthDataSlice.actions;
export const { AreaChartHeightData } = AreaChartHeightDataSlice.actions;
export const { LineChartIsFocusedData } = LineChartIsFocusedDataSlice.actions;
export const { LineChartWidthData } = LineChartWidthDataSlice.actions;
export const { LineChartHeightData } = LineChartHeightDataSlice.actions;
export const { PieChartIsFocusedData } = PieChartIsFocusedDataSlice.actions;
export const { PieChartWidthData } = PieChartWidthDataSlice.actions;
export const { PieChartHeightData } = PieChartHeightDataSlice.actions;
export const { DonutChartIsFocusedData } = DonutChartIsFocusedDataSlice.actions;
export const { DonutChartWidthData } = DonutChartWidthDataSlice.actions;
export const { DonutChartHeightData } = DonutChartHeightDataSlice.actions;
export const { StackVerticalChartIsFocusedData } = StackVerticalChartIsFocusedDataSlice.actions;
export const { StackVerticalChartWidthData } = StackVerticalChartWidthDataSlice.actions;
export const { StackVerticalChartHeightData } = StackVerticalChartHeightDataSlice.actions;
export const { StackColumnChartIsFocusedData } = StackColumnChartIsFocusedDataSlice.actions;
export const { StackColumnChartWidthData } = StackColumnChartWidthDataSlice.actions;
export const { StackColumnChartHeightData } = StackColumnChartHeightDataSlice.actions;
export const { StackVertical100ChartIsFocusedData } = StackVertical100ChartIsFocusedDataSlice.actions;
export const { StackVertical100ChartWidthData } = StackVertical100ChartWidthDataSlice.actions;
export const { StackVertical100ChartHeightData } = StackVertical100ChartHeightDataSlice.actions;
export const { StackColumn100ChartIsFocusedData } = StackColumn100ChartIsFocusedDataSlice.actions;
export const { StackColumn100ChartWidthData } = StackColumn100ChartWidthDataSlice.actions;
export const { StackColumn100ChartHeightData } = StackColumn100ChartHeightDataSlice.actions;
export const { WidgetResponseData } = WidgetResponseDataSlice.actions;
export const { showOneWidgetResponseData } = showOneWidgetSlice.actions;
export const { databaseSelectedTableData } = databaseSelectedTableSlice.actions;
export const { SelectedWidgetIdData } = SelectedWidgetIdSlice.actions;
export const { selectedWidgetUuidData } = selectedWidgetuuidSlice.actions;
export const { resetSelectedWidgetUuidData } = selectedWidgetuuidSlice.actions;
export const { resetSelectedChartData } = ResetSelectedChartSlice.actions;
export const { resetFalseSelectedChartData } = ResetSelectedChartSlice.actions;
export const { clearChartData} = ClearChartsSlice.actions;
export const { SelecteddChartData} = selecteddChartSlice.actions;
export const { resetSelecteddChartData} = selecteddChartSlice.actions;
export const { ImageChartSelectionData} = ImageChartSelectionSlice.actions;
export const { TotalWidgetDataa} = totalWidgetdataSlice.actions;
export const { IdOfReportData} = responseReportIdSlice.actions;
export const { ResetIdOfReportData} = responseReportIdSlice.actions;
export const { fetchLogicalAttributesData} = fetchLogicalAttributesSlice.actions;
export const { handleModalJoinOpenData} = handleModalJoinOpenSlice.actions;
export const { reportByIdApiResponseData} = reportByIdApiResponseSlice.actions;
export const { resetReportByIdApiResponseData} = reportByIdApiResponseSlice.actions;
export const { cellDataApiData} = cellDataApiSlice.actions;
export const { resetcellDataApiData} = cellDataApiSlice.actions;
export const { focusModeClassData} = focusModeClassSlice.actions;
export const { resetfocusModeClassData} = focusModeClassSlice.actions;
export const { playgroundTabIndexData} = playgroundtabActiveSlice.actions;
export const { resetPlaygroundTabIndexData} = playgroundtabActiveSlice.actions;
export const { removedCheckeditem} = removeCheckeditemSlice.actions;
export const { titleWidgetData} = setTitleWidgetSlice.actions;
export const { resetTitleWidgetData} = setTitleWidgetSlice.actions;


export default rootReducer;