import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { formatDate } from "../../../../directives/date-formate";

const MoreInfoDropdown = ({
  item,
  onDelete,
  onMoreInfo,
  isInfoVisible,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Dropdown className="d-flex justify-content-end">
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        <i className="fa-solid fa-ellipsis-vertical"></i>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
        <Dropdown.Item
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{ position: "relative" }}
          onClick={onMoreInfo}
        >
          More Info
          {isInfoVisible && (
            <div className="info-card">
              <div className="card-header">
                <h5>More Information</h5>
              </div>
              <div className="card-content1">
                <p>Title: Your Title</p>
                <p>Content: Your Content</p>
                <p>Created Date: {formatDate(item.createdDate)}</p>
              </div>
            </div>
          )}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MoreInfoDropdown;
