// ViewToggleButton.js
import React from "react";
import TooltipAnalytics from "../../../../component/toolTip/ToolTip";
const ViewToggleButton = ({ onCardView, onTableView }) => {
  return (
    <>
      <div className="position-relative">
        <TooltipAnalytics text="List View" className="BottomTextTooltip">
          <button
            className="btn btn-outline-secondary me-2"
            onClick={onCardView}
          >
            <i className="fa-solid fa-grip"></i>
          </button>
        </TooltipAnalytics>
      </div>
      <div className="position-relative">
        <TooltipAnalytics text="Table View" className="BottomTextTooltip">
          <button
            className="btn btn-outline-secondary me-2"
            onClick={onTableView}
          >
            <i className="fa-solid fa-list-ul"></i>
          </button>
        </TooltipAnalytics>
      </div>
    </>
  );
};

export default ViewToggleButton;
