// FileUploadButton.js
import React from "react";
import TooltipAnalytics from "../../../../component/toolTip/ToolTip";
const FileUploadButton = ({ onChange }) => {
  return (
    <div className="position-relative">
      <TooltipAnalytics text="Upload Files" className="BottomTextTooltip">
        <label htmlFor="folder" className="btn btn-outline-secondary me-2">
          <i className="fa-solid fa-file-circle-plus"></i>
          <input
            type="file"
            accept=".csv"
            id="folder"
            multiple
            onChange={onChange}
            className="file-inputCustomContent"
          />
        </label>
      </TooltipAnalytics>
    </div>
  );
};

export default FileUploadButton;
