import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getApiUrl } from "../url/UrlConfig";
import getWorkspaceId from "../../util/AttributeUtil";

const fetchLogicalResources = async () => {
  const userAuthToken = GetCookie('token');
  const workspaceId = await getWorkspaceId();
  if (workspaceId) {
    try {
      const lresponse = GetCookie('loginResponsecode');
      const parserOrgId = JSON.parse(lresponse);
      const orgId = parserOrgId.organizationId;
      const response = await fetch(
        getApiUrl() + apiUrls.logicalAttribute.getlogicalResource(workspaceId),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authToken: userAuthToken,
          },
        }
      );

      const data = await response.json();
      // Check if data is null
      if (data === null) {
        console.warn("Received null data from the server.");
        return [];
      }
      return data.response;
    } catch (error) {
      console.error("Failed to fetch logical resources:", error);
      return [];
    }
  }
};
export default fetchLogicalResources;