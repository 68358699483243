const GetCookie = (name) => {
    // Split the document.cookie string into individual cookie strings 
    const cookies = document.cookie.split(';');
    // Iterate over each cookie string 
    for (let cookie of cookies) {
        // Remove leading and trailing whitespace
        cookie = cookie.trim();
        // Check if the cookie starts with the specified name 
        if (cookie.startsWith(name + '=')) {
            // Extract and return the value of the cookie 
            return cookie.substring(name.length + 1);
        }
    }
    // Return null if the cookie with the specified name is not found
    return null;
};
export default GetCookie;