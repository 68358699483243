import { getApiUrl } from "../url/UrlConfig";
import { apiUrls } from "../endPoints/ApiUrls";
import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import { RESTMethod } from "../../enums/enum";
export default async function DeleteUploadedFileApi(payload) {
    const userAuthToken = GetCookie('token');
    try {
      const response = await fetch(
     getApiUrl()+apiUrls.uploadFiles.deleteUploadedFile,
        {
          method:RESTMethod.DELETE, 
          headers: {
            "Content-Type": "application/json",
            'authToken':userAuthToken
          },
          body: JSON.stringify(payload),
        }
      );  
      const result = await response.json();
      if (result.httpStatusCode=='200') {
        console.log("Report api deleted", result);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error, "Please Try Again");
      return false;
    }
  }