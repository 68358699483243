import { GoogleLogin,  } from '@react-oauth/google';
import {React } from 'react';
import { useNavigate } from 'react-router-dom'; 
const GoogleSignUp = ({ onLoginSuccess }) => {
    const navigate = useNavigate(); 
    const handleSuccess = (credentialResponse) => {
        onLoginSuccess(credentialResponse);
        navigate('/sign-up-with-google');
    };
    return (
         <GoogleLogin
        text="signup_with"
        context='signup'
        onSuccess={handleSuccess}
        onError={() => {
            'Login Failed';
        }}
        useOneTap={false}
        auto_select={false}
        size="large"
        shape="rectangular"
        logo_alignment="center"
        width="800"
    />
        
    )
}

export default GoogleSignUp;
