import React from "react";
import Swal from "sweetalert2";
import { BASE_URL } from "../endPoints/Endpoint";


export default async function SignUpApi(payload) {
    try {
      const response = await fetch(
     `${BASE_URL}/forms/signUp`,
        {
          method: "POST", 
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );  
      const result = await response.json();
      console.log("Form saved successfully:", result);
      if (result.httpStatusCode=='201') {
        Swal.fire({
          title: "Success!",
          text: "Thank you for your submission. Our team will be in touch with you shortly.",
          icon: "success",
          showConfirmButton: true,
          timer: 6000,
          allowOutsideClick: false,
          confirmButtonText: "Continue",
          customClass: {
            confirmButton: "NextButton", 
            },
        }).then((result) => {
          if (result.isConfirmed) {
            return true;
          }
        });
        return true;
      } else {
        Swal.fire({
          html: "<h4>Please try again</h4>",
        icon: "error",
          confirmButtonText: "Continue",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "NextButton", 
            },
        });
        return false;
      }
    } catch (error) {
      console.error(error, "Please Try Again");
      return false;
    }
  }