import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./WorkSpace.css";
import { logo } from "../../../constants/pictures/Pictures";
import { useFormik } from "formik";
import * as yup from "yup";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import WorkspaceApi from "../../../service/api/WorkspaceLoginApi";
import RemoveCookie from "../../../persistence/cookies/removeCookie/RemoveCookie";
import { icons } from "../../../constants/icons/Icons";
import { useSelector } from "react-redux";
import UpdateWorkspaceAPI from "../../../service/api/UpdateWorkspace";
import SetCookie from "../../../persistence/cookies/setCookie/SetCookie";
import GetAllUserWorksapce from "../../../service/api/GetAllUserWorkspaceApi";

const WorkSpace = () => {
  const navigate = useNavigate();
  const [createWorkspaceShow, setCreateWorkspaceShow] = useState(false);
  const [orgIdWorkspace, setOrgId] = useState('');
  const [totalWorkspaceAfterCreate, SetTotalWorkspaceAfterCreate] = useState([]);
  const [editWorkspaceId, setEditWorkspaceId] = useState(null);
  const [newWorkspaceName, setNewWorkspaceName] = useState("");

  const fetchGetAllUserWorkspace = async () => {
    try {
      const data = await GetAllUserWorksapce();
      if (data && data.response) {
        SetTotalWorkspaceAfterCreate(data.response);
      } else {
        SetTotalWorkspaceAfterCreate([]); 
      }
    } catch (error) {
      console.error('Error fetching user workspaces:', error);
      SetTotalWorkspaceAfterCreate([]);
    }
  };
  
  useEffect(() => {
    fetchGetAllUserWorkspace();
  }, []);
  

  useEffect(() => {
    if (totalWorkspaceAfterCreate) {
      totalWorkspaceAfterCreate?.forEach((item) => {
        setOrgId(item.organizationDTO.id);
      });
    }
  }, [totalWorkspaceAfterCreate]);

  const formik = useFormik({
    initialValues: {
      companyWorkspace: ""
    },
    validationSchema: yup.object().shape({
      companyWorkspace: yup.string().required("Please select workspace")
    }),
    onSubmit: async (values) => {
      const userAuthToken = GetCookie("token");
      const payloadworkspaceLogin = {
        id: values.companyWorkspace
      };
      const fetchDataWorkspaceLogin = async () => {
        const result = await WorkspaceApi(payloadworkspaceLogin);
        if (result === true) {
          navigate('/home');
        } else {
          if (userAuthToken) {
            navigate("/login");
          } else {
            window.location.href = `https://www.vivrn.com/login`;
          }
          RemoveCookie('token');
          RemoveCookie('userDTO');
          RemoveCookie('workSpaceDTO');
        }
      };
      fetchDataWorkspaceLogin();
    }
  });

  const updateWorkspaceApi = async (workspaceId, name) => {
    const lresponse = GetCookie('loginResponsecode');
    const parserOrgId = JSON.parse(lresponse)
    const orgId = parserOrgId?.organizationId;

    const updateWorkspacePayload = {
      id: workspaceId,
      name: name,
      organizationDTO: {
        id: orgId
      }
    };
    console.log(updateWorkspacePayload, " updateWorkspacePayload");
    await UpdateWorkspaceAPI(JSON.stringify(updateWorkspacePayload));
    // Refresh workspace list after update
    SetTotalWorkspaceAfterCreate(prev => prev.map(ws => ws.id === workspaceId ? { ...ws, name } : ws));
  };

  const handleWorkspaceNameChange = (e) => {
    if (e.key === "Enter") {
      updateWorkspaceApi(editWorkspaceId, newWorkspaceName);
      setEditWorkspaceId(null);
    }
  };

  const handleBlur = (workspaceId, name) => {
    if (editWorkspaceId && name !== newWorkspaceName) {
      updateWorkspaceApi(workspaceId, newWorkspaceName);
      setEditWorkspaceId(null);
    }
  };

  const handleSaveWorkspaceName = (workspaceId) => {
    updateWorkspaceApi(workspaceId, newWorkspaceName);
    setEditWorkspaceId(null);
  };

  const handleCancelEdit = (e) => {
    e.stopPropagation();
    setEditWorkspaceId(null);
    setNewWorkspaceName("");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 pt-4">
          <img src={logo} width={217} height={71} alt="Logo" />
        </div>
      </div>
      <div className="WorkSpaceContainer">
        <div className="row workSpaceRow">
          <div className="col-12">
            <p className="welcome-text mb-2">Welcome User Name!</p>
            <p className="your-workspace-text mb-2 mt2">Your workspaces</p>
            <div className="container-default">
              <form onSubmit={formik.handleSubmit}>
                {totalWorkspaceAfterCreate?.map((workspace) => (
                  <div
                    className="col-md-12 mt-3 position-relative d-flex justify-content-center"
                    key={workspace.id}
                    onClick={(e) => {
                      if (editWorkspaceId !== workspace.id) {
                        formik.setFieldValue('companyWorkspace', workspace.id);
                        SetCookie("selectedWorkspaceId", workspace.id);
                        formik.handleSubmit();
                      }
                    }}
                  >
                    <div className="logoWorkspace">
                      <img src={icons.favIconVivrn} alt="Vivrn Logo" />
                    </div>
                    {editWorkspaceId !== workspace.id && (
                      <input
                        type="radio"
                        id={workspace.id}
                        name="companyWorkspace"
                        value={workspace.id}
                        checked={formik.values.companyWorkspace === workspace.id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="radio-input"
                      />
                    )}
                    <label htmlFor={workspace.id}>
                      {editWorkspaceId === workspace.id ? (
                        <div className="edit-workspace-input">
                          <input
                            type="text"
                            value={newWorkspaceName}
                            onChange={(e) => setNewWorkspaceName(e.target.value)}
                            onKeyDown={handleWorkspaceNameChange}
                            onBlur={() => handleBlur(workspace.id, workspace.name)}
                            autoFocus
                          />
                          <div onClick={() => handleSaveWorkspaceName(workspace.id)} className="check-icon-div">
                            <i className="fa-solid fa-check ms-3"></i>
                          </div>
                          <div onClick={handleCancelEdit} className="cross-icon-div">
                            <i className="fa-solid fa-xmark ms-3"></i>
                          </div>
                        </div>
                      ) : (
                        <span onClick={(e) => e.stopPropagation()}>{workspace.name}</span>
                      )}
                    </label>
                    {editWorkspaceId !== workspace.id && (
                      <i
                        className="fa-solid fa-pen edit-workspace"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditWorkspaceId(workspace.id);
                          setNewWorkspaceName(workspace.name);
                        }}
                      ></i>
                    )}
                  </div>
                ))}
              </form>
              <div className="d-flex justify-content-center">
                <div className="separator-default">
                  <span className="line-default"></span>
                  <span className="or-default">OR</span>
                  <span className="line-default"></span>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button className="create-button-default" onClick={() => navigate('/create-workspace')}>
                  Create Workspace
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkSpace;
