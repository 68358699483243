import Swal from "sweetalert2";
import { BASE_URL } from "../endPoints/Endpoint";
export default async function LandingContactApi(payload) {
    try {
      const response = await fetch(
     `${BASE_URL}/forms/contactUs`,
        {
          method: "POST", 
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );  
      const result = await response.json();
      console.log("Form saved successfully:", result);
      // Display success message

      if (result.httpStatusCode=='201') {
        Swal.fire({
          title: "Success!",
          text: "Thank you for submitting",
          icon: "success",
          showConfirmButton: false,
          timer: 1000, 
          allowOutsideClick: false,
        })
        return true;
      } else {
        Swal.fire({
          html: "<h4>Please try again</h4>",
        icon: "error",
          confirmButtonText: "Continue",
          allowOutsideClick: false,
          customClass: {
            confirmButton: "NextButton",
            },
        });
        return false;
      }
    } catch (error) {
      console.error(error, "Please Try Again");
      return false;
    }
  }