import React, { useEffect, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './OwlCarousel.css';
import { sliderImage1, sliderImage2, sliderImage3, sliderImage4 } from '../../../constants/pictures/Pictures';

const OwlCarousel = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0);
    }
  }, []);

  const settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    focusOnSelect: true,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="wrapper">
      <Slider ref={sliderRef} {...settings}>
        <div><img src={sliderImage1} alt="Slide 1" /></div>
        <div><img src={sliderImage2} alt="Slide 2" /></div>
        <div><img src={sliderImage3} alt="Slide 2" /></div>
        <div><img src={sliderImage4} alt="Slide 2" /></div>
      </Slider>
    </div>
  );
};

export default OwlCarousel;
