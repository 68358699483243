import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { titleWidgetData } from "../../../redux/reduxToolkit/Reducer";

function EditTitleModal({ show, handleClose, chartTitle, saveTitle }) {
    const [newTitle, setNewTitle] = useState(chartTitle);
    console.log(newTitle, "newTitle");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(titleWidgetData(newTitle));
    }, [newTitle])

    const handleSave = () => {
        saveTitle(newTitle);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Chart Title</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formChartTitle">
                        <Form.Label>Chart Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={newTitle}
                            onChange={(e) => setNewTitle(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditTitleModal;
