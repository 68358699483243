import React, { useEffect, useState } from "react";
import { enumDataType } from "../../../enums/enum";
import {
  datatypeNumber,
  datatypeString,
  dateDataType,
} from "../../../constants/pictures/Pictures";
import { useDispatch } from "react-redux";

const TableColumn = (props) => {
  const [refresh, setRefresh] = useState(false);
  const { records, columnSchemaList } = props.data || {};
  const selectedTableName = props.selectedTableName;
  const updatedSelectedData = {
    existingData: props.data,
    selectedTableName: selectedTableName,
  };

  const [refreshTable, setRefreshTable] = useState(false);

  useEffect(() => {
    props.updateApi(refreshTable);
  }, [refreshTable]);

  useEffect(() => {
    props.updateSelectedData(updatedSelectedData);
    setRefresh(true);
  }, [refresh]);

  return (
    <div className="TableColumnContainer">
      {columnSchemaList && columnSchemaList.length > 0 ? (
        columnSchemaList.map((column, index) => (
          <div key={index} className="headingth">
            {column.displayType === enumDataType.NUMBER ? (
              <img src={datatypeNumber} className="ms-1" alt="Number" />
            ) : column.displayType === enumDataType.TEXT ? (
              <img src={datatypeString} className="ms-1" alt="String" />
            ) : (
              <img src={dateDataType} className="ms-1" alt="DATE" />
            )}
            {column.columnName}
          </div>
        ))
      ) : (
        <div className="no-data-found">No data found</div>
      )}
    </div>
  );
};

export default TableColumn;
