import React from "react";
import { LogoVivrnWhite, logo } from "../../../constants/pictures/Pictures";
import "./WorkSpace.css";

const AddTeam = ({ formik, handleChange, value, errors, touched, onAddEmailField, onSkip }) => {
  return (
    <>

      <p className="welcome-text mb-2">Add Your Team</p>
      <div className="container-default">
        <div className="add-team-container">
          {value.emails.map((email, index) => (
            <input
              key={index}
              type="email"
              name={`emails[${index}]`}
              value={value.email}
              onChange={handleChange}
              placeholder="Add Email"
              className="email-input"
            />
          ))}
          {touched.emails && errors.emails && (
            <div className="ErrorMessage">{errors.emails}</div>
          )}
          <button
            type="button"
            onClick={onAddEmailField}
            className="add-email-button"
          >
            Add Email
          </button>
          {/* <button type="button" onClick={formik.handleSubmit} className="next-button">
                    Next
                  </button> */}
          <button type="button" onClick={onSkip} className="skip-link">
            Skip for Now
          </button>
        </div>
      </div>
    </>
  );
};

export default AddTeam;
