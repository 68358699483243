import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import LogIn from "./pages/login/normalLogin/Login";
import Home from "./pages/home/Home";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import NewPassword from "./pages/newPassword/NewPassword";
import Register from "./notUsedFiles/register/Register";
import Verification from "./pages/verification/Verification";
import PrepareData from "./pages/prepareData/PrepareData";
import { UploadData } from "./pages/prepareData/myContent/uploadData/UploadData";
import ConnectWireHouse from "./pages/dataWarehouse/ConnectWireHouse";
import FileReport from "./pages/uploadFilesReport/FileReport";
import ContentTab from "./pages/content/ContentTab";
import Upload from "./pages/upload/Upload";
import Recent from "./pages/recent/Recent";
import DashBoard from "./pages/dashboard/Dashboard";
import RowColumnSummary from "./pages/upload/summary/RowColumnSummary";
import Landing from "./pages/landing/Landing";
import SuperAdminComponent from "./pages/user/superAdmin/SuperAdmin";
import AllUser from "./pages/user/allUser/AllUser";
import Protected from "./navigation/ProtectedRoutes";
import SignUp from "./pages/landing/SignUp";
import Trash from "./pages/trash/Trash";
import Analytics from "./pages/analytics/Analytics";
import Collabration from "./pages/collaboration/Collabration";
import NotFound from "./component/pageNotFound/notFound";
import React, { useEffect } from "react";
import CreateReport from "./pages/createReport/CreateReport.component.js";
import RoutesEnum from "./dtos/RouteType";
import GetCookie from "./persistence/cookies/getCookie/GetCookie";
import SignUpToday from "./pages/register/signUp/SignUpToday";
import VerifyEmailToActivate from "./pages/register/signUp/VerifyEmailToActivate";
import SignUpAccount from "./pages/register/signUp/SetUpAccount";
import WorkSpace from "./pages/login/workspaceLogin/WorkSpace";
import LoginAccountVerify from "./pages/register/signUp/FirstTimeLoginByEmailVerify";
import SignUpWithGoogleStepForm from "./pages/signUp/SignUpWithGoogleStepForm";
import QueryPromptDetails from "./pages/home/queryPrompt/QueryPromptDetails";
import CreateWorkSpace from "./pages/login/workspaceLogin/CreateWorkSpace";
import AddTeam from "./pages/login/workspaceLogin/AddTeam";
import StepCreateWorkSpace from "./pages/login/workspaceLogin/StepCreateWorkspace";
import WorkSapceNewuser from "./pages/login/workspaceLogin/WorkspaceNewUser";
import SignUpAccountForNewUser from "./pages/login/workspaceLogin/SetupAccountForNewUser";

export default function App() {
  const token = GetCookie('token');
  const loginResponse = GetCookie('loginResponsecode');
  useEffect(() => {
    if (token && loginResponse) {
      const parsedResponse = JSON.parse(loginResponse);
      const organizationUrl = parsedResponse.organizationUrl;
      let subdomain = null;
      
      if (organizationUrl) {
        const parts = organizationUrl.split('.');
        if (parts.length >= 3) {
          subdomain = parts[0];
          parts.splice(0, 1);
        }
      }
      
      console.log(subdomain, "subdomain");
  
      if (window.location.hostname !== "localhost") {
        if (window.location.hostname !== `${subdomain}.vivrn.com`) {
          const redirectUrl = `https://${subdomain}.vivrn.com${window.location.pathname}${window.location.search}${window.location.hash}`;
          window.location.href = redirectUrl;
        }
      }
    }
  }, []);
  

  return (
    <Router>
      <Routes>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          element={<Landing />}
        />
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.LOGIN}`} element={<LogIn />} />

        <Route
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.FORGOT_PASSWORD}`}
          element={<ForgotPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.NEW_PASSWORD}`}
          element={<NewPassword />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.REGISTER}`}
          element={<Register />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.VERIFICATION}`}
          element={<Verification />}
        />
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.SIGNUP}`} element={<SignUp />} />
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.SIGNUPTODAY}`} element={<SignUpToday/>} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.SET_UP_ACCOUNT}`}
          element={ <SignUpAccount />}
        />
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.VERIFYEMAILTOACTIVATE}`} element={<VerifyEmailToActivate />} />
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.SET_UP_ACCOUNT}`} element={<SignUpAccount />}/>
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.SET_UP_ACCOUNT_FOR_NEW_USER}`} element={<SignUpAccountForNewUser />}/>
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.SIGN_UP_WITH_GOOGLE}`} element={<SignUpWithGoogleStepForm />}/>

        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.LOGIN_ACCOUNT_VERIFY}`} element={<LoginAccountVerify />}/>

        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.WORKSPACE_NEW_USER}`} element={<WorkSapceNewuser/>}/>
        
         
        {/* protected routes */}
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.WORKSPACE}`} element={<Protected Cmp={WorkSpace} />}/>
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.CREATE_WORKSPACE}`} element={<Protected Cmp={CreateWorkSpace} />}/>
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.ADD_TEAM}`} element={<Protected Cmp={AddTeam} />}/>
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.CREATEWORKSPACE}`} element={<Protected Cmp={StepCreateWorkSpace} />}/>
        <Route path={`${process.env.PUBLIC_URL}/${RoutesEnum.HOME}`} element={<Protected Cmp={Home} />} />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.PREPARE_DATA}`}
          element={<Protected Cmp={PrepareData} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.CONNECT_WIREHOUSE}`}
          element={<Protected Cmp={ConnectWireHouse} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.UPLOAD_DATA}`}
          element={<Protected Cmp={UploadData} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.DASHBOARD}`}
          element={<Protected Cmp={DashBoard} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.FILE_REPORT}`}
          element={<Protected Cmp={FileReport} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.CONTENT_TAB}`}
          element={<Protected Cmp={ContentTab} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.UPLOAD}`}
          element={<Protected Cmp={Upload} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.CONNECT_WIRE_HOUSE}`}
          element={<Protected Cmp={ConnectWireHouse} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.RECENT}`}
          element={<Protected Cmp={Recent} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.ROW_COLUMN_SUMMARY}`}
          element={<Protected Cmp={RowColumnSummary} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.SUPER}`}
          element={<Protected Cmp={SuperAdminComponent} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.ALL_USER}`}
          element={<Protected Cmp={AllUser} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.CREATE_REPORT}`}
          element={<Protected Cmp={CreateReport} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.CREATE_REPORT}/:cardId/:show`}
          element={<Protected Cmp={CreateReport} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.TRASH}`}
          element={<Protected Cmp={Trash} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.ANALYTICS}`}
          element={<Protected Cmp={Analytics} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.COLLAB}`}
          element={<Protected Cmp={Collabration} />}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/${RoutesEnum.QUERYPROMPTSDETAILS}`}
          element={<Protected Cmp={QueryPromptDetails} />}
        />
        <Route
          path={`${process.env.PUBLIC_URL}/*`}
          element={<NotFound />}
        />
      </Routes>


    </Router>
  );
}

