import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import 'highcharts/css/highcharts.css';
import { useDispatch } from 'react-redux';
import { focusModeClassData, resetfocusModeClassData } from '../../../redux/reduxToolkit/Reducer';
// Initialize exporting modules
exportingInit(Highcharts);
exportDataInit(Highcharts);
export default function AreaDoubleMain(props) {
  const chartRef = useRef(null);
  const [fetchData, setFetchData] = useState(props?.responseData?.responseData?.data);
  useEffect(() => {
      setFetchData(props?.responseData?.responseData?.data);
  }, [props,fetchData]);
  // const fetchData = {
  //   "John": [
  //     {
  //       metric: {
  //         "ID": 100,
  //         "Marks": 50,
  //         "Age": 100
  //       },
  //       sgb: "Doe"
  //     },
  //     {
  //       metric: {
  //         "ID": 100,
  //         "Marks": 50,
  //         "Age": 100
  //       },
  //       sgb: "abc"
  //     },
  //     {
  //       metric: {
  //         "ID": 100,
  //         "Marks": 50,
  //         "Age": 100
  //       },
  //       sgb: "def"
  //     }
  //   ],
  //   'JSF': [
  //     {
  //       metric: {
  //         "ID": 200,
  //         "Marks": 100,
  //         "Age": 500
  //       },
  //       sgb: "Smith"
  //     }
  //   ],
  // };
  const [getFocus, setGetFocus] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
      if (getFocus) {
          dispatch(focusModeClassData(props?.responseData?.responseData));
      }else{
          dispatch(resetfocusModeClassData());
      }
  }, [getFocus,props?.responseData?.responseData]);
  const toggleFocusMode = () => {
    setGetFocus(prev => !prev);
};
  const categories = Object.values(fetchData).flatMap((data) => data.map((item) => `${item.sgb}<br/>(${Object.keys(fetchData).find(key => fetchData[key] === data)})`));
  const seriesData =
  fetchData[Object.keys(fetchData)[0]][0]?.metric &&
    Object.keys(fetchData[Object.keys(fetchData)[0]][0].metric).map((metricName) => ({
      name: metricName,
      data: Object.values(fetchData).flatMap((data) =>
        data.map((item) => item.metric[metricName])
      ),
    }));

  const options = {
    chart: {
      type: 'area',
      height: '100%',
    },
    credits: {
      enabled: false
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: 'Metric Values',
      },
    },
    series: seriesData,
    plotOptions: {
      area: {
        stacking: 'normal', // Adjust stacking if needed
      },
    },
    exporting: {
      buttons: {
          contextButton: {
              menuItems: [
                  "viewFullscreen",
                  "printChart",
                  "separator",
                  "downloadPNG",
                  "downloadJPEG",
                  "downloadPDF",
                  "downloadSVG",
                  "customItem" // Add a reference to the custom item
              ],
              menuItemDefinitions: {
                  customItem: {
                      text: getFocus ? 
                      '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
                      '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
                      onclick: toggleFocusMode,
                      useHTML: true // Enable HTML rendering for the menu item text
                  }
              }
          },
          customButton: {
              text: getFocus ? 
              '<i class="fa-solid fa-down-left-and-up-right-to-center"></i>': 
              '<i class="fa-solid fa-up-right-and-down-left-from-center"></i>',
              onclick: toggleFocusMode,
              align: 'right',
              verticalAlign: 'top',
              x: -30,
              y: 5,
              useHTML: true // Enable HTML rendering for the button text
          }
      }
  }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.redraw();
    }
  }, [fetchData]);

  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
    </>
  );
}
