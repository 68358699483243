import GetCookie from "../../persistence/cookies/getCookie/GetCookie";
import SetCookie from "../../persistence/cookies/setCookie/SetCookie";
import { apiUrls } from "../endPoints/ApiUrls";
import { getSignUp } from "../url/UrlConfig";

export default async function verifyInvitation(payload) {

  try {
    const response = await fetch(getSignUp() + apiUrls.signUp.verifyInvitation, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const result = await response.json();
    if (result.httpStatusCode == '200') {
    //   const token = result.response;
    //   // localStorage.setItem("token", token);
    //   const authToken = token?.authToken;
    //   SetCookie('token', authToken);
    //   // localStorage.setItem("token", jsonString);
    //   console.log("Login Successfully", result);
      return result;
    }
    else {
      return null;
    }
  } catch (error) {
    console.error(error.message || error);
    return false;
  }
}
