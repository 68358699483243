import React, { useState } from "react";
import ClusteredColumnDoubleMain from "./ClusteredColumnDoubleMain";
import ClusteredColumnDouble from "./ClusteredColumnDouble";

const ClusteredColumnDoubleToggle = (responseData) => {
  const [showMainChart, setShowMainChart] = useState(true);
  console.log(responseData, "responseData toggle");

  const toggleChart = () => {
    setShowMainChart((prev) => !prev);
  };

  return (
    <div>
      <button onClick={toggleChart}>
        {showMainChart ? "Lower hierarchy" : "Upper hierarchy"}
      </button>
      {showMainChart ? (
        <ClusteredColumnDoubleMain responseData={responseData} />
      ) : (
        <ClusteredColumnDouble responseData={responseData} />
      )}
    </div>
  );
};

export default ClusteredColumnDoubleToggle;
