import React, { useState, useEffect } from "react";
import "./SidebarContent2.css";
// store.js
import { useDispatch } from "react-redux";
import {
  clearChartData,
  resetTitleWidgetData,
  setData,
  showOneWidgetResponseData,
} from "../../../redux/reduxToolkit/Reducer";
import { useSelector } from "react-redux";
import { enumChartTypes } from "../../../enums/enum";
import ChartSelection from "../playground/ChartSelection.component";
import AxisConfiguration from "../playground/AxisConfig.component";
import GetCookie from "../../../persistence/cookies/getCookie/GetCookie";
import VisualizeWidgetApi from "../../../service/api/GetVisualizationApi";
import FilterReport from "./FilterReport";

export default function SidebarContent2({
  updateChartUserDesire,
  updatedApiChartType,
  updateSelectedCharts,
  projectName,
  receivedData,
  updateChartsData,
  receivedOperation,
  receivedOperationString,
  receivedUserInputValue,
  receivedOperatorValue,
  setVisualizeWidgetShow,
  setDefaultGraph,
}) {
  const [chartData, setChartData] = useState([]);
  const date = new Date().getTime();
  const lresponse = GetCookie("loginResponsecode");
  const parserOrgId = JSON.parse(lresponse);
  const orgId = parserOrgId?.organizationId;
  const userId = parserOrgId?.userId;
  const userJSON = localStorage.getItem("logicaluserId");
  const user = JSON.parse(userJSON);
  console.log(user, "userTable")
  const logicalUserId = user?.id;
  const PhyscicalResourceDtoId = user?.physicalResourceDTO?.id;
  const dispatch = useDispatch();
  //received checkedKeys
  const checkedKeysData = useSelector((state) => state.checked);
  const updateCheckedKeys = useSelector((state) => state.checkedkey);
  console.log(updateCheckedKeys, "updateCheckedKeys");
  const draggedItem = useSelector((state) => state.dragged);
  const [selectedCharts, setSelectedCharts] = useState("");
  useEffect(() => {
    updateChartUserDesire(selectedCharts);
  }, [selectedCharts]);
  useEffect(() => {
    if (Array.isArray(checkedKeysData) && checkedKeysData.length > 0) {
      let hasTextDataType = false;
      for (let i = 0; i < checkedKeysData.length; i++) {
        if (
          checkedKeysData[i].logicalDataType == "TEXT" ||
          checkedKeysData[i].logicalDataType == "DATE"
        ) {
          console.log(checkedKeysData[i].logicalDataType, "datatype");
          hasTextDataType = true;
          console.log("find out");
          break;
        }
      }

      if (hasTextDataType) {
        console.log("Setting selectedCharts to CLUSTERED_COLUMN");
        setSelectedCharts(enumChartTypes.CLUSTERED_COLUMN);
      } else {
        console.log("Setting selectedCharts to TEXT");
        setSelectedCharts(enumChartTypes.TEXT);
      }
    }
  }, [checkedKeysData]);
  const setOneWidgettt = useSelector((state) => state.showOneWidgetResponse);
  const [showOneWidget, setShowOneWidget] = useState(setOneWidgettt);
  console.log(showOneWidget, "trueorfalse");
  console.log(setOneWidgettt, "setOneWidgettttrue");
  useEffect(() => {
    setShowOneWidget(setOneWidgettt);
  }, [setOneWidgettt]);
  const selectedWidget = useSelector((state) => state.SelectedWidgetId);
  console.log(selectedWidget, "selectedWidgettt");

  useEffect(() => {
    updatedApiChartType(selectedCharts);
    updateSelectedCharts(selectedCharts);
    updateChartsData(chartData);
  }, [selectedCharts, updateSelectedCharts, chartData, updateChartsData]);

  const [droppedItems, setDroppedItems] = useState([]);
  const SelectedWidgetUuid = useSelector((state) => state.SelectedWidgetUuid);

  const fetchLogicalAttributes = useSelector(
    (state) => state.fetchLogicalAttributes
  );
  const selectedTabIndexPlaygrond = useSelector(
    (state) => state.playgoundTabIndex
  );
  const widgetNameUpdate = useSelector(
    (state) => state.titleWidget
  );
  console.log(fetchLogicalAttributes, "fetchLogicalAttributessss");
  const [responsedataa, setResponseData] = useState("");
  const response = fetchLogicalAttributes;
  console.log(
    response[0]?.logicalResourceDTO.physicalResourceDTO.dataSourceDTO,
    "fetchLogicalAttributes"
  );
  useEffect(() => {
    if (response) {
      setResponseData(
        response[0]?.logicalResourceDTO.physicalResourceDTO.dataSourceDTO
      );
    }
  }, [response]);
  console.log(responsedataa, "responsedataa")
  const visualizationWidget = () => {
    const newPayload = {
      name: widgetNameUpdate,
      description: "start",
      createdDate: date,
      chartType: selectedCharts,
      uuid: SelectedWidgetUuid ? SelectedWidgetUuid : null,
      layout: {
        tabIndex: selectedTabIndexPlaygrond
      },
      createdBy: {
        id: userId,
      },
      organizationDTO: {
        id: orgId,
      },
      metricDTOS: checkedKeysData
        ?.filter(
          (dataItem) =>
            dataItem?.logicalDataType === "Long" ||
            dataItem?.logicalDataType === "NUMBER" ||
            dataItem?.logicalDataType === "DECIMAL"
        )
        ?.map((dataItem) => {
          let operation = "";
          switch (true) {
            case operation === "COUNT (DISTINCT)":
              return "DISTINCT_COUNT";
          }
          if (
            dataItem?.logicalDataType === "String" ||
            dataItem?.logicalDataType === "TEXT"
          ) {
            operation = receivedOperationString?.[dataItem?.label]
              ? receivedOperationString?.[dataItem?.label]
              : "ACTUAL_VALUE";
          } else if (
            dataItem?.logicalDataType === "Long" ||
            dataItem?.logicalDataType === "NUMBER" ||
            dataItem?.logicalDataType === "DECIMAL"
          ) {
            operation = receivedOperation?.[dataItem?.label]
              ? receivedOperation?.[dataItem?.label]
              : "SUM";
          } else if (dataItem?.logicalDataType === "DATE") {
            operation = receivedOperationString?.[dataItem?.label]
              ? receivedOperationString?.[dataItem?.label]
              : "ACTUAL_VALUE";
          }
          let aggregateFunction;

          switch (true) {
            case operation === "Distint Count":
              aggregateFunction = "DISTINCT_COUNT";
              break;
            // Add more cases for other operations as needed
            case operation === "Average":
              aggregateFunction = "AVERAGE";
              break;
            case operation === "Max":
              aggregateFunction = "MAX";
              break;
            case operation === "Min":
              aggregateFunction = "MIN";
              break;
            case operation === "Count":
              aggregateFunction = "COUNT";
              break;
            case operation === "Sum":
              aggregateFunction = "SUM";
              break;
            default:
              aggregateFunction = operation;
          }

          return {
            createdDate: date,
            selectColumn: {
              id: dataItem?.id,
              label: dataItem?.label,
              logicalDataType: dataItem?.logicalDataType,
            },
            logicalResourceDTO: {
              id: logicalUserId,
            },
            aggregateFunction: aggregateFunction,
          };
        }),
      widgetType: "CUSTOMIZED",
      logicalResourceDTOS: [
        {
          id: logicalUserId,
          physicalResourceDTO: {
            id: PhyscicalResourceDtoId,
            dataSourceDTO: responsedataa,
          },
        },
      ],
      groupByMapDTOS: checkedKeysData
        .filter(
          (dataItem) =>
            dataItem?.logicalDataType === "String" ||
            dataItem?.logicalDataType === "TEXT" ||
            dataItem?.logicalDataType === "DATE"
        )
        .map((dataItem) => ({
          createdDate: date,
          logicalAttributeDTO: {
            id: dataItem?.id,
            label: dataItem?.label,
            logicalDataType: dataItem?.logicalDataType,
          },
        })),
      ...(Object.keys(receivedOperatorValue).length > 0
        ? {
          filterDTOS: checkedKeysData?.map((dataItem, index) => {
            const operatorKey = dataItem?.label;
            const operator = receivedOperatorValue?.[operatorKey] || "";
            const userInputValue = receivedUserInputValue?.[operatorKey];
            const arrayJoin = userInputValue?.join(":");
            return {
              logicalAttributeDTO: {
                id: dataItem?.id,
                label: dataItem?.label,
                logicalDataType: dataItem?.logicalDataType,
              },
              operator: operator,
              organizationDTO: {
                id: orgId,
              },
              createdDate: date,
              value: arrayJoin,
            };
          }),
        }
        : {}),
    };
    const uniqueObjectsSet = new Set();
    const uniqueArray = [];
    VisualizeWidgetApi(newPayload)
      .then((data) => {
        dispatch(showOneWidgetResponseData(false));
        dispatch(clearChartData(false));
        dispatch(setData(data));
        setDefaultGraph("ClusteredColumnChart");
        dispatch(resetTitleWidgetData());
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    setVisualizeWidgetShow(true);
  };

  const handleChartSelection = (chart) => {
    setSelectedCharts(chart);
    console.log(chart, "charthandle");
  };

  const onDrop = (droppedItem) => {
    const isDuplicate = droppedItems.some((item) => {
      return item.id === droppedItem.id;
    });
    if (!isDuplicate) {
      setDroppedItems((prevItems) => [...prevItems, droppedItem]);
    }
  };

  useEffect(() => {
    const handleDrop = (event) => {
      updateCheckedKeys();
      event.preventDefault();
      if (draggedItem) {
        onDrop(draggedItem);
      }
    };

    // Add event listeners for drag-and-drop
    document.addEventListener("drop", handleDrop);
    document.addEventListener("dragover", (event) => event.preventDefault());

    return () => {
      // Remove event listeners when the component unmounts
      document.removeEventListener("drop", handleDrop);
      document.removeEventListener("dragover", (event) =>
        event.preventDefault()
      );
    };
  }, [onDrop, draggedItem]);

  const labelsToRemove = receivedData
    .filter((item) => !item.label)
    .map((item) => item.labelToRemove);

  // Remove items with labels in labelsToRemove from droppedItems
  const updatedDroppedItems = droppedItems.filter(
    (item) => !labelsToRemove.includes(item.label)
  );

  // Combine items from updatedDroppedItems and receivedData
  const combinedData = [...updatedDroppedItems, ...receivedData];

  // Create an object to group items by dataType
  let groupedData = combinedData.reduce((acc, item) => {
    if (!acc[item.logicalDataType]) {
      acc[item.logicalDataType] = [];
    }
    if (
      !acc[item.logicalDataType].find(
        (existingItem) => existingItem.label === item.label
      )
    ) {
      acc[item.logicalDataType].push(item);
    }
    return acc;
  }, {});

  useEffect(() => {
    groupedData = {};
  }, [groupedData]);

  return (
    <>
      <ChartSelection handleChartSelection={handleChartSelection} />
      <AxisConfiguration
        groupedData={groupedData}
        changeName={selectedCharts}
        receivedOperation={receivedOperation}

      />
      <FilterReport />
      {showOneWidget && checkedKeysData.length > 0 && (
        <div>
          <button
            className="btn btn-sm-secondary"
            type="submit"
            onClick={() => {
              visualizationWidget();
            }}
          >
            {" "}
            Widget
          </button>
        </div>
      )}
    </>
  );
}
